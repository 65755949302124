import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { MallList, mallListSearch } from "../../../store/vendorslice";
import { imagePath } from "../../../functions/URLString";
import Loader from "../../Common/Loader";

export default function JoinMallList() {
  const { data, loading, message } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (search){
      dispatch(mallListSearch(search))
    }
    else{
      dispatch(MallList());
    }
  }, [search]);
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Join Mall",
          },
        ]}
      />

      {/* search */}
      <div className="flex justify-between gap-5">
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full  px-8 py-2 md:py-3 text-xs md:text-sm rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px]"
            type="search"
            placeholder="Search Malls..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      {/* table */}

      <div className="flex justify-center border-gray-500 overflow-auto ">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[800px] md:min-w-[768px] lg:min-w-full">
            {/* Table Header */}

            <div className="flex  items-center justify-between gap-2 p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
              <span className="text-left w-[25%]">MALL</span>

              <span className="text-left w-[15%]">STORE COUNT</span>

              <span className="  text-left w-[25%]">LOCATION</span>

              <span className=" text-left w-[10%] ">ACTION</span>
            </div>

            {/* Table Data Row */}

            {loading ? (
              <div className="flex justify-center py-4">
                <Loader />
              </div>
            ) : data?.data?.length > 0 ? (
              data?.data?.map((mall) => {
                return (
                  <div
                    // key={item.id}
                    className="flex w-full justify-between items-center  p-4 border-t  font-semibold text-sm "
                  >
                    <span className="flex text-left  items-center gap-4 w-[25%]">
                      <span className="">
                        <img
                          src={mall.image}
                          alt=""
                          className="w-10 h-10 rounded-lg"
                        />
                      </span>
                      <span className="text-sm font-semibold text-[#18120F]  ">
                        {mall.mall_name}
                      </span>
                    </span>
                    <span className="w-[15%] text-left">{mall.active_vendor_count}</span>

                    <span className="w-[25%]  text-nowrap text-left">
                      {" "}
                      {mall.city + ", " + mall.country}
                    </span>

                    <span className="w-[10%] text-left">
                      <Link
                        to={"/vendor/my-shop/join-mall/view-mall/" + mall.id}
                      >
                        <img
                          src="/assets/icons/view.svg"
                          alt=""
                          className="w-9 h-9"
                        />
                      </Link>
                    </span>
                  </div>
                );
              })
            ) : (
              <div className="text-center font-bold tracking-wide py-4">
                No Malls Found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
