import React from "react";

import { FaRegHeart } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { MdVerified } from "react-icons/md";

import "./number-input.css";
import { useState } from "react";

export default function VendorOfferModal() {
  const [isEntireStore, setIsEntireStore] = useState(null);

  return (
    <div className="w-full flex flex-col gap-3 md:gap-6">
      {/* store info  */}

      <div className="w-full flex flex-col md:flex-row justify-between items-start gap-6 md:gap-12">
        {/* img  */}
        <div className="flex items-center gap-8">
          <span>
            <img
              src="/assets/images/vendor.png"
              alt=""
              className="w-20 h-20 rounded-full"
            />
          </span>

          {/* store name  */}
          <div className="flex flex-col items-start gap-1">
            <span className="flex gap-1 items-center font-medium text-2xl">
              Khan Baba{" "}
              <span>
                <MdVerified className="text-blue-600" />
              </span>
            </span>
            <div className="flex gap-5">
              <span className="flex flex-col items-center gap-1">
                <FaRegHeart className="w-6 h-6" />
                88k
              </span>

              <span className="flex flex-col items-center gap-1">
                <LuUser2 className="w-6 h-6" />
                88k
              </span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-fit flex justify-end">
          <button className="ml-4 border border-[#C19A6B] py-[4.5px] px-[22px] rounded-[4.5px] text-lg font-medium text-[#C19A6B] ">
            Visit Store
          </button>
        </div>
      </div>

      <div className="flex flex-col border border-[#EBF0ED] py-6 px-4 rounded-xl gap-4">
        {/* offer  */}
        <div className="flex flex-col gap-2">
          <p className="font-medium text-lg">Offer</p>

          <div className="flex flex-col gap-2">
            <span className="font-medium text-xs">Entire Store Products</span>
            <div className="flex items-center gap-6">
              <span className="flex items-center gap-2">
                <input
                  type="radio"
                  name=""
                  id=""
                  checked={isEntireStore === "yes"}
                  onChange={() => setIsEntireStore("yes")}
                  className="w-4 h-4"
                />
                <label htmlFor="" className="text-sm text-[#828282]">
                  Yes
                </label>
              </span>
              <span className="flex items-center gap-2">
                <input
                  type="radio"
                  name=""
                  id=""
                  checked={isEntireStore === "no"}
                  onChange={() => setIsEntireStore("no")}
                  className="w-4 h-4"
                />
                <label htmlFor="" className="text-sm text-[#828282]">
                  No
                </label>
              </span>
            </div>
          </div>
        </div>

        {/* number of products  */}

        <div className="flex flex-col gap-1 numberInput">
          <label htmlFor="" className="font-medium text-xs ">
            Number of Products
          </label>
          <input
            type="number"
            placeholder="Enter Number of Products"
            className="border border-[#EBF0ED] text-xs outline-none focus:border-[#d4d5d4] p-3 rounded-lg bg-[#FAFAFA] disabled:cursor-not-allowed"
            disabled={isEntireStore === "yes"}
          />
        </div>

        {/* percentage  */}
        <div className="flex flex-col gap-1 numberInput">
          <label htmlFor="" className="font-medium text-xs ">
            Percentage
          </label>
          <input
            type="number"
            placeholder="Enter Percentage between 0-50"
            min={0}
            max={50}
            className="border border-[#EBF0ED] text-xs outline-none focus:border-[#d4d5d4] p-3 rounded-lg bg-[#FAFAFA]"
          />
        </div>
      </div>

      {/* button  */}
      <div className="w-full flex gap-4 justify-end">
        <button
          onClick={() => document.getElementById("my_modal_3").close()}
          className="py-[10px] px-9 rounded-lg  text-[#C19A6B] border border-[#C19A6B] "
        >
          Close
        </button>

        <button className="py-[10px] px-9 rounded-lg text-white bg-[#C19A6B] ">
          Send Offer
        </button>
      </div>
    </div>
  );
}
