import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ReplyModal from "./ReplyModal";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { manageReviews } from "../../../../store/vendorslice";
import Loader from "../../../Common/Loader";

export default function ManageReviews() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);
  const [selectedReview, setSelectedReview] = useState({
    rating_review_id: null,
    description: "",
  });

  useEffect(() => {
    dispatch(manageReviews({}));
  }, []);


  const generateStarRating = (rating) => {
    const stars = [];
    const filledStar = "/assets/icons/filled-star.svg";
    const unfilledStar = "/assets/icons/unfilled-star.svg";

    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
        );
      } else {
        stars.push(
          <img
            key={i}
            src={unfilledStar}
            alt="unfilled star"
            className="w-4 h-4"
          />
        );
      }
    }
    return stars;
  };

  const handleOpenModal = (productId, reviewId) => {
    setSelectedReview({ productId, reviewId });
    document.getElementById("my_modal_4").showModal();
  };

  return (
    <>
      <div className="md:px-4 w-full flex flex-col gap-4">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Orders & Reviews",
              path: "/vendor/my-shop/orders-management",
            },
            {
              name: "Manage Reviews",
            },
          ]}
        />

        {/* search */}
        {/* <div className="flex items-center justify-between gap-4 md:mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full px-8 py-2 md:py-3 text-xs md:text-sm rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px]"
              type="search"
              placeholder="Search Order …"
            />
          </div>
        </div> */}

        {/* filter */}
        {/* <div className="flex gap-6 text-xs md:text-sm md:py-3">
          <NavLink
            onClick={() => setActiveRole("All")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ALL REVIEWS
            <span
              className={` font-semibold md:px-2 px-1 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              23
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("Replied")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Replied"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            REPLIED
            <span
              className={` font-semibold md:px-2 px-1 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Replied"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              33
            </span>
          </NavLink>
        </div> */}

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1020px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[10%] text-left">ORDER ID</span>
                <span className="w-[20%] text-left">PRODUCT</span>
                <span className="w-[20%] text-left">REVIEW</span>
                <span className="w-[20%] text-left">RATING</span>
                <span className="w-[10%] text-left">BUYER</span>
                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <div className="flex justify-center py-4">
                  <Loader />
                </div>
              ) : data?.reviews?.length > 0 ? (
                data?.reviews?.map((item) => {
                  return (
                    <div className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b ">
                      <span className="w-[10%] text-left ">
                        #{item.order_uid}
                      </span>
                      <div className="w-[20%] text-left flex items-center gap-3">
                        <span>
                          <img
                            src={item.product_image}
                            alt=""
                            className="max-w-10 h-8 md:h-10 rounded-[10px]"
                          />
                        </span>
                        <div className="flex flex-col">
                          <span className="font-semibold ">
                            {item.product_name}
                          </span>
                          <span className="font-medium  text-[#6B6B6B]">
                            Category: {item.category}
                          </span>
                        </div>
                      </div>

                      <span className="w-[20%] text-left flex flex-col gap-1 items-center">
                        {item.review}
                      </span>
                      <span className="w-[20%] text-left flex gap-1 items-center">
                        {generateStarRating(Number(item.rating))}
                      </span>

                      <span className="w-[10%] text-left">{item.customer}</span>
                      <span className="w-[10%] text-left">
                        <img
                          className="cursor-pointer w-7 h-7 md:w-9 md:h-9"
                          onClick={() =>
                            handleOpenModal(item.product_id, item.review_id)
                          }
                          src="/assets/icons/share1.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="text-center font-bold tracking-wide py-4">
                  No Reviews Found
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Pagination */}
      </div>
      <ReplyModal
      productId={selectedReview.productId}
      reviewId={selectedReview.reviewId}
      />
    </>
  );
}
