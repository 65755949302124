import AccountStatementPage from "../../pages/My-Shop/Finance-Management-Pages/Account-Statement-Page/AccountStatementPage";
import TransactionsPage from "../../pages/My-Shop/Finance-Management-Pages/Transactions-Page/TransactionsPage";

const financeRoutes = [
    {
        path: "/vendor/my-shop/account-statement",
        element: <AccountStatementPage />,
      },
      {
        path: "/vendor/my-shop/transactions",
        element: <TransactionsPage />,
      },
 
 
];

export default financeRoutes;
