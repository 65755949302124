import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import ProductManagmentEditProduct from "../../../components/MyShop/Product-Managment-Components/ProductManagmentEditProduct";

export default function ProductManagmentEditProductPage() {
  return (
    <>
      <MyShopLayout>
        <ProductManagmentEditProduct />
      </MyShopLayout>
    </>
  );
}
