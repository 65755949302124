import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import StoreManagmentStoreInfo from "../../../components/MyShop/Store-Decoration-Components/StoreManagmentStoreInfo";

export default function StoreManagmentStoreInfoPage() {
  return (
    <>
      <MyShopLayout>
        <StoreManagmentStoreInfo />
      </MyShopLayout>
    </>
  );
}
