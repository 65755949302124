import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  joinOfferUpdateProducts,
  listVendorProduct,
} from "../../../../store/vendorslice";
import { imagePath } from "../../../../functions/URLString";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";

export default function ModalSingleRegisteredOffer({
  offerProductIds,
  offerId,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedCardIds, setSelectedCardIds] = useState([]); // Initialize as empty array
  const [selectedProducts, setSelectedProducts] = useState([]);

  // Fetching available vendor products
  useEffect(() => {
    dispatch(listVendorProduct());
  }, [dispatch]);

  // Facilitating state with slice data and logging matching IDs
  useEffect(() => {
    if (!loading && data && data.products) {
      const available_products_list = data.products;
      setAvailableProducts(available_products_list);
      console.log("send ids to modal", offerProductIds);
      setSelectedCardIds([...offerProductIds]); // Update state with offerProductIds when data is available
      console.log("send ids to modal state", offerProductIds);
      console.log("idddd", offerId);
    }
  }, [data, loading, offerProductIds]); // Add offerProductIds to the dependency array

  const handleCheckboxChange = (item) => {
    const itemId = item.id;
    setSelectedCardIds((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  // Log selected product IDs whenever it changes
  useEffect(() => {
    console.log("Selected Product IDs: ", selectedCardIds);
  }, [selectedCardIds, offerProductIds]);

  // Log selected products whenever it changes
  useEffect(() => {
    const selected = availableProducts.filter((product) =>
      selectedCardIds.includes(product.id)
    );
    setSelectedProducts(selected);
  }, [selectedCardIds, availableProducts]);

  // const handleSelectAndClose = () => {
  //   // Call the onSelect prop with the selected products
  //   // Here you can pass selectedProducts or selectedCardIds as needed
  //   console.log("All Selected Product IDs: ", selectedCardIds);
  //   document.getElementById("my_modal_4").close();
  // };

  const handleUpdateProducts = () => {
    const payload = {
      offer_id: offerId,
      product_id: selectedCardIds,
    };

    dispatch(joinOfferUpdateProducts(payload)).then(() => {
      navigate("/vendor/booster/offers");
      toast.success("Products Updated Success");
    });
    console.log("id", offerId);
  };

  return (
    <div>
      {/* Modal code for selecting products */}
      <dialog id="my_modal_4" className="modal bg-none">
        <div className="modal-box bg-white p-3 xl:p-8 max-w-[85rem] max-h-[50rem] rounded-lg relative">
          <form method="dialog">
            {/* Header div */}
            <div className="sticky top-0 z-50 bg-white w-full py-2 px-3 xl:px-8 flex items-center justify-between">
              <h2 className="font-bold text-xl xl:text-[22px]">
                Select Products
              </h2>
              <button
                className="text-3xl w-8 h-8 bg-white rounded-full hover:shadow-xl hover:bg-slate-50 shadow-lg"
                onClick={() => document.getElementById("my_modal_4").close()}
              >
                <img
                  src="/assets/icons/cancel1.svg"
                  className="mx-auto"
                  alt="Close"
                />
              </button>
            </div>
            <div className="h-[360px] overflow-y-auto overflow-x-hidden mx-auto mt-3 cardContainer">
              <div className="grid grid-cols-2 md:grid-cols-3  xl:grid-cols-5 gap-6 w-[95%] mx-auto">
                {loading ? (
                  <div className="flex justify-center py-4">
                    <Loader />
                  </div>
                ) : availableProducts.length > 0 ? (
                  availableProducts.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="card !rounded-lg border-none card-shadow relative cursor-pointer w-full xl:w-[195px]"
                      >
                        {/* Checkbox */}
                        <div className="flex z-50 top-1 right-1 absolute items-center">
                          <input
                            type="checkbox"
                            checked={selectedCardIds.includes(item.id)}
                            onChange={() => handleCheckboxChange(item)}
                            className="hidden"
                          />
                          <div
                            className={`w-8 h-8 flex items-center justify-center rounded-full border-2 border-black cursor-pointer transition-colors duration-200 ${
                              selectedCardIds.includes(item.id)
                                ? "bg-black text-white"
                                : "bg-transparent"
                            }`}
                            onClick={() => handleCheckboxChange(item)}
                          >
                            {selectedCardIds.includes(item.id) && <FaCheck />}
                          </div>
                        </div>

                        {/* Product Details */}
                        <div className="gap-1 absolute left-2 mt-1">
                          <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 px-2 rounded-xl mt-2 text-sm font-bold">
                            {item.discount}
                          </p>
                        </div>
                        <Link to="#">
                          <img
                            src={item.image}
                            alt="Product"
                            className="max-h-[282px] w-full rounded-lg"
                          />
                        </Link>
                        <div className="px-3 py-1">
                          <Link to="#">
                            <h2 className="card-title text-xs md:text-sm mb-0">
                              {item.name.slice(0, 51)}...
                            </h2>
                          </Link>
                          <div className="h-4">
                            <div className="flex gap-2 items-center slide-from-top">
                              <div className="rating rating-xs space-x-1 h-[9px] md:h-[10px] lg:h-3">
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
                                />
                              </div>
                              <div className="text-[#8C8C8C] text-xs">224</div>
                            </div>
                          </div>

                          <div className="card-actions justify-between items-center">
                            <div className="flex items-center gap-1">
                              <h5 className="font-semibold text-black text-xs md:text-sm">
                                <span className="">$</span>
                                {item.price}.99
                              </h5>
                              <span className="text-xs md:text-sm line-through text-[#8C8C8C]">
                                $27.99
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center font-bold tracking-wide py-4">
                    No products Found
                  </div>
                )}
              </div>
            </div>
            {/* Select button */}
            <div className="w-full h-10">
              <button
                className="bg-[#C19A6B] text-white px-6 py-2 rounded-lg absolute bottom-2 right-8"
                // onClick={handleSelectAndClose}
                onClick={handleUpdateProducts}
              >
                Select
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
