import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import { HiMiniCheckCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";

export default function ReviewStoreDetails() {
  return (
    <div className="flex justify-between  gap-8 md:gap-12 px-6 boost-shop-card-shadow py-5 rounded-lg max-w-[537px]  ">
      <div className="flex flex-col">
        <img
          className=" rounded-full max-h-[90px] max-w-[90px] md:max-h-[100px] md:max-w-[100px] xl:max-h-[130px] xl:max-w-[130px]  mx-auto"
          src="/assets/images/reviews/owner-shop-1.png"
          alt=""
        />
        <div className="flex justify-center space-x-2 items-center mt-1">
          <div className="text-[17px] md:text-[18px] xl:text-[23px] font-bold text-start">
            Gwen Star
          </div>
          <div className="">
            <HiMiniCheckCircle className="text-[#1983FF] w-[13px] h-[13px] md:w-[15px] md:h-[15px] xl:w-5 xl:h-5 " />
          </div>
        </div>
        <div className="flex gap-2 items-center ">
          <div className="rating space-x-1 rating-xs">
            <input
              type="radio"
              name="rating-1"
              className="mask mask-star-2 bg-[#FFB800] max-w-3 max-h-3"
              readOnly
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-[#FFB800] max-w-3 max-h-3"
              readOnly
            />
            <input
              type="radio"
              name="rating-3"
              className="mask mask-star-2 bg-[#FFB800] max-w-3 max-h-3"
              checked
              readOnly
            />
            <input
              type="radio"
              name="rating-4"
              className="mask mask-star-2 bg-[#FFB800] max-w-3 max-h-3"
              readOnly
            />
            <input
              type="radio"
              name="rating-5"
              className="mask mask-star-2 bg-[#FFB800] max-w-4 max-h-4"
              readOnly
            />
          </div>
          <div className="text-[#8C8C8C] text-[11px] md:text-xs xl:text-base">
            (92)
          </div>
        </div>
        {/* heart and person icon */}
        <div className="flex justify-around items-center mt-3">
          <div className="flex flex-col items-center">
            {/* <img src="./assets/icons/heart-icon-shop.png" alt="" /> */}
            <AiOutlineHeart className="text-[#828282] w-4 h-4 md:w-[17.65px] md:h-[15px] xl:w-[23px] xl:h-[19px]" />
            <p className="text-xs  xl:text-[18px] text-[#828282]">88k</p>
          </div>
          <div className="flex flex-col items-center ">
            {/* <img className='ms-2' src="./assets/icons/person-icon.png" alt="" /> */}
            <AiOutlineUser className=" text-[#828282] w-4 h-4 md:w-[17.65px] md:h-[15px] xl:w-[23px] xl:h-[19px]" />
            <p className="text-xs xl:text-[18px] text-center text-[#828282]">
              23.5k
            </p>
          </div>
        </div>
        <Link className="border-2 border-[#C19A6B] py-[5px] px-[25px] md:py-[5px] md:px-[28px] xl:py-[7px] xl:px-[37px] text-nowrap rounded-md bg-white text-[#C19A6B] font-semibold text-xs md:text-sm md:text-[17px] mt-3 text-center">
          Visit Store
        </Link>
      </div>

      <div className="max-w-[176px] md:max-w-[195px] xl:max-w-[255px] flex flex-col gap-4 justify-between ">
        <Link className="w-full flex gap-2 items-center border border-opacity-25 border-[#CBCBCB] rounded">
          <div>
            <img
              className="max-w-[65px] max-h-[58px] md:max-h-[64px] md:max-w-[72px] xl:max-h-[84px] xl:max-w-[94px]"
              src="/assets/images/reviews/shop-p-1.png"
              alt=""
            />
          </div>
          <div className="w-full flex flex-col ">
            <h2 className=" text-[11px] md:text-xs xl:text-base font-semibold tracking-wide">
              Male Perfume limited...
            </h2>
            <div className="flex gap-4 xl:gap-8 items-center w-full ">
              <div className="rating  space-x-[2px] ">
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
              </div>
              <div className="text-[15px] md:text-[17px] xl:text-[22px] text-[#1983FF] font-semibold">
                $90
              </div>
            </div>
          </div>
        </Link>

        <Link className="w-full flex gap-2 items-center border border-opacity-25 border-[#CBCBCB] rounded">
          <div>
            <img
              className="max-w-[65px] max-h-[58px] md:max-h-[64px] md:max-w-[72px] xl:max-h-[84px] xl:max-w-[94px]"
              src="/assets/images/reviews/shop-p-1.png"
              alt=""
            />
          </div>
          <div className="w-full flex flex-col ">
            <h2 className=" text-[11px] md:text-xs xl:text-base font-semibold tracking-wide">
              Male Perfume limited...
            </h2>
            <div className="flex gap-4 xl:gap-8 items-center w-full ">
              <div className="rating  space-x-[2px] ">
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
              </div>
              <div className="text-[15px] md:text-[17px] xl:text-[22px] text-[#1983FF] font-semibold">
                $90
              </div>
            </div>
          </div>
        </Link>
        <Link className="w-full flex gap-2 items-center border border-opacity-25 border-[#CBCBCB] rounded">
          <div>
            <img
              className="max-w-[65px] max-h-[58px] md:max-h-[64px] md:max-w-[72px] xl:max-h-[84px] xl:max-w-[94px]"
              src="/assets/images/reviews/shop-p-1.png"
              alt=""
            />
          </div>
          <div className="w-full flex flex-col ">
            <h2 className=" text-[11px] md:text-xs xl:text-base font-semibold tracking-wide">
              Male Perfume limited...
            </h2>
            <div className="flex gap-4 xl:gap-8 items-center w-full ">
              <div className="rating  space-x-[2px] ">
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
                <input
                  type="radio"
                  name="rating-2"
                  className="mask mask-star-2 bg-yellow-500 max-w-2 max-h-2 md:max-w-[9px] md:max-h-[9px] xl:max-w-3 xl:max-h-3"
                  checked
                  readOnly
                />
              </div>
              <div className="text-[15px] md:text-[17px] xl:text-[22px] text-[#1983FF] font-semibold">
                $90
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
