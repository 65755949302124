import Protected from "../pages/Protected";
import Root from "../pages/Root";
import boosterDashboardRoutes from "./BoosterRoutes/boosterDashboardRoutes";
import marketingSolutionsRoutes from "./BoosterRoutes/marketingSolutionsRoutes";
import accountAndSettingRoutes from "./MyShopRoutes/accountAndSettingRoutes";
import dashboardRoutes from "./MyShopRoutes/dashboardRoutes";
import financeRoutes from "./MyShopRoutes/financeRoutes";
import joinMallRoutes from "./MyShopRoutes/joinMallRoutes";
import orderReviewsRoutes from "./MyShopRoutes/orderReviewsRoutes";
import productsRoutes from "./MyShopRoutes/productsRoutes";
import sellerSupportRoutes from "./MyShopRoutes/sellerSupportRoutes";
import sellerToolsRoutes from "./MyShopRoutes/sellerToolsRoutes";
import accountCreationRoutes from "./accountCreationRoutes";
import authRoutes from "./authRoutes";
import headerRoutes from "./headerRoutes";


const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes,
      ...accountCreationRoutes,
      {
        path: "/vendor", //vendor should be added here routing is not correct and for all children routes attach /vendor in the beginning
        element: <Protected />,
        children: [
          ...headerRoutes,
          ...dashboardRoutes,
          ...joinMallRoutes,
          ...productsRoutes,
          ...orderReviewsRoutes,
          ...sellerToolsRoutes,
          ...financeRoutes,
          ...sellerSupportRoutes,
          ...accountAndSettingRoutes,
          ...boosterDashboardRoutes,
          ...marketingSolutionsRoutes,
        ],
      },
    ],
  },
];

export default mainRoutes;
