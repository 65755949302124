import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import UpdateVendorBundlesUplaod from "../../../components/MyShop/Vendor-Bundles-Components/UpdateVendorBundlesUplaod";

export default function UpdateVendorBundlesUplaodPage() {
  return (
    <>
      <MyShopLayout>
        <UpdateVendorBundlesUplaod />
      </MyShopLayout>
    </>
  );
}
