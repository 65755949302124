import StoreManagmentBannerAndProductPage from "../../pages/My-Shop/Store-Decoration-Page/StoreManagmentBannerAndProductPage";
import StoreManagmentIntroVedioPage from "../../pages/My-Shop/Store-Decoration-Page/StoreManagmentIntroVedioPage";
import StoreManagmentStoreInfoPage from "../../pages/My-Shop/Store-Decoration-Page/StoreManagmentStoreInfoPage";
import UpdateVendorBundlesUplaodPage from "../../pages/My-Shop/Vendor-Bundles-Pages/UpdateVendorBundlesUplaodPage";
import VendorBundlesPage from "../../pages/My-Shop/Vendor-Bundles-Pages/VendorBundlesPage";
import VendorBundlesUplaodPage from "../../pages/My-Shop/Vendor-Bundles-Pages/VendorBundlesUplaodPage";
import VendorInreelsPage from "../../pages/My-Shop/Vendor-Inreels-Pages/VendorInreelsPage";
import VendorInreelsUpdatePage from "../../pages/My-Shop/Vendor-Inreels-Pages/VendorInreelsUpdatePage";
import VendorInreelsUplaodPage from "../../pages/My-Shop/Vendor-Inreels-Pages/VendorInreelsUplaodPage";

const sellerToolsRoutes = [
  {
    path: "/vendor/my-shop/store-decoration/intro",
    element: <StoreManagmentIntroVedioPage />,
  },
  {
    path: "/vendor/my-shop/store-decoration/banner-products",
    element: <StoreManagmentBannerAndProductPage />,
  },
  {
    path: "/vendor/my-shop/store-decoration",
    element: <StoreManagmentStoreInfoPage />,
  },

  {
    path: "/vendor/my-shop/bundles",
    element: <VendorBundlesPage />,
  },
  {
    path: "/vendor/my-shop/bundles/uplaod-bundle",
    element: <VendorBundlesUplaodPage />,
  },
  {
    path: "/vendor/my-shop/bundles/uplaod-bundle/:id",
    element: <UpdateVendorBundlesUplaodPage />,
  },
  {
    path: "/vendor/my-shop/inreels",
    element: <VendorInreelsPage />,
  },
  {
    path: "/vendor/my-shop/inreels/update-reel/:id",
    element: <VendorInreelsUpdatePage />,
  },
  {
    path: "/vendor/my-shop/inreels/uplaod-reel",
    element: <VendorInreelsUplaodPage />,
  },
];

export default sellerToolsRoutes;
