import React, { useEffect, useState } from "react";
import { FaHeart, FaPlay } from "react-icons/fa";
import InreelsDrowerProductCard from "./InreelsDrowerProductCard";
import { Link } from "react-router-dom";
import ReviewsProductCard from "./ReviewsProductCard";
import ReviewStoreShortDetails from "./ReviewStoreShortDetails";
import ReviewStoreDetails from "./ReviewStoreDetails";
import StoreInfo from "./StoreInfo";

export default function VendorDashboardInreelDrower({
  item,
  onBackButtonClick,
  setIsOpen,
  isOpen,
}) {
  const [reviewsProduct, setReviewsProduct] = useState([]);
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const handleVideoCrossButton = () => {
    // onBackButtonClick();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/reviews-product.json");
        const data = await response.json();
        setReviewsProduct(data);
        console.log(reviewsProduct);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col md:flex-row items-center gap-6 relative">
        <div
          className={` rounded-xl relative overflow-hidden  h-[366px] max-w-[289px]  `}
        >
          {/* heart icon  */}
          <div className="absolute top-4 right-4 bg-[#1983FF33] rounded-full   cursor-pointer  flex justify-center items-center h-12 w-12 md:h-[38px] md:w-[38px] lg:h-[54px] lg:w-[54px]">
            <FaPlay className="w-7 h-7 md:h-[23px] md:w-[23px] lg:h-7 lg:w-7 text-white" />
          </div>
          {/* image  */}
          <img
            className="w-full "
            src="/assets/images/reviews/review-2.png"
            alt="boosted offers"
          />

          <div className="absolute px-4 bottom-2  w-full flex justify-between items-center">
            <Link className="flex gap-2 items-center text-white">
              <img
                className="w-[55px] h-[55px] md:w-11 md:h-11 lg:w-11 lg:h-11"
                src="/assets/images/reviews/review-2-author.png"
                alt=""
              />
              <span className="font-bold text-[17px] text-xs lg:text-xl">
                Gwen Umar
              </span>
            </Link>

            <div className={`flex flex-col gap-1 items-center`}>
              <span className="w-[31px] h-[31px] lg:w-9 lg:h-9 md:w-[25px] md:h-[25px] cursor-pointer bg-[#1983FF33] rounded-full  flex justify-center items-center">
                <FaHeart
                  onClick={handleHeartClick}
                  className={`w-[21px] h-[18px] md:w-[17px] md:h-[14px] lg:h-[21px] lg:w-6   ${
                    isHeartFilled
                      ? "text-blue-500 duration-500 ease-in-out"
                      : "text-white"
                  }`}
                />
              </span>
              <span className="text-white text-sm md:text-xs lg:text-base">
                123
              </span>
            </div>
          </div>
        </div>

        {/* if there are one product in review */}
        {reviewsProduct.length === 1 && (
          <div className="flex   items-center gap-4">
            <div className="flex gap-4">
              {reviewsProduct.map((item) => {
                return <ReviewsProductCard key={item._id} item={item} />;
              })}
            </div>

            <div className="flex lg:hidden">
              <ReviewStoreShortDetails />
            </div>

            <div className="hidden lg:flex">
              <ReviewStoreDetails />
            </div>
          </div>
        )}

        {/* if there is two product in review */}
        {reviewsProduct.length === 2 && (
          <div className="flex flex-col lg:flex-row items-center gap-4">
            {/* for tab  */}
            <div className="hidden md:flex justify-start w-full lg:hidden">
              <StoreInfo />
            </div>

            <div className="flex gap-8">
              {reviewsProduct.map((item) => {
                return <ReviewsProductCard key={item._id} item={item} />;
              })}
            </div>

            {/* for mobile  */}
            <div className="md:hidden">
              <ReviewStoreDetails />
            </div>

            {/* for tab and laptop  */}
            <div className="hidden lg:flex">
              <ReviewStoreShortDetails />
            </div>
          </div>
        )}

        {/* if there is three product in review */}
        {reviewsProduct.length === 3 && (
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="hidden md:flex">
              <StoreInfo />
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {reviewsProduct.map((item) => {
                return <ReviewsProductCard key={item._id} item={item} />;
              })}

              {/* for mobile  */}
              <div className="flex md:hidden">
                <ReviewStoreShortDetails />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
