import { NavLink, useLocation } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { GoPackage } from "react-icons/go";
import { LuPackage2 } from "react-icons/lu";
import { LuWallet } from "react-icons/lu";
import { HiOutlinePaintBrush } from "react-icons/hi2";
import { PiHeadphones } from "react-icons/pi";
import { BiCog } from "react-icons/bi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { useDispatch } from "react-redux";

import "./side-bar.css";
import { logout } from "../../store/vendorslice";

export default function MyShopSidebar() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname === "/vendor/my-shop/home" ||
      location.pathname === "/vendor/my-shop/affiliate-dashboard" ||
      location.pathname === "/vendor/my-shop/new-orders"
    ) {
      setOpenDropdown("dashboard");
    } else if (
      location.pathname.startsWith("/vendor/my-shop/product-management") ||
      location.pathname.startsWith(
        "/vendor/my-shop/product-management/add-product"
      )
    ) {
      setOpenDropdown("products");
    } else if (
      location.pathname.startsWith("/vendor/my-shop/orders-management") ||
      location.pathname.startsWith("/vendor/my-shop/customer-returns") ||
      location.pathname.startsWith("/vendor/my-shop/manage-reviews")
    ) {
      setOpenDropdown("order");
    } else if (
      location.pathname.startsWith("/vendor/my-shop/store-decoration") ||
      location.pathname.startsWith("/vendor/my-shop/bundles") ||
      location.pathname.startsWith("/vendor/my-shop/inreels")
    ) {
      setOpenDropdown("sellerTool");
    } else if (
      location.pathname.startsWith("/vendor/my-shop/account-statement") ||
      location.pathname.startsWith("/vendor/my-shop/transactions")
    ) {
      setOpenDropdown("finance");
    } else if (
      location.pathname.startsWith("/vendor/my-shop/help-center") ||
      location.pathname.startsWith("/vendor/my-shop/contact-us") ||
      location.pathname.startsWith("/vendor/my-shop/tickets")
    ) {
      setOpenDropdown("sellerSupport");
    } else if (
      location.pathname.startsWith("/vendor/my-shop/profile") ||
      location.pathname.startsWith("/vendor/my-shop/chat-setting")
    ) {
      setOpenDropdown("accountSettings");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="w-full xl:w-64 h-[100vh] xl:h-[95vh]  bg-[#C19A6B] rounded-[0.7rem] hide-scrollbar overflow-y-auto">
        {/* shop name */}
        <div className=" px-4 py-4">
          <div className="flex gap-2 items-center mb-4">
            <div>
              <img
                className="h-14 w-14 lg:h-16 lg:w-16"
                src="/assets/images/shop-profile.svg"
                alt=""
              />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="text-white text-sm lg:text-base font-medium tracking-wide">
                  Shop Name
                </div>
              </div>
            </div>
          </div>
          {/* hr */}
          <hr
            className="h-[0.1rem]"
            style={{
              borderRadius: "50%",
            }}
          />
        </div>

        <div className="flex flex-col gap-1 lg:gap-2 px-4">
          {/* dashboard  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("dashboard")}
              className={`${
                location.pathname === "/vendor/my-shop/home" ||
                location.pathname === "/vendor/my-shop/affiliate-dashboard" ||
                location.pathname === "/vendor/my-shop/new-orders"
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <LuLayoutDashboard className="w-4 h-4" />
              Dashboard
              {openDropdown === "dashboard" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* Dashboard dropdown  */}
            {openDropdown === "dashboard" && (
              <>
                <NavLink
                  to="/vendor/my-shop/home"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Vendor Dashboard
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/affiliate-dashboard"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Vendor Affiliate Dashboard
                </NavLink>
              </>
            )}
          </div>
          {/* join mall  */}
          <NavLink
            to="/vendor/my-shop/join-mall"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <HiOutlineOfficeBuilding className="w-4 h-4" />
            Join Mall
          </NavLink>

          {/* product section  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("products")}
              className={`${
                location.pathname.startsWith(
                  "/vendor/my-shop/product-management"
                )
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <GoPackage className="w-4 h-4" />
              Products
              {openDropdown === "products" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* product dropdown  */}
            {openDropdown === "products" && (
              <>
                <NavLink
                  to="/vendor/my-shop/product-management"
                  className={`${
                    location.pathname ===
                      "/vendor/my-shop/product-management" ||
                    location.pathname.startsWith(
                      "/vendor/my-shop/product-management/edit-product"
                    )
                      ? "childActiveLink"
                      : "childLink"
                  }`}
                >
                  Manage Products
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/product-management/add-product"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  {" "}
                  Add Product
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/affilate-products"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  {" "}
                  Affilate Products
                </NavLink>
              </>
            )}
          </div>

          {/* Orders & Reviews section  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("order")}
              className={`${
                location.pathname.startsWith(
                  "/vendor/my-shop/orders-management"
                ) ||
                location.pathname.startsWith(
                  "/vendor/my-shop/customer-returns"
                ) ||
                location.pathname.startsWith("/vendor/my-shop/manage-reviews")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <LuPackage2 className="w-4 h-4" />
              Orders & Reviews
              {openDropdown === "order" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* order dropdown  */}
            {openDropdown === "order" && (
              <>
                <NavLink
                  to="/vendor/my-shop/orders-management"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Manage Orders
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/customer-returns"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Customer Returns
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/manage-reviews"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Manage Reviews
                </NavLink>
              </>
            )}
          </div>

          {/* seller tool section  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("sellerTool")}
              className={`${
                location.pathname.startsWith(
                  "/vendor/my-shop/store-decoration"
                ) ||
                location.pathname.startsWith("/vendor/my-shop/bundles") ||
                location.pathname.startsWith("/vendor/my-shop/inreels")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <HiOutlinePaintBrush className="w-4 h-4" />
              Seller Tools
              {openDropdown === "sellerTool" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* seller tool dropdown  */}
            {openDropdown === "sellerTool" && (
              <>
                <NavLink
                  to="/vendor/my-shop/store-decoration"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Store Decoration
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/bundles"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Bundles
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/inreels"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  InReels
                </NavLink>
              </>
            )}
          </div>

          {/* Finance section  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("finance")}
              className={`${
                location.pathname.startsWith(
                  "/vendor/my-shop/account-statement"
                ) ||
                location.pathname.startsWith("/vendor/my-shop/transactions")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <LuWallet className="w-4 h-4" />
              Finance
              {openDropdown === "finance" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* Finance dropdown  */}
            {openDropdown === "finance" && (
              <>
                <NavLink
                  to="/vendor/my-shop/account-statement"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Account Statement
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/transactions"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Transactions
                </NavLink>
              </>
            )}
          </div>

          {/* Seller Support section  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("sellerSupport")}
              className={`${
                location.pathname.startsWith("/vendor/my-shop/help-center") ||
                location.pathname.startsWith("/vendor/my-shop/contact-us") ||
                location.pathname.startsWith("/vendor/my-shop/tickets")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <PiHeadphones className="w-4 h-4" />
              Seller Support
              {openDropdown === "sellerSupport" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* Seller Support dropdown  */}
            {openDropdown === "sellerSupport" && (
              <>
                <NavLink
                  to="/vendor/my-shop/help-center"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Help Center
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/contact-us"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Contact Us
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/tickets"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Tickets
                </NavLink>
              </>
            )}
          </div>

          {/* Account & Settings section  */}
          <div className="flex relative flex-col ">
            <NavLink
              onClick={() => toggleDropdown("accountSettings")}
              className={`${
                location.pathname.startsWith("/vendor/my-shop/profile") ||
                location.pathname.startsWith("/vendor/my-shop/chat-setting")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <BiCog className="w-4 h-4" />
              Account & Settings
              {openDropdown === "accountSettings" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </NavLink>
            {/* Account & Settings dropdown  */}
            {openDropdown === "accountSettings" && (
              <>
                <NavLink
                  to="/vendor/my-shop/profile"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Profile
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/chat-setting"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Chat Settings
                </NavLink>
              </>
            )}
          </div>

          {/* logout  */}
          <NavLink onClick={handleLogout} className="navLink">
            <IoIosLogOut className="w-4 h-4" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
}
