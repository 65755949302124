import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import BreadCrums from "../../Common/BreadCrums";
import { toast } from "react-toastify";
import { addStoreVideoInfo, clearMessage, getIntroVideo } from "../../../store/vendorslice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";

export default function StoreManagmentIntroVedio() {
  const { data, message, error, loading, storeIntroVideo } = useSelector((state) => state.admin);
  const [activeTab, setActiveTab] = useState("INTRO VEDIO");
  const [videoFile, setVideoFile] = useState(storeIntroVideo?.[0]?.video || null);
  const fileInputRef = useRef(null);
  const [payload, setPayload] = useState({
    title: storeIntroVideo?.[0]?.title || "",
    video: "",
    description: storeIntroVideo?.[0]?.description || "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIntroVideo());
  }, [dispatch]);

  useEffect(() => {
    if (storeIntroVideo && storeIntroVideo.length > 0) {
      const videoInfo = storeIntroVideo[0];
      setPayload({
        title: videoInfo.title,
        video: "",
        description: videoInfo.description,
      });
      setVideoFile(videoInfo.video);
    }
  }, [storeIntroVideo]);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPayload((prev) => ({ ...prev, video: file }));
      setVideoFile(URL.createObjectURL(file));
      event.target.value = "";
    }
  };

  const handleDeleteVideo = () => {
    setVideoFile(null);
    setPayload((prev) => ({ ...prev, video: "" }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const isVideoInfoEmpty = !storeIntroVideo || storeIntroVideo.length === 0;

    if (isVideoInfoEmpty) {
      // Add all fields if there's no existing video info
      Object.keys(payload).forEach((key) => {
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      });
    } else {
      // Add only changed fields
      Object.keys(payload).forEach((key) => {
        if (payload[key] && payload[key] !== storeIntroVideo[0][key]) {
          formData.append(key, payload[key]);
        }
      });
    }

    if (formData.has("title") || formData.has("video") || formData.has("description")) {
      dispatch(addStoreVideoInfo(formData));
    } else {
      toast.error("Kindly Fill All Fields Correctly");
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      if (message === "IntroVideo created successfully") {
        setPayload({
          title: "",
          video: "",
          description: "",
        });
        setVideoFile(null);
      }
      dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [error, message, dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* head */}
        <div className="flex justify-between items-center gap-2">
          <BreadCrums
            breadCrum={[
              {
                name: "Store Decoration",
                path: "/vendor/my-shop/store-decoration",
              },
              {
                name: "Intro",
                path: "/vendor/my-shop/store-decoration/intro",
              },
            ]}
          />

          <button
            disabled={loading}
            className="py-2 md:py-[10px] px-5 md:px-9 bg-[#C19A6B] rounded-xl text-white font-semibold text-xs"
            onClick={handleSubmit}
          >
            {!loading ? "Publish" : "Publishing ..."}
          </button>
        </div>
        {/* filter */}
        <div className="flex text-[10px] md:text-sm gap-2 md:gap-6 py-1 md:py-3">
          <Link
            to="/vendor/my-shop/store-decoration"
            onClick={() => setActiveTab("STORE INFO")}
            className={`font-semibold text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "STORE INFO"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STORE INFO
          </Link>
          <Link
            to="/vendor/my-shop/store-decoration/intro"
            onClick={() => setActiveTab("INTRO VEDIO")}
            className={`font-semibold text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "INTRO VEDIO"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INTRO VEDIO
          </Link>

          <Link
            to="/vendor/my-shop/store-decoration/banner-products"
            onClick={() => setActiveTab("BANNERS AND PRODUCTS")}
            className={`font-semibold text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "BANNERS AND PRODUCTS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            BANNERS AND PRODUCTS
          </Link>
        </div>
        {/*  */}
        <div>
          <h2 className="text-base md:text-lg font-semibold">Intro Video</h2>
          <div className="border border-[#EBF0ED] rounded-lg p-4 mb-5">
            <div className="">
              <h2 className="text-[10px] md:text-xs font-semibold text-[#6B6B6B] mb-1">
                UPLOAD INTRO VIDEO
              </h2>
              <div>
                {/* Display video */}
                {videoFile ? (
                  <div className="relative w-full md:w-[474px] h-[120px] md:h-[267px] border-2 border-gray-300 rounded-xl overflow-hidden">
                    <video className="w-full h-full object-cover" controls>
                      <source src={videoFile} type="video/mp4" />
                    </video>
                    <button
                      className="absolute top-2 right-2 text-red-500 bg-white p-2 rounded-full"
                      onClick={handleDeleteVideo}
                    >
                      <RxCross2 className="w-6 h-6" />
                    </button>
                  </div>
                ) : (
                  <div
                    className="w-full md:w-[474px] h-[120px] md:h-[267px] flex items-center justify-center border-2 border-dashed border-[#D9D9D9] rounded-xl bg-[#F6F6F6] cursor-pointer"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <div className="flex items-center justify-center bg-[#D9D9D9] rounded-full">
                      <img
                        src="/assets/icons/StoreDecoration/plus.svg"
                        className="p-2"
                        alt="Upload"
                      />
                    </div>
                  </div>
                )}

                {/* Hidden file input for video upload */}
                <input
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleVideoUpload}
                />
              </div>
            </div>
            <div className="py-2">
              <label
                htmlFor=""
                className="text-[10px] md:text-xs font-semibold text-[#6B6B6B]"
              >
                TITLE
              </label>
              <input
                type="text"
                placeholder="Title ..."
                className="w-full text-xs outline-none rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] py-[0.69rem] px-[0.62rem]"
                value={payload.title}
                onChange={(e) =>
                  setPayload({ ...payload, title: e.target.value })
                }
              />
            </div>
            <div>
              <label
                htmlFor=""
                className="text-[10px] md:text-xs font-semibold text-[#6B6B6B]"
              >
                DESCRIPTION
              </label>
              <textarea
                type="text"
                placeholder="description ..."
                className="w-full h-16 md:h-48 text-xs outline-none rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] py-[0.69rem] px-[0.62rem]"
                value={payload.description}
                onChange={(e) =>
                  setPayload({ ...payload, description: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
