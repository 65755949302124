import React from "react";
import { useState } from "react";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { GoDotFill } from "react-icons/go";
import "./helpcenter.css";
import BreadCrums from "../../Common/BreadCrums";

export default function HelpCenter({ categoriesList, sellerSupportFaqs }) {
  const [faqs, setFaqs] = useState([
    {
      id: 1,
      question: "Lorem ipsum dolor sit amet consectetur?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Mi amet volutpat purus sit ultrices sed dolor. Egestas eget feugiat nisl et. Ultrices volutpat vitae sem volutpat facilisis. Sed pretium non adipiscing aliquet sem. Quis lorem imperdiet sit sed pulvinar vulputate enim. Fringilla lectus nunc sit malesuada suspendisse est tristique. Porttitor sed augue sed quisque diam nec purus sem.",
      show: false,
    },
    {
      id: 2,
      question: "Vel semper condimentum in euismod gravida?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Mi amet volutpat purus sit ultrices sed dolor. Egestas eget feugiat nisl et. Ultrices volutpat vitae sem volutpat facilisis. Sed pretium non adipiscing aliquet sem. Quis lorem imperdiet sit sed pulvinar vulputate enim. Fringilla lectus nunc sit malesuada suspendisse est tristique. Porttitor sed augue sed quisque diam nec purus sem.",
      show: false,
    },
    {
      id: 3,
      question: "Vel semper condimentum in euismod gravida?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Mi amet volutpat purus sit ultrices sed dolor. Egestas eget feugiat nisl et. Ultrices volutpat vitae sem volutpat facilisis. Sed pretium non adipiscing aliquet sem. Quis lorem imperdiet sit sed pulvinar vulputate enim. Fringilla lectus nunc sit malesuada suspendisse est tristique. Porttitor sed augue sed quisque diam nec purus sem.",
      show: false,
    },
    {
      id: 4,
      question: "Vel semper condimentum in euismod gravida?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Mi amet volutpat purus sit ultrices sed dolor. Egestas eget feugiat nisl et. Ultrices volutpat vitae sem volutpat facilisis. Sed pretium non adipiscing aliquet sem. Quis lorem imperdiet sit sed pulvinar vulputate enim. Fringilla lectus nunc sit malesuada suspendisse est tristique. Porttitor sed augue sed quisque diam nec purus sem.",
      show: false,
    },
    {
      id: 5,
      question: "Vel semper condimentum in euismod gravida?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Mi amet volutpat purus sit ultrices sed dolor. Egestas eget feugiat nisl et. Ultrices volutpat vitae sem volutpat facilisis. Sed pretium non adipiscing aliquet sem. Quis lorem imperdiet sit sed pulvinar vulputate enim. Fringilla lectus nunc sit malesuada suspendisse est tristique. Porttitor sed augue sed quisque diam nec purus sem.",
      show: false,
    },
    {
      id: 6,
      question: "Vel semper condimentum in euismod gravida?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Mi amet volutpat purus sit ultrices sed dolor. Egestas eget feugiat nisl et. Ultrices volutpat vitae sem volutpat facilisis. Sed pretium non adipiscing aliquet sem. Quis lorem imperdiet sit sed pulvinar vulputate enim. Fringilla lectus nunc sit malesuada suspendisse est tristique. Porttitor sed augue sed quisque diam nec purus sem.",
      show: false,
    },
  ]);

  const toggleFaq = (id) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq) => (faq.id === id ? { ...faq, show: !faq.show } : faq))
    );
  };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-5">
        <BreadCrums
          breadCrum={[
            {
              name: "Seller Support",
              path: "/vendor/my-shop/help-center",
            },
            {
              name: "Help Center",
              path: "/vendor/my-shop/help-center",
            },
          ]}
        />

        {/* Self Service Tools */}
        <div className="md:px-12">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            Self Service Tools
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
            {categoriesList?.map((item) => {
              return (
                <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
                  <img
                    src={item.icon}
                    className="w-7 h-7 lg:w-10 lg:h-10"
                    alt=""
                  />
                  <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                    {item.label}
                  </span>
                </div>
              );
            })}

            {/* <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Cube.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                Add Products
              </span>
            </div>{" "}
            <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Shipping.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                Manage Orders
              </span>
            </div>{" "}
            <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Paintbrush.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                Store Decoration
              </span>
            </div>{" "}
            <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Megaphone.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                Offer Managment
              </span>
            </div>{" "}
            <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Wallet.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                ACcount Statements
              </span>
            </div>
            <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Transfer.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                Order Returns
              </span>
            </div>{" "}
            <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/User.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                Profile Management
              </span>
            </div>{" "} */}
          </div>
        </div>

        {/* FAQs */}
        <div className=" md:px-12">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            FAQS
          </h2>
          <div className="flex flex-col gap-2 md:gap-5">

            
            {sellerSupportFaqs?.map((faq, index) => (
              <div
                key={faq.id}
                className="collapse collapse-arrow bg-base-200 border-[#DCDCDC] border rounded-[10px]"
              >
                <input
                  type="radio"
                  name="my-accordion-2"
                  defaultChecked={index === 0} // Only check the first one
                />
                <div className="collapse-title text-sm font-medium text-[#18120F]">
                  {faq.title}
                </div>
                <div className="collapse-content -mt-4">
                  <p className="font-normal text-[#18120F]">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Contacts */}
        <div className=" md:px-12 mb-5">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            Contact
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 text-xs md:text-sm lg:text-base">
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Chat-Bubble.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F] text-nowrap">
                  Chat With Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  10 AM - 6 PM (Mon-Sat)
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Phone--Streamline.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Call Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  123-456-7890123
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Mail-Send.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Email Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  contact@wizalot.com
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Cyborg-2.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F] text-nowrap">
                  Tell Us Your Problem
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  Assistance 24/7
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
