import React from "react";

import { FaRegHeart } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { MdVerified } from "react-icons/md";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";

import "./number-input-1.css";
import { useState } from "react";
import ProductsModal from "./ProductsModal";

const cityOptions = ["1 day", "2 day"];

export default function SendOfferModal() {
  // const [isYesChecked, setIsYesChecked] = useState(false);
  // const [isNoChecked, setIsNoChecked] = useState(false);
  const [isDaysDropdownVisible, setDaysDropdownVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");

  // const handleYesChange = () => {
  //   setIsYesChecked(true);
  //   setIsNoChecked(false);
  // };

  // const handleNoChange = () => {
  //   setIsYesChecked(false);
  //   setIsNoChecked(true);
  // };

  const toggleDayDropdown = () => {
    setDaysDropdownVisible((prev) => !prev);
  };

  const selectDay = (city) => {
    setSelectedDay(city);
    setDaysDropdownVisible(false);
  };
  const hanldesecondmodal = () => {
    document.getElementById("my_modal_23").showModal();
    document.getElementById("my_modal_03").close();
  };

  return (
    <>
      <div className="w-full flex flex-col gap-6">
        {/* store info  */}

        <div className="w-full flex flex-col md:flex-row justify-between  items-start gap-12">
          {/* img  */}
          <div className="flex items-center gap-8">
            <span>
              <img
                src="/assets/images/vendor.png"
                alt=""
                className="w-20 h-20 rounded-full"
              />
            </span>

            {/* store name  */}
            <div className="flex flex-col items-start gap-1">
              <span className="flex gap-1 items-center font-medium text-2xl">
                Khan Baba{" "}
                <span>
                  <MdVerified className="text-blue-600" />
                </span>
              </span>
              <div className="flex gap-5 font-semibold">
                <span className="flex flex-col items-center gap-1">
                  <FaRegHeart className="w-6 h-6" />
                  88k
                </span>

                <span className="flex flex-col items-center gap-1">
                  <LuUser2 className="w-6 h-6" />
                  32.6k
                </span>
              </div>
            </div>
          </div>
          <div className=" w-full md:w-fit justify-between flex flex-row md:flex-col gap-3">
            <button className=" border border-[#C19A6B] py-[4.5px] px-[22px] rounded-[4.5px] text-[17px] font-medium text-[#C19A6B]">
              Visit Store
            </button>
            <div className="flex items-center gap-3 justify-end">
              <img src="/assets/icons/chati.svg" alt="" />
              <img src="/assets/icons/new.svg" alt="" />
            </div>
          </div>
        </div>

        <div className="flex flex-col border border-[#EBF0ED] py-6 px-4 rounded-xl gap-4">
          {/* offer  */}
          <p className="font-medium text-lg">Offer</p>

          {/* Days */}
          <div className="w-full">
            <label className=" text-[12px] ml-1 my-1 font-semibold">
              Time Limit
            </label>
            <div className="relative w-full ">
              <input
                className="bg-[#FAFAFA] text-[12px] cursor-pointer font-normal  rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                type="text"
                value={selectedDay}
                readOnly
                onClick={toggleDayDropdown}
                placeholder="Select Number Of Days"
              />
              {isDaysDropdownVisible ? (
                <GoChevronUp className="absolute right-4 top-1/2 transform -translate-y-1/2 " />
              ) : (
                <GoChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 " />
              )}

              {isDaysDropdownVisible && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                  {cityOptions.map((city) => (
                    <div
                      key={city}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => selectDay(city)}
                    >
                      {city}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* percentage  */}
          <div className="flex flex-col gap-1 numberInput">
            <label htmlFor="" className="font-semibold text-xs ">
              Percentage
            </label>
            <input
              type="number"
              placeholder="Enter Percentage between 0-50"
              min={0}
              max={50}
              className="border border-[#EBF0ED] text-xs outline-none focus:border-[#d4d5d4] p-3 rounded-lg bg-[#FAFAFA]"
            />
          </div>
          <div className="">
            <h2 className="text-xs font-semibold mb-1">Products</h2>
            <button
              onClick={hanldesecondmodal}
              className=" border border-[#C19A6B] py-[12px] px-[25px] rounded-[4.5px] text-xs font-medium text-[#C19A6B]"
            >
              Select Products
            </button>
          </div>
        </div>

        {/* button  */}
        <div className="w-full flex justify-end gap-2">
          <button
            onClick={() => document.getElementById("my_modal_03").close()}
            className="py-[10px] px-9 text-sm rounded-lg text-[#C19A6B] border border-[#C19A6B] "
          >
            Close
          </button>
          <button className="py-[10px] px-9 text-sm    rounded-lg text-white bg-[#C19A6B] ">
            Send Offer
          </button>
        </div>
      </div>
      <ProductsModal />
    </>
  );
}
