import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import VendorBundlesUplaod from "../../../components/MyShop/Vendor-Bundles-Components/VendorBundlesUplaod";


export default function VendorBundlesUplaodPage() {
  return (
    <>
      <MyShopLayout>
        <VendorBundlesUplaod />
      </MyShopLayout>
    </>
  );
}
