import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import PreMadeAd from "../../../../components/Booster/Marketing-Solutions/Product-Booster-Components/PreMadeAd";

export default function PreMadeAdPage() {
  return (
    <>
      <BoosterLayout>
        <PreMadeAd />
      </BoosterLayout>
    </>
  );
}
