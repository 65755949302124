import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { getInreels } from "../../../store/vendorslice";

export default function VendorInreels() {
  const { inReelsData } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [reels, setReels] = useState([]);
  // const [isHeartFilled, setIsHeartFilled] = useState(false);
  // const handleHeartClick = () => {
  //   setIsHeartFilled(!isHeartFilled);
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("/AffiliateInreels.json");
  //       const data = await response.json();
  //       setReels(data);
  //       console.log(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    dispatch(getInreels());
  }, []);

  return (
    <>
      <div className="xl:px-4">
        {/* buttons */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "InReels",
                path: "/vendor/my-shop/inreels",
              },
            ]}
          />{" "}
          <div>
            <Link
              to="/vendor/my-shop/inreels/uplaod-reel"
              className="py-[7px] md:py-[10px] px-5 md:px-9 text-xs md:text-[15px] bg-[#C19A6B]  rounded-lg text-white  font-semibold"
            >
              Add InReel
            </Link>
          </div>
        </div>
        {/* search */}
        <div className="relative flex items-center w-full py-2 md:py-4">
          <svg
            className="absolute left-3"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z"
              fill="#036666"
            />
          </svg>

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
            type="search"
            placeholder="Search product ..."
          />
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 mt-4">
            {inReelsData?.inReels?.data?.map((item) => (
              <>
                <Link
                to={`/vendor/my-shop/inreels/update-reel/${item.id}`}
                >
                  <div className="w-[155px] md:w-[205px] h-[195px] md:h-[260px]   relative  shadow-md">
                    <div className="relative ">
                      {/* Large image */}
                      <video
                        className="w-[14rem] h-[16rem] rounded-lg"
                        src={item.video}
                      ></video>
                      {/* Small image  */}
                      <div className=" absolute bottom-0 flex items-center w-full px-2 py-2 justify-between">
                        <div className=" flex gap-1 items-center  left-0  z-10">
                          <img
                            className="w-9 h-9 rounded-full"
                            src={item.dp}
                            alt=""
                          />
                          <p className="text-[12px] text-white">{item.name}</p>
                        </div>
                        <div className="  right-0 w-9 h-9 items-center z-10">
                          <div
                            className={`rating gap-1 px-1 py-1 bg-[#1983FF33] rounded-full text-white`}
                            // onClick={handleHeartClick}
                          >
                            <FaHeart className="h-5 w-5 cursor-pointer" />
                          </div>
                          <p className="text-white ml-2 text-[10px]">
                            {item.like}
                          </p>
                        </div>
                      </div>

                      <p className="absolute top-2 right-2 z-40 text-black">
                        <img src="./assets/icons/play.svg" alt="" />
                      </p>
                    </div>
                  </div>
                </Link>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
