import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import PaymentModal from "../../../../components/Booster/Marketing-Solutions/Product-Booster-Components/PaymentModal";

export default function PaymentPage() {
  return (
    <>
      <BoosterLayout>
        <PaymentModal />
      </BoosterLayout>
    </>
  );
}
