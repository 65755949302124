import React, { useEffect, useState } from "react";
import BreadCrums from "../../../Common/BreadCrums";
import ModalSingleRegisteredOffer from "./ModalSingleRegisteredOffer";
import { useDispatch, useSelector } from "react-redux";
import {
  joinOfferProductsList,
  joinOfferUpdateProducts,
} from "../../../../store/vendorslice";
import { useParams } from "react-router-dom";
import Loader from "../../../Common/Loader";
import { imagePath } from "../../../../functions/URLString";

export default function SingleRegisteredOffer() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading,products } = useSelector((state) => state.admin);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [temp, setTemp] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  const [offersProducts, setOfferProducts] = useState();
  const [offerProductIds, setOfferProductIds] = useState([]);
  const [offerId, setOfferId] = useState();

  useEffect(() => {
    dispatch(joinOfferProductsList({ id:id }));
  }, []);

  useEffect(() => {
    // if(data.productlist){
    //   setOfferProducts(data.productlist)
    // }
  }, [data]);

  useEffect(() => {
    console.log(data);
    // if (
    //   !loading &&
    //   data &&
    //   data.product_vendor_join_offers &&
    //   data.product_vendor_join_offers[0].products
    // ) {
    //   let offer_products_list = data.product_vendor_join_offers[0].products;
    //   setOfferId(data.product_vendor_join_offers[0].id);

    //   setOfferProducts(offer_products_list);

    //   if (offer_products_list) {
    //     // Extracting IDs
    //     const productIds = offer_products_list.map(
    //       (product) => product.product_id
    //     );
    //     setOfferProductIds(productIds); // Setting IDs to state
    //     console.log("dd", offerProductIds);
    //   }
    // }
  }, [data, loading]);

  const handleDeleteProduct = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );
    setSelectedProducts(updatedProducts);
  };

  const handleUpdateProducts = () => {
    // dispatch(joinOfferUpdateProducts());
  };

  // pagination Function components
  // function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
  //   const pages = [];

  //   for (let i = 1; i <= totalNumberOfPages; i++) {
  //     pages.push(
  //       <button
  //         key={i}
  //         onClick={() => handlePageChange(i)}
  //         className={`px-2 py-1 rounded ${
  //           currentPage === i
  //             ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
  //             : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
  //         }`}
  //       >
  //         {i}
  //       </button>
  //     );
  //   }
  //   return <div className="pagination flex gap-2">{pages}</div>;
  // }

  // // pagination data handling + handle page change
  // useEffect(() => {
  //   if (data?.pagination_urls && data?.pagination_urls.total) {
  //     let totalLength = data?.pagination_urls.total;
  //     let totalPages = Math.ceil(totalLength / totalItemPerPages);
  //     setTotalPages(totalPages);
  //   }
  // }, [data]);

  // const handlePageChange = (id, page) => {
  //   if (page >= 1 && page <= totalPages) {
  //     setCurrentPage(page);
  //     dispatch(joinOfferProductsList({ id:id, page:page }));
  //   }
  // };

  // // pagination index varibale
  // const totalOffersCount = data?.pagination_urls?.total || 0;
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = Math.min(startIndex + itemsPerPage, totalOffersCount);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* Header */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "Single Registered Offer",
                path: "",
              },
            ]}
          />
          <div className="flex gap-2 md:gap-4 items-center">
            <button
              onClick={() => document.getElementById("my_modal_4").showModal()}
              className="border text-xs font-semibold border-[#EBF0ED] bg-[#FAFAFA] rounded-lg px-4 md:px-[20px] py-2 md:py-[10px]"
            >
              Add Product
            </button>
            <button
              onClick={handleUpdateProducts}
              className="border text-xs font-semibold text-white border-[#C19A6B] bg-[#C19A6B] rounded-lg px-4 md:px-[20px] py-2 md:py-[10px]"
            >
              Update
            </button>
          </div>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto ">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[800px] md:min-w-[768px] lg:min-w-full">
              {/* Table Header */}

              <div className="flex  items-center justify-between gap-2 p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
                <span className="w-[20%] text-left">PRODUCT</span>
                <span className="w-[10%] text-left">STOCK</span>

                <span className="w-[15%] text-left">CATEGORY</span>
                <span className="w-[15%] text-left">ORIGINAL PRICE</span>
                <span className="w-[10%] text-left">OFFER PRICE</span>
                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <div>
                  <span colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </span>
                </div>
              ) : products?.productlist?.length > 0 ? (
                products.productlist.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="flex w-full justify-between items-center  p-4 border-t  font-semibold text-sm "
                    >
                      <span className="w-[20%] flex gap-2 items-center py-1 text-left font-[600] mt-2">
                        <img
                          className="w-10 h-10"
                          src={item.image}
                          alt=""
                        />
                        <span className="w-[10%]">{item.name}</span>
                      </span>

                      <span className="text-left w-[10%]">{item.quantity}</span>

                      <span className="text-left w-[15%]">
                        {item.category_id}
                      </span>
                      <span className="text-left w-[10%]">
                        ${item.price}
                      </span>
                      <span className="text-left w-[10%]">
                        ${item.discountprice}
                      </span>

                      <span onClick={() => handleDeleteProduct(item.id)} className="cursor-pointer text-left w-[10%] text-[#C19A6B] text-base font-[500]">
                        Delete
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="text-center font-bold tracking-wide py-4">
                  <span colSpan={6}>No Products Found</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Pagination start here */}
        {/* <div className="flex flex-col md:flex-row justify-between items-center ">
          <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
            SHOWING {startIndex + 1}-{endIndex} OF {totalOffersCount} RESULTS
          </span> */}
          {/* <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0"> */}
            {/* Previous Button */}
            {/* <div
              className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                currentPage === 1 ? "opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            > */}
              {/* <svg
                className={`${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
            {/* Page Numbers */}
            {/* <div className="flex items-center gap-2">
              {
                <Pagination
                  totalNumberOfPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              }
            </div> */}
            {/* Next Button */}
            {/* <div
              className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                currentPage === totalPages ? "opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            > */}
              {/* <svg
                className={`${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div> */}
        {/* Pagination end here */}
      </div>
      <ModalSingleRegisteredOffer
        offerId={offerId}
        offerProductIds={offerProductIds}
      />
    </>
  );
}
