import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BoosterPerformanceBarChart from "../../../Graphs/BoosterPerformanceBarChart";
import BreadCrums from "../../../Common/BreadCrums";

export default function BoosterPerformance() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/viewoffer.json");
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solution",
              path: "/vendor/booster/booster-performance",
            },
            {
              name: "Booster Performance",
              path: "/vendor/booster/booster-performance",
            },
          ]}
        />

        <div className="flex items-center justify-between gap-4 w-full">
          <div className="w-full relative flex flex-col gap-1 bg-[#C19A6B] p-2 md:py-5 md:px-7 rounded-xl  ">
            <p className="font-semibold text-xs md:text-base  text-white tracking-widest">
              Performance Summary
            </p>

            <div className="flex justify-between py-2 px-2 md:px-6 bg-white rounded-xl gap-2 w-full">
              <span className="w-full flex flex-col items-center justify-center   ">
                <p className="font-extrabold text-xs md:text-3xl">$200</p>
                <p className="font-semibold text-[9px] text-nowrap text-[#6B6B6B] ">
                  Total Spend
                </p>
              </span>
              <span className="border-[#EBF0ED] border-r-2"></span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-xs md:text-3xl">1982</p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Clicks
                </p>
              </span>
              <span className="border-[#EBF0ED] border-r-2"></span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-xs md:text-3xl">4553</p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  IMPRESSIONS
                </p>
              </span>
              <span className="border-[#EBF0ED] border-r-2"></span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-xs md:text-3xl">2.3%</p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">CTR</p>
              </span>{" "}
              <span className="border-[#EBF0ED] border-r-2"></span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-xs md:text-3xl">$100</p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">CPC</p>
              </span>{" "}
              <span className="border-[#EBF0ED] border-r-2"></span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-xs md:text-3xl">$19.5</p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">CPM</p>
              </span>{" "}
              <span className="border-[#EBF0ED] border-r-2"></span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-xs md:text-3xl">1.32</p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">ROI</p>
              </span>
            </div>
            <button className=" hidden absolute w-6 h-6 rounded-full bg-white md:flex items-center justify-center top-1/2 left-[2px]">
              <img
                className=""
                src="/assets/icons/boost-performance/left1.svg"
                alt=""
              />
            </button>
            <button className="hidden absolute w-6 h-6 rounded-full bg-white md:flex items-center justify-center top-1/2 right-[2px]">
              <img src="/assets/icons/boost-performance/right1.svg" alt="" />
            </button>
          </div>
        </div>

        {/* graph  */}
        <div className="w-full ">
          <BoosterPerformanceBarChart />
        </div>

        {/* products - keywords */}
        <div className="flex flex-col xl:flex-row gap-8">
          <div className="w-full border border-[#EBF0ED] flex flex-col gap-3  rounded-xl p-2 md:p-4 md:mb-4">
            <h2 className="text-lg font-semibold md:mt-3">Product List</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {cards.slice(0, 4).map((card, index) => (
                <>
                  <div className="flex gap-3 border  border-[#E1E1E1] rounded-2xl">
                    <img
                      className="w-16 h-16 md:w-20 md:h-20 rounded-s-2xl"
                      src={card.img}
                      alt=""
                    />
                    <div className="flex flex-col gap-1 py-[2px] md:py-1">
                      <h2 className="mb-1 text-xs md:text-sm font-semibold">
                        {card.name}
                      </h2>
                      <span className="text-xs md:text-sm font-[500] text-[#6B6B6B]">
                        Category: {card.category}
                      </span>
                      <span className="text-xs md:text-sm font-[500] text-[#6B6B6B]">
                        ID: {card.totalearning}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="w-full border border-[#EBF0ED] flex flex-col gap-3  rounded-xl p-2  md:p-4 mb-4">
            <h2 className="text-base md:text-lg font-semibold md:mt-3">
              Top Keywords
            </h2>
            <div className="flex flex-wrap gap-3">
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Sandals
              </span>
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Bag
              </span>{" "}
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Accessories
              </span>{" "}
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Hair Accessories
              </span>{" "}
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Men Perfume
              </span>{" "}
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Watches
              </span>
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                Vedio Game
              </span>{" "}
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                AR
              </span>{" "}
              <span className="bg-[#FAFAFA] p-2 md:p-3 text-xs rounded-lg">
                VR
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
