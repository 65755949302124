import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PremiumProductBoosterModal from "./PremiumProductBoosterModal";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { listPremiumProduct, premiumProductCategory } from "../../../../store/vendorslice";
import { imagePath } from "../../../../functions/URLString";

export default function PremiumProductBooster() {
  const [activeRole, setActiveRole] = useState("All");
  const { data, message, error, loading } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate()

  const filteredProducts =
    activeRole === "All"
      ? orders
      : orders.filter((user) => user.status === activeRole);

  useEffect(() => {
    // dispatch(listPremiumProduct());
    dispatch(premiumProductCategory());
  }, []);

  // const totalItemPerPages = 10;
  // const [totalPages, setTotalPages] = useState();
  // const [currentPage, setCurrentPage] = useState(1);

  // useEffect(() => {
  //   if (categories?.total) {
  //     let totalLength = categories.total;
  //     let totalPages = Math.ceil(totalLength / totalItemPerPages);
  //     setTotalPages(totalPages);
  //   }
  // }, [categories]);

  // const handlePageChange = (page) => {
  //   if (page >= 1 && page <= totalPages) {
  //     setCurrentPage(page);
  //     dispatch(mainCategory(page));
  //   }
  // };

  // function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
  //   const pages = [];
  //   let tempEnd =
  //     totalNumberOfPages - currentPage > 2
  //       ? currentPage + 2
  //       : totalNumberOfPages;
  //   let tempStart = 0;
  //   if (totalNumberOfPages > 3) {
  //     tempStart =
  //       totalNumberOfPages - currentPage < 2
  //         ? totalNumberOfPages - 3
  //         : currentPage - 1;
  //   }

  //   for (let i = 1; i <= totalNumberOfPages; i++) {
  //     pages.push(i);
  //   }

  //   return (
  //     <>
  //       {pages.slice(tempStart, tempEnd).map((i) => (
  //         <button
  //           key={i}
  //           onClick={() => handlePageChange(i)}
  //           className={`px-2 py-1 rounded ${
  //             currentPage === i
  //               ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
  //               : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
  //           }`}
  //         >
  //           {i}
  //         </button>
  //       ))}
  //     </>
  //   );
  // }

  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solution",
              path: "/vendor/booster/premium-product-booster",
            },
            {
              name: "Premium Product",
              path: "/vendor/booster/premium-product-booster",
            },
          ]}
        />

        <div className="flex flex-col md:flex-row  justify-between gap-4 w-full">
          <div className="w-full md:w-[60%] flex flex-col gap-2 bg-[#C19A6B] p-2 md:p-6 rounded-xl  ">
            <p className="font-semibold text-base  text-white tracking-widest">
              Performance Summary
            </p>
            <p className="font-[400] text-xs  text-white tracking-wide">
              Summary of last 30 days of ads performance.
            </p>

            <div className="flex justify-between py-1 md:py-2 px-2 md:px-6 bg-white rounded-xl gap-2 w-full">
              <span className="w-full flex flex-col items-center justify-center border-r-2 border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl md:text-3xl">$200</p>
                <p className="font-semibold text-[8px] md:text[9px] text-[#6B6B6B] ">
                  Total Spend
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center border-r-2 border-[#EBF0ED] ">
                <p className="font-extrabold text-xl md:text-3xl">1982</p>
                <p className="font-semibold text-[8px] md:text[9px] text-[#6B6B6B]">
                  Ckicks
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center  border-r-2 border-[#EBF0ED] ">
                <p className="font-extrabold text-xl md:text-3xl">3.12</p>
                <p className="font-semibold text-[8px] md:text[9px] text-[#6B6B6B]">
                  ROI
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center ">
                <p className="font-extrabold text-xl md:text-3xl">$1200</p>
                <p className="font-semibold text-[8px] md:text[9px] text-[#6B6B6B]">
                  GMV
                </p>
              </span>
            </div>
          </div>
          <div className="w-full md:w-[40%] md:text-end">
            <button onClick={()=>navigate(`/vendor/booster/premium-product-booster/your-bids`)} className="bg-[#C19A6B] text-xs md:text-base py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg text-white">
              Your Bids
            </button>
          </div>
        </div>

        {/* search */}
        <div className="flex flex-col gap-3 md:mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Order …"
            />
          </div>
        </div>

        {/* table */}
        <table className="w-full bg-white  border border-[#EBF0ED] border-separate rounded-lg mt-2">
          {/* Table Header */}
          <thead className=" w-full border-b border-gray-300 ">
            <tr className="flex w-full justify-between items-center gap-4  p-2 md:p-4 text-xs md:text-base  text-[#6B6B6B] font-semibold text-[12px] ">
              <th className="w-[200px] text-left">MAIN CATEGORY</th>

              <th className="w-[100px] text-left">ACTION</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="w-full">
            {!loading
              ? data?.data?.length > 0 &&
              data.data.map((order) => (
                  <tr
                    key={order.id}
                    className="flex w-full justify-between items-center gap-4  p-4 border-t  font-semibold text-sm "
                  >
                    <td className="w-[200px]  ">
                      <div className="flex text-xs md:text-base items-center gap-3">
                        <img
                          className="w-[60]  md:w-[72px] h-[48px] md:h-[60px] rounded-lg"
                          src={order.image}
                          alt=""
                        />
                        <span>{order.name}</span>
                      </div>
                    </td>

                    <td className="w-[100px] ">
                      <Link
                        to={`/vendor/booster/premium-product-booster/premium-product/${order.id}`}
                      >
                        <img
                          src="/assets/icons/manage-products/view.svg"
                          alt=""
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              : "loading"}
          </tbody>
        </table>

        {/* Pagination */}
        {/* {!loading &&
        data &&
        data?.data?.length > 0 ? ( */}
          {/* <div className="flex flex-col md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING {categories.from + "-" + categories.to} OF{" "}
              {categories.total}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0"> */}
              {/* <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div> */}
              {/* Page Numbers */}
              {/* <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div> */}
              {/* Next Button */}
              {/* <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )} */}

      </div>
      <PremiumProductBoosterModal />
    </>
  );
}
