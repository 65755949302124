import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVendorProduct,
  listVendorProduct,
  vendorProductSearch,
} from "../../../store/vendorslice";

const ProductManagmentManageProduct = () => {
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [activeRole, setActiveRole] = useState("All");
  const [instockCount, setInstockCount] = useState(0);
  const [outofstockCount, setOutofstockCount] = useState(0);
  const { data, loading } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  const deleteProduct = (id) => {
    dispatch(deleteVendorProduct(id));
    dispatch(listVendorProduct());
    setCurrentPage(1);
  };

 
  // Filter product based on active role
  const filteredProducts =
    activeRole === "All"
      ? product
      : product.filter((item) => item.status === activeRole);

  useEffect(() => {
    if (data?.total) {
      let totalLength = data.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  useEffect(() => {
    dispatch(listVendorProduct());
  }, [dispatch]);

  // Pagination control

  const totalPagesa = Math.ceil(filteredProducts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredProducts.length);
  const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

  const shouldShowPagination =
    filteredProducts.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(listVendorProduct(page));
    }
  };
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Products",
            },
          ]}
        />
        {/* search */}
        <div className="w-full flex gap-3 items-center">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="w-full  px-8 py-2 md:py-3 text-xs md:text-sm rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px]"
              type="search"
              placeholder="Search Products..."
              onChange={(e)=> dispatch(vendorProductSearch(e.target.value))}
            />
          </div>

          <Link
            to="/vendor/my-shop/product-management/add-product"
            className="bg-[#C19A6B] px-5 md:px-9 py-2 md:py-3 text-xs md:text-sm text-nowrap  rounded-lg text-white  font-semibold"
          >
            Add Product
          </Link>
        </div>

        {/* filter */}
        <div className="flex text-[10px] md:text-sm gap-6 ">
          <NavLink
            onClick={() => setActiveRole("All")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ALL PRODUCTS
            <span
              className={` font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              {product.length}
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("In Stock")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "In Stock"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            AVAILABLE
            <span
              className={` font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "In Stock"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {instockCount}
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("Out of Stock")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Out of Stock"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            OUT OF STOCK
            <span
              className={` font-semibold px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Out of Stock"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {outofstockCount}
            </span>
          </NavLink>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[668px] md:min-w-[1024px] lg:w-full">
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                {" "}
                <span className="w-[35%] text-left">PRODUCT </span>
                <span className="w-[10%] text-left">PRICE</span>
                <span className="w-[15%] text-left">THRESHOLD</span>
                <span className="w-[10%] text-left">STOCK</span>
                <span className="w-[20%] text-left text-nowrap">
                  TOTAL EARNING
                </span>
                <span className="w-[25%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {!loading ?
                data?.products?.length > 0 &&
                data?.products.map((item) => {
                  console.log(item);
                  return (
                    <div
                      key={item.id}
                      className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="flex items-center gap-1 w-[35%] text-left">
                        <img
                          src={item.image}
                          className="w-8 h-8 md:w-10 md:h-10 rounded-lg "
                          alt=""
                        />
                        <div className="flex flex-col">
                          <span>{item?.name && item?.name}</span>
                          <span className="text-[#6B6B6B] font-[500] text-nowrap">
                            Category: {item?.category?.name}
                          </span>
                        </div>
                      </div>
                      <span className="w-[10%] text-left">${item.price}</span>
                      <div className="w-[15%] text-left">
                        {/* <span
                          className={`${
                            item.status === "1"
                              ? "bg-[#F6FFF7]  px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                              : "bg-[#FFF3F4] px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl"
                          }`}
                        >
                          {item.status === 0
                            ? "pending"
                            : item.status === 1
                            ? "accepted"
                            : "rejected"}
                        </span> */}
                        {item.threshold}
                      </div>
                      <span className="w-[10%] text-left">{item.quantity}</span>
                      <span className="w-[20%] text-left">${12}</span>
                      <div className="w-[25%] text-left flex gap-2 items-center">
                        <img
                          className="w-[28px] md:w-[38px]"
                          src="/assets/icons/view.svg"
                          alt=""
                        />
                        <Link
                          to={
                            "/vendor/my-shop/product-management/edit-product/" +
                            item.id
                          }
                        >
                          <img
                            className="w-[28px] md:w-[38px]"
                            src="/assets/icons/Edit.svg"
                            alt=""
                          />
                        </Link>

                        <img
                          onClick={(e) => deleteProduct(item.id)}
                          className="w-[28px] md:w-[38px]"
                          src="/assets/icons/delete1.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  );
                }):"loading.."}
            </div>
          </div>
        </div>

        {!loading &&
        data?.products?.length > 0? (
          <div className="flex flex-col md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              {/* SHOWING {categories.from + "-" + categories.to} OF{" "}
              {categories.total} */}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ProductManagmentManageProduct;
