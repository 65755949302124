import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./drower.css";
import VendorDashboardInreelDrower from "./VendorDashboardInreelDrower";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  addInreels,
  clearMessage,
  getInreelByID,
  listVendorProduct,
  updateInreels,
  vendorProductSearch,
} from "../../../store/vendorslice";
import { toast } from "react-toastify";

export default function VendorInreelsUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productTags, setProductTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [initialTags, setInitialTags] = useState([]);
  const [initialVideo, setInitialVideo] = useState(null);

  const { data, message, error, loading, inReelByID } = useSelector(
    (state) => state.admin
  );
  const dispatch = useDispatch();

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    dispatch(listVendorProduct({}));
    dispatch(getInreelByID(id));
  }, [dispatch, id]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedVideo(file);
    }
  };

  const handleDelete = () => {
    setUploadedVideo(null);
  };

  const handleTagAdd = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag && !productTags.some((tag) => tag.tag === newTag)) {
        setProductTags([...productTags, { id: Date.now(), tag: newTag }]);
      }
      event.target.value = "";
    }
  };

  const handleTagDelete = (tag) => {
    setProductTags(productTags.filter((t) => t.id !== tag.id));
  };

  useEffect(() => {
    if (inReelByID?.inReel?.in_reel_products) {
      setProductTags(inReelByID?.inReel?.in_reel_tags);
      setInitialTags(inReelByID?.inReel?.in_reel_tags);
      setUploadedVideo(inReelByID?.inReel?.video);
      setInitialVideo(inReelByID?.inReel?.video);
      setSelectedIds(
        inReelByID?.inReel?.in_reel_products?.map(
          (product) => product.product_id
        )
      );
    }
  }, [inReelByID]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedIds(data?.products?.map((product) => product.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });

    if (!isChecked) {
      setSelectAll(false);
    } else if (selectedIds.length + 1 === data.products.length) {
      setSelectAll(true);
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      setSelectAll(false);
      setSelectedIds([]);
      navigate(-1);
      dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [message, error, dispatch, navigate]);

  const handleSubmit = () => {
    const formData = new FormData();

    if (selectedIds.length < 4) {
      toast.error("Select at least four products!");
    } else {
      // Add product IDs
      selectedIds.forEach((id) => formData.append("product_id[]", id));

      // Add updated tags
      productTags.forEach((tag) => {
        if (
          !initialTags.some(
            (initialTag) =>
              initialTag.id === tag.id && initialTag.tag === tag.tag
          )
        ) {
          const tagPayload = { tag: tag.tag };
          if (
            tag.id &&
            initialTags.some((initialTag) => initialTag.id === tag.id)
          ) {
            tagPayload.id = tag.id;
            tagPayload.in_reel_id = inReelByID?.inReel?.id;
          }
          formData.append("tag[]", JSON.stringify(tagPayload));
        }
      });

      // Add updated video if changed
      if (uploadedVideo !== initialVideo) {
        formData.append("video", uploadedVideo);
      }

      dispatch(updateInreels(formData));
    }
  };

  return (
    <>
      <div className="xl:px-4">
        {/* buttons */}
        <div className="flex justify-between  ">
          <BreadCrums
            breadCrum={[
              {
                name: "InReels",
                path: "/vendor/my-shop/inreels",
              },
              {
                name: "Create InReels",
                path: "/vendor/my-shop/inreels/upload-inreels",
              },
            ]}
          />{" "}
          <div className="flex gap-2">
            <button
              disabled={loading}
              onClick={(e) => handleSubmit()}
              className="py-2 md:py-[10px] px-5 md:px-9 text-xs md:text-[15px] bg-[#C19A6B]  rounded-lg text-white  font-semibold"
            >
              Save
            </button>

            <div
              onClick={toggleDrawer}
              className="px-4 py-[10px] cursor-pointer  shadow-lg text-[15px]  flex items-center justify-center rounded-l-lg text-white text-center font-semibold"
            >
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.334746 5.84716C-0.111583 6.26366 -0.111583 6.94006 0.334745 7.35657L6.04775 12.6878C6.49408 13.1043 7.21892 13.1043 7.66525 12.6878C8.11158 12.2713 8.11158 11.5949 7.66525 11.1784L2.7592 6.6002L7.66168 2.02198C8.10801 1.60548 8.10801 0.929079 7.66168 0.512575C7.21535 0.0960705 6.49051 0.0960704 6.04419 0.512574L0.331175 5.84382L0.334746 5.84716Z"
                  fill="#C19A6B"
                />
              </svg>
            </div>
          </div>
        </div>
        {/* tags */}

        <div className="flex flex-col gap-2">
          <label className="text-[#6B6B6B] font-semibold" htmlFor="productTags">
            TAGS
          </label>
          <div className=" flex items-center rounded-lg w-full h-[50px] px-5 border border-[#E4E4E4] outline-none">
            {productTags?.map((tag) => (
              <span
                key={tag.id || tag.tag}
                className="bg-gray-200 text-gray-700 rounded-lg px-3 h-[40px] inline-flex items-center gap-2 mr-2"
              >
                {tag.tag}
                <button
                  onClick={() => handleTagDelete(tag)}
                  className="text-red-500"
                >
                  ✕
                </button>
              </span>
            ))}
            <input
              type="text"
              onKeyPress={handleTagAdd}
              className="bg-transparent outline-none w-auto inline-flex"
              placeholder="Enter Tags"
            />
          </div>
        </div>
        {/* uplaod vedio */}
        <div className="flex items-center justify-center my-2">
          <div className="w-full">
            <h2 className="font-semibold py-2">Upload Video</h2>
            <div
              className="w-full  float-right h-[137px] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded-md cursor-pointer"
              onClick={() =>
                document.getElementById("video-upload-input").click()
              }
            >
              <div className="flex flex-col items-center justify-center">
                <img src="/assets/icons/StoreDecoration/uplaod.svg" alt="" />
                <p>Upload Video</p>
              </div>
            </div>

            <input
              type="file"
              id="video-upload-input"
              className="hidden"
              accept="video/*"
              onChange={handleFileChange}
            />
          </div>
          {/* Display uploaded video card if uploaded video */}
          {uploadedVideo && (
            <div className="border mt-10 ml-2 relative rounded-md p-1 w-[200px] h-[137px] flex flex-col items-center justify-center bg-white">
              <button
                className="absolute cursor-pointer top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                onClick={handleDelete}
              >
                ×
              </button>
              <video
                src={uploadedVideo}
                controls
                width="200px"
                height="250px"
              />
              <p className="mt-2 text-sm text-center">Uploaded Video</p>
            </div>
          )}
        </div>

        {/* products */}
        <div>
          <h2 className="font-semibold ">Products</h2>
          <div className="relative flex items-center w-full py-4">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search product ..."
              onChange={(e) => dispatch(vendorProductSearch(e.target.value))}
            />
          </div>
        </div>
        {/* table */}
        <div className="flex justify-center border-gray-500">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden">
            {/* Table Header */}
            <div className="flex  items-center p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                className="mr-4"
              />

              <span className="flex flex-1 text-left">PRODUCTS</span>

              <span className="flex flex-1 justify-end">PRICE</span>

              {/* <span className="flex flex-1 text-center">COMMISSION</span> */}
              <span className="flex flex-1 justify-end">TOTAL EARNING</span>

              {/* <span className="flex  mr-2  text-center ">ACTION</span> */}
            </div>

            {/* Table Data Row */}
            {data?.products?.length > 0 ? (
              data.products.map((item, index) => (
                <>
                  <div
                    className="flex items-center text-[#18120F]  p-4 border-b "
                    key={item.id}
                  >
                    <input
                      type="checkbox"
                      className="mr-4"
                      checked={selectedIds.includes(item.id)}
                      onChange={(event) => handleCheckboxChange(event, item.id)}
                    />

                    <div className="flex flex-1 items-center">
                      <img
                        src={item.image}
                        alt="Product"
                        className="w-10 h-10 rounded-lg mr-4"
                      />
                      <div>
                        <p className="text-sm tracking-wide pb-1  text-[#18120F] font-semibold ">
                          {item.name}
                        </p>
                        <p className="text-[#6B6B6B] tracking-wider  font-semibold text-sm">
                          Category:{" "}
                          {item?.category?.name
                            ? item?.category?.name
                            : "no category"}
                        </p>
                      </div>
                    </div>

                    <span className="flex text-sm font-[600] text-[#18120F] flex-1 justify-end">
                      ${item.price}
                    </span>

                    {/* <span className="flex text-sm font-[600] flex-1 text-center">
                      ${item.commission}
                    </span> */}
                    <span className="flex text-sm font-[600] flex-1 justify-end pr-4">
                      ${0}
                    </span>

                    {/* <div className="flex gap-3 text-sm font-[600]   justify-around">
                      <div className="bg-[#FAFAFA] rounded-xl p-3 border border-[#EBF0ED]">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 6.69999C16 7.96011 12.6421 12.3999 8.49999 12.3999C4.35785 12.3999 1 7.96011 1 6.69999C1 5.43988 4.35785 1 8.49999 1C12.6421 1 16 5.43988 16 6.69999Z"
                            stroke="#828282"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.50078 8.68052C9.76878 8.68052 10.482 7.96727 10.482 6.69927C10.482 5.43127 9.76878 4.71802 8.50078 4.71802C7.23278 4.71802 6.51953 5.43127 6.51953 6.69927C6.51953 7.96727 7.23278 8.68052 8.50078 8.68052Z"
                            stroke="#828282"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div> */}
                  </div>
                </>
              ))
            ) : (
              <p>No Products Found</p>
            )}
          </div>
        </div>

        {/* drawer */}
        {isOpen && (
          <>
            <div className="relative ">
              <div
                className={` fixed top-20 right-0  border rounded-l-2xl shadow-xl bg-white  ${
                  isOpen ? " drower-custom" : " drowertoright "
                }`}
              >
                <div className="p-4 overflow-y-auto h-[32rem]">
                  <div
                    onClick={toggleDrawer}
                    className="w-[34px] h-[50px] cursor-pointer  shadow-lg text-[15px]  flex items-center justify-center rounded-r-lg text-white text-center font-semibold"
                  >
                    <svg
                      width="8"
                      height="13"
                      viewBox="0 0 8 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66525 7.15284C8.11158 6.73634 8.11158 6.05994 7.66525 5.64343L1.95224 0.312182C1.50591 -0.104322 0.781076 -0.104322 0.334747 0.312182C-0.111582 0.728685 -0.111582 1.40509 0.334747 1.82159L5.24079 6.3998L0.338318 10.978C-0.108011 11.3945 -0.108011 12.0709 0.338318 12.4874C0.784647 12.9039 1.50949 12.9039 1.95581 12.4874L7.66882 7.15618L7.66525 7.15284Z"
                        fill="#C19A6B"
                      />
                    </svg>
                  </div>
                  <VendorDashboardInreelDrower
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
