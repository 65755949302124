import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, clearMessage } from "../store/vendorslice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, error, loading, token, step } = useSelector(
    (state) => state.admin
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = () => {
    if (email === "" && password === "") {
      toast.error("Please fill all fields");
    } else {
      dispatch(loginUser({ email, password }));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (message === "Vendor login Successfully") {
        toast.success(message);
        if (token && step == 1) {
          navigate("/vendor/profile-creation");
        } else if (token && step == 2) {
          navigate("/vendor/profile-creation/address");
        } else if (token && step == 0) {
          toast.success(message);
          navigate("/vendor/my-shop/home");
        }
      }
      if (error) {
        toast.error(error);
      }
    }
    dispatch(clearMessage());
  }, [loading, message, error,token, dispatch, step, navigate]);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // if someone come to the login page we are clearing it existing token and other details
  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  return (
    <div className="  h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Login to Account
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Login to your Wizalot account!
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <input
              type="email"
              placeholder="mike.tyson@mail.com"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </label>
          <Link
            to="/forgot-password"
            className="flex float-end pt-1 text-[#C19A6B] text-sm md:text-base font-semibold"
          >
            forgot password?
          </Link> 
          <button
            disabled={loading}
            onClick={handleLogin}
            className="bg-[#C19A6B] w-full rounded-lg p-3 md:p-4 mt-[3rem] text-white font-bold tracking-wider flex justify-center items-center"
          >
            {!loading ? (
              "Login"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button>
        </div>
        <p className="mt-3">
          Don't have an account? <span onClick={()=>navigate("/vendor/profile-creation")} className="text-[#C19A6B] hover:font-bold cursor-pointer">Sign Up</span>
        </p>
      </div>
    </div>
  );
}
