import React, { useEffect, useRef, useState } from "react";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  clearMessage,
  fetchAllBrands,
  fetchAllMainCategories,
  fetchSubCategories,
  fetchSubSubCategories,
} from "../../../store/vendorslice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ProductManagmentAddProduct() {
  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");
  const [productSubSubCategory, setProductSubSubCategory] = useState("");
  const [brands, setBrands] = useState("");
  const [productTags, setProductTags] = useState([]);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(null);
  const [inputFields, setInputFields] = useState([
    { attribute: "", price: "" },
  ]);
  const [coverImage, setCoverImage] = useState(null);
  const [images, setImages] = useState(Array(5).fill(null));
  const labels = ["Image 1", "Image 2", "Image 3", "Image 4", "Image 5"];
  const [payload, setPayload] = useState({
    name: "",
    description: "",
    affiliate: isAffiliate,
    commission: "",
    brand_id: brands,
    category_id: productCategory,
    subcategory_id: "",
    subsubcategory_id: "",
    tag: [],
    short_description: "",
    cover_image: null,
    images: [],
    price: 0,
    discountprice: 0,
    sale_from: "",
    sale_to: "",
    threshold: "",
    quantity: 0,
    meta_data: [],
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data,
    loading,
    error,
    message,
    category,
    subCategory,
    subSubCategory,
    brand,
  } = useSelector((state) => state.admin);

  const handleAddField = () => {
    setInputFields([...inputFields, { attribute: "", price: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = inputFields.filter((_, i) => i !== index);
    setInputFields(updatedFields);
    setPayload({ ...payload, meta_data: updatedFields });
  };

  const handleInputChange = (index, event, name) => {
    if (name === "attribute") {
      const newInputFields = [...inputFields];
      newInputFields[index].attribute = event.target.value;
      setInputFields(newInputFields);
    } else {
      const newInputFields = [...inputFields];
      newInputFields[index].price = event.target.value;
      setInputFields(newInputFields);
    }
    setPayload({ ...payload, meta_data: inputFields });
  };

  const handleAffiliateChange = (event) => {
    setIsAffiliate(event.target.value === "yes" ? "yes" : "no");
    setPayload({ ...payload, affiliate: isAffiliate });
  };

  const updateImage = (index, file) => {
    const newImageArray = [...payload.images];
    newImageArray[index] = file;
    setPayload({
      ...payload,
      images: newImageArray,
    });
  };

  const handleImageUpload = (index, event) => {
    if (index === "cover") {
      const file = event.target.files[0];
      if (file) {
        setPayload({ ...payload, cover_image: file });
        const reader = new FileReader();
        reader.onload = () => {
          setCoverImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      const file = event.target.files[0];
      if (file) {
        // payload.images.push(file)
        updateImage(index, file);
        const reader = new FileReader();
        reader.onload = () => {
          const newImages = [...images];
          newImages[index] = reader.result;
          setImages(newImages);
          // updateImage(index, reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDeleteImage = (index) => {
    if (index === "index") {
      setCoverImage(null);
      setPayload({ ...payload, cover_image: null });
    } else {
      updateImage(index, null);
      const newImages = [...images];
      newImages[index] = null;
      setImages(newImages);
    }
  };

  const handleCategorySelect = (category, name) => {
    if (name === "main") {
      setProductCategory(category);
      if (category === "") {
        setPayload({
          ...payload,
          category_id: category,
          subcategory_id: category,
          subsubcategory_id: category,
        });
        setProductSubCategory(category);
        setProductSubSubCategory(category);
      } else {
        setPayload({ ...payload, category_id: category });
        dispatch(fetchSubCategories(category));
      }
    } else if (name === "sub") {
      setProductSubCategory(category);
      if (category === "") {
        setPayload({
          ...payload,
          subcategory_id: category,
          subsubcategory_id: category,
        });
        setProductSubSubCategory(category);
      } else {
        setPayload({ ...payload, subcategory_id: category });
        dispatch(fetchSubSubCategories(category));
      }
    } else {
      setProductSubSubCategory(category);
      setPayload({ ...payload, subsubcategory_id: category });
    }
  };

  const handleBrandSelect = (category) => {
    setBrands(category);
    setDropdownOpen1(false);
    setPayload({ ...payload, brand_id: category });
  };

  const handleTagAdd = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag && !productTags.includes(newTag)) {
        setProductTags([...productTags, newTag]);
        var arr = [...payload.tag];
        arr.push(newTag);
        setPayload({ ...payload, tag: arr });
      }
      event.target.value = "";
    }
  };

  const handleTagDelete = (tag) => {
    setProductTags(productTags.filter((t) => t !== tag));
    var arr = [...payload.tag].filter((t) => t !== tag);

    setPayload({ ...payload, tag: arr });
  };

  const handleSubmit = () => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (
        key == "images" ||
        value == "" ||
        key == "meta_data" ||
        key == "tag"
      ) {
      } else {
        formData.append(key, value);
      }
    }

    payload.images.forEach((file, index) => {
      if (file instanceof File) {
        formData.append("images[]", file);
      }
    });
    if (payload?.tag?.length > 0) {
      payload.tag.forEach((item, index) => {
        formData.append("tag[]", item);
      });
    } else {
      toast.error("Kindly Add Atleast One Tag");
      return;
    }

    payload.meta_data.forEach((item, index) => {
      formData.append("meta_data[]", JSON.stringify(item));
    });
   
    dispatch(addProduct(formData));
  };

  useEffect(() => {
    if (message == "Product inserted successfully") {
      toast.success(message);
      // navigate(-1);s
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [error, message]);

  useEffect(() => {
    dispatch(fetchAllMainCategories());
    dispatch(fetchAllBrands());
  }, []);

  return (
    <>
      <div className="md:px-4 w-full">
        {/* head */}
        <div className="flex justify-between ">
          <BreadCrums
            breadCrum={[
              {
                name: "Products",
                path: "/vendor/my-shop/product-management",
              },
              {
                name: "Upload Product",
              },
            ]}
          />
          <div className="flex gap-3">
            {/* <button className="px-3 py-1 md:px-6 md:py-3  text-center text-[10px] md:text-xs text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Draft
            </button> */}
            {loading ? (
              <button
                disabled={true}
                className="px-3 py-1 md:px-6 md:py-3 text-[10px]  md:text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
              >
                Publishing....
              </button>
            ) : (
              <button
                onClick={(e) => handleSubmit()}
                className="px-3 py-1 md:px-6 md:py-3 text-[10px]  md:text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
              >
                Publish
              </button>
            )}
          </div>
        </div>

        <div className="py-5 flex flex-col md:flex-row gap-4">
          {/* Left Side -------------------------------- */}
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-4">
              {/* description */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Description
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="product name "
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none "
                      type="text"
                      name="product name"
                      id=""
                      placeholder="Enter product name "
                      value={payload.name}
                      onChange={(e) => {
                        setPayload({ ...payload, name: e.target.value });
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] text-xs h-16 placeholder: rounded-lg w-full py-3 px-3 outline-none"
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Enter product description "
                      value={payload.description}
                      onChange={(e) => {
                        setPayload({ ...payload, description: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Affilliate */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Affiliate
                </h1>
                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex items-center gap-2 text-xs font-semibold text-[#6B6B6B]">
                    <span>Available For Affiliate</span>
                    <input
                      type="radio"
                      name="affiliate"
                      id="affiliate-yes"
                      value="yes"
                      onChange={handleAffiliateChange}
                    />
                    <label htmlFor="affiliate-yes">Yes</label>
                    <input
                      type="radio"
                      name="affiliate"
                      id="affiliate-no"
                      value="no"
                      onChange={handleAffiliateChange}
                    />
                    <label htmlFor="affiliate-no">No</label>
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="commission"
                    >
                      COMMISSION $
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-xs rounded-lg w-full py-2 px-3 outline-none"
                      type="number"
                      name="commission"
                      id="commission"
                      placeholder="$"
                      disabled={!isAffiliate}
                      value={payload.commission}
                      onChange={(e) => {
                        setPayload({ ...payload, commission: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* CATEGORIES */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Product Categories
                </h1>

                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  {/* brand dropdown */}
                  <div className="relative flex flex-col gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="brands"
                    >
                      BRAND
                    </label>
                    <div className="relative w-full">
                      {brand.length > 0 && (
                        <select
                          name=""
                          id=""
                          value={payload.brand_id}
                          onChange={(e) => handleBrandSelect(e.target.value)}
                          className="bg-[#FAFAFA] placeholder:text-black text-xs md:text-sm rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                        >
                          <option value="">none</option>
                          {brand.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      )}
                    </div>
                  </div>
                  {/* Product Category Dropdown */}
                  <div className="relative flex flex-col gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="productCategory"
                    >
                      PRODUCT CATEGORY
                    </label>
                    <div className="relative w-full">
                      <select
                        name=""
                        id=""
                        value={payload.category_id}
                        onChange={(e) =>
                          handleCategorySelect(e.target.value, "main")
                        }
                        className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                      >
                        <option value="">none</option>
                        {category.length > 0 &&
                          category.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {productCategory != "" && (
                    <div className="relative flex flex-col gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 whitespace-nowrap md:ml-3 font-semibold"
                        htmlFor="productCategory"
                      >
                        PRODUCT SUB-CATEGORY
                        <span className="text-gray-400 font-normal pl-1">
                          (OPTIONAL)
                        </span>
                      </label>
                      <div className="relative w-full">
                        <select
                          name=""
                          id=""
                          value={payload.subcategory_id}
                          onChange={(e) =>
                            handleCategorySelect(e.target.value, "sub")
                          }
                          className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                        >
                          <option value="">none</option>
                          {subCategory.length > 0 &&
                            subCategory.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}

                  {productSubCategory != "" && productCategory != "" && (
                    <div className="relative flex flex-col gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 whitespace-nowrap md:ml-3 font-semibold"
                        htmlFor="productCategory"
                      >
                        PRODUCT SUBSUB-CATEGORY
                        <span className="text-gray-400 font-normal pl-1">
                          (OPTIONAL)
                        </span>
                      </label>
                      <div className="relative w-full">
                        <select
                          name=""
                          id=""
                          value={payload.subsubcategory_id}
                          onChange={(e) =>
                            handleCategorySelect(e.target.value, "subsub")
                          }
                          className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                        >
                          <option value="">none</option>
                          {subSubCategory.length > 0 &&
                            subSubCategory.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}

                  {/* Product Tags */}
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="productTags"
                    >
                      PRODUCT TAGS
                    </label>
                    <div className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none">
                      {productTags.map((tag) => (
                        <span
                          key={tag}
                          className="bg-gray-200 text-gray-700 rounded-lg px-3 py-1 inline-flex items-center gap-2 mr-2"
                        >
                          {tag}
                          <button
                            onClick={() => handleTagDelete(tag)}
                            className="text-red-500"
                          >
                            ✕
                          </button>
                        </span>
                      ))}
                      <input
                        type="text"
                        onKeyPress={handleTagAdd}
                        className="bg-transparent outline-none text-xs w-auto inline-flex"
                        placeholder="Press Enter to add tags"
                      />
                    </div>
                  </div>
                  {/* desc */}
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT SHORT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] h-16 text-xs placeholder: rounded-lg w-full py-3 px-3 outline-none"
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Add short description for product"
                      value={payload.short_description}
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          short_description: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side ------------------------------- */}
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-4">
              {/* Product gallery */}
              <div className="w-full flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm  md:text-lg font-semibold">
                  Product Gallery
                </h1>
                <div className=" grid grid-cols-4 md:grid-cols-5 gap-3 md:gap-4 border  py-[15px] px-3 border-[#EBF0ED] rounded-lg">
                  <div
                    key="coverImg"
                    className="relative flex flex-col items-center "
                  >
                    {!coverImage ? (
                      <div
                        className="relative w-14 h-14  lg:w-20 lg:h-20 xl:w-[90px] xl:h-[90px] cursor-pointer border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0]"
                        onClick={() =>
                          document.getElementById(`coverInput`).click()
                        }
                      >
                        <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                          <span>+</span>
                        </label>
                        <input
                          type="file"
                          id="coverInput"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleImageUpload("cover", event)
                          }
                        />
                      </div>
                    ) : (
                      <div className="relative">
                        <img
                          src={coverImage}
                          alt="{`uploaded-${index}`}"
                          className="w-14 h-14 md:w-[90px] md:h-[90px]  object-cover rounded-lg"
                        />
                        <button
                          onClick={() => handleDeleteImage("index")}
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] md:text-xs"
                        >
                          x
                        </button>
                      </div>
                    )}
                    <div className="md:mt-1 text-center text-[8px] lg:text-xs xl:text-sm text-[#828282]">
                      Cover Image
                    </div>
                  </div>

                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col items-center "
                    >
                      {!image ? (
                        <div
                          className="relative w-14 h-14  lg:w-20 lg:h-20 xl:w-[90px] xl:h-[90px] cursor-pointer border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0]"
                          onClick={() =>
                            document.getElementById(`fileInput${index}`).click()
                          }
                        >
                          <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                            <span>+</span>
                          </label>
                          <input
                            type="file"
                            id={`fileInput${index}`}
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleImageUpload(index, event)
                            }
                          />
                        </div>
                      ) : (
                        <div className="relative">
                          <img
                            src={image}
                            alt={`uploaded-${index}`}
                            className="w-14 h-14 md:w-[90px] md:h-[90px]  object-cover rounded-lg"
                          />
                          <button
                            onClick={() => handleDeleteImage(index)}
                            className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] md:text-xs"
                          >
                            x
                          </button>
                        </div>
                      )}
                      <div className="md:mt-1 text-center text-[8px] lg:text-xs xl:text-sm text-[#828282]">
                        {labels[index]}
                        {index < 2 && <span className="text-red-600">*</span>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Product Price */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Price & Stock
                </h1>
                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        PRICE $
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                        type="number"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.price}
                        onChange={(e) => {
                          setPayload({ ...payload, price: e.target.value });
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        SALE PRICE%
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                        type="number"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.discountprice}
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            discountprice: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        SALE FROM
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                        type="date"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.sale_from}
                        onChange={(e) => {
                          setPayload({ ...payload, sale_from: e.target.value });
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        SALE TO
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                        type="date"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.sale_to}
                        onChange={(e) => {
                          setPayload({ ...payload, sale_to: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        STOCK QUANTITY
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                        type="number"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.quantity}
                        onChange={(e) => {
                          setPayload({ ...payload, quantity: e.target.value });
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        STOCK THRESHOLD
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                        type="number"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.threshold}
                        onChange={(e) => {
                          setPayload({ ...payload, threshold: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Product Price */}
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                    meta_data
                  </h1>
                  <label
                    onClick={handleAddField}
                    className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white"
                  >
                    <span>+</span>
                  </label>
                </div>

                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  {inputFields.map((inputField, index) => (
                    <div className="flex gap-2 items-center">
                      <div className="w-full flex flex-col gap-1 md:gap-2">
                        <label
                          className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                          htmlFor="price"
                        >
                          ATTRIBUTE
                        </label>
                        <input
                          className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                          type="text"
                          name="attribute"
                          id=""
                          placeholder="attribute"
                          value={inputField.attribute}
                          onChange={(event) =>
                            handleInputChange(index, event, "attribute")
                          }
                        />
                      </div>
                      <div className="w-full flex flex-col gap-1 md:gap-2">
                        <div className="w-full h-fit flex flex-row justify-between">
                          <label
                            className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                            htmlFor="price"
                          >
                            PRICE
                          </label>
                          <button
                            onClick={() => handleRemoveField(index)}
                            disabled={loading}
                            className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold cursor-pointer hover:bg-gray-100 rounded-full px-1"
                          >
                            x
                          </button>
                        </div>
                        <input
                          className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                          type="number"
                          name="price"
                          id=""
                          placeholder="0 "
                          value={inputField.price}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
