import AffilateProductAddPage from "../../pages/My-Shop/Affilate-Products-Pages/AffilateProductAddPage";
import AffilateProductManagementPage from "../../pages/My-Shop/Affilate-Products-Pages/AffilateProductManagementPage";
import ProductManagmentAddProductPage from "../../pages/My-Shop/Product-Managment-Pages/ProductManagmentAddProductPage";
import ProductManagmentEditProductPage from "../../pages/My-Shop/Product-Managment-Pages/ProductManagmentEditProductPage";
import ProductManagmentManageProductPage from "../../pages/My-Shop/Product-Managment-Pages/ProductManagmentManageProductPage";

const productsRoutes = [
  {
    path: "/vendor/my-shop/product-management",

    children: [
      {
        index: true,
        element: <ProductManagmentManageProductPage />,
      },
      {
        path: "/vendor/my-shop/product-management/add-product",
        element: <ProductManagmentAddProductPage />,
      },

      {
        path: "/vendor/my-shop/product-management/edit-product/:id",
        element: <ProductManagmentEditProductPage />,
      },
    ],
  },
  {
    path: "/vendor/my-shop/affilate-products",

    children: [
      {
        index: true,
        element: <AffilateProductManagementPage />,
      },
      {
        path: "/vendor/my-shop/affilate-products/add-affilate",
        element: <AffilateProductAddPage />,
      },
      // {
      //   path: "/vendor/my-shop/affilate-products/edit-product/:id",
      //   element: <ProductManagmentEditProductPage />,
      // },
    ],
  },
];

export default productsRoutes;
