import React from "react";
import ProductBooster from "../../../../components/Booster/Marketing-Solutions/Product-Booster-Components/ProductBooster";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";

export default function ProductBoosterPage() {
  return (
    <>
      <BoosterLayout>
        <ProductBooster />
      </BoosterLayout>
    </>
  );
}
