import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import { listVendorProduct } from "../../../store/vendorslice";

export default function JoinOfferModal({ onSelect, selectedProducts, setSelectedProducts, temp, setTemp }) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedCardIds, setSelectedCardIds] = useState([]); // IDs of the selected reels in the modal

  // fetching available vendor products
  useEffect(() => {
    dispatch(listVendorProduct("all"));
  }, []);

  useEffect(() => {
    setTemp(true)
    setSelectedCardIds(selectedCardIds.filter(id => selectedProducts.some(product => product.id === id)));
  }, [temp]);

  // facilitating state with slice data
  useEffect(() => {
    if (!loading && data && data.products) {
      let available_products_list = data.products;
      setAvailableProducts(available_products_list);
    }
  }, [data, loading]);

  const handleCheckboxChange = (item) => {
    const itemId = item.id;
    setSelectedCardIds((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [itemId]
    );

    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.some((product) => product.id === itemId)
        ? prevSelectedProducts.filter((product) => product.id !== itemId)
        : [item]
    );
  };

  const handleSelectAndClose = () => {
    onSelect(selectedProducts);
    document.getElementById("my_modal_4").close();
  };

  return (
    <div>
      {/* Modal code for selecting reels */}
      <dialog id="my_modal_4" className="modal bg-none">
        <div className="modal-box bg-white p-2 md:p-8 max-w-[85rem] max-h-[50rem] rounded-lg relative">
          <form method="dialog">
            {/* header div */}
            <div className="sticky top-0 z-50 bg-white w-full py-2 px-2 md:px-8 flex justify-between">
              <h2 className="font-bold text-[22px]">Select Products</h2>
              <button
                className="text-3xl w-4 h-4  md:w-8 md:h-8 bg-white rounded-full hover:shadow-xl hover:bg-slate-50 shadow-lg"
                onClick={() => document.getElementById("my_modal_4").close()}
              >
                <img
                  src="/assets/icons/cancel1.svg"
                  className="mx-auto"
                  alt="Close"
                />
              </button>
            </div>
            <div className="h-[360px] overflow-y-auto overflow-x-hidden mx-auto mt-3 cardContainer">
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5 w-[95%] mx-auto">
                {loading ? (
                  <div className="flex justify-center py-4">
                    <Loader />
                  </div>
                ) : availableProducts.length > 0 ? (
                  availableProducts.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="card !rounded-lg border-none card-shadow relative cursor-pointer w-full xl:w-[195px]"
                      >
                        {/* heart and discount start here */}
                        <div className="flex z-50 top-1 right-1 absolute items-center">
                          <input
                            type="checkbox"
                            checked={selectedCardIds.includes(item.id)}
                            onChange={() => handleCheckboxChange(item)}
                            className="hidden"
                          />
                          <div
                            className={`w-4 h-4 md:w-8 md:h-8 flex items-center justify-center rounded-full border-2 border-black cursor-pointer transition-colors duration-200 ${
                              selectedCardIds.includes(item.id)
                                ? "bg-black text-white"
                                : "bg-transparent"
                            }`}
                            onClick={() => handleCheckboxChange(item)}
                          >
                            {selectedCardIds.includes(item.id) && <FaCheck />}
                          </div>
                        </div>

                        <div className="gap-1 absolute left-2 mt-1">
                          <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 px-2 rounded-xl mt-2 text-sm font-bold">
                            {item.discountprice}
                          </p>
                        </div>

                        <Link>
                          <img
                            src={item.image}
                            alt="Shoes"
                            className="max-h-[200px] md:max-h-[282px] w-full"
                          />
                        </Link>

                        <div className="px-3 py-1">
                          <Link>
                            <h2 className="card-title text-sm mb-0">
                              {item.name.slice(0, 51)}...
                            </h2>
                          </Link>
                          <div className="h-4">
                            <div className="flex gap-2 items-center slide-from-top">
                              <div className="rating rating-xs space-x-1">
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400"
                                />
                                <input
                                  type="radio"
                                  name="rating-6"
                                  className="mask mask-star-2 bg-orange-400"
                                />
                              </div>
                              <div className="text-[#8C8C8C] text-xs">224</div>
                            </div>
                          </div>

                          <div className="card-actions justify-between items-center">
                            <div className="flex items-center gap-1">
                              <h5 className="font-semibold text-black text-md">
                                <span className="">$</span>
                                {item.price}
                              </h5>
                              <span className="text-sm line-through text-[#8C8C8C]">
                                ${item.discountprice}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center font-bold tracking-wide py-4">
                    No products Found
                  </div>
                )}
              </div>
            </div>
            {/* button */}
            <div className="w-full h-10">
              <Link
                to=""
                className="bg-[#C19A6B] text-white px-6 md:my-4 py-2 rounded-lg absolute bottom-1 md:bottom-2 right-2 md:right-8"
                onClick={handleSelectAndClose}
              >
                Submit
              </Link>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
