import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import TicketDetails from "../../../components/MyShop/Seller-Support-Components/TicketDetails";

export default function TicketDetailsPage() {
  return (
    <>
      <MyShopLayout>
        <TicketDetails />
      </MyShopLayout>
    </>
  );
}
