import React from "react";

export default function TopProducts() {
  return (
    <>
      <div
        className="rounded-2xl py-7 px-6 mt-5 bg-white lg:mx-4"
        style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F" }}
      >
        <h1 className="font-bold">Top Products</h1>

        <div className="lg:mx-10">
          <div className="flex  overflow-auto">
            <table className="bg-white w-full rounded-lg overflow-hidden overflow-x-auto">
              {/* head */}
              <thead className="min-w-[868px] md:min-w-[1024px] lg:w-full">
                <tr className="flex text-left items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-bold  border-gray-300 justify-between">
                  <th className="w-[40%]"></th>
                  <th className="w-[7.5%] ">Sold</th>
                  <th className="w-[20%]">Price</th>
                  <th className="w-[20%]">Profit</th>
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}
                <tr className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 ">
                  <td className="w-[40%]">
                    <div className="flex items-center gap-2">
                      <div>
                        <img
                          className="w-14 h-14 md:h-20 md:w-20"
                          src="/assets/images/dashboard-home/top-product-1.svg"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="font-bold">Leather Flat Sandals</div>
                        <div className="text-[#6B6B6B]">
                          <span>Category: </span>
                          <span>Clothes</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="font-semibold w-[7.5%]">10</td>
                  <td className="font-semibold w-[20%]">$85.00</td>
                  <td className="font-semibold w-[20%]">$850.00</td>
                </tr>
                {/* <tr>
                  <td>
                    <div className="flex items-center gap-4">
                      <div>
                        <img
                          className="h-20 w-20"
                          src="/assets/images/dashboard-home/top-product-1.svg"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="font-bold">Leather Flat Sandals</div>
                        <div className="text-[#6B6B6B]">
                          <span>Category: </span>
                          <span>Clothes</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="font-semibold">10</td>
                  <td className="font-semibold">$85.00</td>
                  <td className="font-semibold">$850.00</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
