import { configureStore } from "@reduxjs/toolkit";
import vendorReducer from "./vendorslice"; // Importing the reducer directly

const store = configureStore({
    reducer: {
        admin: vendorReducer // Using the reducer directly
    }
});

export default store;
