import BreadCrums from "../../../Common/BreadCrums";

export default function AccountStatement() {
  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-6">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Finance",
              path: "/vendor/my-shop/account-statement",
            },
            {
              name: "Account Statement",
              path: "/vendor/my-shop/account-statement",
            },
          ]}
        />

        <div className="flex flex-col w-full">
          <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
            Time Period
          </span>
          <div className="flex justify-between w-full items-center">
            <div></div>

            <div className="flex gap-4 items-center">
              {/* print button  */}
              <div className="flex items-center justify-center gap-2 py-[5px] px-2 md:py-[10px] md:px-5 rounded-lg w-full bg-[#FAFAFA] border border-[#EBF0ED] ">
                <span>
                  <img
                    src="/assets/icons/order-management/export.svg"
                    alt=""
                    className="max-w-[9px] h-[18px]"
                  />
                </span>
                <span className="font-semibold text-xs text-[#828282]">
                  Print
                </span>
              </div>

              {/* export button  */}
              <div className="flex items-center justify-center gap-2 py-[5px] px-2 md:py-[10px] md:px-5 rounded-lg  bg-[#FAFAFA] border border-[#EBF0ED] ">
                <span>
                  <img
                    src="/assets/icons/order-management/export.svg"
                    alt=""
                    className="max-w-[9px] h-[18px]"
                  />
                </span>
                <span className="font-semibold text-xs text-[#828282]">
                  Export
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5 items-center justify-center w-full ">
          {/* total payout  */}
          <div className="flex justify-between items-center gap-6 bg-[#F1F1F1] w-full md:w-3/4 xl:w-1/2 px-4 py-4 md:py-8 rounded-sm">
            {/* 1st div  */}
            <div className="flex flex-col  gap-4">
              <div className="flex flex-col items-start">
                <span className="font-semibold text-base md:text-xl">
                  Total Payout
                </span>
                <span className="font-semibold text-sm md:text-base">
                  Payout Date
                </span>
              </div>

              <div className="flex flex-col gap-1 items-start text-xs md:text-base">
                <span className="font-semibold  text-sm md:text-base">
                  Total Balance
                </span>
                <span>Outstanding dues from previous statement</span>
                <span>Total Delivered Orders</span>
                <span>Total Returned Orders</span>
                <span>Total Services Used</span>
              </div>
            </div>

            {/* div 2  */}
            <div className="flex flex-col gap-4  ">
              <div className="flex flex-col items-end">
                <span className="font-semibold text-base md:text-xl">
                  $1181.38
                </span>
                <span className="font-semibold text-sm md:text-base text-nowrap">
                  Completed on: 2024-05-18
                </span>
              </div>

              <div className="flex flex-col  gap-1 items-end text-xs md:text-base">
                <span className="font-semibold text-sm md:text-base">
                  $1181.38
                </span>
                <span>$0.00</span>
                <span>$1381.38</span>
                <span>$0.00</span>
                <span>-$200.00</span>
              </div>
            </div>
          </div>

          {/* delivered order summary  */}
          <div className="flex flex-col bg-[#F1F1F1] w-full md:w-3/4 xl:w-1/2  px-4 py-4 md:py-8 rounded-sm gap-4">
            <p className="font-semibold text-base md:text-xl">
              Delivered Orders Summary
            </p>
            <div className="flex justify-between items-center gap-6 border-b-2 border-[#DCDCDC] pb-3">
              {/* 1st div  */}

              <div className="flex flex-col gap-2 text-xs   md:text-base items-start">
                <span className="flex flex-col">
                  <span> Delivered Orders</span>
                  <span className="md:pl-6 flex flex-col">
                    <span>Shipping Fee</span>
                    <span>Product Price</span>
                  </span>
                </span>

                <span>Commission Fee</span>

                <span>Withholding Tax</span>
                <span>GST</span>
              </div>

              {/* div 2  */}

              <div className="flex flex-col gap-2 items-end text-xs md:text-base ">
                <span className="flex flex-col">
                  <span>$1181.38</span>
                  <span className="flex flex-col">
                    <span>$1181.38</span>
                    <span>$1181.38</span>
                  </span>
                </span>

                <span>$0.00</span>
                <span>$1381.38</span>
                <span>$0.00</span>
              </div>
            </div>
            <div className="flex justify-between text-xs md:text-base">
              <span>Subtotal</span>
              <span>$1181.38</span>
            </div>
          </div>

          {/* Returned order summary  */}
          <div className="flex flex-col bg-[#F1F1F1] w-full md:w-3/4 xl:w-1/2  px-4 py-4 md:py-8 rounded-sm gap-4">
            <p className="font-semibold text-base md:text-xl">
              Returned Orders Summary
            </p>
            <div className="flex justify-between items-center gap-6 border-b-2 border-[#DCDCDC] pb-3">
              {/* 1st div  */}

              <div className="flex flex-col gap-2 items-start text-xs md:text-base">
                <span>Returned Orders</span>
                <span>Commission Fee</span>
                <span>Withholding Tax</span>
                <span>GST</span>
              </div>

              {/* div 2  */}

              <div className="flex flex-col gap-2 items-end  text-xs md:text-base">
                <span>$1181.38</span>
                <span>$0.00</span>
                <span>$1381.38</span>
                <span>$0.00</span>
              </div>
            </div>
            <div className="flex justify-between text-xs md:text-base">
              <span>Subtotal</span>
              <span>$1181.38</span>
            </div>
          </div>

          {/*Services Used Summary */}
          <div className="flex flex-col bg-[#F1F1F1] w-full md:w-3/4 xl:w-1/2  px-4 py-4 md:py-8 rounded-sm gap-4">
            <p className="font-semibold text-base md:text-xl">
              Services Used Summary
            </p>
            <div className="flex justify-between items-center gap-6 border-b-2 border-[#DCDCDC] pb-3">
              {/* 1st div  */}

              <div className="flex flex-col gap-2 items-start text-xs md:text-base">
                <span>Handling Fee</span>
                <span>Advertising & Marketing Services</span>
                <span>Advertising & Marketing Services</span>
                <span>Inventory Management</span>
                <span>GST</span>
              </div>

              {/* div 2  */}

              <div className="flex flex-col gap-2 items-end text-xs md:text-base">
                <span>$1181.38</span>
                <span>$0.00</span>
                <span>$1381.38</span>
                <span>$1381.38</span>
                <span>$0.00</span>
              </div>
            </div>
            <div className="flex justify-between text-xs md:text-base">
              <span>Subtotal</span>
              <span>$1181.38</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
