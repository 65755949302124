import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import StoreManagmentIntroVedio from "../../../components/MyShop/Store-Decoration-Components/StoreManagmentIntroVedio";

export default function StoreManagmentIntroVedioPage() {
  return (
    <>
      <MyShopLayout>
        <StoreManagmentIntroVedio />
      </MyShopLayout>
    </>
  );
}
