import React, { useState } from "react";
import InSightSearch from "../../components/InSight/InSight-Components/Insight-Searchbar/InSightSearch";
import InSight from "../../components/InSight/InSight-Components/InSight-Map/InSight";

export default function InSightPage() {
  const [test, setTest] = useState(false);

  const handleDrawer = () => {
    setTest(!test);
  };
  return (
    <>
      <InSightSearch
        setTest={setTest}
        handleDrawer={handleDrawer}
        test={test}
      />
      <InSight show={test} />
    </>
  );
}
