import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getCities, getCountries, getStates, vendorAddressGet, vendorAddressUpdate } from "../../../store/vendorslice";
import { toast } from "react-toastify";

export default function Adresses() {
  const [activeTab, setActiveTab] = useState("ADDRESS");
  const [selectedOption, setSelectedOption] = useState(false);
  const [stateNcity, setstateNcity] = useState({states:[],cities:[]});
  const [stateNcity1, setstateNcity1] = useState({states:[],cities:[]});
  const [stateNcitystatus, setstateNcitystatus] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);

  const [selectedCountry1, setSelectedCountry1] = useState("");
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedCity1, setSelectedCity1] = useState("");

  const [isCountryDropdownVisible1, setCountryDropdownVisible1] = useState(false);
  const [isStateDropdownVisible1, setStateDropdownVisible1] = useState(false);
  const [isCityDropdownVisible1, setCityDropdownVisible1] = useState(false);

  const dispatch = useDispatch();
  const { message, error, loading, profileSetting, countries, cities, states } = useSelector((state) => state.admin);

  const [data, setData] = useState({
    name: "",
    company_name: "",
    country_id: "",
    state_id: "",
    city_id: "",
    address: "",
    personal_email: "",
    personal_number: "",
    return_name: "",
    return_company_name: "",
    return_country_id: "",
    return_state_id: "",
    return_city_id: "",
    return_address: "",
    return_personal_email: "",
    return_personal_number: ""
  });

  useEffect(() => {
    if (error) {
      Object.keys(error).map((key) => {
        const errorMessage = error[key].join(" ");
        toast.error(errorMessage);
      });
    }
    if (message) {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error,message]);

  useEffect(() => {
    if(stateNcitystatus){
      setstateNcity({cities:cities.cities,states:states.states})
    } else {
      setstateNcity1({cities:cities.cities,states:states.states})
    }
  }, [cities, states]);

  useEffect(() => {
    dispatch(vendorAddressGet());
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if(profileSetting && profileSetting.warehouses ){
      const warehouse = profileSetting?.warehouses[0];
      const returnwarehouse = profileSetting?.returnWarehouses[0];
      setData({
        name: warehouse?.name || "",
        company_name: warehouse?.company_name || "",
        country_id: warehouse?.country_id || "",
        state_id: warehouse?.state_id || "",
        city_id: warehouse?.city_id || "",
        address: warehouse?.address || "",
        personal_email: warehouse?.personal_email || "",
        personal_number: warehouse?.personal_number || "",
        return_name: returnwarehouse?.name || "",
        return_company_name: returnwarehouse?.company_name || "",
        return_country_id: returnwarehouse?.country_id || "",
        return_state_id: returnwarehouse?.state_id || "",
        return_city_id: returnwarehouse?.city_id || "",
        return_address: returnwarehouse?.address || "",
        return_personal_email: returnwarehouse?.personal_email || "",
        return_personal_number: returnwarehouse?.personal_number || "",
      })
      setSelectedCountry(warehouse?.country);
      setSelectedCity(warehouse?.city);
      setSelectedState(warehouse?.state);
      setSelectedCountry1(returnwarehouse?.country);
      setSelectedCity1(returnwarehouse?.city);
      setSelectedState1(returnwarehouse?.state);
    }
    console.log(profileSetting)
   }, [profileSetting]) 

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const toggleCountryDropdown = () => {
    setstateNcitystatus(true)
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setstateNcitystatus(true)
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const toggleStateDropdown = () => {
    setstateNcitystatus(true)
    setStateDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const selectCountry = (country) => {
    console.log(country,country.id)
    setSelectedCountry(country.name);
    setData({
      ...data,
      country_id: country.id,state_id: "",city_id: ""
    });
    console.log(data)
    setCountryDropdownVisible(false);
    dispatch(getStates(country.id))

    setSelectedState("");
    setSelectedCity("");
  };

  const selectState = (state) => {
    setSelectedState(state.name);
    setData({
      ...data,
      state_id: state.id,city_id: ""
    });
    setStateDropdownVisible(false);
    dispatch(getCities(state.id))
    setSelectedCity("");
  };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setData({
      ...data,
      city_id: city.id
    });
    setCityDropdownVisible(false);
  };

  const toggleCountryDropdown1 = () => {
    setstateNcitystatus(false)
    setCountryDropdownVisible1((prev) => !prev);
    setCityDropdownVisible1(false);
  };

  const toggleCityDropdown1 = () => {
    setstateNcitystatus(false)
    setCityDropdownVisible1((prev) => !prev);
    setCountryDropdownVisible1(false);
  };

  const toggleStateDropdown1 = () => {
    setstateNcitystatus(false)
    setStateDropdownVisible1((prev) => !prev);
    setCountryDropdownVisible1(false);
  };

  const selectCountry1 = (country) => {
    setSelectedCountry1(country.name);
    setData({
      ...data,
      return_country_id: country.id,return_state_id: "",return_city_id: ""
    });
    setCountryDropdownVisible1(false);
    dispatch(getStates(country.id))

    setSelectedState1("");
    setSelectedCity1("");
  };

  const selectState1 = (state) => {
    setSelectedState1(state.name);
    setData({
      ...data,
      return_state_id: state.id,return_city_id: ""
    });
    setStateDropdownVisible1(false);
    dispatch(getCities(state.id))
    setSelectedCity1("");
  };

  const selectCity1 = (city) => {
    setSelectedCity1(city.name);
    setData({
      ...data,
      return_city_id: city.id
    });
    setCityDropdownVisible1(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (selectedOption) {
      for (const [key, value] of Object.entries(data)) {
        if(!key.startsWith("return"))formData.append(key, value);
      }
    } else {
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
    }
    dispatch(vendorAddressUpdate(formData));
  };

  return (
    <>
      <div className="xl:px-4">
        {/* head */}
        <div className="flex justify-between mb-2">
          <BreadCrums
            breadCrum={[
              {
                name: "Addresses",
                path: "/vendor/my-shop/profile/adresses",
              },
            ]}
          />

          <button
            onClick={handleSubmit}
            disabled={loading}
            type="submit"
            className="py-2 px-7 md:py-[10px] md:px-9 text-xs md:text-base text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
          >
            {loading?"Updating":"Update"}
          </button>
        </div>
        {/* filter */}
        <div className="flex gap-6 py-3">
          <Link
            to="/vendor/my-shop/profile"
            onClick={() => setActiveTab("PROFILE")}
            className={`font-semibold text-xs md:text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "PROFILE"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            PROFILE
          </Link>

          <Link
            to="/vendor/my-shop/profile/information"
            onClick={() => setActiveTab("INFORMATION")}
            className={`font-semibold text-xs md:text-sm text-center cursor-pointer  px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "INFORMATION"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INFORMATION
          </Link>

          <Link
            to="/vendor/my-shop/profile/adresses"
            onClick={() => setActiveTab("ADDRESS")}
            className={`font-semibold text-xs md:text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "ADDRESS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            ADDRESS
          </Link>
        </div>
        <div className="flex flex-col gap-5">
          {/* 1st */}
          <div className="flex flex-col ">
            <h1 className=" text-[#18120F] text-lg font-semibold">
              Warehouse Address
            </h1>
            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex flex-col md:flex-row items-center md:gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Khan"
                    name="name"
                    value={data.name}
                    onChange={updateData}
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COMPANY NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="bingtechs"
                    name="company_name"
                    value={data.company_name}
                    onChange={updateData}
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex flex-col md:flex-row items-center md:gap-7 md:my-2">

                {/* Country */}
                <div className="w-full">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COUNTRY
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="text"
                      value={selectedCountry}
                      readOnly
                      onClick={toggleCountryDropdown}
                      placeholder="Select Your Country"
                    />
                    <svg
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      onClick={toggleCountryDropdown}
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                        fill="#6B6B6B"
                      />
                    </svg>

                    {isCountryDropdownVisible && (
                      <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                        {countries?.countries?.map((country) => (
                          <div
                            key={country.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectCountry(country)}
                          >
                            {country.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* state */}
                <div className="w-full">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    STATE
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="text"
                      value={selectedState}
                      readOnly
                      onClick={toggleStateDropdown}
                      placeholder="Select Your State"
                    />
                    <svg
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      onClick={toggleStateDropdown}
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                        fill="#6B6B6B"
                      />
                    </svg>

                    {isStateDropdownVisible && (
                      <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                        {stateNcity?.states?.map((state) => (
                          <div
                            key={state.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectState(state)}
                          >
                            {state.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

              </div>

              {/* Contact Inputs */}
              <div className="flex flex-col md:flex-row items-center md:gap-7 md:my-2">

                {/* City */}
                <div className="w-full">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    CITY
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="text"
                      value={selectedCity}
                      readOnly
                      onClick={toggleCityDropdown}
                      placeholder="Select Your City"
                    />
                    <svg
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      onClick={toggleCityDropdown}
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                        fill="#6B6B6B"
                      />
                    </svg>

                    {isCityDropdownVisible && (
                      <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                        {stateNcity?.cities?.map((city) => (
                          <div
                            key={city.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectCity(city)}
                          >
                            {city.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Address"
                    name="address"
                    value={data.address}
                    onChange={updateData}
                  />
                </div>

              </div>

              {/* Contact Inputs */}
              <div className="flex flex-col md:flex-row items-center md:gap-7 md:my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="email"
                    placeholder="Enter Your Phone Email"
                    name="personal_email"
                    value={data.personal_email}
                    onChange={updateData}
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone"
                    name="personal_number"
                    value={data.personal_number}
                    onChange={updateData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col ">
            <div className="flex gap-9 items-center">
              <h1 className=" text-[#18120F] text-lg font-semibold">
                Return Address
              </h1>
              <div className="flex items-center gap-2">
                <span className="text-xs font-semibold text-[#6B6B6B]">
                  Copy From Wearhouse
                </span>
                <input
                  className="text-sm md:text-base"
                  type="radio"
                  name="option"
                  id="yes"
                  value={true}
                  checked={selectedOption === true}
                  onChange={()=>setSelectedOption(true)}
                />
                <label htmlFor="yes">Yes</label>

                <input
                  className="text-sm md:text-base"
                  type="radio"
                  name="option"
                  id="no"
                  value={false}
                  checked={selectedOption === false}
                  onChange={()=>setSelectedOption(false)}
                />
                <label htmlFor="no">No</label>

              </div>
            </div>
            {!selectedOption?(
              <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
                {/* Name Inputs */}
                <div className="flex flex-col md:flex-row items-center md:gap-7">
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Name"
                      name="return_name"
                      value={data.return_name}
                      onChange={updateData}
                      />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      COMPANY NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Company Name"
                      name="return_company_name"
                      value={data.return_company_name}
                      onChange={updateData}
                      />
                  </div>
                </div>

                {/* Contact Inputs */}
                <div className="flex flex-col md:flex-row items-center md:gap-7 md:my-2">

                  {/* Country */}
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      COUNTRY
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedCountry1}
                        readOnly
                        onClick={toggleCountryDropdown1}
                        placeholder="Select Your Country"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleCountryDropdown1}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isCountryDropdownVisible1 && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                          {countries?.countries?.map((country) => (
                            <div
                              key={country.id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectCountry1(country)}
                            >
                              {country.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* state */}
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      STATE
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedState1}
                        readOnly
                        onClick={toggleStateDropdown1}
                        placeholder="Select Your State"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleStateDropdown1}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isStateDropdownVisible1 && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {stateNcity1?.states?.map((state) => (
                            <div
                              key={state.id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectState1(state)}
                            >
                              {state.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                </div>
                {/* Contact Inputs */}
                <div className="flex flex-col md:flex-row items-center md:gap-7 md:my-2">

                {/* City */}
                <div className="w-full">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    CITY
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="text"
                      value={selectedCity1}
                      readOnly
                      onClick={toggleCityDropdown1}
                      placeholder="Select Your City"
                    />
                    <svg
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      onClick={toggleCityDropdown1}
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                        fill="#6B6B6B"
                      />
                    </svg>

                    {isCityDropdownVisible1 && (
                      <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                        {stateNcity1?.cities?.map((city) => (
                          <div
                            key={city.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectCity1(city)}
                          >
                            {city.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Email"
                      name="return_address"
                      value={data.return_address}
                      onChange={updateData}
                      />
                  </div>
                </div>
                {/* Contact Inputs */}
                <div className="flex flex-col md:flex-row items-center md:gap-7 md:my-2">
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      EMAIL
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="email"
                      placeholder="example@example.com"
                      name="return_personal_email"
                      value={data.return_personal_email}
                      onChange={updateData}
                      />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      PHONE NUMBER
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="number"
                      placeholder="+92-342-8875643"
                      name="return_personal_number"
                      value={data.return_personal_number}
                      onChange={updateData}
                      />
                  </div>
                </div>
              </div>
            ):(<></>)}
          </div>
        </div>
      </div>
    </>
  );
}
