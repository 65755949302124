import React, { useEffect, useState } from "react";
import ProductBoosterModal from "../Product-Booster-Components/ProductBoosterModal";
import BreadCrums from "../../../Common/BreadCrums";
import "./modal.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addbid,
  clearMessage,
  listVendorProduct,
} from "../../../../store/vendorslice";
import { toast } from "react-toastify";
import { imagePath } from "../../../../functions/URLString";

export default function PremiumProductBoostSetting() {
  const { id } = useParams();
  const [cards, setCards] = useState([]);
  const [recomendedProducts, setRecomendedProducts] = useState([]);
  const [selectedCardIds, setSelectedCardIds] = useState([]);
  const [payload, setPayload] = useState({
    bid: 0,
    product_id: "",
    premium_product_id: id,
  });
  const dispatch = useDispatch();
  const { cities, countries, states, message, data, loading, category, error } =
    useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(listVendorProduct({}));
  }, []);

  // useEffect(() => {
  //   console.log(cards, "-----------cards");
  // }, [cards]);

  const handleSubmit = () => {
    if (payload.bid && selectedCardIds.length && payload.premium_product_id) {
      console.log(payload.bid,selectedCardIds[0],payload.premium_product_id)
      dispatch(
        addbid({
          bid: payload.bid,
          product_id: selectedCardIds[0],
          premium_product_id: id,
        })
      );
    } else {
      toast.error("Kindly Fill All Fields");
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);

    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [message, error]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3 ">
        {/* Header */}
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solution",
              path: "/vendor/booster/premium-product-booster",
            },
            {
              name: "Premium Product",
              path: "/vendor/booster/premium-product-booster",
            },
            {
              name: "Product",
              path: -1,
            },
            {
              name: "Boost Setting",
              path: "",
            },
          ]}
        />
        <div className="text-end">
          <button
            className="text-white bg-[#C19A6B] px-5  md:px-9 py-[7px] md:py-[10px] text-xs md:text-sm font-semibold rounded-lg md:rounded-xl"
            onClick={(e) => handleSubmit()}
          >
            Bid Now
          </button>
        </div>

        <div className="flex  gap-3 ">
          <div className="border flex flex-col justify-between w-full border-[#EBF0ED] px-3 md:px-6 py-4 md:py-8 rounded-xl ">
            <h2 className="font-bold text-sm md:text-base">Bid</h2>
            <div className="flex flex-col numberInput">
              <label
                htmlFor=""
                className="text-[#828282] text-xs md:text-sm  font-semibold "
              >
                Enter Amount
              </label>
              <input
                className="border border-[#EBF0ED] outline-none p-2 md:p-3 text-xs font-[400] bg-[#FAFAFA] rounded-lg md:rounded-xl"
                placeholder="$200"
                type="number"
                name=""
                id=""
                onChange={(e) =>
                  setPayload({ ...payload, bid: e.target.value })
                }
              />
            </div>
          </div>
          {/* <div className="p-2 md:p-7  flex flex-col justify-between shadow-sm">
            <h2 className="text-[#18120F] text-xs text-nowrap md:text-[20px] font-semibold ">
              Highest Bid
            </h2>
            <h1 className="text-[#18120F] text-[20px] md:text-[60px] font-bold ">
              $583
            </h1>
          </div> */}
        </div>
        <div className="p-4 border flex flex-col gap-1 border-[#EBF0ED] rounded-xl">
          <div className="flex items-center justify-between">
            <h2 className="text-sm md:text-lg font-semibold ">
              Premium Product{" "}
            </h2>
            <button
              onClick={() => document.getElementById("my_modal_8").showModal()}
              className="text-white bg-[#C19A6B] px-5 md:px-9 py-[7px] md:py-[10px] text-xs md:text-sm font-semibold rounded-lg md:rounded-xl"
            >
              Change
            </button>
          </div>
          {cards.length > 0 &&
            cards.map((item) => {
              return (
                <div className="grid grid-cols-1 md:grid-cols-3">
                  <div className="flex gap-3 border  border-[#E1E1E1] rounded-2xl">
                    <img
                      className="w-20 h-20 rounded-s-2xl"
                      src={item.image}
                      alt=""
                    />
                    <div className="flex flex-col gap-1 py-1">
                      <h2 className="mb-1 text-xs md:text-sm font-semibold">
                        {item.name}
                      </h2>
                      <span className="text-xs md:text-sm font-[500] text-[#6B6B6B]">
                        Category: {item.category.name}
                      </span>
                      <span className="text-xs md:text-sm font-[500] text-[#6B6B6B]">
                        ID: {item.id}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <ProductBoosterModal
        cards={cards}
        selectedCardIds={selectedCardIds}
        setSelectedCardIds={setSelectedCardIds}
        setCards={setCards}
        recomendedProducts={recomendedProducts}
        data={data}
        premium={true}
        setRecomendedProducts={setRecomendedProducts}
      />
    </>
  );
}
