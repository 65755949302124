import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import StoreBoost from "../../../../components/Booster/Marketing-Solutions/Store-Boost-Components/StoreBoost";

export default function StoreBoostPage() {
  return (
    <>
      <BoosterLayout>
        <StoreBoost />
      </BoosterLayout>
    </>
  );
}
