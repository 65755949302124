import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { addTicket, clearMessage, getCategoryList } from "../../../store/vendorslice";
import { toast } from "react-toastify";

export default function TicketForm() {
  const { loading, ticketCategoryList,message, error } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    name: "",
    email: "",
    ticket_category_id:"",
    ticket_headline: "",
    upload_attachment: "",
    ticket_summary: "",
  });

  useEffect(() => {
    dispatch(getCategoryList());
  },[])

  const handleSubmit = () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (!value) return toast.error("Fill All Fields Correctly");
      formData.append(key, value);
    }
    dispatch(addTicket(formData));
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      setPayload({
        name: "",
        email: "",
        ticket_headline: "",
        upload_attachment: "",
        ticket_summary: "",
      });
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [error, message]);

  return (
    <>
      <div className="xl:px-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Seller Support",
              path: "/vendor/my-shop/help-center",
            },
            {
              name: "Tickets",
              path: "/vendor/my-shop/tickets",
            },
            {
              name: "Ticket Form",
              path: "/vendor/my-shop/tickets/ticket-form",
            },
          ]}
        />

        {/* Profile Section */}
        <div className="flex justify-end">
          <button
            disabled={loading}
            onClick={(e) => handleSubmit()}
            className="bg-[#C19A6B] px-5 py-2 md:px-9 md:py-[10px] text-xs xl:text-sm rounded-lg text-white"
          >
            {loading?"Publishing":"Publish"}
          </button>
        </div>
        <div className="flex gap-6 mt-5">
          {/* Right */}
          <div className="w-full flex flex-col gap-5 flex-2">
            <div className="w-full border border-[#EBF0ED] rounded-lg p-3">
              <h1 className=" text-[#18120F] text-sm md:text-lg font-semibold">
                Ticket Form
              </h1>
              {/* Name Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                    Full Name
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Full Name"
                    value={payload.name}
                    onChange={(e) => {
                      setPayload({ ...payload, name: e.target.value });
                    }}
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Email
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                    type="email"
                    placeholder="test@gmail.com"
                    value={payload.email}
                    onChange={(e) => {
                      setPayload({ ...payload, email: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Ticket Category
                  </label>
                    <div className="relative w-full">
                      <select
                        name=""
                        id=""
                        value={payload.ticket_category_id}
                        onChange={(e) =>
                          setPayload({...payload,ticket_category_id:e.target.value})
                        }
                        className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                      >
                        <option value="">none</option>
                        {ticketCategoryList?.categories?.length > 0 &&
                          ticketCategoryList.categories.map((item) => {
                            return <option value={item.id}>{item.label}</option>;
                          })}
                      </select>
                    </div>
                  </div>

              {/* Contact Inputs */}
              <div className="flex items-center gap-2 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Ticket Headline
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter a short headline for your ticket"
                    value={payload.ticket_headline}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        ticket_headline: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              {/* category*/}
              <div className="relative flex flex-col md:flex-row gap-2 items-center ">
                {/* <div className="w-full">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Category
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-[#FAFAFA] cursor-pointer text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="text"
                      value={selectedCategory}
                      readOnly
                      onClick={toggleCategoryDropdown}
                      placeholder="Select Your Country"
                    />
                    {isCategoryDropdownVisible ? (
                      <GoChevronUp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    ) : (
                      <GoChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    )}

                    {isCategoryDropdownVisible && (
                      <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                        {categoryOptions.map((category) => (
                          <div
                            key={category}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectCountry(category)}
                          >
                            {category}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div> */}

                {/* City */}
                <div className="w-full">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                    for="upload"
                  >
                    <input
                      type="file"
                      id="upload"
                      className="hidden w-full h-full"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          upload_attachment: e.target.files[0],
                        });
                      }}
                    />
                    Upload Attachment
                    <div className="bg-[#FAFAFA] flex items-center justify-between px-3 text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px]  border border-[#EBF0ED] ">
                      <span>
                        {payload.upload_attachment
                          ? "Choose Another File"
                          : "No File Choosen"}
                      </span>
                      <span className="cursor-pointer text-xs font-semibold text-[#C19A6B]">
                        {payload.upload_attachment
                          ? "File Uploaded Successfully"
                          : "Choose File"}
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              <div className="flex w-full flex-col ga-2">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  Ticket Summary
                </label>
                <textarea
                  className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[80px] md:h-[146px] px-2 border border-[#EBF0ED] outline-none "
                  type="text"
                  placeholder="Text"
                  value={payload.ticket_summary}
                  onChange={(e) => {
                    setPayload({ ...payload, ticket_summary: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
