import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import NewOrders from "../../../components/home-page-components/NewOrders";

export default function NewOrdersPage() {
  return (
    <>
      <MyShopLayout>
        <NewOrders />
      </MyShopLayout>
    </>
  );
}
