import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, vendorAccountSetting, vendorInformationUpdate } from "../../../store/vendorslice";
import { toast } from "react-toastify";

export default function Information() {
  const [activeTab, setActiveTab] = useState("INFORMATION");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImage1, setUploadedImage1] = useState(null);
  const fileInputRef = useRef();
  const fileInputRef1 = useRef();
  const dispatch = useDispatch();
  const { message, error, loading, profileSetting } = useSelector((state) => state.admin);

  const [data, setData] = useState({
    account_title: "",
    account_number: "",
    bank_name: "",
    bank_branch: "",
    branch_code: "",
    iban: "",
    chequebook_picture: "",
    store_name: "",
    social_security_number: "",
    social_security_image: "",
  });

  useEffect(() => {
    dispatch(vendorAccountSetting())
  }, []);

  useEffect(() => {
    if (error) {
      Object.keys(error).map((key) => {
        const errorMessage = error[key].join(" ");
        toast.error(errorMessage);
      });
    }
    if (message) {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error,message]);

  useEffect(() => {
    if(profileSetting && profileSetting.vendors ){
       const vendor = profileSetting?.vendors[0];
       setData({
         account_title: vendor?.account_title || "",
         account_number: vendor?.account_number || "",
         bank_name: vendor?.bank_name || "",
         bank_branch: vendor?.bank_branch || "",
         branch_code: vendor?.branch_code || "",
         iban: vendor?.iban || "",
         chequebook_picture: vendor?.chequebook_picture || "",
         store_name: vendor?.store_name || "",
         account_number: vendor?.account_number || "",
         social_security_number: vendor?.user?.social_security_number || "",
         social_security_image: vendor?.social_security_image || "",
      })
      setUploadedImage(vendor?.chequebook_picture || null)
      setUploadedImage1(vendor?.social_security_image || null)
    }
   }, [profileSetting]) 

  const handleFileChange = (e,check) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if(check=="cheque"){
        setData({...data,chequebook_picture:file})
        const reader = new FileReader();
        reader.onload = () => {
            setUploadedImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setData({...data,social_security_image:file})
        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImage1(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (value == "" || key == "chequebook_picture" || key == "social_security_image") {
      } else {
        formData.append(key, value);
      }
    }

    if(data.chequebook_picture instanceof File || data.social_security_image instanceof File){
      if(data.chequebook_picture instanceof File) formData.append("chequebook_picture", data.chequebook_picture);
      if(data.social_security_image instanceof File) formData.append("social_security_image", data.social_security_image);
    }
    formData.append("_method", "PUT");

    dispatch(vendorInformationUpdate(formData));
  };

  return (
    <>
      <form
        id="uploadForm"
        enctype="multipart/form-data"
        method="post"
        onSubmit={handleSubmit}
      >

      <div className="xl:px-4">
        {/* head */}
        <div className="flex justify-between mb-2">
          <BreadCrums
            breadCrum={[
              {
                name: "Information",
                path: "/vendor/my-shop/profile/information",
              },
            ]}
          />

          <button
            type="submit"
            disabled={loading}
            className="py-2 px-7 md:py-[10px] md:px-9 text-xs md:text-base text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
          >
            Update
          </button>
        </div>
        {/* filter */}
        <div className="flex gap-6 py-3">
          <Link
            to="/vendor/my-shop/profile"
            onClick={() => setActiveTab("PROFILE")}
            className={`font-semibold text-xs md:text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "PROFILE"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            PROFILE
          </Link>

          <Link
            to="/vendor/my-shop/profile/adresses"
            onClick={() => setActiveTab("INFORMATION")}
            className={`font-semibold text-xs md:text-sm text-center cursor-pointer  px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "INFORMATION"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INFORMATION
          </Link>

          <Link
            to="/vendor/my-shop/profile/adresses"
            onClick={() => setActiveTab("ADDRESS")}
            className={`font-semibold text-xs md:text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "ADDRESS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            ADDRESS
          </Link>
        </div>
        <div className="flex flex-col gap-5">
          {/* 1st */}
          <div className="flex flex-col ">
            <h1 className=" text-[#18120F] text-lg font-semibold">
              Bank Information
            </h1>
            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ACCOUNT TITLE
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Khan"
                    name="account_title"
                    value={data.account_title}
                    onChange={updateData}
                />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ACCOUNT NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="number"
                    placeholder="1233-53444-2323"
                    name="account_number"
                    value={data.account_number}
                    onChange={updateData}
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BANK NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Country"
                    name="bank_name"
                    value={data.bank_name}
                    onChange={updateData}
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BANK BRANCH
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Select State"
                    name="bank_branch"
                    value={data.bank_branch}
                    onChange={updateData}
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BRANCH CODE
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="number"
                    placeholder="Branch Code"
                    name="branch_code"
                    value={data.branch_code}
                    onChange={updateData}
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    IBAN
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="number"
                    placeholder="IBAN"
                    name="iban"
                    value={data.iban}
                    onChange={updateData}
                  />
                </div>
              </div>
              {/* CHEQUE COPY */}
              <div className="py-1">
                <h2 className="text-xs mb-1 font-semibold text-[#6B6B6B]">
                  UPLAOD CHEQUE COPY
                </h2>
                <div className="relative w-[100px] h-[75px]">
                  <div
                    className="w-[100px] h-[75px] bg-[#FEFEFE] border border-dashed border-[#E0E0E0] rounded-lg flex justify-center items-center cursor-pointer"
                    onClick={() => fileInputRef.current.click()}
                  >
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                        className="w-full h-full object-cover rounded-lg"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {uploadedImage && (
                    <button
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center"
                      onClick={()=> {
                        setUploadedImage(null);
                        setData({...data,chequebook_picture:""})
                      }}
                    >
                      X
                    </button>
                  )}

                  {/* Hidden file input */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e)=>handleFileChange(e,"cheque")} // Handle file change
                    style={{ display: "none" }} // Keep the input hidden
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 2nd */}
          <div className="flex flex-col ">
            <h1 className=" text-[#18120F] text-lg font-semibold">
              Bussiness Information
            </h1>

            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COMPANY NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Company Name"
                    name="store_name"
                    value={data.store_name}
                    onChange={updateData}
                  />
                </div>
                {/* <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BUSSINESS REGISTERATION NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="number"
                    placeholder="Resgisteration Number"
                    name="business_registratio_number"
                    value={data.business_registratio_number}
                    onChange={updateData}
                  />
                </div> */}
              </div>

              {/* Contact Inputs */}
              {/* <div className="flex flex-col md:flex-row items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PERSON INCHARGE EMAIL
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="email"
                    placeholder="example@gmail.com"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PERSON INCHARGE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="number"
                    placeholder="+92-312-4523122"
                  />
                </div>
              </div> */}
              {/* Contact Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-7 my-2">
                {/* <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COUNTRTY
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Country"
                  />
                </div> */}
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    SOCIAL SECIURITY NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="number"
                    placeholder="0854367"
                    name="social_security_number"
                    value={data.social_security_number}
                    onChange={updateData}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-7">
                {/* <div className="w-full">
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      STATE
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="State"
                    />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      CITY
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="City"
                    />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Address"
                    />
                  </div>
                </div> */}
                <div className="w-full">
                  {/* CHEQUE COPY */}
                  <div className="py-1">
                    <h2 className="text-xs mb-1 font-semibold text-[#6B6B6B]">
                      SOCIAL SECIURITY NUMBER IMAGE
                    </h2>
                    <div className="relative w-[100px] h-[75px]">
                      <div
                        className="w-[100px] h-[75px] bg-[#FEFEFE] border border-dashed border-[#E0E0E0] rounded-lg flex justify-center items-center cursor-pointer"
                        onClick={()=> fileInputRef1.current.click()}
                      >
                        {uploadedImage1 ? (
                          <img
                            src={uploadedImage1}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded-lg"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      {uploadedImage1 && (
                        <button
                          className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center"
                          onClick={()=> {
                            setUploadedImage1(null);
                            setData({...data,social_security_image:""})
                          }}
                        >
                          X
                        </button>
                      )}

                      <input
                        type="file"
                        ref={fileInputRef1}
                        onChange={(e)=>handleFileChange(e,"socialSecurity")}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </form>
    </>
  );
}
