import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, vendorPasswordReset } from "../../store/vendorslice";
import { toast } from "react-toastify";

export default function NewPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, status } = useSelector(
    (state) => state.admin
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmNewPassword === "") {
      toast.error("Please fill all the fields");
    } else if (confirmNewPassword != newPassword) {
      toast.error("Password and confirm password not match");
    } else {
      dispatch(
        vendorPasswordReset({
          email,
          password: newPassword,
          password_confirmation: confirmNewPassword,
          token,
        })
      );
    }
  };

  useEffect(() => {
    if (message && message === "Password reset successfully.") {
      navigate("/");
      localStorage.clear();
    }

    if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [message, error, status,dispatch,navigate]);



  return (
    <div className=" h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          New Password
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Enter your new password and confirm{" "}
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter New Password
              </span>
            </div>
            <input
              type="password"
              placeholder="• • • • • • • • • • • • • • • •"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </label>

          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Confirm New Password
              </span>
            </div>
            <input
              type="password"
              placeholder="• • • • • • • • • • • • • • • •"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </label>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-[#C19A6B] w-full text-xs md:text-sm rounded-lg p-3 md:p-4 mt-[1.5rem] text-white font-bold tracking-wider"
          >
            {!loading ? "Confirm" : "Loading"}
          </button>
        </div>
        {/* <div className="text-[#C19A6B] text-end mt-3 font-bold tracking-wide cursor-pointer">
          Forgot Password?
        </div> */}
      </div>
    </div>
  );
}
