import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";
import { vendorPremiumCategory } from "../../../../store/vendorslice";
import { useDispatch, useSelector } from "react-redux";

export default function PremiumProduct() {
  const { id } = useParams();
  const [activeRole, setActiveRole] = useState("active");
  const dispatch = useDispatch();
  const { data, message, error, loading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(vendorPremiumCategory({id:id,status:activeRole}));
  }, [activeRole]);

  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.data?.pagination?.total) {
      let totalLength = data.data.pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(vendorPremiumCategory({id:id,page:page,status:activeRole}));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3 ">
        {/* Header */}
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solution",
              path: "/vendor/booster/premium-product-booster",
            },
            {
              name: "Premium Product",
              path: "/vendor/booster/premium-product-booster",
            },
            {
              name: "Product",
              path: "",
            },
          ]}
        />

        {/* search  */}
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />

          <input
            className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search…"
          />
        </div>

        {/* filter */}

        <div className="flex gap-6 text-xs md:text-sm py-3">
          <NavLink
            onClick={() => setActiveRole("active")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "active"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            ACTIVE
            <span
              className={` font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "active"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.activePremiumOffers}
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("upcoming")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "upcoming"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            UPCOMING
            <span
              className={` font-semibold px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "upcoming"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.upcomingPremiumOffers}
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("ended")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "ended" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ENDED
            <span
              className={` font-semibold px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "ended" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.endedPremiumOffers}
            </span>
          </NavLink>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[5%] text-left">TITLE</span>

                <span className="w-[8%] text-left">STATUS</span>
                <span className="w-[10%] text-nowrap text-left">
                  HIGHEST Bid
                </span>
                <span className="w-[20%] text-nowrap text-left">
                  REGISTERATION CLOSES IN{" "}
                </span>
                <span className="w-[35%] text-nowrap text-left">
                  OFFER LIVE PERIOD{" "}
                </span>
                <span className="w-[5%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {data?.data?.allPremium &&
                data?.data?.allPremium.length > 0 &&
                data.data.allPremium.map((req) => (
                  <div
                    key={req.id}
                    className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <span className="w-[5%] text-left ">{req.title}</span>

                    <div className="w-[8%] text-left">
                      <span
                        className={`${
                          activeRole === "1"
                            ? "border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-1 md:px-3  font-[400] rounded-lg"
                            : activeRole === "3"
                            ? "border border-[#FEC600] bg-[#FFFBF0] text-[#FEC600] py-1 px-1 md:px-3  font-[400] rounded-lg"
                            : "border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-1 md:px-3  font-[400] rounded-lg"
                        }`}
                      >
                        {req.status == "1"
                          ? "Active"
                          : req.status == "2"
                          ? "Rejected"
                          : "Upcomming"}
                      </span>
                    </div>
                    <span className="w-[10%] text-left">
                      ${req.highest_bid}
                    </span>
                    <div className="w-[20%] text-left">
                      <span className="  border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]  font-[500] px-2 py-[3px] rounded-xl">
                        {req.live_status}
                      </span>
                    </div>
                    <div className="w-[35%] text-left flex items-center gap-[2px] md:gap-2 text-nowrap">
                      <span className="border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-1 md:px-3  font-[600] rounded-lg">
                        {req.livestartdatetime}
                      </span>{" "}
                      to{" "}
                      <span className="border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-1 md:px-3  font-[600] rounded-lg">
                        {req.liveenddatetime}
                      </span>
                    </div>

                    <Link
                      className="w-[5%] text-left"
                      to={`/vendor/booster/premium-product-booster/premium-product-Boost-Setting/${req.id}`}
                    >
                      <img
                        className="w-7 h-7 md:w-9 md:h-9"
                        src="/assets/icons/view.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* pagination */}
        {!loading &&
        data &&
        data?.data?.allPremium?.length > 0 ? (
          <div className="flex flex-col md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              {/* SHOWING {categories.from + "-" + categories.to} OF{" "}
              {categories.total} */}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

      </div>
    </>
  );
}
