import React, { useState } from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import { Link, Outlet } from "react-router-dom";
import BreadCrums from "../../../components/Common/BreadCrums";

export default function ChatSettingPage() {
  const [activeTab, setActiveTab] = useState("userManagement");

  const handleClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      <MyShopLayout>
        <div className="xl:px-4">
          <BreadCrums
            breadCrum={[
              {
                name: "Account & Setting",
                path: "/vendor/my-shop/chat-setting",
              },
            ]}
          />
          {/* tabs */}
          <div className="mt-5  md:ml-3">
            <div className="text-xs md:text-sm font-semibold flex gap-4 mb-2">
              <Link
                to="/vendor/my-shop/chat-setting"
                className={`text-${
                  activeTab === "userManagement" ? "[#C19A6B]" : "[#828282]"
                } tracking-wide cursor-pointer`}
                onClick={() => handleClick("userManagement")}
              >
                User Management
              </Link>
              <Link
                to="/vendor/my-shop/chat-setting/quick-reply"
                className={`text-${
                  activeTab === "quickReply" ? "[#C19A6B]" : "[#828282]"
                } tracking-wide cursor-pointer`}
                onClick={() => handleClick("quickReply")}
              >
                Quick Reply
              </Link>
              <Link
                to="/vendor/my-shop/chat-setting/auto-reply"
                className={`text-${
                  activeTab === "autoReply" ? "[#C19A6B]" : "[#828282]"
                } tracking-wide cursor-pointer`}
                onClick={() => handleClick("autoReply")}
              >
                Auto Reply
              </Link>
            </div>
          </div>
          <Outlet />
        </div>
      </MyShopLayout>
    </>
  );
}
