import React from "react";
import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";
import OrderView from "../../../../components/MyShop/Orders-Management/OrderView";

export default function OrderViewPage() {
  return (
    <>
      <MyShopLayout>
        <OrderView />
      </MyShopLayout>
    </>
  );
}
