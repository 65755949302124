import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";

export default function PremiumPreMadeAd() {
  const [cards, setCards] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);
  const [cityDropdownVisible, setCityDropdownVisible] = useState(false);

  const [savedTexts, setSavedTexts] = useState([]);
  const [inputText, setInputText] = useState("");

  const countryOptions = [
    "USA",
    "Canada",
    "UK",
    "Australia",
    "India",
    "USA",
    "Canada",
    "UK",
    "Australia",
    "India",
  ];
  const cityOptions = ["ISB", "PESHAWAR", "KARACHI"];
  const categoryOptions = ["Electronics", "Clothes", "Footwear"];
  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
  };
  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
  };
  const toggleCategoryDropdown = () => {
    setCategoryDropdownVisible((prev) => !prev);
  };
  const selectCountry = (country) => {
    setSelectedCountry(country);
    setCountryDropdownVisible(false);
  };
  const selectCity = (city) => {
    setSelectedCity(city);
    setCityDropdownVisible(false);
  };
  const selectCategory = (category) => {
    setSelectedCategory(category);
    setCategoryDropdownVisible(false);
  };

  const handleSave = () => {
    if (inputText.trim() !== "") {
      setSavedTexts([...savedTexts, inputText]);
      setInputText("");
    }
  };

  const handleDelete = (index) => {
    const newSavedTexts = [...savedTexts];
    newSavedTexts.splice(index, 1);
    setSavedTexts(newSavedTexts);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent textarea from creating new line
      handleSave();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/viewoffer.json");
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="px-4">
        <div className="flex items-center gap-2">
          <img src="/assets/icons/manage-products/bars.svg" alt="" />
          <h2 className="text-[22px] font-[400] text-[#828282]">
            Marketing Solution
          </h2>
          <h2 className="text-[22px] font-[400] text-[#828282]">
            Premium Products
          </h2>
          <h2 className="text-[22px] font-semibold "> Boost Setting</h2>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-end">
            <button className="bg-[#C19A6B] text-white px-6 py-2 rounded-lg text-sm ">
              Publish Ad
            </button>
          </div>
          <div className=" border border-[#EBF0ED] p-4 rounded-xl">
            <h1 className="text-lg font-[500]">Ad Setting</h1>
            <div className="flex gap-4 ">
              <div className="w-full flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <label className="font-[500] text-xs" htmlFor="">
                    Ad Name
                  </label>
                  <input
                    className="border outline-none w-full h-[38px] px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                    placeholder="Ad name ... "
                    type="text"
                  />
                </div>
                <div className="flex gap-2">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="font-[500] text-xs " htmlFor="">
                      Start Date
                    </label>
                    <input
                      disabled
                      className="border outline-none w-full text-gray-400 h-[38px] px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                      placeholder="Ad name ... "
                      type="date"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label
                      className="font-[500] text-xs text-gray-400"
                      htmlFor=""
                    >
                      End Date
                    </label>
                    <input
                      disabled
                      className="border text-gray-400 outline-none w-full h-[38px] px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA] disabled"
                      placeholder="Ad name ... "
                      type="date"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className="font-[500] text-xs text-gray-400"
                    htmlFor=""
                  >
                    daily Budget
                  </label>
                  <input
                    disabled
                    className="border outline-none text-gray-400 w-full h-[38px] px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                    placeholder="$400 "
                    type="text"
                  />
                </div>
              </div>
              {/*  */}
              <div className="w-full">
                {/* main category */}
                <div className="w-full">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Main Category
                  </label>
                  <div className="relative w-full">
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="text"
                      value={selectedCategory}
                      readOnly
                      onClick={toggleCategoryDropdown}
                      placeholder="Select Category"
                    />
                    <svg
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      onClick={toggleCategoryDropdown}
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                        fill="#6B6B6B"
                      />
                    </svg>

                    {categoryDropdownVisible && (
                      <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                        {categoryOptions.map((category) => (
                          <div
                            key={category}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectCategory(category)}
                          >
                            {category}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label className="font-[500] text-xs" htmlFor="">
                    Keywords
                  </label>
                  <div className="w-full flex flex-col gap-5 border border-[#EBF0ED] rounded-xl p-4 h-fit">
                    {/* Display saved texts */}

                    <div className="flex gap-2">
                      {savedTexts.map((text, index) => (
                        <div
                          key={index}
                          className="border flex gap-2 border-[#EBF0ED] rounded-xl text-xs font-[400] text-[#6B6B6B] px-4 py-[0.7rem] w-fit "
                        >
                          <span>{text}</span>
                          <button
                            className="text-red-500"
                            onClick={() => handleDelete(index)}
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      ))}
                    </div>
                    {/* Input field */}

                    <textarea
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                      onKeyDown={handleKeyDown} // Add keydown event listener
                      className="w-full border outline-none border-[#EBF0ED] bg-[#FAFAFA] h-10 rounded-xl text-[#6B6B6B] text-xs font-[400] px-3 pt-2"
                      placeholder="Enter Tags"
                    ></textarea>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          <div className="border border-[#EBF0ED]  rounded-xl w-full p-4">
            <h2 className="text-lg font-semibold">
              Where whould you like to boost
            </h2>
            <div className="flex gap-4">
              {/* Country */}
              <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  COUNTRY
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCountry}
                    readOnly
                    onClick={toggleCountryDropdown}
                    placeholder="Select Your Country"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    onClick={toggleCountryDropdown}
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {countryDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                      {countryOptions.map((country) => (
                        <div
                          key={country}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCountry(country)}
                        >
                          {country}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* City */}
              <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  CITY
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCity}
                    readOnly
                    onClick={toggleCityDropdown}
                    placeholder="Select Your City"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    onClick={toggleCityDropdown}
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {cityDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                      {cityOptions.map((city) => (
                        <div
                          key={city}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCity(city)}
                        >
                          {city}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border border-[#EBF0ED] flex flex-col gap-3  rounded-xl w-full p-4 mb-4">
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold">Product List</h2>
              <button className="bg-[#C19A6B] text-white rounded-lg py-[10px] px-[36px] ">
                Change
              </button>
            </div>
            <div className="grid grid-cols-3 gap-10">
              {cards.slice(0, 9).map((card, index) => (
                <>
                  <div className="flex gap-3 border  border-[#E1E1E1] rounded-2xl">
                    <img
                      className="w-20 h-20 rounded-s-2xl"
                      src={card.img}
                      alt=""
                    />
                    <div className="flex flex-col gap-1 py-1">
                      <h2 className="mb-1 text-sm font-semibold">
                        {card.name}
                      </h2>
                      <span className="text-sm font-[500] text-[#6B6B6B]">
                        Category: {card.category}
                      </span>
                      <span className="text-sm font-[500] text-[#6B6B6B]">
                        ID: {card.totalearning}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
