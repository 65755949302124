import React, { useState } from "react";

export default function ChatSettingAutoReply() {
  const [welcomeMessageEnabled, setWelcomeMessageEnabled] = useState(true);
  const [autoReplyEnabled, setAutoReplyEnabled] = useState(true);

  const handleWelcomeMessageToggle = () => {
    setWelcomeMessageEnabled(!welcomeMessageEnabled);
  };

  const handleAutoReplyToggle = () => {
    setAutoReplyEnabled(!autoReplyEnabled);
  };

  return (
    <>
      <div className="border flex flex-col gap-5 border-[#EBF0ED] rounded-xl py-4 md:py-8 px-4">
        <div className="flex flex-col gap-2">
          <h2 className="text-sm font-semibold text-[#18120F] ">
            Welcome Message
          </h2>
          <span className="text-xs font-[400] text-[#18120F]">
            When turned on, the buyer will receive an automatic welcome message
            whenever a new conversation is started.
          </span>
          <div>
            <span className="flex gap-2 items-center text-xs">
              <input
                type="radio"
                name="welcomeMessage"
                id="welcomeOff"
                checked={!welcomeMessageEnabled}
                onChange={handleWelcomeMessageToggle}
              />
              <label htmlFor="welcomeOff">Turn Off</label>
              <input
                type="radio"
                name="welcomeMessage"
                id="welcomeOn"
                checked={welcomeMessageEnabled}
                onChange={handleWelcomeMessageToggle}
              />
              <label htmlFor="welcomeOn">Turn On</label>
            </span>
          </div>
          <textarea
            disabled={!welcomeMessageEnabled}
            className={`${
              welcomeMessageEnabled
                ? "w-full mt-2 border outline-none border-[#EBF0ED] bg-[#FAFAFA] h-16 md:h-24 rounded-xl resize-none text-[#6B6B6B] text-xs font-[400] px-3 pt-2"
                : "w-full mt-2 border outline-none border-[#EBF0ED]  bg-[#f0f0f0] h-16 md:h-24 rounded-xl resize-none text-[#b4b1b1] text-xs font-[400] px-3 pt-2"
            }`}
            placeholder="Welcome to our store. We will get back to you shortly."
          ></textarea>
          <div className="text-end mt-2">
            <button className="text-white bg-[#C19A6B] px-5 md:px-8 py-[7px] md:py-[0.625rem] text-xs md:text-sm font-semibold rounded-lg">
              Save
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="text-sm font-semibold text-[#18120F] ">
            Auto Reply (when outside working hours)
          </h2>
          <span className="text-xs font-[400] text-[#18120F]">
            When turned on, the buyer will receive an automatic reply whenever
            they will text outside your working hours.
          </span>
          <div>
            <span className="flex gap-2 items-center text-xs">
              <input
                type="radio"
                name="autoReply"
                id="autoReplyOff"
                checked={!autoReplyEnabled}
                onChange={handleAutoReplyToggle}
              />
              <label htmlFor="autoReplyOff">Turn Off</label>
              <input
                type="radio"
                name="autoReply"
                id="autoReplyOn"
                checked={autoReplyEnabled}
                onChange={handleAutoReplyToggle}
              />
              <label htmlFor="autoReplyOn">Turn On</label>
            </span>
          </div>
          <textarea
            disabled={!autoReplyEnabled}
            className={`${
              autoReplyEnabled
                ? "w-full mt-2 border outline-none border-[#EBF0ED] bg-[#FAFAFA] h-16 md:h-24 rounded-xl resize-none text-[#6B6B6B] text-xs font-[400] px-3 pt-2"
                : "w-full mt-2 border outline-none border-[#EBF0ED]  bg-[#f0f0f0] h-16 md:h-24 rounded-xl resize-none text-[#b4b1b1] text-xs font-[400] px-3 pt-2"
            }`}
            placeholder="We are currently closed, but don’t worry we will reply as soon as possible."
          ></textarea>
          <div className="text-end mt-2">
            <button className="text-white bg-[#C19A6B] px-5 md:px-8 py-[7px] md:py-[0.625rem] text-xs md:text-sm font-semibold rounded-lg">
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
