import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./number-input-1.css";

export default function ProductsModal() {
  // const [reels, setReels] = useState([]);
  const [selectedCardIds, setSelectedCardIds] = useState([]);

  const handleCheckboxChange = (itemId) => {
    setSelectedCardIds((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  // Toggle heart fill state
  // const handleHeartClick = () => {
  //   setIsHeartFilled(!isHeartFilled);
  // };

  const [recomendedProducts, setRecomendedProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/topSell.json");
        const data = await response.json();
        setRecomendedProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Get selected cards data
  // const getSelectedCardsData = () => {
  //   // const selectedCardsData = reels.filter((item) =>
  //   //   selectedCardIds.includes(item._id)
  //   // );
  //   // return selectedCardsData;
  // };

  // Handle selecting reels and closing modal
  const handleSelectAndClose = () => {
    // const selectedCardsData = getSelectedCardsData();
    document.getElementById("my_modal_23").close();
  };

  return (
    <div>
      {/* Modal code for selecting reels */}
      <dialog id="my_modal_23" className="modal bg-none">
        <div className="modal-box bg-white p-4 xl:p-8 max-w-[85rem] max-h-[50rem] rounded-lg relative">
          <form method="dialog">
            {/* header div */}
            <div className="sticky top-0 z-50 bg-white w-full py-2 px-4 flex justify-between items-center">
              <h2 className="font-bold text-[22px]">Select Products</h2>
              <button
                className="text-3xl w-8 h-8 bg-white rounded-full hover:shadow-xl hover:bg-slate-50 shadow-lg"
                onClick={() => document.getElementById("my_modal_23").close()}
              >
                <img
                  src="/assets/icons/cancel1.svg"
                  className="mx-auto"
                  alt="Close"
                />
              </button>
            </div>
            <div className="h-[360px] overflow-y-auto overflow-x-hidden mx-auto mt-3 cardContainer">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 w-[95%] mx-auto ">
                {recomendedProducts.map((item) => (
                  <div
                    className={`card border-none card-shadow relative  cursor-pointer  w-full md:w-[195px] 
                    `}
                  >
                    {/* heart and discount start here */}
                    <div className="flex z-50 top-1 right-1 absolute items-center">
                      <input
                        type="checkbox"
                        checked={selectedCardIds.includes(item._id)}
                        onChange={() => handleCheckboxChange(item._id)}
                        className="hidden"
                      />
                      <div
                        className={`w-8 h-8 flex items-center justify-center rounded-full border-2 border-black cursor-pointer transition-colors duration-200 ${
                          selectedCardIds.includes(item._id)
                            ? "bg-black text-white"
                            : "bg-transparent"
                        }`}
                        onClick={() => handleCheckboxChange(item._id)}
                      >
                        {selectedCardIds.includes(item._id) && <FaCheck />}
                      </div>
                    </div>

                    <div className={` gap-1 absolute left-2 mt-1`}>
                      <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 px-2 rounded-xl mt-2 text-sm font-bold">
                        {item.discount}
                      </p>
                    </div>
                    {/* heart and discount end here */}

                    {/* image start here  */}
                    <Link>
                      <img
                        src={item.image}
                        alt="Shoes"
                        className="max-h-[282px] w-full"
                      />
                    </Link>
                    {/* Image end here */}

                    <div className="px-3 py-1">
                      <Link>
                        <h2 className="card-title text-sm mb-0">
                          {item.name.slice(0, 51)}...
                        </h2>
                      </Link>
                      <div className="h-4">
                        <div className="flex gap-2 items-center slide-from-top">
                          <div className="rating rating-xs space-x-1">
                            <input
                              type="radio"
                              name="rating-6"
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name="rating-6"
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name="rating-6"
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name="rating-6"
                              className="mask mask-star-2 bg-orange-400"
                            />
                            <input
                              type="radio"
                              name="rating-6"
                              className="mask mask-star-2 bg-orange-400"
                            />
                          </div>
                          <div className="text-[#8C8C8C] text-xs">224</div>
                        </div>
                      </div>

                      <div className="card-actions justify-between items-center ">
                        <div className="flex items-center gap-1">
                          <h5 className="font-semibold text-black text-md">
                            <span className="">$</span>
                            {item.price}.99
                          </h5>
                          <span className="text-sm line-through text-[#8C8C8C]">
                            $27.99
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* button */}
            <div className="w-full h-14">
              <Link
                to=""
                className="bg-[#C19A6B] text-white px-6 my-4 py-2 rounded-lg absolute bottom-0 right-8"
                onClick={handleSelectAndClose}
              >
                Select
              </Link>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
