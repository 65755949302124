import React, { useState } from "react";
import InSight from "../InSight-Map/InSight";
import CategoryModal from "./CategoryModal";
import LocationModal from "./LocationModal";
import HeatMapComponent from "../InSight-Map/HeatMapComponent";

export default function InSightSearch({ setTest, handleDrawer, test }) {
  const [category, setCategory] = useState(false);
  const [location, setLocation] = useState(false);

  const handleCatModal = () => {
    setCategory(true);
    setLocation(false);
  };
  const handlelocationModal = () => {
    setLocation(true);
    setCategory(false);
  };

  return (
    <>
      <div className="px-4 md:px-10  z-30 w-full bg-white">
        <div className="h-[82px] flex flex-col-reverse gap-1 md:gap-2  md:flex-row justify-between md:items-center shadow-md rounded-xl p-2 md:p-4">
          <div className="flex gap-2 md:gap-4 items-center ">
            <button
              onMouseEnter={handlelocationModal}
              className="py-2 md:py-4 group flex items-center justify-center gap-1 md:gap-2 px-2 md:px-8 text-[10px] md:text-xs text-[#828282] font-[500] rounded-lg bg-[#F9F9F9]"
            >
              <span>Location</span>
              <img src="/assets/images/insight/down1.svg" alt="" />
            </button>

            <button
              onMouseEnter={handleCatModal}
              className="py-2 md:py-4 flex items-center justify-center gap-1 md:gap-2 px-2 md:px-8 text-[10px] md:text-xs text-[#828282] font-[500] rounded-lg bg-[#F9F9F9] "
            >
              <span>Category</span>
              <img src="/assets/images/insight/down1.svg" alt="" />
            </button>
          </div>
          <div className="w-full flex items-center gap-2 md:gap-4">
            <div className="w-full xl:w-[874px]  relative ">
              <input
                className="w-full outline-none  p-2 md:p-3 text-[#828282] text-xs md:text-base font-[400] px-8 md:px-8 bg-[#F9F9F9] rounded-lg md:rounded-xl"
                placeholder="Search Product.."
                type="search"
              />
              <img
                className="absolute   left-3 top-2 md:top-[18px] "
                src="/assets/images/insight/search1.svg"
                alt=""
              />
            </div>
            <button
              // by clicking this button i want to sent props/function to another component, by clicking this button i want to open drower which is is another component
              className=" py-2 md:py-4 px-5 md:px-14 text-xs text-white font-[500] rounded-lg bg-[#C19A6B] "
              onClick={handleDrawer}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {/* <InSight show={test} /> */}
      <CategoryModal category={category} setCategory={setCategory} />
      <LocationModal locationModal={location} setLocation={setLocation} />
    </>
  );
}
