import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";

import Information from "../../../components/MyShop/Vendor-Profile-Components/Information";

export default function InformationPage() {
  return (
    <>
      <MyShopLayout>
        <Information />
      </MyShopLayout>
    </>
  );
}
