import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  joinOffer,
  joinOfferView,
} from "../../../../store/vendorslice";
import { useParams } from "react-router-dom";
import Loader from "../../../Common/Loader";
import Countdown from "react-countdown";
import { imagePath } from "../../../../functions/URLString";
import { toast } from "react-toastify";

export default function OfferName() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading, error, message } = useSelector((state) => state.admin);

  // const [offerView, setOfferView] = useState([]);
  const [endTime, setEndTime] = useState(null);
  const [completed, setCompleted] = useState(false);

  // Facilitate state with slice data
  useEffect(() => {
    if(data?.offer?.registration_status === "Registration Closed") setCompleted(true);
    if (!loading && data && data?.offer?.registration_status.includes(Number)) {
      const [days] = data.offer.regenddate
      const [hours, minutes, seconds] = data.offer.registration_status
        .match(/\d+/g)
        .map(Number);
      const totalSeconds =
        days * 86400 + hours * 3600 + minutes * 60 + seconds;
      const now = Date.now();
      const endTime = now + totalSeconds * 1000;
      setEndTime(endTime);
    }
  }, [data, loading]);

  // join offer handle
  const handleJoin = (id) => {
    // dispatch(joinOffer(id));
  };

  // handling messages and navigation
  useEffect(() => {
    if (message === "offer join successfully") {
      toast.success(message);
      navigate(`/vendor/booster/offers/join-offer/${id}`);
    }
    if (error) {
      const firstKey = Object.values(error)[0];
      toast.error(firstKey[0]);
    }
    dispatch(clearMessage());
  }, [error, message, loading]);

  useEffect(() => {
    dispatch(joinOfferView(id));
  }, [id]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Registration Ended</span>;
    } else {
      return (
        <span>
          {days}d : {hours}h : {minutes}m : {seconds}s
        </span>
      );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        <BreadCrums
          breadCrum={[
            {
              name: "Offers",
              path: "/vendor/booster/offers",
            },
            {
              name: "Available Offers",
              path: "/vendor/booster/offers",
            },
            {
              name: !loading && data?.offer?.title,
              path: "/vendor/booster/offers/offer-name",
            },
          ]}
        />

        {/* hero */}
        <div className=" md:border flex flex-col md:flex-row gap-1 md:border-[#EBF0ED] rounded-xl  p-4 mt-4">
          <div className="w-full">
            <img
              className="rounded-xl"
              src={data?.offer?.image}
              alt=""
            />
          </div>
          <div className="w-full flex flex-col gap-2 py-2 px-4">
            <div className="outline-none text-[#18120F] text-lg font-semibold">
              {!loading && data?.offer?.title}
            </div>

            <div className="text-[#828282]">{!loading && data?.offer?.description}</div>
          </div>
          <div className="w-full flex items-center justify-center md:border-l md:border-l-[#EBF0ED]">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col justify-center md:justify-start items-center md:items-center gap-2">
                <h2 className="text-[16px]  font-semibold">
                  Offer Live Period
                </h2>
                <div className=" flex gap-2  justify-center md:justify-start ">
                  <span className="bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl">
                    {!loading && data?.offer?.livestartdate}
                  </span>
                  <span>to</span>
                  <span className="bg-[#FFF3F4] text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl">
                    {!loading && data?.offer?.regenddate}
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start gap-2 mt-3">
                {completed?(
                  <h2 className="text-[16px] font-semibold">
                    Registration Closed
                  </h2>
                ):(
                  <>
                    <h2 className="text-[16px] font-semibold">
                      Registration Ends In
                    </h2>
                    <span className="text-sm text-center w-[70%] py-[0.189rem] bg-[#FAFAFA] font-bold border border-[#EBF0ED] rounded-xl text-[#828282]">
                      {!loading && endTime && (
                        <Countdown date={endTime} renderer={renderer} />
                      )}
                    </span>
                  </>
                )}
              </div>
              <Link
                // onClick={() => handleJoin(id)}
                to={`/vendor/booster/offers/join-offer/${id}`}
                className="cursor-pointer md:mt-8 text-white rounded-md text-center text-sm font-bold bg-[#C19A6B] shadow-md px-20 py-[0.65rem]"
              >
                Join
              </Link>
              <span className="text-[#828282] text-center md:text-start text-sm font-[400]">
                {!loading && data?.vendorsCount} Sellers are Joined |{" "}
                {!loading && data?.productsCount} Products
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
