import HomePage from "../../pages/My-Shop/Home-Page/HomePage";
import NewOrdersPage from "../../pages/My-Shop/Home-Page/NewOrdersPage";
import VendorAffiliateDashboardPage from "../../pages/My-Shop/Vendor-Affiliate-Dashboard-Page/VendorAffiliateDashboardPage";

const boosterDashboardRoutes = [
  {
    path: "/vendor/home",
    element: <HomePage/>,
  },
  {
    path: "/vendor/vendor-affiliate-dashboard",
    element: <VendorAffiliateDashboardPage/>,
  },
  {
    path: "/vendor/vendor-dashboard/new-orders",
    element: <NewOrdersPage />,
  },
 
 
];

export default boosterDashboardRoutes;
