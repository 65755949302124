import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ordersList, ordersListSearch } from "../../../store/vendorslice";

export default function OrdersManagement() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  const [activeRole, setActiveRole] = useState("All");

  useEffect(() => {
    if (search) {
      dispatch(ordersListSearch(search));
    }
    else{
      dispatch(ordersList({}));
    }
  }, [search]);

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  return (
    <>
      <div className="md:px-5 w-full flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Orders",
            },
          ]}
        />

        <div className="flex flex-col lg:flex-row items-center justify-between gap-4 w-full">
          <div className="flex flex-col gap-2 bg-[#C19A6B] p-3 md:p-6 rounded-xl w-full ">
            <p className="font-semibold  text-xs uppercase text-white tracking-widest">
              Order Statuses
            </p>

            <div className="flex justify-between py-4 md:px-2 bg-white rounded-xl md:gap-2 w-full uppercase">
              <span className="flex flex-col items-center text-center  border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl  md:text-3xl">
                  {data?.data?.allOrdersCount}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B] w-20 text-nowrap">
                  All Orders
                </p>
              </span>
              <span className=" border-r-2"></span>
              <span className="flex flex-col items-center text-center   border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.pendingOrdersCount}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Pending
                </p>
              </span>
              <span className=" border-r-2"></span>

              <span className="flex flex-col items-center text-center   border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.completedOrdersCount}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Completed
                </p>
              </span>
              <span className=" border-r-2"></span>

              <span className="flex flex-col text-center  items-center px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.activeOrdersCount}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Progress
                </p>
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-2 bg-[#C19A6B] p-3 md:p-6 rounded-xl w-full uppercase">
            <p className="font-semibold text-xs uppercase text-white tracking-widest">
              failed orders
            </p>

            <div className="flex justify-between py-4 px-2 bg-white rounded-xl gap-2 w-full">
              <span className="flex flex-col items-center   border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.abandedOrders}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Abanded
                </p>
              </span>
              <span className=" border-r-2"></span>

              <span className="flex flex-col items-center   border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.returnedOrders}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Returned
                </p>
              </span>
              <span className=" border-r-2"></span>

              <span className="flex flex-col items-center   border-[#EBF0ED] px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.cancelledOrdersCount}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Cancelled
                </p>
              </span>
              <span className=" border-r-2"></span>

              <span className="flex flex-col items-center px-2">
                <p className="font-extrabold text-xl md:text-3xl">
                  {data?.data?.damagedOrders}
                </p>
                <p className="font-semibold text-[9px] text-[#6B6B6B]">
                  Damaged
                </p>
              </span>
            </div>
          </div>
        </div>
        {/* search */}
        <div className="flex items-center justify-between gap-4 mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] text-[#6B6B6B] focus:outline-none text-[#6B6B6B} font-semibold text-[14px]"
              type="search"
              placeholder="Search Order by customer name or ID …"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* filter */}
        <div className="flex gap-2 md:gap-6  md:py-3">
          <NavLink
            onClick={() => {
              setActiveRole("All");
              dispatch(ordersList({}));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ALL ORDERS
            <span
              className={` font-semibold px-1 md:px-2 py-[2px] md:py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data?.data?.allOrdersCount
                ? data?.data?.allOrdersCount
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("In Progress");
              dispatch(ordersList({ status: "active" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "In Progress"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            ACTIVE
            <span
              className={` font-semibold px-1 md:px-2 py-[2px] md:py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "In Progress"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data?.data?.allOrdersCount
                ? data?.data?.allOrdersCount
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Pending");
              dispatch(ordersList({ status: "pending" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "Pending"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            PENDING
            <span
              className={` font-semibold px-1 md:px-2 py-[2px] md:py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Pending"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data?.data?.pendingOrdersCount
                ? data?.data?.pendingOrdersCount
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Completed");
              dispatch(ordersList({ status: "delivered" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "Completed"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            DELIVERED
            <span
              className={` font-semibold px-1 md:px-2 py-[2px] md:py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Completed"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data?.data?.allOrdersCount
                ? data?.data?.allOrdersCount
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Cancelled");
              dispatch(ordersList({ status: "cancelled" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "Cancelled"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            CANCELLED
            <span
              className={` font-semibold px-1 md:px-2 py-[2px] md:py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Cancelled"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data?.data?.allOrdersCount
                ? data?.data?.allOrdersCount
                : 0}
              )
            </span>
          </NavLink>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[10%] text-left text-nowrap">ORDER ID</span>

                <span className="w-[10%] text-left ">CUSTOMER</span>
                <span className="w-[10%] text-left ">AMOUNT</span>
                <span className="w-[15%] text-left ">PAYMENT METHOD</span>
                <span className="w-[12.5%] text-left ">ORDER DATE</span>
                <span className="w-[15%] text-left ">PAYMENT STATUS</span>
                <span className="w-[12.5%] text-left ">ORDER STATUS</span>
                <span className="w-[12.5%] text-left ">ACTION</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <div className="flex justify-center py-4">
                  <Loader />
                </div>
              ) : data?.data?.allOrders?.length > 0 ? (
                data?.data?.allOrders?.map((order) => {
                  return (
                    <div
                      key={order.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <span className="w-[10%] text-left  ">
                        {order.order_uid}
                      </span>

                      <span className="w-[10%] text-left ">
                        {order.customer}
                      </span>
                      <span className="w-[12.5%] text-left ">
                        ${order.amount}{" "}
                      </span>
                      <span className="w-[15%] text-left  text-nowrap ">
                        {order.payment_method}
                      </span>
                      <span className="w-[12.5%] text-left ">{order.date}</span>
                      <div className="w-[15%] text-left ">
                        <span
                          className={`${
                            order.payment_status == "1"
                              ? "bg-[#F6FFF7]  py-1 px-1 md:px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                              : "bg-[#FFF3F4]  py-1 px-1 md:px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                          }`}
                        >
                          {order.payment_status == "1"
                            ? "Completed"
                            : "Pending"}
                        </span>
                      </div>

                      <div className="w-[12.5%] text-left ">
                        <span
                          // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                          className={`${
                            order.status == "delivered"
                              ? "bg-[#F6FFF7]  py-1 px-1 md:px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                              : order.status == "pending"
                              ? "bg-[#F1F7FF]  py-1 px-1 md:px-3 border border-[#44A6E9] text-[#44A6E9] rounded-lg"
                              : order.status == "active"
                              ? "bg-[#FFFBF0]  py-1 px-1 md:px-3 border border-[#FEC600] text-[#FEC600] rounded-lg"
                              : "bg-[#FFF3F4]  py-1 px-1 md:px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                          }`}
                        >
                          {/* {order.status == "2"
                            ? "Completed"
                            : order.status == "0"
                            ? "Pending"
                            : order.status == "3"
                            ? "Cancelled"
                            : order.status == "1"
                            ? "In Progress"
                            : "Unknown Status"} */}
                            {order.status}
                        </span>
                      </div>

                      <div className="w-[12.5%] text-left flex items-center gap-2  ">
                        <Link to={`/vendor/my-shop/orders-management/order-view/${order.id}`}>
                          <img
                            className="w-7 h-7  md:w-[38] md:h-[38]"
                            src="/assets/icons/view.svg"
                            alt=""
                          />
                        </Link>

                        <Link>
                          <img
                            className="w-7 h-7  md:w-[38] md:h-[38]"
                            src="/assets/icons/manage-products/edit.svg"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center font-bold tracking-wide py-4">
                  No Orders Found
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Pagination */}
      </div>
    </>
  );
}
