import React from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import BreadCrums from "../../../Common/BreadCrums";

export default function Transactions() {
  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-6">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Finance",
              path: "/vendor/my-shop/account-statement",
            },
            {
              name: "Transactioons",
              path: "/vendor/my-shop/transactions",
            },
          ]}
        />

        <div className="flex flex-col gap-2 md:gap-0 md:flex-row  justify-between w-full">
          <div className="flex justify-between items-start md:justify-normal gap-4">
            <div className="w-full">
              <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
                Time Period
              </span>
              <div>{/*Date range picker*/}</div>
            </div>

            <div className="w-full">
              <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
                Transaction Type
              </span>
              <div className="flex justify-between  bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg items-center w-full md:w-64 p-1 md:p-2">
                <span className="font-normal text-xs text-[#6B6B6B]">
                  All Transactions
                </span>
                <span>
                  <RiArrowDropDownLine className="!w-6 !h-6" />
                </span>
              </div>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            {/* print button  */}
            <div className="flex items-center justify-center gap-2 py-[5px] px-2 md:py-[10px] md:px-5 rounded-lg  bg-[#FAFAFA] border border-[#EBF0ED] ">
              <span>
                <img
                  src="/assets/icons/order-management/export.svg"
                  alt=""
                  className="max-w-[9px] h-[18px]"
                />
              </span>
              <span className="font-semibold text-xs text-[#828282]">
                Print
              </span>
            </div>

            {/* export button  */}
            <div className="flex items-center justify-center gap-2 py-[5px] px-2 md:py-[10px] md:px-5 rounded-lg  bg-[#FAFAFA] border border-[#EBF0ED] ">
              <span>
                <img
                  src="/assets/icons/order-management/export.svg"
                  alt=""
                  className="max-w-[9px] h-[18px]"
                />
              </span>
              <span className="font-semibold text-xs text-[#828282]">
                Export
              </span>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] ">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm font-semibold border-b text-[#6B6B6B]   border-gray-300 justify-between">
                <span className="w-[16%] text-left">DATE</span>
                <span className="w-[18%] text-left">TRANSACTION TYPE</span>
                <span className="w-[16%] text-left">TRANSACTION NUMBER</span>
                <span className="w-[20%] text-left">DETAILS</span>
                <span className="w-[10%] text-left">ORDER NUMBER</span>
                <span className="w-[10%] text-left">AMOUNT</span>
                {/* <span className="w-[16%] text-left">WITHHOLDING</span> */}
                {/* <span className="w-[16%] text-left">GST</span> */}
              </div>

              {/* Table Body */}
              <div className="flex justify-between items-center text-[10px] md:text-sm border- border-[#EBF0ED] text-[#18120F] font-semibold  p-2 md:p-4 rounded-b-md">
                <span className="w-[16%] text-left ">2024-02-12</span>

                <span className="w-[18%] text-left ">
                  Shipping Fee Paid by Buyer
                </span>
                <span className="w-[16%] text-left">#SWE267NZ-407388</span>
                <span className="w-[20%] text-left ">
                  Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit
                  amet consectetur.
                </span>
                <span className="w-[10%] text-left">#YU34692</span>
                <span className="w-[10%] text-left">$100.00</span>
                {/* <span className="w-[70px]">$10.00</span> */}
                {/* <span className="w-[70px]">$15.00</span> */}
              </div>
            </div>
          </div>
        </div>

        {/* pagination  */}
      </div>
    </>
  );
}
