import { NavLink, Outlet } from "react-router-dom";
import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";
import BreadCrums from "../../../../components/Common/BreadCrums";
// import CustomerReturnsClosed from "../../../../components/MyShop/Orders-Management/Customer-Returns/CustomerReturnsClosed";

export default function CustomerReturnsPage() {
  return (
    <>
      <MyShopLayout>
        <div className="md:px-5 w-full text-xs md:text-sm flex flex-col gap-4">
          <BreadCrums
            breadCrum={[
              {
                name: "Orders & Reviews",
                path: "/vendor/my-shop/orders-management",
              },
              {
                name: "Customer Returns",
              },
            ]}
          />

          <div className="flex items-center gap-4 md:pl-4 md:mt-3">
            <NavLink
              to="/vendor/my-shop/customer-returns"
              className={({ isActive }) =>
                isActive
                  ? "font-semibold  text-[#C19A6B]"
                  : "font-semibold  text-[#828282] hover:text-[#C19A6B]"
              }
              end
            >
              In Progress
            </NavLink>
            <NavLink
              to="/vendor/my-shop/customer-returns/closed"
              className={({ isActive }) =>
                isActive
                  ? "font-semibold  text-[#C19A6B]"
                  : "font-semibold  text-[#828282] hover:text-[#C19A6B]"
              }
            >
              Closed
            </NavLink>
          </div>

          <Outlet />
        </div>
      </MyShopLayout>
    </>
  );
}
