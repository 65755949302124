import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import Tickets from "../../../components/MyShop/Seller-Support-Components/Tickets";

export default function TicketsPage() {
  return (
    <>
      <MyShopLayout>
        <Tickets />
      </MyShopLayout>
    </>
  );
}
