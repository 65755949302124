import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrums from "../../Common/BreadCrums";
import JoinOfferModal from "./JoinOfferModal";
import { addAffilate, clearMessage, getInfluencers } from "../../../store/vendorslice";

export default function AfflilateProductsAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [inflencerSelect, setInflencerSelect] = useState("");
  const [temp, setTemp] = useState(true);
  const { error, message, influencers, data, loading } = useSelector(
    (state) => state.admin
  );
  const [payload, setPayload] = useState({
    product_id: "",
    user_id: [],
    commission: "",
  });

  useEffect(() => {
    dispatch(getInfluencers());
  },[])

  useEffect(() => {
    if (message) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
        toast.error(error);
    }
    dispatch(clearMessage());
  }, [error, message, loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    payload.user_id = selectedInfluencers.map((a) => a.id);
    payload.product_id = selectedProducts[0]? String(selectedProducts[0].id) : ""

    dispatch(addAffilate(payload));
  };

  const handleSelectedProducts = (products) => {
    setSelectedProducts(products);
  };

  const handleDeleteProduct = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );
    setSelectedProducts(updatedProducts);
  };

  const handleInfluencers = (influencer) => {
    const exists = selectedInfluencers.some((item) => item.id === influencer.id);
    if(!exists){
      setInflencerSelect(influencer.name);
      selectedInfluencers.push(influencer);      
    }
  };

  const handleInfluencerDelete = (influencerId) => {
    setSelectedInfluencers((prevSelected) =>
      prevSelected.filter((item) => item.id !== influencerId)
    );
  };

  return (
    <form className="xl:px-4 flex flex-col gap-4 w-full">
      {/* top bar */}
      <div className="flex flex-col md:flex-row gap-3 justify-between items-center w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Affilates",
              path: "/vendor/my-shop/affilate-products",
            },
            {
              name: "Add",
              path: "",
            },
          ]}
        />

            <button
              disabled={loading ? true : false}
              onClick={handleSubmit}
              className="md:py-[12px] px-5 md:px-8 bg-[#C19A6B] rounded-lg text-white justify-center font-semibold text-xs md:text-sm tracking-widest"
            >
              {!loading ? (
                  "Add"
                ) : (
                  <span className="loading loading-spinner loading-md"></span>
                )}
            </button>

      </div>

      {/* create form  */}
      <div className="flex flex-col gap-2">

        <div className="border border-[#EBF0ED] p-3 gap-10 rounded-lg">
          <p className="font-medium text-base pb-3">New Influencers Group</p>
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Commission
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="number"
                name="commission"
                id=""
                placeholder="commission..."
                value={payload.commission}
                onChange={(e) => setPayload({...payload,commission:e.target.value})}
              />
            </div>
          </div>

          <div className="flex w-full flex-col pt-2">
          <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
            Choose Influencers
          </label>
            <div className="relative w-full">
              <select
                name=""
                id=""
                value={inflencerSelect}
                onChange={(e) =>
                  handleInfluencers(JSON.parse(e.target.value))
                }
                className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
              >
                <option value="">none</option>
                {influencers?.users?.length > 0 &&
                  influencers.users.map((item) => {
                    return <option key={item.id} value={JSON.stringify(item)} >{item.name}</option>;
                  })}
              </select>
            </div>
          </div>
          <div className="flex flex-row w-full gap-x-1 my-4">
            {selectedInfluencers?.length > 0 && selectedInfluencers.map((a) => (
              <div className="flex w-fit h-fit bg-gray-100 rounded-full px-2">{a.name} | <span onClick={() => handleInfluencerDelete(a.id)} className="text-red-400 pl-1 hover:text-red-600 hover:cursor-pointer">x</span></div>
            ))}
          </div>
          <div className="flex justify-between w-full mt-3">
            <Link
              onClick={()=>{
                setTemp(false);
                document.getElementById("my_modal_4").showModal()            
                }}
              className="md:py-[12px] px-5 md:px-8 bg-[#C19A6B] rounded-lg text-white justify-center font-semibold text-xs md:text-sm tracking-widest"
            >
                Add Products
            </Link>
          </div>

        </div>

        <div className="flex justify-center border-[#EBF0ED] overflow-auto">
          <div className="bg-white w-full rounded-lg border border-[#EBF0ED] overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-[#EBF0ED] justify-between">
                <span className="w-[20%] text-left">PRODUCT</span>
                <span className="w-[15%] text-left">STOCK</span>
                <span className="w-[20%] text-left">CATEGORY</span>
                <span className="w-[15%] text-left">ORIGINAL PRICE</span>
                <span className="w-[15%] text-left">OFFER PRICE</span>
                <span className="w-[15%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              <div>
                {selectedProducts.length > 0 ? (
                  <>
                    {selectedProducts.slice(0, 10).map((item, index) => (
                      <div
                        key={index}
                        className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                      >
                        <div className="w-[20%] text-left flex gap-2 items-center  font-[600] ">
                          <img
                            className="w-8 h-8 md:w-10 md:h-10"
                            src={item.image}
                            alt=""
                          />
                          <span className="font-semibold text-[#18120F] ">
                            {item.name}
                          </span>
                        </div>
                        <span className="w-[15%] text-left  font-semibold text-[#18120F] ">
                          {item.quantity}
                        </span>

                        <span className="w-[20%] text-left font-[600]">
                          {item.category?.name}
                        </span>
                        <span className=" w-[15%] text-left  font-semibold text-[#18120F] ">
                          ${item.price}
                        </span>

                        <span className=" w-[15%] text-left  font-semibold text-[#18120F] ">
                          ${item.discountprice}
                        </span>

                        <span
                          className="w-[15%] text-left text-[#C19A6B]  font-[500] cursor-pointer"
                          onClick={() => handleDeleteProduct(item.id)}
                        >
                          Delete
                        </span>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="text-center font-bold tracking-wide py-4">
                      <span colSpan={6}>Add Products</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>

      <JoinOfferModal onSelect={handleSelectedProducts} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} temp={temp} setTemp={setTemp} />

    </form>
  );
}
