import React from "react";
import SendOfferModal from "./SendOfferModal";

export default function AcceptedOffers() {
  return (
    <>
      <div
        className="w-full h-full lg:h-[22.5rem] py-5 px-6 rounded-2xl"
        style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F" }}
      >
        <div className="font-bold">Accepted Offers</div>
        <div className="mt-5 flex flex-col justify-between">
          <div className="flex justify-between gap-8">
            <div className="flex gap-4 items-center">
              <img
                className="h-9 w-9"
                src="/assets/images/dashboard-home/accept-offer-user.svg"
                alt=""
              />{" "}
              <span className="text-sm">Asim Muneer</span>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => document.getElementById("my_modal_03").showModal()}
            >
              <img
                className="w-8 h-8"
                src="/assets/images/dashboard-home/view-accept-offer-btn.svg"
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-between gap-8 mt-4">
            <div className="flex gap-4 items-center">
              <img
                className="h-9 w-9"
                src="/assets/images/dashboard-home/accept-offer-user.svg"
                alt=""
              />{" "}
              <span className="text-sm">Asim Muneer</span>
            </div>
            <div>
              <img
                className="w-8 h-8"
                src="/assets/images/dashboard-home/view-accept-offer-btn.svg"
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-between gap-8 mt-4">
            <div className="flex gap-4 items-center">
              <img
                className="h-9 w-9"
                src="/assets/images/dashboard-home/accept-offer-user.svg"
                alt=""
              />{" "}
              <span className="text-sm">Asim Muneer</span>
            </div>
            <div>
              <img
                className="w-8 h-8"
                src="/assets/images/dashboard-home/view-accept-offer-btn.svg"
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-between gap-8 mt-4">
            <div className="flex gap-4 items-center">
              <img
                className="h-9 w-9"
                src="/assets/images/dashboard-home/accept-offer-user.svg"
                alt=""
              />{" "}
              <span className="text-sm">Asim Muneer</span>
            </div>
            <div>
              <img
                className="w-8 h-8"
                src="/assets/images/dashboard-home/view-accept-offer-btn.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* modal  */}
      <dialog id="my_modal_03" className="modal ">
        <div className="modal-box bg-white !max-w-[40rem]">
          <form method="dialog"></form>
          <SendOfferModal />
        </div>
      </dialog>
    </>
  );
}
