import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import ViewMall from "../../../components/MyShop/Join-Mall-Components/ViewMall";

export default function ViewMallPage() {
  return (
    <>
      <MyShopLayout>
        <ViewMall />
      </MyShopLayout>
    </>
  );
}
