import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";
import Transactions from "../../../../components/MyShop/Finance-Management/Transactions/Transactions";

export default function TransactionsPage() {
  return (
    <>
      <MyShopLayout>
        <Transactions />
      </MyShopLayout>
    </>
  );
}
