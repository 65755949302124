import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, reviewReply } from "../../../../store/vendorslice";
import { toast } from "react-toastify";

export default function ReplyModal({ productId, reviewId }) {
  const dispatch = useDispatch();

  const { data, loading, message } = useSelector((state) => state.admin);

  const [reply, setReply] = useState("");

  const handleReplySubmit = (e) => {
    e.preventDefault();
    if (reply === "") {
      toast.error("Please enter reply first");
    } else {
      dispatch(
        reviewReply({
          rating_review_id: reviewId,
          productId: productId,
          description: reply,
        })
      );
    }
  };

  useEffect(() => {
    if (message === "VendorReplay created successfully!") {
      toast.success(message);
      document.getElementById("my_modal_4").close();
      setReply("");
    }

    dispatch(clearMessage());
  }, [message]);

  return (
    <div>
      {/* You can open the modal using document.getElementById('ID').showModal() method */}
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box bg-white max-w-[90%] rounded-lg">
          <div className="flex items-center justify-between py-1">
            <span className="text-xl md:text-[32px] font-semibold">
              Reply to Review
            </span>
            <span
              onClick={() => document.getElementById("my_modal_4").close()}
              className="bg-white cursor-pointer rounded-full p-3 shadow-sm font-bold"
            >
              <img
                className="w-4 h-4 md:h-8 md:w-8"
                src="/assets/icons/cross1.svg"
                alt=""
              />
            </span>
          </div>
          <form onSubmit={handleReplySubmit}>
            <textarea
              className="text-sm md:mt-2 font-[400] w-full outline-none p-2 bg-[#F9FAFB] h-[100px] md:h-[180px]
                text-[#828282] rounded-md resize-none"
              placeholder="Type Reply Here ..."
              type="text"
              value={reply}
              onChange={(e) => setReply(e.target.value)}
            />
            <div className="text-end  md:mt-2">
              <button
                disabled={loading}
                type="submit"
                className="text-[#C19A6B] text-sm md:text-base px-5 md:px-[70px] py-[7px] md:py-[14px] border border-[#C19A6B] rounded-md"
              >
                {!loading ? "Submit" : "Submitting"}
              </button>
            </div>
          </form>
          <div className="modal-action"></div>
        </div>
      </dialog>
    </div>
  );
}
