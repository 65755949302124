import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import CustomAd from "../../../../components/Booster/Marketing-Solutions/Product-Booster-Components/CustomAd";

export default function CustomAdPage() {
  return (
    <>
      <BoosterLayout>
        <CustomAd />
      </BoosterLayout>
    </>
  );
}
