import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import VendorAffiliateDashboard from "../../../components/MyShop/Vendor-Affiliate-Dashbaord/VendorAffiliateDashboard";

export default function VendorAffiliateDashboardPage() {
  return (
    <MyShopLayout>
      <VendorAffiliateDashboard />
    </MyShopLayout>
  );
}
