import BreadCrums from "../../Common/BreadCrums";
import AffiliateVendorBarCharts from "../../Graphs/AffiliateVendorBarChart";
import VendorOfferModal from "./VendorOfferModal";

export default function VendorAffiliateDashboard() {
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4  w-full">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Affiliate Dashboard",
              path: "/vendor/affiliate-dashboard",
            },
          ]}
        />

        {/* recently gainer  */}
        <div className=" w-full flex flex-col gap-3 border border-[#EBF0ED] bg-[#FFFFFF] rounded-xl px-4 py-6">
          <span className="font-semibold text-xl md:text-2xl">
            Recent Gainers
          </span>
          <div className="w-full flex gap-4 overflow-hidden">
            <img
              onClick={() => document.getElementById("my_modal_3").showModal()}
              src="/assets/images/vendor.png"
              alt=""
              className="w-14 h-14 md:w-[72px] md:h-[72px] rounded-full cursor-pointer"
            />
          </div>
        </div>

        {/* graph  */}
        <div className="w-full">
          <AffiliateVendorBarCharts />
        </div>
      </div>

      {/* modal  */}
      <dialog id="my_modal_3" className="modal ">
        <div className="modal-box bg-white !max-w-[40rem]">
          <VendorOfferModal />
        </div>
      </dialog>
    </>
  );
}
