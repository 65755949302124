import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearMessage, vendorAccountCreationSecondForm } from "../../store/vendorslice";

export default function BankVerification() {
  const location = useLocation();
  const { country, state, city, postalCode, fullAddress } =
    location.state || {};
  const [data, setData] = useState({
    account_number: "",
    account_title: "",
    bank: "",
    branch_code: "",
    branch_name: "",
    chequeCopy: "",
    iban: "",
    idCardBack: "",
    idCardFront: "",
    national_identity: "",
    social_security: "",
    tax_identity: "",
  });

  const [selectedBank, setSelectedBank] = useState("");

  const [idCardFront, setIdCardFront] = useState(null);
  const [idCardBack, setIdCardBack] = useState(null);
  const [chequeCopy, setChequeCopy] = useState(null);
  const [clickCheck, setClickCheck] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, error, loading, shop_id } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    console.log(selectedBank,shop_id);
  }, [selectedBank,shop_id]);

  useEffect(() => {
    if (message === "Vendor inserted successfully") {
      toast.success(message);
      setData({
        account_number: "",
        account_title: "",
        bank: "",
        branch_code: "",
        branch_name: "",
        chequeCopy: "",
        iban: "",
        idCardBack: "",
        idCardFront: "",
        national_identity: "",
        social_security: "",
        tax_identity: "",
      });
      setIdCardFront(null);
      setIdCardBack(null);
      setChequeCopy(null);
      setSelectedBank("");
      navigate("/vendor/home");
    }
    if(error){
      toast.error(error)
    }
    dispatch(clearMessage());
  }, [message,error,dispatch,navigate]);

  const handleIdCardFrontUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setIdCardFront(imageUrl);
      setData({
        ...data,
        idCardFront: file,
      });
    }
  };

  const handleIdCardBackUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setIdCardBack(imageUrl);
      setData({
        ...data,
        idCardBack: file,
      });
    }
  };

  const handleChequeCopyUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setChequeCopy(imageUrl);
      setData({
        ...data,
        chequeCopy: file,
      });
    }
  };

  const handleIdCardFrontDelete = () => {
    setIdCardFront(null);
    setData({
      ...data,
      idCardFront: "",
    });
  };

  const handleIdCardBackDelete = () => {
    setIdCardBack(null);
    setData({
      ...data,
      idCardBack: "",
    });
  };

  const handleChequeDelete = () => {
    setChequeCopy(null);
    setData({
      ...data,
      chequeCopy: "",
    });
  };

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (clickCheck === true) {
      setClickCheck(false);
      if (
        country == "" ||
        state == "" ||
        city == "" ||
        postalCode == "" ||
        fullAddress == ""
      ) {
        toast.error("First Fill out the previous page!");
      } else {
        if (
          data.account_number === "" ||
          data.account_title === "" ||
          data.bank === "" ||
          data.branch_code === "" ||
          data.branch_name === "" ||
          data.chequeCopy === "" ||
          data.iban === "" ||
          data.idCardBack === "" ||
          data.idCardFront === "" ||
          data.national_identity === "" ||
          data.social_security === "" ||
          data.tax_identity === ""
        ) {
          toast.error("Input all feilds");
        } else {
          const formData = new FormData();

          formData.append("country_id", country);
          formData.append("state_id", state);
          formData.append("city_id", city);
          formData.append("postal_code", postalCode);
          formData.append("fulladdress", fullAddress);

          formData.append("account_number", data.account_number);
          formData.append("account_title", data.account_title);
          formData.append("bank_branch", data.branch_name);
          formData.append("branch_code", data.branch_code);
          formData.append("bank_name", data.bank);
          formData.append("cheque_copy", data.chequeCopy);
          formData.append("iban", data.iban);
          formData.append("id_card_back", data.idCardBack);
          formData.append("id_card_front", data.idCardFront);
          formData.append("nid_card_number", data.national_identity);
          formData.append("social_security_number", data.social_security);
          formData.append("tax_identification_number", data.tax_identity);

          formData.append("_method", "PUT");
          formData.append("banner_logo", data.chequeCopy);
          formData.append("phone", "+1435353454");
          formData.append("address", "isl");

          console.log("user data...", data, "formdata..", formData);
          dispatch(vendorAccountCreationSecondForm(formData));
        }
      }
    }
  };

  return (
    <div className="flex justify-center w-full mt-14">
      <div className="flex flex-col">
        <div className="flex justify-center gap-16">
          <img
            className="w-[300px] md:w-[372px]"
            src="/assets/icons/progress-3.svg"
            alt=""
          />
        </div>
        <p
          onClick={() => navigate("/vendor/profile-creation/address")}
          className="px-4 flex mt-4 md:mt-16 font-bold cursor-pointer hover:underline"
        >
          Back
        </p>
        <div className="p-4 flex flex-col-reverse md:flex-row gap-16">
          <div className="flex flex-col gap-4">
            <span className="font-semibold text-base">
              Please complete the form below to start your own online business
              store!
            </span>

            <span className="font-bold text-lg md:text-2xl flex flex-col md:mt-3">
              <span>ID Verification & Bank Account Verification</span>
            </span>

            <form
              id="uploadForm"
              enctype="multipart/form-data"
              method="post"
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 w-full md:mt-2"
            >
              <div className="font-bold text-base md:text-xl">
                ID Verification
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Social Security Number
                </label>
                <input
                  type="text"
                  name="social_security"
                  placeholder="Enter Your Social Security Number"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.social_security}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  National Identity Card Number
                </label>
                <input
                  type="text"
                  name="national_identity"
                  placeholder="Enter Your National Identity Card Number"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.national_identity}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Tax Identification Number
                </label>
                <input
                  type="text"
                  name="tax_identity"
                  placeholder="Enter Your Tax Identification Number"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.tax_identity}
                />
              </div>
              <div className="flex flex-col gap-3">
                <span className="font-semibold text-xs">
                  National Identity Card
                </span>
                {/* id card front  */}
                <div className="w-full h-44 md:h-72">
                  <h2 className="text-xs text-[#18120F] ">ID Card Front</h2>
                  <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                    <input
                      id="frontFileInput"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleIdCardFrontUpload}
                    />
                    {idCardFront ? (
                      <div className="relative w-full h-full">
                        <img
                          src={idCardFront}
                          alt="Uploaded"
                          className="w-full h-full object-cover rounded"
                        />
                        <div className="absolute top-0 right-0 flex gap-2 p-2">
                          <button
                            className="bg-[#C19A6B] text-white p-2 rounded-md"
                            onClick={handleIdCardFrontDelete}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-[0.75rem] font-[400]">
                          Drag and drop file
                        </span>
                        <span className="text-[0.75rem] font-[400]">or</span>
                        <button
                          className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                          onClick={() =>
                            document.getElementById("frontFileInput").click()
                          }
                        >
                          Browse
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {/* id card back  */}
                <div className="w-full h-44 md:h-72">
                  <h2 className="text-xs text-[#18120F] ">ID Card Back</h2>
                  <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                    <input
                      id="backFileInput"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleIdCardBackUpload}
                    />
                    {idCardBack ? (
                      <div className="relative w-full h-full">
                        <img
                          src={idCardBack}
                          alt="Uploaded"
                          className="w-full h-full object-cover rounded"
                        />
                        <div className="absolute top-0 right-0 flex gap-2 p-2">
                          <button
                            className="bg-[#C19A6B] text-white p-2 rounded-md"
                            onClick={handleIdCardBackDelete}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-[0.75rem] font-[400]">
                          Drag and drop file
                        </span>
                        <span className="text-[0.75rem] font-[400]">or</span>
                        <button
                          className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                          onClick={() =>
                            document.getElementById("backFileInput").click()
                          }
                        >
                          Browse
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="font-bold text-base md:text-xl">
                Bank Verification
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Account Title
                </label>
                <input
                  type="text"
                  name="account_title"
                  placeholder="Enter Your Account Title"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.account_title}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Account Number
                </label>
                <input
                  type="text"
                  name="account_number"
                  placeholder="Enter Your Account Number"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.account_number}
                />
              </div>
              <div className="flex flex-col gap-1 relative">
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    name="bank"
                    placeholder="Enter Bank Name"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                    value={data.bank}
                  />

                  </div>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Branch Name
                </label>
                <input
                  type="text"
                  name="branch_name"
                  placeholder="Enter Your Branch Name"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.branch_name}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Branch Code
                </label>
                <input
                  type="text"
                  name="branch_code"
                  placeholder="Enter Your Branch Code"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.branch_code}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  IBAN
                </label>
                <input
                  type="text"
                  name="iban"
                  placeholder="Enter Your IBAN"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                  value={data.iban}
                />
              </div>

              {/* cheque copy image  */}
              <div className="w-full h-44 md:h-72">
                <h2 className="text-xs font-semibold ">Cheque Copy</h2>
                <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                  <input
                    id="chequeFileInput"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleChequeCopyUpload}
                  />
                  {chequeCopy ? (
                    <div className="relative w-full h-full">
                      <img
                        src={chequeCopy}
                        alt="Uploaded"
                        className="w-full h-full object-cover rounded"
                      />
                      <div className="absolute top-0 right-0 flex gap-2 p-2">
                        <button
                          className="bg-[#C19A6B] text-white p-2 rounded-md"
                          onClick={handleChequeDelete}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <span className="text-[0.75rem] font-[400]">
                        Drag and drop file
                      </span>
                      <span className="text-[0.75rem] font-[400]">or</span>
                      <button
                        className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                        onClick={() =>
                          document.getElementById("chequeFileInput").click()
                        }
                      >
                        Browse
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {loading ? (
                <button disabled={loading} className="bg-[#C19A6B] animate-pulse py-3 md:py-[14px] px-9 rounded-lg text-center text-xs md:text-base font-semibold tracking-widest text-white mt-4">
                  Loading
                </button>
              ) : (
                <button
                disabled={loading}
                  type="submit"
                  onClick={() => setClickCheck(true)}
                  className="bg-[#C19A6B] py-3 md:py-[14px] px-9 rounded-lg text-center text-xs md:text-base font-semibold tracking-widest text-white mt-4"
                >
                  Complete
                </button>
              )}
            </form>
          </div>
          <div className="hidden lg:block">
            <img src="/assets/icons/bank-verify.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
