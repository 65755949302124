import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../functions/axios";

import {
  ALL_CATEGORY,
  STORE_LEAVE_REQUEST_TO_MALL,
  STORE_SEND_REQUEST_TO_MALL,
  VENDOR_BUNDLES,
  VENDOR_DECORATION_STORE,
  VENDOR_INTRO_VIDEO,
  VENDOR_PEEMIUM_PRODUCT_MAIN,
  VENDOR_PRODUCT,
  VENDOR_PRODUCT_BOOSTER,
  VENDOR_PRODUCT_IN_STOCK,
  VENDOR_PRODUCT_OUT_OF_STOCK,
  VENDOR_STORE_BOOSTER,
  VENDOR_STORE_INFO,
  VENDOR_TICKETS,
  mall_LIST,
  UPCOMING_OFFERS,
  REGISTERED_OFFERS,
  JOIN_OFFER_UPDATE_PRODUCTS,
  VENDOR_BRAND,
  VENDOR_FORGOT_PASSWORD,
  VENDOR_PASSWORD_RESET,
  VENDOR_ORDERS,
  VENDOR_PRODUCT_IMAGE,
  VENDOR_PRODUCT_SEARCH,
  VENDOR_ORDERS_SEARCH,
  VENDOR_ORDER_BY_ID,
  VENDOR_MANAGE_REVIEWS,
  mall_LIST_SEARCH,
  PROFILE_SETTING,
} from "./URLConsts";

const initialState = {
  data: [],
  imageId: null,
  products: [],
  token: localStorage.getItem("token") || "",
  user: JSON.parse(localStorage.getItem("user")) || "",
  step: localStorage.getItem("step") || null,
  vendorEmail: localStorage.getItem("email") || null,
  loading: false,
  error: null,
  message: null,
  signout: false,
  shop_id: localStorage.getItem("shop_id") || 27,
  state: [],
  category: [],
  subCategory: [],
  subSubCategory: [],
  brand: [],
  storeType: [],
  storeStyle: [],
  countries: [],
  cities: [],
  states: [],
  ticketCategoryList: [],
  profileSetting: [],
  influencers: [],
  storeInfoData: null,
  storeIntroVideo: null,
  sellerSupportFaqs: null,
  inReelsData:null,
  inReelByID:null,
};

// Async thunk for logging in the user
export const loginUser = createAsyncThunk(
  "api/login",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axios.post("/vendor/login", user);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// vendor forgot password
export const vendorForgotPassword = createAsyncThunk(
  "vendorForgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const res = await axios.post(VENDOR_FORGOT_PASSWORD, email);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const vendorImageUpdate = createAsyncThunk(
  "vendorImageUpdate",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const res = await axios.post(
          VENDOR_PRODUCT_IMAGE + arg.id,
          arg.formData
        );
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// vendor new password
export const vendorPasswordReset = createAsyncThunk(
  "vendorPasswordReset",
  async (password_detail, { rejectWithValue }) => {
    try {
      const res = await axios.post(VENDOR_PASSWORD_RESET, password_detail);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// vendor storeTypeGet
export const storeTypeGet = createAsyncThunk(
  "/storeTypeGet",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/public/store-type");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// vendor storeStyle
export const storeStyleGet = createAsyncThunk(
  "/storeStyle",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/public/store-style");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// vendor accnt creation
export const vendorAccountCreation = createAsyncThunk(
  "/vendorAccountCreation",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("/vendor/register", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const storeSendRequestToMall = createAsyncThunk(
  "/storeSendRequestToMall",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${STORE_SEND_REQUEST_TO_MALL}/${arg.id}?status=${arg.status}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const storePremadeAdd = createAsyncThunk(
  "/storePremadeAdd",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/vendor/boosters-premade`, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const vendorStoreBoosterCustom = createAsyncThunk(
  "/vendorStoreBoosterCustom",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_STORE_BOOSTER, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const storeLeaveRequestToMall = createAsyncThunk(
  "/storeLeaveRequestToMall",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.delete(STORE_LEAVE_REQUEST_TO_MALL + arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const MallList = createAsyncThunk(
  "/MallList",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(mall_LIST, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// vendor order list search
export const mallListSearch = createAsyncThunk(
  "mallListSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      // Build the URL with the determined query parameter
      const res = await axios.get(`${mall_LIST_SEARCH}?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getBundle = createAsyncThunk(
  "/getBundle",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_BUNDLES, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const listVendorProduct = createAsyncThunk(
  "/listVendorProduct",
  async ({ page } = {}, { rejectWithValue }) => {
    try {
      const response = page
        ? await axios.get(`vendor/products?page=${page}`)
        : await axios.get(`vendor/products`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const bundleSearch = createAsyncThunk(
  "/bundleSearch",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/vendor/bundles-search?search=${arg}`,
        arg
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// export const listVendorProduct = createAsyncThunk("/listVendorProduct", async ({page}, { rejectWithValue }) => {
//     try {
//       if (page) {
//         const response = await axios.get(`/vendor/products?page=${page}`);
//         return response.data;
//       } else {
//         const response = await axios.get(`/vendor/products`);

//         return response.data;
//       } catch (error) {
//         if (error.response && error.response.data) {
//           return rejectWithValue(error.response.data);
//         }
//         return rejectWithValue(error.message);
//       }
//     }
//     }
//   );
export const vnedorBundleById = createAsyncThunk(
  "/vnedorBundleById",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(VENDOR_BUNDLES + "/" + arg, arg);
        return response.data;
      } else {
        const response = await axios.get(VENDOR_PRODUCT + "/" + arg, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAllMainCategories = createAsyncThunk(
  "/fetchAllMainCategories",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(`/public/categories`);
        return response.data;
      } else {
        const response = await axios.get(`/public/categories`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSubCategories = createAsyncThunk(
  "/fetchSubCategories",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/list-sub-category/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSubSubCategories = createAsyncThunk(
  "/fetchSubSubCategories",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/list-sub-sub-category/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAllBrands = createAsyncThunk(
  "/fetchAllBrands",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(VENDOR_BRAND);
        return response.data;
      } else {
        const response = await axios.get(VENDOR_BRAND);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getCountrie = createAsyncThunk(
  "/getCountrie",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get("countries", arg);
        return response.data;
      } else {
        const response = await axios.get("countries", arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getCitiesByCountries = createAsyncThunk(
  "/getCitiesByCountries",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(`/countries/cities/${arg}`);
        return response.data;
      } else {
        const response = await axios.get(`/countries/cities/${arg}`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getStateByCountry = createAsyncThunk(
  "/getStateByCountry",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(`countries/${arg}`, arg);
        return response.data;
      } else {
        const response = await axios.get(`countries/${arg}`, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const vendorProductBooster = createAsyncThunk(
  "/vendorProductBooster",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          VENDOR_PRODUCT_BOOSTER + `?${arg.arg1}=` + arg.arg2
        );
        return response.data;
      } else {
        const response = await axios.get(
          VENDOR_PRODUCT_BOOSTER + "?page=" + 1,
          arg
        );
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getVendorProduct = createAsyncThunk(
  "/getVendorProduct",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(VENDOR_PRODUCT + "/" + arg, arg);
        return response.data;
      } else {
        const response = await axios.get(VENDOR_PRODUCT + "?page=" + 1, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const vendorProductSearch = createAsyncThunk(
  "/vendorProductSearch",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(VENDOR_PRODUCT_SEARCH + arg, arg);
        return response.data;
      } else {
        const response = await axios.get(VENDOR_PRODUCT + "?page=" + 1, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const deleteVendorProduct = createAsyncThunk(
  "/deleteVendorProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.delete(VENDOR_PRODUCT + "/" + arg, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorProductInStock = createAsyncThunk(
  "/vendorProductInStock",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_PRODUCT_IN_STOCK, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const vendorProductOutOfStock = createAsyncThunk(
  "/vendorProductOutOfStock",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_PRODUCT_OUT_OF_STOCK, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const MallListById = createAsyncThunk(
  "/MallListById",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(mall_LIST + "/" + arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const listPremiumProduct = createAsyncThunk(
  "/listPremiumProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_PEEMIUM_PRODUCT_MAIN);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductCategory = createAsyncThunk(
  "/premiumProductCategory",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vendor/premium-product`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorStoreBooster = createAsyncThunk(
  "/vendorStoreBooster",
  async ({ page, status }, { rejectWithValue }) => {
    try {
      if (page && status) {
        const response = await axios.get(
          `/vendor/boosters?status=${status}&page=${page}`
        );
        return response.data;
      } else if (!page && status) {
        const response = await axios.get(`/vendor/boosters?status=${status}`);
        return response.data;
      } else if (page && !status) {
        const response = await axios.get(`/vendor/boosters?page=${page}`);
        return response.data;
      } else {
        const response = await axios.get(`/vendor/boosters`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const vendorPremiumCategory = createAsyncThunk(
  "/vendorPremiumCategory",
  async ({ id, status, page }, { rejectWithValue }) => {
    try {
      if (status && !page) {
        const response = await axios.get(
          `/vendor/premium-category/${id}?status=${status}`
        );
        return response.data;
      } else if (!status && page) {
        const response = await axios.get(
          `/vendor/premium-category/${id}?page=${page}`
        );
        return response.data;
      } else if (status && page) {
        const response = await axios.get(
          `/vendor/premium-category/${id}?status=${status}&page=${page}`
        );
        return response.data;
      } else {
        const response = await axios.get(`/vendor/premium-category/${id}`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductAllBids = createAsyncThunk(
  "/premiumProductAllBids",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          `/vendor/premium-product-all?page=${arg}`
        );
        return response.data;
      } else {
        const response = await axios.get(`/vendor/premium-product-all`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorAccountCreationSecondForm = createAsyncThunk(
  "/vendorAccountCreationSecondForm",
  async (formData) => {
    const res = await axios.post(`/vendor/register-step-2`, formData);
    return res.data;
  }
);

// get countries
export const getCountries = createAsyncThunk("/getCountries", async () => {
  const res = await axios.get(`/countries`);
  return res.data;
});

// get countries
export const getStates = createAsyncThunk("/getStates", async (id) => {
  const res = await axios.get(`/countries/${id}`);
  return res.data;
});

// get countries
export const getCities = createAsyncThunk("/getCities", async (id) => {
  const res = await axios.get(`/countries/state/${id}`);
  return res.data;
});

export const vendorOtpVerification = createAsyncThunk(
  "/vendorOtpVerification",
  async (data) => {
    const res = await axios.post("/verify-otp", data);
    return res.data;
  }
);

export const vendorProfileUpdate = createAsyncThunk(
  "/vendorProfileUpdate",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("vendor/update", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorInformationUpdate = createAsyncThunk(
  "/vendorInformationUpdate",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("vendor/update-information", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorAddressUpdate = createAsyncThunk(
  "/vendorAddressUpdate",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("vendor/ware-house", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorAddressGet = createAsyncThunk(
  "/vendorAddressGet",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("vendor/get", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "/addProdcut",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_PRODUCT, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const addbid = createAsyncThunk(
  "/addbid",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/vendor/premium-product`, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const vendorCustomBooster = createAsyncThunk(
  "/vendorCustomBooster",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_PRODUCT_BOOSTER, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const addStoreDecoration = createAsyncThunk(
  "/addStoreDecoration",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_DECORATION_STORE, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const addBundles = createAsyncThunk(
  "/addBundles",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_BUNDLES, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const addInreels = createAsyncThunk(
  "/addInreels",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post('vendor/reels',  arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateInreels = createAsyncThunk(
  "/updateInreels",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post('vendor/reels',  arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getInreels = createAsyncThunk(
  "/getInreels",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get('vendor/reels');
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getInreelByID = createAsyncThunk(
  "/getInreelByID",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(`vendor/reels/${arg}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateBundle = createAsyncThunk(
  "/updateBundle",
  async ({ id, object }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/vendor/bundles/${id}`, object);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const addTicket = createAsyncThunk(
  "/addTicket",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_TICKETS, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCategoryList = createAsyncThunk(
  "/getCategoryList",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/vendor/category-list", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addStoreInfo = createAsyncThunk(
  "/addStoreInfo",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_STORE_INFO, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getVendorStoreInfo = createAsyncThunk(
  "/getVendorStoreInfo",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_STORE_INFO, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const addStoreVideoInfo = createAsyncThunk(
  "/addStoreVideoInfo",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(VENDOR_INTRO_VIDEO, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getIntroVideo = createAsyncThunk(
  "/getIntroVideo",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_INTRO_VIDEO, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTicket = createAsyncThunk(
  "/getTicket",
  async ({ page, status }, { rejectWithValue }) => {
    try {
      if (page) {
        const response = await axios.get(
          `/vendor/tickets?status=${status}&page=${page}`
        );
        return response.data;
      } else {
        const response = await axios.get(`/vendor/tickets?status=${status}`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const ticketSearch = createAsyncThunk(
  "/ticketSearch",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg === "") {
        const response = await axios.get(`/vendor/tickets?status=pending`);
        return response.data;
      } else {
        const response = await axios.get(`/vendor/ticket-search?search=${arg}`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTicketById = createAsyncThunk(
  "/getTicketById",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(VENDOR_TICKETS + "/" + arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateProduct = createAsyncThunk(
  "/updateProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        VENDOR_PRODUCT + "/" + arg.id,
        arg.formData
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Offer Async Thunks Start here

// available offers
export const availableOffers = createAsyncThunk(
  "/availableOffers",
  async ({ status, page }, { rejectWithValue }) => {
    try {
      if (status && !page) {
        const response = await axios.get(`/vendor/offers?status=${status}`);
        return response.data;
      } else if (!status && page) {
        const response = await axios.get(`/vendor/offers?page=${page}`);
        return response.data;
      } else if (status && page) {
        const response = await axios.get(
          `/vendor/offers?page=${page}&status=${status}`
        );
        return response.data;
      } else {
        const response = await axios.get(`/vendor/offers`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// upcoming offers
export const upcomingOffers = createAsyncThunk(
  "/upcomingOffers",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(UPCOMING_OFFERS + "?page=" + arg, arg);
        return response.data;
      } else {
        const response = await axios.get(UPCOMING_OFFERS + "?page=" + 1, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// registered offers
export const registeredOffers = createAsyncThunk(
  "/registeredOffers",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          REGISTERED_OFFERS + "?page=" + arg,
          arg
        );
        return response.data;
      } else {
        const response = await axios.get(REGISTERED_OFFERS + "?page=" + 1, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// join offer view
export const joinOfferView = createAsyncThunk(
  "/joinOfferView",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vendor/offers/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// join offer
export const joinOffer = createAsyncThunk(
  "/joinOffer",
  async (offer_id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/vendor/offer-join/${offer_id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// sending products for specific join offer
export const joinOfferProductPost = createAsyncThunk(
  "/joinOfferProductPost",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/vendor/offer-join/${id}`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// fetching products for specific join offer
export const joinOfferProductsList = createAsyncThunk(
  "/joinOfferProductsList",
  async ({ id, page }, { rejectWithValue }) => {
    try {
      if (page) {
        const response = await axios.get(`/vendor/offers/${id}?page=${page}`);
        return response.data;
      } else {
        const response = await axios.get(`/vendor/offers/${id}`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Updating offer products
export const joinOfferUpdateProducts = createAsyncThunk(
  "/joinOfferUpdateProducts",
  async (payload, { rejectWithValue }) => {
    const { offer_id, product_id } = payload; // Destructure offer_id and product_id from the payload

    try {
      const payloadFormatted = { product_id }; // Create the payload object in the required format

      const response = await axios.put(
        `${JOIN_OFFER_UPDATE_PRODUCTS}/${offer_id}`,
        payloadFormatted
      );
      console.log("Response:", response);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Offer Async Thunks End here

// vendor -orders list
export const ordersList = createAsyncThunk(
  "/ordersList",
  async ({ page, status }) => {
    if (!page && !status) {
      const res = await axios.get(VENDOR_ORDERS);
      return res.data;
    } else if (page && !status) {
      const res = await axios.get(`${VENDOR_ORDERS}?page=` + page);
      return res.data;
    } else if (!page && status) {
      const res = await axios.get(`${VENDOR_ORDERS}?status=` + status);
      return res.data;
    } else if (page && status) {
      const res = await axios.get(
        `${VENDOR_ORDERS}?status=` + status + "&page=" + page
      );
      return res.data;
    }
  }
);

// vendor order list search
export const ordersListSearch = createAsyncThunk(
  "ordersListSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      // Build the URL with the determined query parameter
      const res = await axios.get(`${VENDOR_ORDERS_SEARCH}?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// vendor order by id
export const orderByID = createAsyncThunk(
  "orderByID",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${VENDOR_ORDER_BY_ID}/${id}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// manage reviews
export const manageReviews = createAsyncThunk(
  "/manageReviews",
  async ({ page, status }) => {
    if (!page && !status) {
      const res = await axios.get(VENDOR_MANAGE_REVIEWS);
      return res.data;
    } else if (page && !status) {
      const res = await axios.get(`${VENDOR_MANAGE_REVIEWS}?page=` + page);
      return res.data;
    } else if (!page && status) {
      const res = await axios.get(`${VENDOR_MANAGE_REVIEWS}?status=` + status);
      return res.data;
    } else if (page && status) {
      const res = await axios.get(
        `${VENDOR_MANAGE_REVIEWS}?status=` + status + "&page=" + page
      );
      return res.data;
    }
  }
);
// Async thunk for logging in the user
export const reviewReply = createAsyncThunk(
  "reviewReply",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`vendor/reviews/${data.productId}`, {
        rating_review_id: data.rating_review_id,
        description: data.description,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const vendorAccountSetting = createAsyncThunk(
  "vendorAccountSetting",
  async (profileSetting, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${PROFILE_SETTING}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
/////////////////////
export const getInfluencers = createAsyncThunk(
  "/getInfluencers",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/vendor/affilates");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addAffilate = createAsyncThunk(
  "/addAffilate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/vendor/affilates", data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchAffilate = createAsyncThunk(
  "/searchAffilate",
  async (search, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vendor/search?search=${search}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getAffilate = createAsyncThunk(
  "/getAffilate",
  async ({ status, page }, { rejectWithValue }) => {
    try {
      if (status && page) {
        const response = await axios.get(
          `/vendor/affilates-list?status=${status}&page=${page}`
        );
        return response.data;
      } else if (status && !page) {
        const response = await axios.get(
          `/vendor/affilates-list?status=${status}`
        );
        return response.data;
      } else if (!status && page) {
        const response = await axios.get(`/vendor/affilates-list?page=${page}`);
        return response.data;
      } else {
        const response = await axios.get("/vendor/affilates-list");
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);



export const getSellerSupportFaqs = createAsyncThunk(
  "/getSellerSupportFaqs",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vendor/faqs`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const manageSingleOrder = createAsyncThunk(
  "/manageSingleOrder",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vendor/orders/${arg.id}?status=${arg.status}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
////////////////////

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload; // Set the token from the action payload
      localStorage.setItem("token", action.payload); // Store the token in localStorage
    },
    addUser: (state, action) => {
      // state.user = localStorage.getItem("user");
      state.user = localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("step");
    },
    searchIT: (state, action) => {
      state.searchData = action.payload;
    },
    clearMessage: (state) => {
      state.message = null;
      state.error = null;
    },
    clearImageId: (state, action) => {
      state.imageId = null;
    },
    resetSignout: (state) => {
      state.signout = false;
    },
  },
  extraReducers: (builder) => {
    // for login
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.error = payload.message;
        } else {
          console.log("vendor", payload);
          if (payload?.vendor_token) state.token = payload.vendor_token;
          if (payload?.temp_token) state.token = payload.temp_token;
          state.message = payload.message;
          state.step = payload.step;
          state.vendorEmail = payload.email;
          localStorage.setItem(
            "token",
            payload?.temp_token ? payload?.temp_token : payload?.vendor_token
          ); // Store the token in localStorage
          localStorage.setItem("step", payload.step); // Store the token in localStorage
          localStorage.setItem("email", payload.email); // Store the token in localStorage
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }

        state.loading = false;
        state.error = action.payload.message || "Login failed";
      });

    // forgot password
    builder
      .addCase(vendorForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorForgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(vendorForgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        console.log(action.payload);
      });
    builder
      .addCase(vendorImageUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorImageUpdate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.imageId = payload;
        console.log(payload);
      })
      .addCase(vendorImageUpdate.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message
        console.log(action.payload);
      });

    // password reset
    builder
      .addCase(vendorPasswordReset.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorPasswordReset.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
        state.status = payload.success;
      })
      .addCase(vendorPasswordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        // if (action.payload.errors){
        //     state.error = Object.values(action.payload.errors)[0][0]
        // }
        // else{
        //     state.error = Object.values(action.payload.errors)[0][0]
        // }
      });

    // for vendor storetypeget
    builder.addCase(storeTypeGet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storeTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.storeType = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(storeTypeGet.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
    });

    // for vendor storetypeget
    builder.addCase(storeStyleGet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storeStyleGet.fulfilled, (state, action) => {
      state.loading = false;
      state.storeStyle = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(storeStyleGet.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    // for vendorAccountCreation
    builder.addCase(vendorAccountCreation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorAccountCreation.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.data.message;
      localStorage.setItem("token", action.payload.data.token);
    });
    builder.addCase(vendorAccountCreation.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(storeSendRequestToMall.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storeSendRequestToMall.fulfilled, (state, action) => {
      state.loading = false;

      state.message = action.payload.message;
    });
    builder.addCase(storeSendRequestToMall.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.error;
    });
    builder.addCase(storePremadeAdd.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storePremadeAdd.fulfilled, (state, action) => {
      state.loading = false;

      state.message = action.payload.message;
    });
    builder.addCase(storePremadeAdd.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
    });
    builder.addCase(vendorStoreBoosterCustom.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorStoreBoosterCustom.fulfilled, (state, action) => {
      state.loading = false;

      state.message = action.payload.message;
    });
    builder.addCase(vendorStoreBoosterCustom.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      if (Object.values(action.payload?.errors)[0][0]) {
        state.error = Object.values(action.payload?.errors)[0][0];
      } else {
        state.error = action.payload?.errors;
      }
    });

    builder.addCase(vendorPremiumCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorPremiumCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.message = action.payload.message
    });
    builder.addCase(vendorPremiumCategory.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(premiumProductAllBids.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(premiumProductAllBids.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.message = action.payload.message
    });
    builder.addCase(premiumProductAllBids.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(storeLeaveRequestToMall.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storeLeaveRequestToMall.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(storeLeaveRequestToMall.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
    });

    // for vendorAccountCreationSecondForm
    builder.addCase(vendorAccountCreationSecondForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      vendorAccountCreationSecondForm.fulfilled,
      (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      }
    );
    builder.addCase(
      vendorAccountCreationSecondForm.rejected,
      (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }

        state.loading = false;
      }
    );

    // get countries
    builder.addCase(getCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.countries = action.payload;
    });
    builder.addCase(getCountries.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
    });

    // get states
    builder.addCase(getStates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.loading = false;
      state.states = action.payload;
    });
    builder.addCase(getStates.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
    });

    // get cities
    builder.addCase(getCities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.loading = false;
      state.cities = action.payload;
    });
    builder.addCase(getCities.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
    });

    // for vendorOtpVerification
    builder.addCase(vendorOtpVerification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorOtpVerification.fulfilled, (state, action) => {
      state.loading = false;
      // state.userid = action.payload.user_id;
      state.message = action.payload.message;
    });
    builder.addCase(vendorOtpVerification.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      console.log(action);
    });

    //vendorProfileUpdate
    builder.addCase(vendorProfileUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorProfileUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorProfileUpdate.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });

    //vendorInformationUpdate
    builder.addCase(vendorInformationUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorInformationUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorInformationUpdate.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });

    //vendorInformationUpdate
    builder.addCase(vendorAddressGet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorAddressGet.fulfilled, (state, action) => {
      state.loading = false;
      state.profileSetting = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorAddressGet.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });

    builder.addCase(addProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      if (Object.values(action.payload?.errors)[0][0] == "P") {
        state.error = action.payload?.errors;
      } else {
        state.error = Object.values(action.payload?.errors)[0][0];
      }
    });
    builder.addCase(addbid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addbid.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addbid.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload?.error;
      console.log(action);
    });
    builder.addCase(vendorCustomBooster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorCustomBooster.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorCustomBooster.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      console.log(action);
      if (action.payload?.error?.message) {
        state.error = action.payload.error.message;
      } else if (
        action.payload?.errors &&
        Object.values(action.payload.errors).length > 0
      ) {
        state.error = Object.values(action.payload.errors)[0][0];
      } else {
        state.error = "An unknown error occurred";
      }
    });
    builder.addCase(addStoreDecoration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreDecoration.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addStoreDecoration.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });
    builder.addCase(addBundles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBundles.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addBundles.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });
    builder.addCase(addInreels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addInreels.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addInreels.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });
    builder.addCase(updateInreels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInreels.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(updateInreels.rejected, (state, action) => {
      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
    });
    builder.addCase(updateBundle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBundle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(updateBundle.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(addTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTicket.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addTicket.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(getCategoryList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.loading = false;
      state.ticketCategoryList = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getCategoryList.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(addStoreInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addStoreInfo.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });
    builder.addCase(addStoreVideoInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreVideoInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addStoreVideoInfo.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(getTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTicket.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getTicket.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(getVendorStoreInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVendorStoreInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.storeInfoData = action.payload.data;
      // state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getVendorStoreInfo.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      // state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });
    builder.addCase(getIntroVideo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIntroVideo.fulfilled, (state, action) => {
      state.loading = false;
      state.storeIntroVideo = action.payload.data;
      // state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getIntroVideo.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      // state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(ticketSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ticketSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(ticketSearch.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload?.message;
      state.data = action.payload;
    });

    builder.addCase(getTicketById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTicketById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getTicketById.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });
    builder.addCase(updateProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      console.log(action);
    });

    builder.addCase(listPremiumProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listPremiumProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(listPremiumProduct.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });

    builder.addCase(premiumProductCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(premiumProductCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.message = action.payload.message
    });
    builder.addCase(premiumProductCategory.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(vendorStoreBooster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorStoreBooster.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorStoreBooster.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });
    builder.addCase(MallList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(MallList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(MallList.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      // state.error = action.payload.errors;
      console.log(action);
    });
    // for mallListSearch search
    builder
      .addCase(mallListSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(mallListSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        console.log(payload);
        state.data = payload;
      })
      .addCase(mallListSearch.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });

    builder.addCase(fetchAllMainCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMainCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.category = action.payload.categories;
      state.message = action.payload.message;
    });
    builder.addCase(fetchAllMainCategories.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(fetchSubCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.subCategory = action.payload.subcategories;
      state.message = action.payload.message;
    });
    builder.addCase(fetchSubCategories.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(fetchSubSubCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubSubCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.subSubCategory = action.payload.subSubCategories;
      state.message = action.payload.message;
    });
    builder.addCase(fetchSubSubCategories.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(fetchAllBrands.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllBrands.fulfilled, (state, action) => {
      state.loading = false;
      state.brand = action.payload.data;
      state.message = action.payload.message;
    });
    builder.addCase(fetchAllBrands.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(getBundle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBundle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(getBundle.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(bundleSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bundleSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(bundleSearch.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    builder.addCase(vendorProductBooster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorProductBooster.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorProductBooster.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
    });

    builder.addCase(listVendorProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listVendorProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(listVendorProduct.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });
    builder.addCase(vnedorBundleById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vnedorBundleById.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vnedorBundleById.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });
    builder.addCase(getCountrie.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountrie.fulfilled, (state, action) => {
      state.loading = false;
      state.countries = action.payload.countries;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getCountrie.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });
    builder.addCase(getCitiesByCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCitiesByCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.cities = action.payload.cities;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getCitiesByCountries.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });
    builder.addCase(getVendorProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVendorProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getVendorProduct.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });
    builder.addCase(vendorProductSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorProductSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorProductSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.data = [];
      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });
    builder.addCase(deleteVendorProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteVendorProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(deleteVendorProduct.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      console.log(action);
    });

    builder.addCase(vendorProductInStock.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorProductInStock.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorProductInStock.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });

    builder.addCase(vendorProductOutOfStock.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vendorProductOutOfStock.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(vendorProductOutOfStock.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.errors;
      console.log(action);
    });

    builder.addCase(MallListById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(MallListById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      console.log(action);
    });
    builder.addCase(MallListById.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action.payload.error;
      console.log(action);
    });

    // add case for offers section started here

    // avialble offers
    builder
      .addCase(availableOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(availableOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload)
        state.data = payload;
      })
      .addCase(availableOffers.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // upcoming offers
    builder
      .addCase(upcomingOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(upcomingOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload)
        state.data = payload;
      })
      .addCase(upcomingOffers.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // registered offers
    builder
      .addCase(registeredOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(registeredOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload)
        state.data = payload;
      })
      .addCase(registeredOffers.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // join offers view
    builder
      .addCase(joinOfferView.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinOfferView.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(joinOfferView.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // join offers
    builder
      .addCase(joinOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinOffer.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.message = payload.message;
      })
      .addCase(joinOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // join offers product post
    builder
      .addCase(joinOfferProductPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinOfferProductPost.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.message = payload.message;
      })
      .addCase(joinOfferProductPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        console.log(action.payload.error);
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // join offers product list
    builder
      .addCase(joinOfferProductsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinOfferProductsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.products = payload;
      })
      .addCase(joinOfferProductsList.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // join offers update products
    builder
      .addCase(joinOfferUpdateProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinOfferUpdateProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        console.log("update", payload.message);
      })
      .addCase(joinOfferUpdateProducts.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // add case for offers section ended here

    // vendor order list
    builder
      .addCase(ordersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(ordersList.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload)
        state.data = payload;
      })
      .addCase(ordersList.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // for order-list search
    builder
      .addCase(ordersListSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(ordersListSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        console.log(payload);
        state.data = payload;
      })
      .addCase(ordersListSearch.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // vendor order by id
    builder
      .addCase(orderByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderByID.fulfilled, (state, { payload }) => {
        state.loading = false;
        console.log(payload);
        state.data = payload;
        state.message = payload?.message;
      })
      .addCase(orderByID.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });

    // manage reviews
    builder
      .addCase(manageReviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageReviews.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload)
        state.data = payload;
      })
      .addCase(manageReviews.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });

    //  review reply
    builder
      .addCase(reviewReply.pending, (state) => {
        state.loading = true;
      })
      .addCase(reviewReply.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.message = payload.message;
      })
      .addCase(reviewReply.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        console.log(action.payload.error);
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });

    // profile setting
    builder
      .addCase(vendorAccountSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorAccountSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profileSetting = payload;
      })
      .addCase(vendorAccountSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });

    //////////////
    builder.addCase(getInfluencers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInfluencers.fulfilled, (state, action) => {
      state.loading = false;
      state.influencers = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(getInfluencers.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload?.errors;
      console.log(action);
    });

    builder.addCase(addAffilate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAffilate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(addAffilate.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload?.errors;
      state.message = action.payload.message;
    });

    builder.addCase(searchAffilate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchAffilate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.data = action.payload;
    });
    builder.addCase(searchAffilate.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload?.errors;
      state.message = action.payload.message;
      state.data = action.payload;
    });

    builder.addCase(getAffilate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAffilate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.data = action.payload;
    });
    builder.addCase(getAffilate.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload?.errors;
    });


    builder
    .addCase(getSellerSupportFaqs.pending, (state) => {
      state.loading = true;
    })
    .addCase(getSellerSupportFaqs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.sellerSupportFaqs = payload;
    })
    .addCase(getSellerSupportFaqs.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.payload.error;
    });

    builder
    .addCase(getInreels.pending, (state) => {
      state.loading = true;
    })
    .addCase(getInreels.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.inReelsData = payload;
    })
    .addCase(getInreels.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.payload.error;
    });

    builder
    .addCase(getInreelByID.pending, (state) => {
      state.loading = true;
    })
    .addCase(getInreelByID.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.inReelByID = payload;
    })
    .addCase(getInreelByID.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.payload.error;
    });

    builder
    .addCase(manageSingleOrder.pending, (state) => {
      state.loading = true;
    })
    .addCase(manageSingleOrder.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    })
    .addCase(manageSingleOrder.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.payload.error;
    });
    //////////////
  },
});

export const {
  addToken,
  addUser,
  logout,
  searchIT,
  clearMessage,
  resetSignout,
  clearImageId,
} = adminSlice.actions;
export default adminSlice.reducer;
// Exporting the reducer directly without naming it
