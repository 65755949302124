
export const mall_LIST = "vendor/malls"
export const mall_LIST_SEARCH = "vendor/mall-search"
export const STORE_SEND_REQUEST_TO_MALL="vendor/mall"
export const STORE_LEAVE_REQUEST_TO_MALL="/leave-send-request/"
export const VENDOR_PRODUCT="/vendor/products"
export const VENDOR_PRODUCT_IN_STOCK="/products-in-stock"
export const VENDOR_PRODUCT_OUT_OF_STOCK="/products-out-of-stock"
export const VENDOR_TICKETS="/vendor/tickets"
export const VENDOR_STORE_INFO="vendor/store-info"
export const VENDOR_INTRO_VIDEO="vendor/intro-video" 
export const VENDOR_DECORATION_STORE="vendor/store-decoration" 
export const VENDOR_PRODUCT_BOOSTER="vendor/product-booster"

export const VENDOR_PEEMIUM_PRODUCT="/vendor-premium-product-list"
export const VENDOR_PEEMIUM_PRODUCT_MAIN="/vendor-premium-product"
export const VENDOR_STORE_BOOSTER="/vendor/boosters"
export const VENDOR_STORE_BOOSTER_PREMADEAD="/vendorstore-booster-premade"
export const VENDOR_BUNDLES="/vendor/bundles"
export const VENDOR_BRAND="/brands-list"
export const ALL_CATEGORY="/public/list-main-category"
export const VENDOR_PREMIUM_CATEGORY="vendor-premium-category/"


// offers section urls
export const UPCOMING_OFFERS = "/upcoming-offer-list"
export const REGISTERED_OFFERS = "/registered-offer-list"
export const JOIN_OFFER_PRODUCTS_POST = "/product-vendor-join-offer"
export const JOIN_OFFER_PRODUCTS_LIST = "/single-register-offer"
export const JOIN_OFFER_UPDATE_PRODUCTS = "/single-register-offer-update"

// vendor forgot password
export const VENDOR_FORGOT_PASSWORD = "vendor/forgot-password"
export const VENDOR_PASSWORD_RESET = "vendor/reset-password"

// vendor order
export const VENDOR_ORDERS = "vendor/orders"

export const VENDOR_PRODUCT_IMAGE = "/vendor/product-image/"
export const VENDOR_PRODUCT_SEARCH = "/vendor/products-search?search="


export const VENDOR_ORDERS_SEARCH = "vendor/orders-search"
export const VENDOR_ORDER_BY_ID = "vendor/orders"
export const VENDOR_MANAGE_REVIEWS = "vendor/reviews"
export const PROFILE_SETTING = "vendor/list"
