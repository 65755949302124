import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import JoinMallList from "../../../components/MyShop/Join-Mall-Components/JoinMallList";

export default function JoinMallListPage() {
  return (
    <>
      <MyShopLayout>
        <JoinMallList />
      </MyShopLayout>
    </>
  );
}
