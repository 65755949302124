import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";

import Adresses from "../../../components/MyShop/Vendor-Profile-Components/Adresses";

export default function AdressesPage() {
  return (
    <>
      <MyShopLayout>
        <Adresses />
      </MyShopLayout>
    </>
  );
}
