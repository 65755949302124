import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import VendorInreelsUpload from "../../../components/MyShop/Vendor-Inreels-Components/VendorInreelsUpload";

export default function VendorInreelsUplaodPage() {
  return (
    <>
      <MyShopLayout>
        <VendorInreelsUpload />
      </MyShopLayout>
    </>
  );
}
