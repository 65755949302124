import TheHeader from "../components/VendorHeader/TheHeader.jsx";
import BoosterSidebar from "../components/Vendor-Dashboard-Sidebars/BoosterSideBar.jsx";
import { BiMenu } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";

export default function BoosterLayout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [closing, setClosing] = useState(false);

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[2];

  const sidebarRef = useRef(null);

  const toggleSidebarOpen = () => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    } else {
      setIsSidebarOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  }, [location.pathname]);
  return (
    <>
      <div
        className="flex py-2 items-center gap-4 bg-white fixed top-0 right-0 xl:right-4 z-50 w-full xl:w-[calc(100%-18rem)] px-4"
        // style={{ width: "calc(100% - 18rem)" }}
      >
        <div className="block xl:hidden">
          <BiMenu className="w-7 h-7" onClick={toggleSidebarOpen} />
        </div>
        <div className="w-full">
          <div className="flex-grow flex-shrink-0">
            <TheHeader />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="hidden xl:flex  fixed top-4 left-4 h-full">
          <BoosterSidebar />
        </div>
        <div className="xl:ml-64 w-full mt-24 px-4">{children}</div>
      </div>

      {/* For mobile sidebar */}
      {isSidebarOpen && (
        <div
          ref={sidebarRef}
          className={`flex flex-col xl:hidden fixed top-0 left-0 h-screen  z-50 w-[250px]  xl:w-[30%] rounded-tr-xl rounded-br-xl ${
            closing ? "animate-sm-out" : "animate-sm"
          }`}
        >
          <IoCloseOutline
            onClick={toggleSidebarOpen}
            className="absolute right-1 top-1 z-10 w-8 h-8 text-white"
          />

          <BoosterSidebar />
        </div>
      )}
    </>
  );
}
