import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitiesByCountries,
  getCountrie,
  storePremadeAdd,
} from "../../../../store/vendorslice";

export default function StoreBoostModal({ payload, setPayload }) {
  const [dropdown, setDropdown] = useState(false);
  const [selectCategory, setSelectCategory] = useState("Main category");
  // const [title, setTitle] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [cost, setCost] = useState("");

  const [savedTexts, setSavedTexts] = useState(payload.tags); //tag
  const [inputText, setInputText] = useState("");
  const [dropdownCountry, setDropdownCountry] = useState(false);
  const [dropdownCity, setDropdownCity] = useState(false);

  const [selectCity, setSelectCity] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const dispatch = useDispatch();

  // const categoryOp = ["1", "2", "3"];

  // const toggleDropdown = (id) => {
  //   if (id === "1") {
  //     setDropdownCountry(!dropdownCountry);
  //     setDropdownCity(false);
  //     setDropdown(false);
  //   }
  //   if (id === "2") {
  //     setDropdownCity(!dropdownCity);
  //     setDropdownCountry(false);
  //     setDropdown(false);
  //   }
  //   if (id === "3") {
  //     setDropdown(!dropdown);
  //     setDropdownCountry(false);
  //     setDropdownCity(false);
  //   }
  // };

  const handleDelete = (index) => {
    const newSavedTexts = [...savedTexts];
    newSavedTexts.splice(index, 1);
    setSavedTexts(newSavedTexts);
    setPayload({...payload,tags:newSavedTexts})
  };
  const handleSave = () => {
    if (inputText.trim() !== "") {
      setSavedTexts([...savedTexts, inputText]);
      // setPayload({ ...payload, tags: savedTexts });
      payload.tags.push(inputText)
      setInputText("");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent textarea from creating new line
      handleSave();
    }
  };
  const { cities, countries, states, message, data, loading, category } =
    useSelector((state) => state.admin);

    const handleCountrySelect = (country) => {
    setSelectCountry(country);
    setPayload({ ...payload, country:country });
    dispatch(getCitiesByCountries(country));
    setDropdownCountry(false);
  };

  const handleCitySelect = (city) => {
    setSelectCity(city);
    setPayload({ ...payload, city: city });
    setDropdownCity(false);
  };

  const handleCategorySelect = (category) => {
    setSelectCategory(category);
    setPayload({ ...payload, category_id: category });
    setDropdown(false);
  };

  const handleSelectAndClose = () => {
    if (
      payload.uid &&
      payload.start_date &&
      payload.tags &&
      payload.country &&
      payload.city &&
      payload.category_id
    ) {
      // console.log(payload)
      dispatch(storePremadeAdd(payload));
    } else {
      toast.error("Kindly Fill All fields");
    }
  };

  useEffect(() => {
    dispatch(getCountrie());
  }, []);

  return (
    <div>
      {/* Modal code for selecting reels */}
      <dialog id="my_modal_10" className="modal bg-none">
        <div className="modal-box bg-white p-8 rounded-lg relative">
          <form method="dialog">
            {/* header div */}
            <div className="sticky top-0 text-end z-50 bg-white w-full mb-1">
              <button
                className="sticky  w-5 h-5 md:w-9 md:h-9 bg-white rounded-full hover:shadow-xl hover:bg-slate-50 shadow-lg"
                onClick={() => document.getElementById("my_modal_10").close()}
              >
                <img
                  src="/assets/icons/cancel1.svg"
                  className="mx-auto"
                  alt="Close"
                />
              </button>
            </div>
            <div className="flex w-full md:w-[460px]  rounded-lg md:p-4">
              {/* offers detail */}
              <div className="w-full border border-[#EBF0ED] rounded-xl px-4 py-4 md:py-8">
                <div>
                  <h2 className="text-[18px] mb-4  font-semibold text-[#18120F]">
                    Custom Boost Setting
                  </h2>
                </div>
                {/* first */}
                <div className="flex gap-5">
                  {/* left */}
                  <div className="w-full flex flex-col gap-3">
                    <div className="flex gap-2">
                      <div className="flex flex-col gap-1 w-full">
                        <label
                          className="text-[12px] font-semibold text-[#18120F]"
                          htmlFor=""
                        >
                          Start Date
                        </label>
                        <input
                          className="border outline-none w-full h-[38px] px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                          placeholder=""
                          type="date"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              start_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label className="font-[500] text-xs" htmlFor="">
                        Keywords
                      </label>
                      <div className="w-full flex flex-col gap-5 border border-[#EBF0ED] rounded-xl p-4 h-fit">
                        {/* Display saved texts */}

                        <div className="flex flex-wrap gap-2">
                          {savedTexts.map((text, index) => (
                            <div
                              key={index}
                              className="border flex  gap-2 border-[#EBF0ED] rounded-xl text-xs font-[400] text-[#6B6B6B] px-4 py-[0.7rem] w-fit "
                            >
                              <span>{text}</span>
                              <button
                                className="text-red-500"
                                onClick={() => handleDelete(index)}
                              >
                                <RxCross2 />
                              </button>
                            </div>
                          ))}
                        </div>
                        {/* Input field */}

                        <textarea
                          // value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                          value={inputText}
                          onKeyDown={handleKeyDown} // Add keydown event listener
                          className="w-full border placeholder:text-black resize-none outline-none border-[#EBF0ED] bg-[#FAFAFA] h-10 rounded-xl text-[#6B6B6B] text-xs font-[400] px-3 pt-2"
                          placeholder="Enter Tags"
                        ></textarea>
                      </div>
                    </div>

                    <div className="flex gap-2">
                      <div className="w-full ">
                        <h2 className="text-[12px] font-semibold text-[#18120F]">
                          Country
                        </h2>
                        <div className="relative flex flex-col gap-2">
                          <div className="relative w-full">
                            {countries.length > 0 && (
                              <select
                                name=""
                                id=""
                                onChange={(e) =>
                                  handleCountrySelect(e.target.value)
                                }
                                className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                              >
                                <option value="">none</option>
                                {countries.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full cursor-pointer">
                        <h2 className="text-[12px] font-semibold text-[#18120F]">
                          City
                        </h2>
                        <div className="relative flex flex-col gap-2">
                          <div className="relative w-full">
                            <select
                              name=""
                              id=""
                              onChange={(e) => handleCitySelect(e.target.value)}
                              className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                            >
                              <option value="">none</option>
                              {cities.length > 0 &&
                                cities.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <h2 className="text-[12px] font-semibold text-[#18120F]">
                        Category
                      </h2>
                      <div className="relative flex flex-col gap-2 cursor-pointer">
                        <div className="relative w-full">
                          {category.length > 0 && (
                            <select
                              name=""
                              id=""
                              onChange={(e) =>
                                handleCategorySelect(e.target.value)
                              }
                              className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                            >
                              <option value="">none</option>
                              {category.map((item) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="numberInput">
                      <h2 className="text-[12px] font-semibold text-[#18120F] ">
                        Boost Cost
                      </h2>
                      <input
                        type="number"
                        className="w-full outline-none placeholder:text-black rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-[12px] font-[400] p-3"
                        placeholder="$2500"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* select button */}
            <div className="w-full  h-10">
              <Link
                to=""
                className="bg-[#C19A6B] text-white px-6 md:my-4 py-2 rounded-lg absolute bottom-2 right-8"
                onClick={handleSelectAndClose}
              >
                Select
              </Link>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
