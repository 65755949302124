import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Notifications from "../Common/Notifications/Notifications";

export default function TheHeader() {
  const [notification, setNotification] = useState(false);
  const [dpOptions, setDpOptions] = useState(false);
  // const [closing, setClosing] = useState(false);
  const dpOptionsRef = useRef(null);

  const location = useLocation();
  const handlenotification = () => {
    setNotification(!notification);
    setDpOptions(false);
  };
  const handleClickOutside = (event) => {
    if (dpOptionsRef.current && !dpOptionsRef.current.contains(event.target)) {
      setDpOptions(false);
    }
  };

  useEffect(() => {
    if (dpOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dpOptions]);

  return (
    <>
      <div className="xl:px-[15px]  flex  items-center gap-4 xl:gap-2 bg-white z-50">
        <div className="w-full flex justify-center ">
          <div
            className="px-[0.20rem] py-1 flex items-center justify-center w-[250px] md:w-[282px]  bg-[#FCFCFC] rounded-full"
            style={{ boxShadow: "0px 0px 18.88px 0px #0000000D inset" }}
          >
            <NavLink
              to="/vendor/my-shop/home"
              className={`flex items-center justify-center w-full py-2  rounded-full transition-all duration-500 
             ${
               location.pathname.startsWith("/vendor/my-shop")
                 ? "bg-white"
                 : "bg-transparent"
             }
              `}
            >
              <p className={`text-[13px] font-semibold`}>My Shop</p>
            </NavLink>

            <NavLink
              to="/vendor/insight"
              className={`flex items-center justify-center w-full  py-2 rounded-full transition-all duration-500  ${
                location.pathname.startsWith("/vendor/insight")
                  ? "bg-white"
                  : "bg-transparent"
              }`}
            >
              <p className={`text-[13px] font-semibold`}>InSight</p>
            </NavLink>
            <NavLink
              to="/vendor/booster/offers"
              className={`flex items-center justify-center w-full  py-2 rounded-full transition-all duration-500  ${
                location.pathname.startsWith("/vendor/booster")
                  ? "bg-white"
                  : "bg-transparent"
              }`}
            >
              <p className={`text-[13px] font-semibold`}>Booster</p>
            </NavLink>
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <Link
            to="/vendor/vendor-chat"
            className="bg-[#F7F7F7] relative p-3 rounded-full hidden xl:flex items-center justify-center"
          >
            <img src="/assets/images/header/chat2.svg" className="w-5" alt="" />
            <div className="absolute top-1 right-0 w-2 h-2 bg-[#C19A6B] rounded-full"></div>
          </Link>
          <Link
            onClick={() => setNotification(!notification)}
            className="bg-[#F7F7F7] relative p-3 rounded-full hidden xl:flex items-center justify-center"
          >
            <img
              src="/assets/images/header/notify.svg"
              alt=""
              className="w-5"
            />
            <div className="absolute top-1 right-0 w-2 h-2 bg-[#C19A6B] rounded-full"></div>
          </Link>
          <div className="relative">
            <img
              onClick={() => setDpOptions(!dpOptions)}
              src="/assets/images/header/super-admin-profile.svg"
              className="w-9 h-9 md:w-11 md:h-11 border rounded-lg border-[#C19A6B]"
              alt=""
            />
            {dpOptions && (
              <div
                ref={dpOptionsRef}
                className="md:hidden absolute flex flex-col gap-3 top-[110%] right-5 bg-white shadow-md rounded-sm mx-auto p-6 font-semibold tracking-wider text-[10px] "
              >
                <Link
                  onClick={() => setDpOptions(false)}
                  to="/vendor/my-shop/profile"
                  className="flex items-center gap-2"
                >
                  <img
                    className="w-4 h-4"
                    src="/assets/icons/person.svg"
                    alt="Profile Icon"
                  />
                  <span>Profile</span>
                </Link>
                <Link
                  onClick={() => setDpOptions(false)}
                  to="/vendor/vendor-chat"
                  className="flex items-center gap-2"
                >
                  <img
                    src="/assets/icons/chat2.svg"
                    className="w-4 h-4"
                    alt="Chat Icon"
                  />
                  <span>Chat</span>
                </Link>
                <div
                  onClick={handlenotification}
                  className="flex items-center gap-2"
                >
                  <img
                    className="w-4 h-4"
                    src="/assets/icons/notify.svg"
                    alt="Notification Icon"
                  />
                  <span>Notifications</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Notifications
        notification={notification}
        setNotification={setNotification}
      />
    </>
  );
}
