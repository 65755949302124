import HomePage from "../../pages/My-Shop/Home-Page/HomePage";
import NewOrdersPage from "../../pages/My-Shop/Home-Page/NewOrdersPage";
import VendorAffiliateDashboardPage from "../../pages/My-Shop/Vendor-Affiliate-Dashboard-Page/VendorAffiliateDashboardPage";

const dashboardRoutes = [
  {
    path: "/vendor/my-shop/home",
    element: <HomePage />,
  },
  {
    path: "/vendor/my-shop/affiliate-dashboard",
    element: <VendorAffiliateDashboardPage />,
  },
  {
    path: "/vendor/my-shop/new-orders",
    element: <NewOrdersPage />,
  },
];

export default dashboardRoutes;
