import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { manageSingleOrder, orderByID } from "../../../store/vendorslice";
import Loader from "../../Common/Loader";

export default function OrderView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(orderByID(id));
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }
  return (
    <div className="md:px-5 w-full flex flex-col gap-4">
      {/* name */}
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-3">
        <BreadCrums
          breadCrum={[
            {
              name: "Orders",
              path: "/vendor/my-shop/orders-management",
            },
            {
              name: "View Order",
            },
          ]}
        />
        <div className="flex items-center gap-2 md:gap-4 justify-end ">
          <button
            onClick={() =>
              dispatch(manageSingleOrder({ id: id, status: "reject" }))
            }
            className="bg-[#FAFAFA] border  border-[#DEDEDE] text-[#828282] py-[10px] px-5 md:px-9 font-semibold text-[10px] md:text-sm rounded-lg"
          >
            Reject
          </button>
          {/* <button
          onClick={() => dispatch(manageSingleOrder({id:id, status:"rejected"}))}
          className="bg-[#C19A6B] text-white py-[10px] font-semibold px-5 md:px-9 text-[10px] md:text-sm rounded-lg">
            InProgress
          </button> */}
          <button
            onClick={() =>
              dispatch(manageSingleOrder({ id: id, status: "accept" }))
            }
            className="bg-[#C19A6B] text-white py-[10px] px-5 md:px-9 text-[10px] md:text-sm font-semibold rounded-lg"
          >
            Accept
          </button>
        </div>
      </div>
      {/* short detail */}
      <div className="w-full grid grid-cols-2 gap-2 xl:flex justify-between border border-[#EBF0ED] rounded-2xl py-4 px-4 md:px-6">
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Customer Name:</span>
          <span className="text-[#6B6B6B] ">{data?.data?.customer}</span>
        </div>
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Order Number:</span>
          <span className="text-[#6B6B6B] ">#{data?.data?.order_number}</span>
        </div>{" "}
        <div className=" flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Payment Method:</span>
          <span className="text-[#6B6B6B] ">{data?.data?.payment_method}</span>
        </div>{" "}
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Customer Name:</span>
          <span className="text-[#6B6B6B] ">{data?.data?.created_on}</span>
        </div>
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Total:</span>
          <span className=" ">${data?.data?.total}</span>
        </div>
      </div>
      {/* cards */}
      <div className="flex flex-col md:flex-row gap-5 items-center">
        <div className="w-full">
          <h2 className="text-sm font-[500] mb-1">Shipping Address</h2>
          <div className="w-full px-[25px] py-[15px] border border-[#EBF0ED] rounded-xl">
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal min-w-[70px]">
                Name:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.name}
              </span>
            </div>
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Phone:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.phone}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Email:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.email}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Country:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.country}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                State:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.state}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                City:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.city}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Zip Code:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.zipcode}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Address:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.shipping_address?.address}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full">
          <h2 className="text-sm font-[500] mb-1">Billing Address</h2>
          <div className="w-full px-[25px] py-[15px] border border-[#EBF0ED] rounded-xl">
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Name:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.name}
              </span>
            </div>
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Phone:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.phone}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Email:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.email}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Country:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.country}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                State:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.state}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                City:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.city}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Zip Code:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.zipcode}
              </span>
            </div>{" "}
            <div className="flex items-center gap-7">
              <span className="text-[#8C8C8C] text-xs md:text-base font-normal  min-w-[70px]">
                Address:
              </span>
              <span className="text-xs md:text-base font-normal ">
                {data?.data?.billing_address?.address}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* table */}
      {loading ? (
        <div className="flex justify-center py-4">
          <Loader />
        </div>
      ) : data?.data?.data?.length > 0 ? (
        data?.data?.data?.map((store) => (
          <div className="flex justify-center border-gray-500 overflow-auto">
            <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
              <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
                {/* Table Header */}
                <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                  <span className="w-[60%] text-left">{store.vendor}</span>
                  <span className="w-[20%] text-left">QUANTITY</span>
                  <span className="w-[20%] text-left">PRICE</span>
                </div>

                {/* Table Body */}

                {store?.products?.length > 0 ? (
                  store?.products?.map((product) => (
                    <div
                      key={product.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="flex gap-2 w-[60%] text-left">
                        <img
                          className="w-[94px] h-[94px]"
                          src={product.image}
                          alt=""
                        />
                        <span className="mt-2 text-sm md:text-base font-[500]">
                          {product.name}
                        </span>
                      </div>

                      <span className="w-[20%] text-left font-[400] text-[#888888]">
                        Quantity: {product.product_quantity}
                      </span>
                      <span className="w-[20%] text-left text-sm md:text-base font-semibold">
                        ${product.discount_price}
                      </span>
                    </div>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="text-center font-bold tracking-wide"
                    >
                      No Products Found
                    </td>
                  </tr>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center font-bold tracking-wide">
          No Products Found
        </div>
      )}
    </div>
  );
}
