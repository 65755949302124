import React, { useState } from "react";

export default function CategoryModal({ category, setCategory }) {
  const [subCat, setSubCat] = useState(false);
  const [subSubCat, setSubSubCat] = useState(false);
  const [subSubSubCat, setSubSubSubCat] = useState(false);
  return (
    <>
      <div
        className={` ${
          category ? "block w-[100%] px-4 md:px-[57px]  mx-auto" : "hidden"
        } `}
      >
        <div
          onMouseLeave={() => setCategory(false)}
          className="absolute w-[93%]  top-[186px]   z-50  bg-white  rounded-2xl border-[#D9D9D9] p-2 md:p-4"
        >
          <div className="text-xs md:text-sm font-bold tracking-wide pb-2 md:ml-3  ">
            <span>Category</span>
          </div>
          <div className="w-full  flex md:gap-5 border-t">
            {/**/}
            <div className="w-[25%]  md:px-5">
              <div className=" text-xs md:text-sm  mt-2 flex flex-col md:gap-2 font-[300] text-[#8a8a8a]">
                <span
                  onMouseEnter={() => setSubCat(true)}
                  className="hover:border-r-2 cursor-pointer text-black font-bold"
                >
                  Electronics
                </span>
                <span>Furniture</span>
                <span>Homes And Living</span>
                <span>Books And Staionary</span>
              </div>
            </div>
            <div className={`${subCat ? "block w-[25%]   md:px-5" : "hidden"}`}>
              <div className=" text-xs md:text-sm  mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
                <span
                  onMouseEnter={() => setSubSubCat(true)}
                  className="hover:border-r-2 cursor-pointer"
                >
                  Mobile Devices
                </span>
                <span>Cameras</span>
                <span>HeadPhones</span>
                <span>Chargers</span>
              </div>
            </div>
            <div
              className={`${subSubCat ? "block w-[25%]   md:px-5" : "hidden"}`}
            >
              <div className=" text-xs md:text-sm  mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
                <span
                  onMouseEnter={() => setSubSubSubCat(true)}
                  className="hover:border-r-2 cursor-pointer"
                >
                  Smart Phones
                </span>
                <span>Tablets</span>
                <span>Ipads</span>
              </div>
            </div>
            <div
              className={`${
                subSubSubCat ? "block w-[24.5%]   md:px-5" : "hidden"
              }`}
            >
              <div className=" text-xs md:text-sm  mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
                <span className="hover:border-r-2 cursor-pointer">
                  Iphone Mobiles
                </span>
                <span>Samsung Phones</span>
                <span>Vivo Phones</span>
                <span>Infinix Phones</span>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}
