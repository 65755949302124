import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { getAffilate, getCategoryList, getTicket, searchAffilate, ticketSearch } from "../../../store/vendorslice";

export default function AffilateProductsManage() {
  const dispatch = useDispatch();
  const [activeRole, setActiveRole] = useState("");
  const { loading, data } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getAffilate({status:activeRole}));
  }, [activeRole]);

  // Pagination control
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.pagination?.total) {
      let totalLength = data.pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(getTicket({status:activeRole,page:page}));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* name */}
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "Affilates",
                path: "",
              },
            ]}
          />
        </div>

        {/* <div className="flex flex-col w-full border border-[#EBF0ED] p-4 gap-5 rounded-lg">
          <div className="flex flex-row gap-1 w-full">
              <label
                  className="text-[#6B6B6B]  font-semibold w-[20%] text-xs uppercase"
                  htmlFor="product name "
                >
                Group Name:
              </label>
              <label
                className="text-[#6B6B6B]  font-semibold w-[80%] text-xs uppercase"
                htmlFor="product name "
              >
                Skinuu
              </label>
          </div>
          <hr/>
          <div className="flex flex-row gap-1 w-full">
              <label
                  className="text-[#6B6B6B]  font-semibold w-[20%] text-xs uppercase"
                  htmlFor="product name "
                >
                Group Name:
              </label>
              <label
                className="text-[#6B6B6B]  font-semibold w-[80%] text-xs uppercase"
                htmlFor="product name "
              >
                Skinuu
              </label>
          </div>
        </div> */}


        {/* search */}
        <div className="w-full flex gap-3 items-center">
          <div className="relative w-full flex items-center">
            <img
              className="absolute left-3"
              src="/assets/icons/manage-products/search.svg"
              alt=""
            />

            <input
              className="w-full p-2 md:p-3 px-8 md:px-8 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-sm"
              type="search"
              placeholder="Search product ..."
              onChange={(e) => searchAffilate(e.target.value)}
            />
          </div>
        </div>

        {/* filter */}
        <div className="flex md:py-3 justify-between">
          <div className="flex gap-6">
            <NavLink
              onClick={() => setActiveRole("")}
              className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
                activeRole === ""
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              ALL AFFILATES
              <span
                className={`text-xs md:text-sm font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === ""
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data?.counts?.total}
              </span>
            </NavLink>
            <NavLink
              onClick={() => setActiveRole("accepted")}
              className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
                activeRole === "accepted"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              APPROVED
              <span
                className={`text-xs md:text-sm font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "accepted"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data && data?.counts?.accepted}
              </span>
            </NavLink>
            <NavLink
              onClick={() => setActiveRole("rejected")}
              className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
                activeRole === "rejected"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              REJECTED
              <span
                className={`text-xs md:text-sm font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "rejected"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data && data?.counts?.rejected}
              </span>
            </NavLink>
          </div>

          <Link
            to="/vendor/my-shop/affilate-products/add-affilate"
            className="bg-[#C19A6B] h-fit px-5 py-[5px] md:px-9 md:py-[10px] text-xs xl:text-sm rounded-lg text-white"
          >
            Add Affilate
          </Link>

        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          {/* Table Header */}
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-scroll">
            <div className="min-w-[768px] md:min-w-[1024px] ">
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm font-semibold border-b text-[#6B6B6B]   border-gray-300 justify-between">
                <span className="w-[15%] text-left">PRODUCT</span>
                <span className="w-[25%] text-left">USERS</span>
                <span className="w-[15%] text-left">STATUS</span>

                <span className="w-[25%] text-left">COMMISION</span>
                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              <div className="flex flex-col">
                {data?.affiliates?.length > 0
                  ? data.affiliates.map((item, index) => (
                      <div
                        key={index}
                        className=" flex justify-between items-center text-[10px] md:text-sm border-b border-[#EBF0ED] text-[#18120F] font-semibold  p-2 md:p-4 rounded-b-md"
                      >
                        <span className="w-[15%] flex flex-row items-center gap-2 text-left">
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src={item?.product?.image}
                            alt=""
                          />
                          {item.product.name}
                        </span>
                        <span className="w-[25%] flex flex-row items-center gap-1 text-left">
                          <img
                            className="w-6 h-6 md:w-8 md:h-8 rounded-full"
                            src={item?.user?.image}
                            alt=""
                          />
                          {item.user.name}
                        </span>
                        <div className="w-[15%]  text-left">
                          <span
                            className={`${
                              item.status === "pending"
                                ? "bg-[#FFFBF0]  px-2 py-[0.189rem] border border-[#FEC600] text-[#FEC600] rounded-xl"
                                : "bg-[#F6FFF7]  px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                            }`}
                          >
                            {item.status == "pending" ? "Pending" : "Accepted"}
                          </span>
                        </div>

                        <span className="w-[25%]  text-left pl-5">
                          {item.commission} %
                        </span>
                        <div className="w-[10%] text-left">
                          <Link
                            // to={`/vendor/my-shop/tickets/ticket-details/${item.id}`}
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/view.svg"
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    ))
                  : "No Affiliate Found"}
              </div>
            </div>
          </div>
        </div>

        {/* Pagination */}
        {!loading &&
        data?.tickets &&
        data?.tickets?.length > 0 ? (
          <div className="flex flex-col md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING {data.pagination.from + "-" + data.pagination.to} OF{" "}
              {data.pagination.total}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
