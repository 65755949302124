import React, { useEffect, useState } from "react";
import ProdCard from "./ProdCard";
import "./drower.css";
import HeatMapComponent from "./HeatMapComponent";

export default function InSight({ show }) {
  const [recomendedProducts, setRecomendedProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/products.json");
        const data = await response.json();
        setRecomendedProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className={`${show ? "flex justify-end w-full" : "flex w-full"}`}>
        {/* <img className="w-full  " src="/assets/images/insight/map.svg" alt="" /> */}
        <HeatMapComponent show={show} />

        {/* drower */}
        {show && (
          <div className="w-[70%] md:w-[60%] xl:w-[710px] z-20 py-2 absolute top-[187px] flex flex-col gap-3 bg-white left-0 p-4 md:p-8 drower-slide-right ">
            <div className="flex flex-col gap-2">
              <h2 className="text-lg md:text-2xl font-semibold py-3">
                Trending Categories In Area
              </h2>
              <div className="grid grid-cols-3 lg:grid-cols-4 gap-2 md:gap-5">
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>{" "}
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>
                <span className="px-1 py-1 md:px-6 md:py-2 text-center text-[8px] md:text-xs xl:text-sm font-semibold rounded-2xl shadow-md text-nowrap">
                  Category Name
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="text-lg md:text-2xl font-semibold py-3">
                Trending Products In Area
              </h2>
              <div className="grid  grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4  ">
                {recomendedProducts.slice(0, 6).map((item) => (
                  <ProdCard key={item._id} item={item} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
