import React, { useEffect, useState } from "react";
import { FaHeart, FaCheck } from "react-icons/fa";
import { Link, useFetcher } from "react-router-dom";
import "./modal.css";
import { imagePath } from "../../../../functions/URLString";
import { toast } from "react-toastify";

export default function ProductBoosterModal({
  data,
  cards,
  setCards,
  selectedCardIds,
  setSelectedCardIds,
  premium,
}) {
  const [reels, setReels] = useState([]); // All available reels
  // IDs of the selected reels in the modal
  // const [isHeartFilled, setIsHeartFilled] = useState(false);
  const [products, setProducts] = useState([]);

  const handleCheckboxChange = (itemId) => {
    // console.log(selectedCardIds, "selectedCardIds")
    // console.log("card",cards)
    if (!premium) {
      setSelectedCardIds((prevSelected) =>
        prevSelected.includes(itemId.id)
          ? prevSelected.filter((id) => id !== itemId.id)
          : [...prevSelected, itemId.id]
      );

      setCards((prevSelected) => {
        // Check if the prevSelected array contains an object with the same id as itemId.id
        const exists = prevSelected.some((item) => item.id === itemId.id);

        // If it exists, filter it out; otherwise, add the new item
        if (exists) {
          return prevSelected.filter((item) => item.id !== itemId.id);
        } else {
          return [...prevSelected, itemId];
        }
      });
    } else {
      if (selectedCardIds.length === 1 || selectedCardIds.length > 1) {
        toast.error("Only One Product Can Be Selected");
        setSelectedCardIds((prevSelected) =>
          prevSelected.includes(itemId.id)
            ? prevSelected.filter((id) => id !== itemId.id)
            : prevSelected
        );

        setCards((prevSelected) => {
          // Check if the prevSelected array contains an object with the same id as itemId.id
          const exists = prevSelected.some((item) => item.id === itemId.id);

          // If it exists, filter it out; otherwise, add the new item
          if (exists) {
            return prevSelected.filter((item) => item.id !== itemId.id);
          } else {
            return [...prevSelected];
          }
        });
      } else {
        setSelectedCardIds((prevSelected) =>
          prevSelected.includes(itemId.id)
            ? prevSelected.filter((id) => id !== itemId.id)
            : [...prevSelected, itemId.id]
        );

        setCards((prevSelected) => {
          // Check if the prevSelected array contains an object with the same id as itemId.id
          const exists = prevSelected.some((item) => item.id === itemId.id);

          // If it exists, filter it out; otherwise, add the new item
          if (exists) {
            return prevSelected.filter((item) => item.id !== itemId.id);
          } else {
            return [...prevSelected, itemId];
          }
        });
      }
    }
  };
  // console.log(cards, "cards");
  // Toggle heart fill state
  // const handleHeartClick = () => {
  //   setIsHeartFilled(!isHeartFilled);
  // };

  useEffect(() => {}, [cards]);

  useEffect(() => {
    // Fetch data from the backend
    if (data?.products) setProducts(data.products);
  }, [data]);

  // Get selected cards data
  const getSelectedCardsData = () => {
    const selectedCardsData = reels.filter((item) =>
      selectedCardIds.includes(item.id)
    );
    return selectedCardsData;
  };

  // Handle selecting reels and closing modal
  const handleSelectAndClose = () => {
    // Do something with the selected cards data, like storing in form data
    setCards(cards);
    // Close the modal
    document.getElementById("my_modal_8").close();
  };

  return (
    <div>
      {/* Modal code for selecting reels */}
      <dialog id="my_modal_8" className="modal bg-none">
        <div className="modal-box bg-white p-4 md:p-8 max-w-[85rem] max-h-[50rem] rounded-lg relative">
          <form method="dialog">
            {/* header div */}
            <div className="sticky top-0 z-50 bg-white w-full py-2 px-2 md:px-8 flex justify-between">
              <h2 className="font-bold text-[22px]">Select Products</h2>
              <button
                className=" w-4 h-4 md:w-8 md:h-8 bg-white rounded-full hover:shadow-xl hover:bg-slate-50 shadow-lg"
                onClick={() => document.getElementById("my_modal_8").close()}
              >
                <img
                  src="/assets/icons/cancel1.svg"
                  className="mx-auto"
                  alt="Close"
                />
              </button>
            </div>
            <div className="h-[360px] overflow-y-auto overflow-x-hidden mx-auto mt-3 cardContainer">
              <div className="grid grid-cols-2 md:grid-cols-5 gap-6 w-[95%] mx-auto">
                {products.length > 0
                  ? products.map((item) => {
                      return (
                        <div
                          className={`card border-none card-shadow relative  cursor-pointer w-[150px] md:w-[195px] 
                    `}
                        >
                          {/* heart and discount start here */}
                          <div className="flex z-50 top-1 right-1 absolute items-center">
                            <input
                              type="checkbox"
                              checked={selectedCardIds.includes(item.id)}
                              onChange={() => handleCheckboxChange(item)}
                              className="hidden"
                            />
                            <div
                              className={`w-4 h-4 md:w-8 md:h-8 flex items-center justify-center rounded-full border-2 border-black cursor-pointer transition-colors duration-200 ${
                                selectedCardIds.includes(item.id)
                                  ? "bg-black text-white"
                                  : "bg-transparent"
                              }`}
                              onClick={() => handleCheckboxChange(item)}
                            >
                              {selectedCardIds.includes(item.id) && <FaCheck />}
                            </div>
                          </div>

                          <div className={` gap-1 absolute left-2 mt-1`}>
                            <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 px-2 rounded-xl mt-2 text-sm font-bold">
                              {item.discountprice}
                            </p>
                          </div>
                          {/* heart and discount end here */}

                          {/* image start here  */}
                          <Link>
                            <img
                              src={item.image}
                              alt="Shoes"
                              className="max-h-[282px] w-full"
                            />
                          </Link>
                          {/* Image end here */}

                          <div className="px-3 py-1">
                            <Link>
                              <h2 className="card-title text-sm mb-0">
                                {item.name.slice(0, 51)}...
                              </h2>
                            </Link>
                            <div className="h-4">
                              <div
                                className={` "flex gap-2 items-center slide-from-top"
                                `}
                              >
                                <div className="rating rating-xs space-x-1">
                                  <input
                                    type="radio"
                                    name="rating-6"
                                    className="mask mask-star-2 bg-orange-400"
                                  />
                                  <input
                                    type="radio"
                                    name="rating-6"
                                    className="mask mask-star-2 bg-orange-400"
                                  />
                                  <input
                                    type="radio"
                                    name="rating-6"
                                    className="mask mask-star-2 bg-orange-400"
                                  />
                                  <input
                                    type="radio"
                                    name="rating-6"
                                    className="mask mask-star-2 bg-orange-400"
                                  />
                                  <input
                                    type="radio"
                                    name="rating-6"
                                    className="mask mask-star-2 bg-orange-400"
                                  />
                                </div>
                                <div className="text-[#8C8C8C] text-xs">
                                  224
                                </div>
                              </div>
                            </div>

                            <div className="card-actions justify-between items-center ">
                              <div className="flex items-center gap-1">
                                <h5 className="font-semibold text-black text-md">
                                  <span className="">$</span>
                                  {item.price}
                                </h5>
                                <span className="text-sm line-through text-[#8C8C8C]">
                                  ${item.price - item.discountprice}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "loading"}
              </div>
            </div>
            {/* button */}
            <div className="w-full h-10">
              <Link
                // to="/vendor/product-booster/pre-made-ad"
                className="bg-[#C19A6B] text-white px-6 md:my-4 py-2 rounded-lg absolute bottom-2 right-8"
                onClick={handleSelectAndClose}
              >
                Select
              </Link>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
