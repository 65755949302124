import React from "react";
import BoosterLayout from "../../../Vender-Dashboard-Layout/BoosterLayout";
import AvailableOffer from "../../../components/Booster/Marketing-Solutions/Offers-Components/AvailableOffer";

export default function OffersPage() {
  return (
    <>
      <BoosterLayout>
        <AvailableOffer />
      </BoosterLayout>
    </>
  );
}
