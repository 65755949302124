import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";
import AccountStatement from "../../../../components/MyShop/Finance-Management/Account-Statement/AccountStatement";

export default function AccountStatementPage() {
  return (
    <>
      <MyShopLayout>
        <AccountStatement />
      </MyShopLayout>
    </>
  );
}
