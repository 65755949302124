import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import VendorInreels from "../../../components/MyShop/Vendor-Inreels-Components/VendorInreels";

export default function VendorInreelsPage() {
  return (
    <>
      <MyShopLayout>
        <VendorInreels />
      </MyShopLayout>
    </>
  );
}
