import { useEffect, useRef, useState } from "react";
import "./email-verification.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { vendorOtpVerification } from "../../store/vendorslice";

export default function EmailVerificationModal({email}) {
  const [inputs, setInputs] = useState(["", "", "", "", "", ""]);
  const [data, setData] = useState({otp:"",email:""});
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const { message, loading } = useSelector((state) => state.admin);

  const navigate = useNavigate();

  useEffect(()=>{
    if(message==="OTP has been verified"){
      toast.success(message)
    navigate("/profile-creation/address");
  }
  },[message,navigate])

  useEffect(()=>{
    const concatenatedString = inputs.join('');
    setData({otp: concatenatedString,email:email})
},[inputs,email])

  const handleChange = (index, event) => {
    const value = event.target.value;

    if (value.length > 1) return;

    const newInputs = [...inputs];
    newInputs[index] = value;

    setInputs(newInputs);

    if (value && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("user data...", inputs);
    if(inputs[0] === "" || inputs[1] === "" || inputs[2] === "" || inputs[3] === "" || inputs[4] === "" || inputs[5] === "" ){
      toast.error("enter complete 6 digits")
    } else {
      console.log(data)

      dispatch(vendorOtpVerification(data))
    }
  };

  return (
    <div className="flex justify-center mt-3">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-8 items-center"
      >
        <div className="flex items-center gap-4">
          {inputs.map((value, index) => (
            <span key={index} className="email">
              <input
                type="number"
                className="w-10 outline-none border-b-2 border-black pb-2 text-center text-black text-lg font-semibold "
                value={value}
                maxLength={1}
                onChange={(event) => handleChange(index, event)}
                ref={(input) => (inputRefs.current[index] = input)}
              />
            </span>
          ))}
        </div>

        {/* buttons  */}
        <div className="flex gap-4">
          <form method="dialog">
            <button disabled={loading} className="btn btn-sm btn-circle btn-ghost py-[10px] px-9 rounded-lg border border-[#C19A6B] text-[#C19A6B] text-sm font-semibold tracking-wider">
              Change Email
            </button>
          </form>
          {loading?(
          <button disabled={loading}
            className="py-[10px] px-[61px] animate-pulse rounded-lg bg-[#C19A6B]  text-white text-sm font-semibold tracking-wider"
          >
            Verifying
          </button>
          ):(
            <button
            type="submit" disabled={loading}
            className="py-[10px] px-[61px] rounded-lg bg-[#C19A6B]  text-white text-sm font-semibold tracking-wider"
          >
            Verify
          </button>
          )}
        </div>
      </form>
    </div>
  );
}
