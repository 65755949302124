import React, { useEffect, useState } from "react";
import { FaHeart, FaCheck } from "react-icons/fa";
import "./cardhover.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listVendorProduct } from "../../../store/vendorslice";
import { imagePath } from "../../../functions/URLString";

const ProductsCard = ({
  selectedBundleId,
  setSelectedBundleId,
  isHeartFilled,
  setIsHeartFilled,
  updatedCardReels,
  setUpdatedCardReels,
}) => {
  const [products, setProducts] = useState([]); // All available products
  const [clickedCardIndex, setClickedCardIndex] = useState(null); // Index of the clicked empty card

  const handleCheckboxChange = (itemId) => {
    console.log(itemId, "-----itemid");
    setSelectedBundleId(itemId);
  };
  
  const { data, message, error } = useSelector((state) => state.admin);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const [recomendedProducts, setRecomendedProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch data from the backend
    dispatch(listVendorProduct());
    console.log("hello from modal");
  }, []);

  useEffect(() => {
    if (data?.products) setRecomendedProducts(data.products);
  }, [data]);

  // Remove a reel from the card
  const handleRemoveReel = (index) => {
    const newUpdatedCardReels = [...updatedCardReels]; // Create a copy of the current state
    newUpdatedCardReels[index] = null; // Reset the specific index to null
    setUpdatedCardReels(newUpdatedCardReels);
  };

  // Opening the modal and tracking which card was clicked
  const handleCardClickReels = (index) => {
    setClickedCardIndex(index); // Store the index of the clicked card
    document.getElementById("my_modal_4").showModal();
  };

  // Update the specific card when the modal's "Select" button is clicked
  const handleModalReels = () => {
    const selectedReel = recomendedProducts.find(
      (item) => item.id === selectedBundleId
    ); // Find the selected reel
    if (selectedReel && clickedCardIndex !== null) {
      const newUpdatedCardReels = [...updatedCardReels]; // Create a copy of the current state
      newUpdatedCardReels[clickedCardIndex] = selectedReel; // Update the specific index with the selected reel
      setUpdatedCardReels(newUpdatedCardReels);
    } else {
      console.error("No item found with the specified ID");
    }
  };

  console.log(selectedBundleId);

  const placeholdersCount = 4 - updatedCardReels.filter(Boolean).length;

  return (
    <div className="">
      <h1 className="text-xs font-semibold mt-4 text-[#6B6B6B] md:mt-12">
        Products
      </h1>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-0 mt-3">
        {updatedCardReels.map((reel, index) => (
          <>
            {reel ? (
              <div
                key={index}
                className="flex card4 relative w-[150px] md:w-[180px] h-[200px] md:h-[273px] flex-col rounded-lg bg-white shadow-md"
              >
                <div
                  onClick={() =>
                    document.getElementById("my_modal_4").showModal()
                  }
                  className="absolute text1 flex flex-col items-center gap-1 top-[40%] cursor-pointer left-6 z-50"
                >
                  <img
                    className="bg-white rounded-full w-8 h-8 md:w-10 md:h-10 mx-auto p-1 md:p-3"
                    src="/assets/icons/edit.svg"
                    alt=""
                  />
                  <h1 className="text-white text-xs md:text-lg">Edit</h1>
                </div>
                {/* remove button */}
                <div
                  onClick={() => handleRemoveReel(index)}
                  className="absolute cursor-pointer flex flex-col items-center gap-1 text2 top-[40%] right-6 z-50"
                >
                  <img
                    className="bg-white rounded-full w-8 h-8 md:w-10 md:h-10 mx-auto p-1 md:p-3"
                    src="/assets/icons/minus.svg"
                    alt=""
                  />
                  <h1 className="text-white text-xs md:text-lg">Remove</h1>
                </div>
                {/* card */}
                <div
                  className={`card border-none card-shadow relative cursor-pointer`}
                >
                  <div className={`gap-1 absolute left-2 mt-1`}>
                    <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 px-2 rounded-xl mt-2 text-sm font-bold">
                      {reel.discount}
                    </p>
                  </div>

                  {/* image start here  */}
                  <Link>
                    <img
                      src={reel.image}
                      alt="Shoes"
                      className="max-h-[282px] w-full"
                    />
                  </Link>
                  {/* Image end here */}

                  <div className="px-3 py-1">
                    <Link>
                      <h2 className="card-title text-sm mb-0">
                        {reel.name.slice(0, 51)}...
                      </h2>
                    </Link>
                    <div className="h-4">
                      <div className="flex gap-2 items-center slide-from-top">
                        <div className="rating rating-xs space-x-1">
                          <input
                            type="radio"
                            name="rating-6"
                            className="mask mask-star-2 bg-orange-400"
                          />
                          <input
                            type="radio"
                            name="rating-6"
                            className="mask mask-star-2 bg-orange-400"
                          />
                          <input
                            type="radio"
                            name="rating-6"
                            className="mask mask-star-2 bg-orange-400"
                          />
                          <input
                            type="radio"
                            name="rating-6"
                            className="mask mask-star-2 bg-orange-400"
                          />
                          <input
                            type="radio"
                            name="rating-6"
                            className="mask mask-star-2 bg-orange-400"
                          />
                        </div>
                        <div className="text-[#8C8C8C] text-xs">224</div>
                      </div>
                    </div>

                    <div className="card-actions justify-between items-center">
                      <div className="flex items-center gap-1">
                        <h5 className="font-semibold text-black text-md">
                          <span className="">$</span>
                          {reel.price}
                        </h5>
                        <span className="text-sm line-through text-[#8C8C8C]">
                          {parseInt(reel.price) - reel.discountprice}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                onClick={() => handleCardClickReels(index)} // Pass the clicked index to the handler
                className="flex cursor-pointer w-[150px] md:w-[180px] h-[200px] md:h-[273px] justify-center items-center border-spacing-2 bg-[#F6F6F6] border-dashed border-2 border-[#D9D9D9] rounded-lg"
              >
                <div className="bg-[#D9D9D9] flex justify-center items-center rounded-full w-5 h-5 md:h-16 md:w-16">
                  <img
                    src="/assets/icons/addicon.svg"
                    className="w-3 h-3 md:h-5 md:w-5 cursor-pointer"
                    alt="Add Reel"
                  />
                </div>
              </div>
            )}
          </>
        ))}

        {placeholdersCount > 0 &&
          Array.from({ length: placeholdersCount }).map((_, index) => (
            <div
              key={`placeholder-${index}`}
              onClick={() => handleCardClickReels(index + updatedCardReels.length)} // Pass the clicked index to the handler
              className="flex cursor-pointer w-[150px] md:w-[180px] h-[200px] md:h-[273px] justify-center items-center border-spacing-2 bg-[#F6F6F6] border-dashed border-2 border-[#D9D9D9] rounded-lg"
            >
              <div className="bg-[#D9D9D9] flex justify-center items-center rounded-full w-5 h-5 md:h-16 md:w-16">
                <img
                  src="/assets/icons/addicon.svg"
                  className="w-3 h-3 md:h-5 md:w-5 cursor-pointer"
                  alt="Add Reel"
                />
              </div>
            </div>
          ))}
      </div>

      <dialog id="my_modal_4" className="modal ">
        <form method="dialog" className="modal-box w-full md:w-[55rem] p-2 bg-white">
          <div className="modal-action m-0 flex justify-end">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </div>
          <div className="overflow-x-hidden mx-2 pt-2">
            <div className="rounded-[10px] shadow-4xl">
              <h1 className="text-xl text-[#1B1B1B] font-semibold my-5">
                Products
              </h1>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {recomendedProducts.map((item, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <input
                      type="checkbox"
                      id={`checkbox-${item.id}`}
                      checked={selectedBundleId === item.id}
                      onChange={() => handleCheckboxChange(item.id)}
                      className="hidden"
                    />
                    <label
                      htmlFor={`checkbox-${item.id}`}
                      className="relative cursor-pointer"
                    >
                      <img
                        src={item.image}
                        className="rounded-xl w-full h-40 object-cover mb-2"
                        alt={item.name}
                      />
                      <div className="absolute top-2 right-2">
                        {selectedBundleId === item.id && (
                          <FaCheck className="text-white bg-green-500 rounded-full p-1" />
                        )}
                      </div>
                      <h2 className="text-sm font-semibold">{item.name}</h2>
                      <p className="text-sm text-gray-500">
                        ${item.price} <span className="line-through">${parseInt(item.price) - item.discountprice}</span>
                      </p>
                    </label>
                  </div>
                ))}
              </div>
              <div className="modal-action justify-center">
                <button
                  className="btn btn-primary"
                  onClick={handleModalReels}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </form>
      </dialog>
    </div>
  );
};

export default ProductsCard;
