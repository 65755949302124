import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../Common/BreadCrums";

export default function NewOrders() {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [activeRole, setActiveRole] = useState("All");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/order-management.json");
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setActiveRole("All")
    fetchData();
  }, []);

  // filtrt
  const filteredProducts =
    activeRole === "All"
      ? orders
      : orders.filter((user) => user.orderStatus === activeRole);

  // Pagination control
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, orders.length);
  const paginatedProducts = orders.slice(startIndex, endIndex);

  const shouldShowPagination = orders.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [paginatedProducts];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }

  return (
    <>
      <div className="md:px-3 w-full flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Orders",
              path: "/vendor/vendor-dashboard/new-orders",
            },
          ]}
        />

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto ">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[800px] md:min-w-[768px] lg:min-w-full">
              <div className="flex  items-center justify-between gap-2 p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
                <span className="w-[10%] text-left">ORDER ID</span>

                <span className="w-[10%] text-left">CUSTOMER</span>
                <span className="w-[10%] text-left">AMOUNT</span>
                <span className="w-[15%] text-left text-nowrap">
                  PAYMENT METHOD
                </span>
                <span className="w-[15%] text-left">ORDER DATE</span>
                <span className="w-[15%] text-left text-nowrap">
                  PAYMENT STATUS
                </span>
                <span className="w-[15%] text-left">ORDER STATUS</span>
                <span className="w-[6%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {filteredProducts.slice(0, 10).map((order) => (
                <div
                  key={order.id}
                  className="flex w-full justify-between items-center  p-4 border-t  font-semibold text-sm "
                >
                  <span className="w-[10%] text-left ">{order.orderId}</span>

                  <span className="w-[10%] text-left ">{order.customer}</span>
                  <span className="w-[10%] text-left ">${order.amount} </span>
                  <span className="w-[15%]  text-left ">
                    {order.paymentMethod}
                  </span>
                  <span className="w-[15%] text-left ">{order.orderDate}</span>
                  <span className="w-[15%] text-left ">
                    <span
                      // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                      className={`${
                        order.paymentStatus === "Completed"
                          ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                          : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                      }`}
                    >
                      {order.paymentStatus}
                    </span>
                  </span>

                  <span className="w-[15%] text-left ">
                    <span
                      // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                      className={`${
                        order.orderStatus === "Completed"
                          ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                          : order.orderStatus === "New"
                          ? "bg-[#F1F7FF] text-sm py-1 px-3 border border-[#44A6E9] text-[#44A6E9] rounded-lg"
                          : order.orderStatus === "In Progress"
                          ? "bg-[#FFFBF0] text-sm py-1 px-3 border border-[#FEC600] text-[#FEC600] rounded-lg"
                          : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                      }`}
                    >
                      {order.orderStatus}
                    </span>
                  </span>

                  <Link className="w-[6%] text-left ">
                    <img src="/assets/icons/view.svg" alt="" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        {shouldShowPagination && (
          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} - {endIndex} OF {orders.length} RESULTS
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {paginationIndexes.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                        : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
