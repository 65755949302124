import React from "react";
import VendorInreelsUpdate from "../../../components/MyShop/Vendor-Inreels-Components/VendorInreelsUpdate";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
export default function VendorInreelsUpdatePage() {
  return (
    <>
      <MyShopLayout>
        <VendorInreelsUpdate />
      </MyShopLayout>
    </>
  );
}
