import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getCities, getCountries, getStates } from "../../store/vendorslice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Address() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const { countries, states, cities, loading } = useSelector(
    (state) => state.admin
  );

  const [data, setData] = useState({
    country: "",
    state: "",
    city: "",
    postalCode: "",
    fullAddress: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
    if(selectedCountry!=""){
      dispatch(getStates(selectedCountry));
    }
    if(selectedState!=""){
      dispatch(getCities(selectedState));
    }
  }, [data,dispatch,selectedState,selectedCountry]);

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountrySelection = (e) => {
    setSelectedCountry(e.target.value);
    setData({
      ...data,
      country: e.target.value,
    });
  };

  const handleStateSelection = (e) => {
    setSelectedState(e.target.value);
    setData({
      ...data,
      state: e.target.value,
    });
  };

  const handleCitySelection = (e) => {
    setSelectedCity(e.target.value);
    setData({
      ...data,
      city: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (data.country == "",
      data.state == "",
      data.city == "",
      data.postalCode == "",
      data.fullAddress == "")
    ) {
      toast.error("Input all fields");
    } else {
      console.log("user data...", data);
      navigate("/vendor/profile-creation/bankverification", {
        state: {
          country: data.country,
          state: data.state,
          city: data.city,
          postalCode: data.postalCode,
          fullAddress: data.fullAddress,
        },
      });
    }
  };

  return (
    <div className="flex justify-center w-full mt-14">
      <div className="flex flex-col gap-4 md:gap-16">
        <div className=" flex justify-center">
          <img
            className="w-[300px] md:w-[372px]"
            src="/assets/icons/progress-2.svg"
            alt=""
          />
        </div>
        <div className=" p-4 flex flex-col-reverse md:flex-row gap-16">
          <div className="flex flex-col gap-4">
            <span className="font-semibold text-base">
              Please complete the form below to start your own online business
              store!
            </span>

            <span className="font-bold text-lg md:text-2xl flex flex-col md:mt-3">
              <span>Address Information</span>
            </span>

            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 w-full"
            >
              {/* country  */}
              <div className="flex flex-col gap-1 relative ">
                <div className="flex flex-col gap-1 ">
                  <label htmlFor="" className="font-semibold text-xs">
                    Country
                  </label>
                  <select 
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs" 
                    id="options"  value={selectedCountry} onChange={handleCountrySelection}>
                    <option className="text-gray-400" value="" disabled>Country</option>
                    {countries?.countries?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                    <option value="">None</option>
                  </select>
                </div>
              </div>

              {/* state*/}
              <div className="flex flex-col gap-1 relative">
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    State
                  </label>
                  <select 
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs" 
                    id="options"  value={selectedState} onChange={handleStateSelection}>
                    <option className="text-gray-400" value="" disabled>State</option>
                    {states?.states?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                    <option value="">None</option>
                  </select>
                </div>
              </div>

              {/* city */}
              <div className="flex flex-col gap-1 relative">
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    City
                  </label>
                  <select 
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs" 
                    id="options"  value={selectedCity} onChange={handleCitySelection}>
                    <option className="text-gray-400" value="" disabled>City</option>
                    {cities?.cities?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                    <option value="">None</option>
                  </select>
                </div>
              </div>

              <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  POSTAL CODE
                </label>
                <div className="relative w-full">
                  <input
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs"
                    type="number"
                    placeholder="Enter Your Postal Code"
                    onChange={updateData}
                    name="postalCode"
                    value={data.postalCode}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Full Address
                </label>
                <textarea
                  name="fullAddress"
                  id=""
                  cols="30"
                  rows="10"
                  className="bg-[#F9FAFB] w-full h-[80px] md:h-[181px] px-4 py-3 rounded-[8px] outline-[#828282] font-normal text-sm resize-none"
                  placeholder="Enter Your Full Address..."
                  onChange={updateData}
                  value={data.fullAddress}
                ></textarea>
              </div>

              {/* submit button  */}
              <button
                to="/vendor/profile-creation/bankverification"
                type="submit"
                disabled={loading}
                className="bg-[#C19A6B] py-3 md:py-[14px] px-5 md:px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
              >
                Next
              </button>
            </form>
          </div>

          <div className="hidden lg:block">
            <img src="/assets/icons/address.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
