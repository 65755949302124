import { NavLink, useLocation } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { VscMegaphone } from "react-icons/vsc";

import "./side-bar.css";

export default function BoosterSidebar() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    // Automatically open the dropdown of the active nav link
    if (
      location.pathname === "/vendor/my-shop/home" ||
      location.pathname === "/vendor/my-shop/affiliate-dashboard"
    ) {
      setOpenDropdown("dashboard");
    } else if (
      location.pathname.startsWith("/vendor/booster/offers") ||
      location.pathname.startsWith("/vendor/booster/product-booster") ||
      location.pathname.startsWith("/vendor/booster/premium-product-booster") ||
      location.pathname.startsWith("/vendor/booster/store-boost") ||
      location.pathname.startsWith("/vendor/booster/booster-performance")
    ) {
      setOpenDropdown("marketing");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <>
      <div className="w-full xl:w-64 h-[100vh] xl:h-[95vh]  bg-[#C19A6B] rounded-[0.7rem] hide-scrollbar overflow-y-auto">
        {/* shop name */}
        <div className=" px-4 py-4">
          <div className="flex gap-2 items-center mb-4">
            <div>
              <img
                className="h-14 w-14 lg:h-16 lg:w-16"
                src="/assets/images/shop-profile.svg"
                alt=""
              />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="text-white  font-bold text-sm lg:text-base  tracking-wide">
                  Shop Name
                </div>
              </div>
            </div>
          </div>
          {/* hr */}
          <hr
            className="h-[0.1rem]"
            style={{
              borderRadius: "50%",
            }}
          />
        </div>

        <div className="flex flex-col gap-1 lg:gap-2 px-4  overflow-hidden">
          {/* dashboard  */}
          <div className="flex relative  flex-col ">
            <div
              onClick={() => toggleDropdown("dashboard")}
              className={`${
                location.pathname === "/vendor/my-shop/home" ||
                location.pathname === "/vendor/my-shop/affiliate-dashboard"
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <LuLayoutDashboard className="w-4 h-4" />
              Dashboard
              {openDropdown === "dashboard" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* Dashboard dropdown  */}
            {openDropdown === "dashboard" && (
              <>
                <NavLink
                  to="/vendor/my-shop/home"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Vendor Dashboard
                </NavLink>
                <NavLink
                  to="/vendor/my-shop/affiliate-dashboard"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Vendor Affiliate Dashboard
                </NavLink>
              </>
            )}
          </div>

          {/* Marketing Solutions section  */}
          <div className="flex relative flex-col ">
            <div
              onClick={() => toggleDropdown("marketing")}
              className={`${
                location.pathname.startsWith("/vendor/booster/offers") ||
                location.pathname.startsWith(
                  "/vendor/booster/product-booster"
                ) ||
                location.pathname.startsWith(
                  "/vendor/booster/premium-product-booster"
                ) ||
                location.pathname.startsWith("/vendor/booster/store-boost") ||
                location.pathname.startsWith(
                  "/vendor/booster/booster-performance"
                )
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <VscMegaphone className="w-4 h-4" />
              Marketing Solutions
              {openDropdown === "marketing" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </div>
            {/* marketing dropdown  */}
            {openDropdown === "marketing" && (
              <>
                <NavLink
                  to="/vendor/booster/offers"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Offers
                </NavLink>
                <NavLink
                  to="/vendor/booster/product-booster"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Product Boosters
                </NavLink>
                <NavLink
                  to="/vendor/booster/premium-product-booster"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Premium Product
                </NavLink>
                <NavLink
                  to="/vendor/booster/store-boost"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Store Booster
                </NavLink>

                <NavLink
                  to="/vendor/booster/booster-performance"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Booster Performance
                </NavLink>
              </>
            )}
          </div>

          {/* logout  */}
          <NavLink
            to="/vendor/booster/dummy" //dummy url
            className="navLink"
          >
            <IoIosLogOut className="w-4 h-4" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
}
