import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

export default function CustomerReturnsClosed() {
  const [orders, setOrders] = useState([]);
  const [activeRole, setActiveRole] = useState("All");
  const [cancelledCount, setCancelledCount] = useState(0);
  const [returnedCount, setReturnedCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter orders based on active role
  const filteredProducts =
    activeRole === "All"
      ? orders
      : orders.filter((item) => item.status1 === activeRole);

  useEffect(() => {
    // Update counts when orders change
    const All = orders.filter((item) => orders).length;
    const Returned = orders.filter(
      (item) => item.status1 === "Returned"
    ).length;
    const Cancelled = orders.filter(
      (item) => item.status1 === "Cancelled"
    ).length;

    setReturnedCount(Returned);
    setCancelledCount(Cancelled);
  }, [orders]);

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        const response = await fetch("/viewoffer.json");
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Pagination control

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredProducts.length);
  const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

  const shouldShowPagination =
    filteredProducts.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  return (
    <>
      <div className=" w-full flex flex-col gap-4">
        {/* name */}

        <div className="flex flex-col gap-2 bg-[#C19A6B] p-2 md:p-6 rounded-xl w-full md:w-fit">
          <p className="font-semibold text-xs uppercase text-white tracking-widest">
            Summary
          </p>

          <div className="flex justify-between   py-4 px-3 md:px-6 bg-white rounded-xl gap-2 w-full">
            <span className="flex flex-col items-center border-[#EBF0ED] px-2">
              <p className="font-extrabold text-xl md:text-3xl">2k</p>
              <p className="font-semibold text-[9px] text-nowrap text-[#6B6B6B] ">
                Orders Cancelled
              </p>
            </span>
            <span className=" border-r-2"></span>
            <span className="flex flex-col items-center   border-[#EBF0ED] px-2">
              <p className="font-extrabold text-xl md:text-3xl">28</p>
              <p className="font-semibold text-[9px] text-nowrap text-[#6B6B6B]  ">
                Products Returned
              </p>
            </span>
            <span className=" border-r-2"></span>

            <span className="flex flex-col items-center px-2  ">
              <p className="font-extrabold text-xl md:text-3xl">87</p>
              <p className="font-semibold text-[9px] text-nowrap text-[#6B6B6B]  ">
                Amount Returned
              </p>
            </span>
          </div>
        </div>
        {/* search */}
        <div className="flex items-center justify-between gap-4 mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Order …"
            />
          </div>
        </div>

        {/* filter */}
        <div className="flex gap-1 md:gap-6 text-[10px] md:text-sm md:py-3">
          <NavLink
            onClick={() => setActiveRole("All")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ALL ORDERS
            <span
              className={` font-semibold md:px-2 px-1 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              ({orders.length})
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("Returned")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Returned"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            RETURNED
            <span
              className={` font-semibold md:px-2 px-1 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Returned"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              ({returnedCount})
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("Cancelled")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Cancelled"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            CANCELLED
            <span
              className={` font-semibold md:px-2 px-1 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Cancelled"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              ({cancelledCount})
            </span>
          </NavLink>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[25%] text-left">PRODUCT </span>

                <span className="w-[10%] text-left">ORDER ID</span>
                <span className="w-[10%] text-left">PRICE</span>
                <span className="w-[15%] text-left">STATUS</span>

                <span className="w-[15%] text-left">REASON</span>
                <span className="w-[25%] text-left">COMMENT</span>
              </div>

              {/* Table Body */}
              {paginatedProducts.slice(0, 10).map((item, index) => (
                <div
                  key={index}
                  className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b "
                >
                  <div className="w-[25%] text-left  gap-3 font-[600] flex items-center">
                    <img
                      src={item.img}
                      className="h-8 w-8  md:w-10 md:h-10 text-center rounded-lg "
                      alt=""
                    />
                    <div className="flex flex-col">
                      <span>{item.name}</span>
                      <span className="text-[#6B6B6B]  font-[500]">
                        Category: {item.category}
                      </span>
                    </div>
                  </div>
                  <span className="w-[10%] text-left font-[600] ">
                    #{item.orderid}
                  </span>
                  <span className="w-[10%] text-left font-[600] ">
                    <span>${item.price}</span>
                  </span>
                  <div className="w-[15%]  text-left font-[600]  flex flex-col">
                    <span>{item.status1}</span>
                    <span>{item.starttime}</span>
                  </div>

                  <span className="w-[15%]  text-left font-[600] ">
                    {item.reason}
                  </span>
                  <span className="w-[25%]  text-left   font-[600] text-[#18120F]">
                    {item.coomment}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        {shouldShowPagination && (
          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} -{" "}
              {Math.min(startIndex + itemsPerPage, orders.length)} OF{" "}
              {orders.length} RESULTS
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className="py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="6"
                  height="12"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .slice(0, 3)
                  .map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`py-[6px] px-[12px] md:py-[10px] md:px-[14.5px] text-lg   rounded ${
                        currentPage === page
                          ? "bg-[#C19A6B] text-white rounded-xl border border-[#EBF0ED]"
                          : " bg-[#FAFAFA] text-[#828282] rounded-xl border border-[#EBF0ED]"
                      }`}
                    >
                      {page}
                    </button>
                  ))}
              </div>

              {/* Next Button */}
              <div
                className="py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="6"
                  height="12"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
