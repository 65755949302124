import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import ProductManagmentManageProduct from "../../../components/MyShop/Product-Managment-Components/ProductManagmentManageProduct";

export default function ProductManagmentManageProductPage() {
  return (
    <>
      <MyShopLayout>
        <ProductManagmentManageProduct />
      </MyShopLayout>
    </>
  );
}
