import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./cardhover.css";
import ProductsCard from "./ProductsCard";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { addStoreDecoration, clearMessage } from "../../../store/vendorslice";
import { toast } from "react-toastify";
export default function StoreManagmentBannerAndProduct() {
  const [activeTab, setActiveTab] = useState("BANNERS AND PRODUCTS");
  const [image, setImage] = useState(null);
  const [banner, setBanner] = useState(null);
  const [selectedBundleId, setSelectedBundleId] = useState(null); // ID of the selected cards
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const { data, message, error } = useSelector((state) => state.admin);
  const [updatedCardReels, setUpdatedCardReels] = useState(
    new Array(1).fill(null)
  );
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({ product_id: [], banner: null });
  // const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBanner(file);
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
  };
  const handleSubmit = () => {
    console.log(updatedCardReels, banner);
    let product = updatedCardReels.map((item) => {
      return item?.id ? item.id : null;
    });
    const formData = new FormData();
    const data = { product_id: product, banner };
    if (updatedCardReels.length && banner) {
      for (const [key, value] of Object.entries(data)) {
        if (key == "product_id") {
          console.log("here");
          formData.append("product_id[]", value);
        } else {
          formData.append(key, value);
        }
        console.log(key, value);
      }

      dispatch(addStoreDecoration(formData));
    }
  };
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      setPayload({
        name: "",
        email: "",
        ticket_headline: "",
        upload_attachment: "",
        ticket_summary: "",
      });
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [error, message]);
  return (
    <>
      <div className=" xl:px-4 flex flex-col gap-3">
        {/* head */}
        <div className="flex justify-between items-center gap-2">
          <BreadCrums
            breadCrum={[
              {
                name: "Store Decoratioon",
                path: "/vendor/my-shop/store-decoration",
              },
              {
                name: "Banner & Products",
                path: "/vendor/my-shop/store-decoration/banner-products",
              },
            ]}
          />

          <button
            className="py-2 md:py-[10px] px-5 md:px-9 bg-[#C19A6B]  rounded-xl text-white  font-semibold text-xs"
            onClick={(e) => handleSubmit()}
          >
            Publish
          </button>
        </div>
        {/* filter */}
        <div className="flex text-[10px] md:text-sm gap-2 md:gap-6 py-1 md:py-3">
          <Link
            to="/vendor/my-shop/store-decoration"
            onClick={() => setActiveTab("STORE INFO")}
            className={`font-semibold  text-center cursor-pointer  px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "STORE INFO"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STORE INFO
          </Link>
          <Link
            to="/vendor/my-shop/store-decoration/intro"
            onClick={() => setActiveTab("INTRO VEDIO")}
            className={`font-semibold  text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "INTRO VEDIO"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INTRO VEDIO
          </Link>

          <Link
            to="/vendor/my-shop/store-decoration/banner-products"
            onClick={() => setActiveTab("BANNERS AND PRODUCTS")}
            className={`font-semibold  text-center cursor-pointer  px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "BANNERS AND PRODUCTS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            BANNERS AND PRODUCTS
          </Link>
        </div>
        {/* Banner image */}
        <div>
          <h2 className="text-sm md:text-lg font-semibold">BANNER</h2>
          <div className="border border-[#EBF0ED] rounded-lg p-4 mb-5">
            <div className="">
              <h2 className=" text-xs text-[#6B6B6B] mb-1">BANNER</h2>
              <div>
                {/* upload image div */}
                <div className="w-full h-[150px] md:h-[220px] flex items-center justify-center border-2 border-dashed border-[#D9D9D9] rounded-xl bg-[#F6F6F6] cursor-pointer relative">
                  {image ? (
                    <>
                      <img
                        src={image}
                        className="w-full h-full object-cover rounded-xl"
                        alt="Uploaded"
                      />
                      <button
                        className="absolute top-0 right-0 m-2 p-2 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                        onClick={handleDeleteImage}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <label
                      htmlFor="imageUpload"
                      className="flex items-center justify-center bg-[#D9D9D9] rounded-full cursor-pointer"
                    >
                      <input
                        type="file"
                        id="imageUpload"
                        className="hidden"
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                      <img
                        src="/assets/icons/StoreDecoration/plus.svg"
                        className="p-2"
                        alt="Upload"
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
            {/* products will replace with the reels for the moodal  */}
            <ProductsCard
              selectedBundleId={selectedBundleId}
              setSelectedBundleId={setSelectedBundleId}
              isHeartFilled={isHeartFilled}
              setIsHeartFilled={setIsHeartFilled}
              updatedCardReels={updatedCardReels}
              setUpdatedCardReels={setUpdatedCardReels}
            />
          </div>
        </div>
      </div>
    </>
  );
}
