
import AddressPage from "../pages/Vendor-Profile-Creation-Pages/AddressPage";
import BankVerificationPage from "../pages/Vendor-Profile-Creation-Pages/BankVerificationPage";
import ProfilePage from "../pages/Vendor-Profile-Creation-Pages/ProfilePage";
const accountCreationRoutes = [
 
  {
    path: "/vendor/profile-creation",
    element: <ProfilePage />,
  },
  {
    path: "/vendor/profile-creation/address",
    element: <AddressPage />,
  },
  {
    path: "/vendor/profile-creation/bankverification",
    element: <BankVerificationPage />,
  },
 
];

export default accountCreationRoutes;
