import React, { useEffect } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MallListById,
  clearMessage,
  storeLeaveRequestToMall,
  storeSendRequestToMall,
} from "../../../store/vendorslice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";

export default function ViewMall() {
  const { id } = useParams();
  const navigate = useNavigate()
  const { message, loading, error, data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  // const approveStoreRequestToMall = (id) => {
  //   dispatch(storeSendRequestToMall({ mall_id: id }));
  //   dispatch(MallListById(id));
  // };
  // const leaveStoreRequestToMall = (id) => {
  //   dispatch(storeLeaveRequestToMall(id));

  //   dispatch(MallListById(id));
  // };
  useEffect(() => {
    dispatch(MallListById(id));
  }, [id]);


  useEffect(() => {
    if (message === "Your request has been submitted successfully" || message === "Request to mall cancelled Successfully.") {
      toast.success(message);
      navigate("/vendor/my-shop/join-mall")
    }
    if (error){
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [message,error]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }



  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <div className="flex items-center justify-between text-xs">
        <BreadCrums
          breadCrum={[
            {
              name: "Join Mall",
              path: "/vendor/my-shop/join-mall",
            },
            {
              name: "Mall Details",
            },
          ]}
        />

{
  data?.data?.joinStatus === "" ? (
    <button
      className="border-[#C19A6B] border text-[#C19A6B] text-xs md:text-sm font-semibold px-5 py-[6px] md:px-9 md:py-[10px] rounded-lg text-nowrap"
      onClick={() =>
        dispatch(storeSendRequestToMall({ id, status: "join" }))
      }
    >
      Send Request
    </button>
  ) : data?.data?.joinStatus == "Pending" ? (
    <button
      className="bg-[#C19A6B] text-xs md:text-sm font-semibold text-white px-5 md:px-9 py-[6px] md:py-[10px] rounded-lg text-nowrap"
      onClick={() =>
        dispatch(storeSendRequestToMall({ id, status: "leave" }))
      }
    >
      Cancel Request
    </button>
  ) : data?.data?.joinStatus === "left" ? (
    <button
    className=" text-xs md:text-sm font-semibold text-red-600 px-5 md:px-9 py-[6px] md:py-[10px] rounded-lg text-nowrap"
    disabled
  >
    You can't join this mall because you have previously left it.
  </button>
  ) : (
    <button
      className="bg-[#C19A6B] text-xs md:text-sm font-semibold text-white px-5 md:px-9 py-[6px] md:py-[10px] rounded-lg text-nowrap"
      onClick={() =>
        dispatch(storeSendRequestToMall({ id, status: "leave" }))
      }
    >
      Leave Mall
    </button>
  )
}

      </div>

      <div className="w-full xl:w-[70%] flex flex-col gap-4 flex-1">
        <div className=" border border-[#EBF0ED] rounded-lg p-[17px]">
          <div className="flex items-center gap-2 mb-4">
            <div className="relative">
              <img
                className="w-14 h-14"
                src={data?.data?.mall_logo}
                alt=""
              />
            </div>
            <div></div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-10">
            <div className="w-full">
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  MALL NAME :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.data?.mall_name}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  PHONE NUMBER :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.data?.number}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  E-MAIL :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.data?.email}
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  CUSTOMER SUPPORT NUMBER :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.data?.number}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  LOCATION :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.data?.city + ", " + data?.data?.country}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  MALLS REGISTERED :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.data?.active_vendor_count}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
