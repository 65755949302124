import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import PremiumPreMadeAd from "../../../../components/Booster/Marketing-Solutions/Premium-Product-Components/PremiumPreMadeAd";

export default function PremiumPreMadeAdPage() {
  return (
    <>
      <BoosterLayout>
        <PremiumPreMadeAd />
      </BoosterLayout>
    </>
  );
}
