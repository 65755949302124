import CustomerReturns from "../../components/MyShop/Orders-Management/Customer-Returns/CustomerReturns";
import CustomerReturnsClosed from "../../components/MyShop/Orders-Management/Customer-Returns/CustomerReturnsClosed";
import CustomerReturnsPage from "../../pages/My-Shop/Order-Management-Pages/Customer-Returrns-Page/CustomerReturnsPage";
import ManageReviewsPage from "../../pages/My-Shop/Order-Management-Pages/Manage-Reviews-Page/ManageReviewsPage";
import OrderManagementPage from "../../pages/My-Shop/Order-Management-Pages/Order-Management-Page/OrderManagementPage";
import OrderTrackPage from "../../pages/My-Shop/Order-Management-Pages/Order-Management-Page/OrderTrackPage";
import OrderViewPage from "../../pages/My-Shop/Order-Management-Pages/Order-Management-Page/OrderViewPage";

const orderReviewsRoutes = [
  {
    path: "/vendor/my-shop/orders-management",
    children: [
      {
        index: true,
        element: <OrderManagementPage />,
      },
      {
        path: "/vendor/my-shop/orders-management/order-view/:id",
        element: <OrderViewPage />,
      },
      {
        path: "/vendor/my-shop/orders-management/track-order",
        element: <OrderTrackPage />,
      },
    ],
  },

  {
    path: "/vendor/my-shop/customer-returns",
    element: <CustomerReturnsPage />,
    children: [
      {
        index: true,
        element: <CustomerReturns />,
      },
      {
        path: "/vendor/my-shop/customer-returns/closed",
        element: <CustomerReturnsClosed />,
      },
    ],
  },

  {
    path: "/vendor/my-shop/manage-reviews",
    element: <ManageReviewsPage />,
  },
];

export default orderReviewsRoutes;
