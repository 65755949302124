import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import EarningCharts from "../../../components/home-page-components/EarningCharts";
import NewVisitorBarChart from "../../../components/home-page-components/NewVisitorBarChart";
import OrderBarChart from "../../../components/home-page-components/OrderBarChart";
import BalanceInfo from "../../../components/home-page-components/BalanceInfo";
import AcceptedOffers from "../../../components/home-page-components/AcceptedOffers";
import TopProducts from "../../../components/home-page-components/TopProducts";
import BreadCrums from "../../../components/Common/BreadCrums";

export default function HomePage() {
  return (
    <>
      <MyShopLayout>
        <div className="w-full xl:px-4">
          <BreadCrums
            breadCrum={[
              {
                name: "Dashboard",
              },
            ]}
          />
        </div>
        <div className=" w-full flex flex-col ">
          <div className="mb-4 flex flex-col lg:flex-row  gap-6 w-full">
            <div className="w-full">
              <EarningCharts />
            </div>
            <div className="w-full">
              <BalanceInfo />
            </div>
          </div>
          <div className="my-2 flex flex-col lg:flex-row  gap-6 w-full">
            <div className="w-full xl:w-[35%]">
              <NewVisitorBarChart />
            </div>
            <div className="w-full xl:w-[35%]">
              <OrderBarChart />
            </div>
            <div className="w-full xl:w-[25%]">
              <AcceptedOffers />
            </div>
          </div>
          <div className="">
            <TopProducts />
          </div>
        </div>
      </MyShopLayout>
    </>
  );
}
