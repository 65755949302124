import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import CustomBoosterSetting from "../../../../components/Booster/Marketing-Solutions/Store-Boost-Components/CustomBoosterSetting";

export default function CustomBoosterSettingPage() {
  return (
    <>
      <BoosterLayout>
        <CustomBoosterSetting />
      </BoosterLayout>
    </>
  );
}
