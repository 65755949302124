import InSightLayout from "../Vender-Dashboard-Layout/InSightLayout";
import OffersPage from "../pages/Booster/Marketing-Solutions/OffersPage";
import InSightPage from "../pages/InSight/InSightPage";
import HomePage from "../pages/My-Shop/Home-Page/HomePage";
import VendorChatPage from "../pages/My-Shop/Vendor-Chat-Page/VendorChatPage";

const headerRoutes = [
  {
    path: "/vendor/my-shop/home",
    element: <HomePage />,
  },
  {
    path: "/vendor/insight",
    element: <InSightLayout />,
    children: [
      {
        index: true,
        element: <InSightPage />,
      },
    ],
  },
  {
    path: "/vendor/booster/offers",
    element: <OffersPage />,
  },
  {
    path: "/vendor/vendor-chat",
    element: <VendorChatPage />,
  },
];

export default headerRoutes;
