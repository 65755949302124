import React from "react";
import BoosterLayout from "../../../Vender-Dashboard-Layout/BoosterLayout";
import JoinOffer from "../../../components/Booster/Marketing-Solutions/Offers-Components/JoinOffer";

export default function JoinOfferPage() {
  return (
    <>
      <BoosterLayout>
        <JoinOffer />
      </BoosterLayout>
    </>
  );
}
