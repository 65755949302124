import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  availableOffers,
} from "../../../../store/vendorslice";
import Loader from "../../../Common/Loader";
import { imagePath } from "../../../../functions/URLString";
import Countdown from "react-countdown";

export default function AvailableOffer() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  const [activeRole, setActiveRole] = useState("");

  useEffect(() => {
    if (activeRole != "") {
      dispatch(availableOffers({status:activeRole}));
    } else {
      dispatch(availableOffers({}));
    }
  }, [activeRole]);

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  function CountDown({index}) {
    const [days, hours, minutes, seconds] = data?.offers[index]?.registration_status
    .match(/\d+/g)
    .map(Number);
    const totalSeconds =
    days * 86400 + hours * 3600 + minutes * 60 + seconds;
    const now = Date.now();
    const endTime = now + totalSeconds * 1000;

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return <span>Registration Ended</span>;
      } else {
        return (
          <span>
            {days}d : {hours}h : {minutes}m : {seconds}s
          </span>
        );
      }
    };
  
    return (
      <>
        <Countdown date={endTime} renderer={renderer} />
      </>
    );
  }


  return (
    <>
      <div className="xl:px-4 flex flex-col ">
        <BreadCrums
          breadCrum={[
            {
              name: "Offers",
              path: "/vendor/booster/offers",
            },
          ]}
        />

        {/* filter */}

        <div className="flex gap-3 md:gap-6 py-5  ">
          <NavLink
            onClick={() => handleRoleChange("")}
            className={`flex items-center text-nowrap font-semibold gap-1 md:gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === ""
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            AVAILABLE OFFERS
            <span
              className={` font-semibold py-[2px] md:py-2 px-[5px] md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === ""
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              ({!loading && data && data?.counts?.all_offers ? data?.counts?.all_offers : 0})
            </span>
          </NavLink>
          <NavLink
            onClick={() => handleRoleChange("upcoming")}
            className={`flex items-center font-semibold text-nowrap  gap-1 md:gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "upcoming"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            UPCOMING OFFERS
            <span
              className={` font-semibold py-[2px] md:py-2 px-[5px] md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "upcoming"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              ({!loading && data && data?.counts?.upcoming ? data?.counts?.upcoming : 0})
            </span>
          </NavLink>
          <NavLink
            onClick={() => handleRoleChange("active")}
            className={`flex items-center font-semibold  text-nowrap  gap-1 md:gap-2 text-[#828282] uppercase text-[8px] md:text-sm ${
              activeRole === "active"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            REGISTERED OFFERS
            <span
              className={` font-semibold py-[2px] md:py-2 px-[5px] md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "active"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data?.counts?.active
                ? data?.counts?.active
                : 0}
              )
            </span>
          </NavLink>
        </div>
        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left">OFFERS BANNER</span>
                <span className="w-[15%] text-left">OFFERS NAME</span>

                {activeRole === "Available" ? (
                  <>
                    <span className="w-[10%] text-left">SELLERS</span>
                  </>
                ) : (
                  <></>
                )}
                {activeRole === "" ? (
                  <>
                    <span className="w-[20%] text-left">
                      REGISTRATION CLOSES IN
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {activeRole === "upcoming" ? (
                  <>
                    <span className="w-[20%] text-left uppercase">
                      Registration Starts In
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {activeRole === "active" ? (
                  <>
                    <span className="w-[20%] text-left uppercase">
                      Offer Ends In
                    </span>
                  </>
                ) : (
                  <></>
                )}

                <span className="w-[35%] text-left">OFFERS LIVE PERIOD</span>
                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              <div>
                {loading ? (
                  <div>
                    <div colSpan="6">
                      <div className="flex justify-center py-4">
                        <Loader />
                      </div>
                    </div>
                  </div>
                ) : data?.offers?.length > 0 ? (
                  data?.offers?.map((item,index) => {
                    return (
                      <div
                        key={item.id}
                        className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                      >
                        <span className="w-[20%] text-left font-[600]">
                          <img
                            className=" w-[70px] h-[50px]  md:w-[110px] md:h-[90px] rounded-md"
                            src={item.image}
                            alt=""
                          />
                        </span>
                        <span className="w-[15%] text-left ">
                          <span className="font-semibold text-[#18120F] ">
                            {item.title}
                          </span>
                        </span>

                        <div className="w-[20%] text-left ">
                          {!item.registration_status.includes(':')? (
                            <>
                              <span className="px-2 py-[0.189rem] bg-[#FAFAFA] border border-[#EBF0ED] rounded-xl text-[#828282]">
                                {item.registration_status}
                              </span>
                            </>
                          ) : 
                            <span className="text-sm text-center w-[70%] py-[0.189rem] bg-[#FAFAFA] font-bold border border-[#EBF0ED] rounded-xl text-[#828282]">
                              <CountDown index={index}/>
                            </span>
                          }
                        </div>
                        <div className="w-[35%] text-left ">
                          <span className="bg-[#F6FFF7]  px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl">
                            {item.livestartdatetime}
                          </span>
                          <span className="px-2">to</span>
                          <span className="bg-[#FFF3F4]  px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl">
                            {item.liveenddatetime}
                          </span>
                        </div>
                        {item.registration_status === "Registration Closed" ? (
                          <>
                            <span className="w-[10%] text-left  text-[#C19A6B]  font-[500]">
                              <Link
                                to={`/vendor/booster/offers/offer-name/${item.id}`}
                                className="cursor-pointer"
                              >
                                Ended
                              </Link>
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                        {item.registration_status != "Registration Closed" ? item.joiningStatus != "Joined" ? (
                          <>
                            <span className="w-[10%] text-left  text-[#C19A6B]  font-[500]">
                              <Link
                                to={`/vendor/booster/offers/offer-name/${item.id}`}
                                className="cursor-pointer"
                              >
                                Join
                              </Link>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="w-[10%] text-left  text-[#C19A6B]  font-[500]">
                              <Link
                                to={`/vendor/booster/offers/single-registered-offer/${item.id}`}
                                className="cursor-pointer"
                              >
                                View
                              </Link>
                            </span>
                          </>
                        ):<></>}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center font-bold tracking-wide py-4">
                    <span colSpan={6}>No Offers Found</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
