import JoinMallListPage from "../../pages/My-Shop/Join-Mall-Pages/JoinMallListPage";

import ViewMallPage from "../../pages/My-Shop/Join-Mall-Pages/ViewMallPage";

const joinMallRoutes = [
  {
    path: "/vendor/my-shop/join-mall",
    children: [
      {
        index: true,
        element: <JoinMallListPage />,
      },
      {
        path: "/vendor/my-shop/join-mall/view-mall/:id",
        element: <ViewMallPage />,
      },
    ],
  },
];

export default joinMallRoutes;
