import ChatSettingAutoReply from "../../components/MyShop/Chat-Setting-Components/ChatSettingAutoReply";
import ChatSettingQuickReply from "../../components/MyShop/Chat-Setting-Components/ChatSettingQuickReply";
import ChatSettingsUserManagment from "../../components/MyShop/Chat-Setting-Components/ChatSettingsUserManagment";
import ChatSettingPage from "../../pages/My-Shop/Chat-Setting-Pages/ChatSettingPage";
import AdressesPage from "../../pages/My-Shop/Vendor-Profile-page/AdressesPage";
import InformationPage from "../../pages/My-Shop/Vendor-Profile-page/InformationPage";
import ProfileSettingPage from "../../pages/My-Shop/Vendor-Profile-page/ProfileSettingPage";

const accountAndSettingRoutes = [
    {
        path: "/vendor/my-shop/profile",
        children: [
          {
            index: true,
            element: <ProfileSettingPage />,
          },
          {
            path: "/vendor/my-shop/profile/information",
            element: <InformationPage />,
          },
          {
            path: "/vendor/my-shop/profile/adresses",
            element: <AdressesPage />,
          },
        ],
      },
      {
        path: "/vendor/my-shop/chat-setting",
        element: <ChatSettingPage />,
        children: [
          {
            index: true,
            element: <ChatSettingsUserManagment />,
          },
          {
            path: "/vendor/my-shop/chat-setting/quick-reply",
            element: <ChatSettingQuickReply />,
          },
          {
            path: "/vendor/my-shop/chat-setting/auto-reply",
            element: <ChatSettingAutoReply />,
          },
        ],
      },
 
];

export default accountAndSettingRoutes;
