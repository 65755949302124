import React from "react";

import { Link } from "react-router-dom";

export default function BalanceInfo() {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between gap-3">
        {/* balance + order div */}
        <div className="w-full">
          <div
            className="py-5 px-5 rounded-2xl mb-4 flex flex-col"
            style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F" }}
          >
            <div className="font-bold">Current Balance</div>
            <div className="font-bold text-5xl mt-5">$345.98</div>
            <Link className="bg-[#C19A6B] py-2 rounded-lg text-white text-center mt-6">
              Withdraw
            </Link>
          </div>
          <div
            className="py-6 px-5 rounded-2xl"
            style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F" }}
          >
            <div className="font-bold">New Orders</div>
            <div className="flex justify-between items-end">
              <div className="font-bold text-6xl mt-5">444</div>
              <Link to="/vendor/my-shop/new-orders" className="">
                <img
                  src="/assets/images/dashboard-home/order-button.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>

        {/* recently bought  */}
        <div
          className="w-full py-5 px-6 rounded-2xl "
          style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F" }}
        >
          <div className="font-bold">Recently Bought</div>
          <Link
            to="/vendor/my-shop/affiliate-dashboard"
            className="grid grid-cols-3  mt-5"
          >
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full "
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full mt-5"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full mt-5"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full mt-5"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full mt-5"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full mt-5"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
            <div>
              <img
                style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                className="h-16 w-16 rounded-full mt-5"
                src="/assets/images/dashboard-home/user-profile.svg"
                alt=""
              />
            </div>
          </Link>
          <div className="flex items-center mt-4 justify-center gap-2">
            <div>
              <div className="avatar-group -space-x-2 rtl:space-x-reverse">
                <div className="avatar border-none">
                  <div className="w-6 h-6">
                    <img
                      className=""
                      src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=600"
                      alt=""
                    />
                  </div>
                </div>
                <div className="avatar border-none">
                  <div className="w-6 h-6">
                    <img
                      className=""
                      src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt=""
                    />
                  </div>
                </div>
                <div className="avatar border-none">
                  <div className="w-6 h-6">
                    <img
                      className=""
                      src="https://images.pexels.com/photos/1499327/pexels-photo-1499327.jpeg?auto=compress&cs=tinysrgb&w=600"
                      alt=""
                    />
                  </div>
                </div>
                <div className="avatar border-none">
                  <div className="w-6 h-6">
                    <img
                      className=""
                      src="https://images.pexels.com/photos/1547971/pexels-photo-1547971.jpeg?auto=compress&cs=tinysrgb&w=600"
                      alt=""
                    />
                  </div>
                </div>
                <div className="avatar border-none">
                  <div className="w-6 h-6">
                    <img
                      className=""
                      src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=600"
                      alt=""
                    />
                  </div>
                </div>
                <div className="avatar border-none">
                  <div className="w-6 h-6">
                    <img
                      className=""
                      src="https://images.pexels.com/photos/2773977/pexels-photo-2773977.jpeg?auto=compress&cs=tinysrgb&w=600"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-sm text-[#6B6B6B]">+29 others</div>
          </div>
        </div>
      </div>
    </>
  );
}
