import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import AfflilateProductsAdd from "../../../components/MyShop/Affilate-Products-Components/AfflilateProductsAdd";

export default function AffilateProductAddPage() {
  return (
    <>
      <MyShopLayout>
        <AfflilateProductsAdd />
      </MyShopLayout>
    </>
  );
}
