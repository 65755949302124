import BoosterPerformancePage from "../../pages/Booster/Marketing-Solutions/Booster-Performance-Pages/BoosterPerformancePage";
import JoinOfferPage from "../../pages/Booster/Marketing-Solutions/JoinOfferPage";
import OfferNamePage from "../../pages/Booster/Marketing-Solutions/OfferNamePage";
import OffersPage from "../../pages/Booster/Marketing-Solutions/OffersPage";
import CustomAdPage from "../../pages/Booster/Marketing-Solutions/Product-Booster-Pages/CustomAdPage";
import PaymentPage from "../../pages/Booster/Marketing-Solutions/Product-Booster-Pages/PaymentPage";
import PreMadeAdPage from "../../pages/Booster/Marketing-Solutions/Product-Booster-Pages/PreMadeAdPage";
import ProductBoosterPage from "../../pages/Booster/Marketing-Solutions/Product-Booster-Pages/ProductBoosterPage";
import SingleRegisteredOfferPage from "../../pages/Booster/Marketing-Solutions/SingleRegisteredOfferPage";
import CustomBoosterSettingPage from "../../pages/Booster/Marketing-Solutions/Store-Boost-page/CustomBoosterSettingPage";
import StoreBoostPage from "../../pages/Booster/Marketing-Solutions/Store-Boost-page/StoreBoostPage";
import PremiumCustomAdPage from "../../pages/Booster/Marketing-Solutions/premium-product-booster/PremiumCustomAdPage";
import PremiumPreMadeAdPage from "../../pages/Booster/Marketing-Solutions/premium-product-booster/PremiumPreMadeAdPage";
import PremiumProductBoostSettingPage from "../../pages/Booster/Marketing-Solutions/premium-product-booster/PremiumProductBoostSettingPage";
import PremiumProductBoosterPage from "../../pages/Booster/Marketing-Solutions/premium-product-booster/PremiumProductBoosterPage";
import PremiumProductPage from "../../pages/Booster/Marketing-Solutions/premium-product-booster/PremiumProductPage";
import PremiumProductYourBidsPage from "../../pages/Booster/Marketing-Solutions/premium-product-booster/PremiumProductYourBidsPage";

const marketingSolutionsRoutes = [
  {
    path: "/vendor/booster/offers",
    children: [
      {
        index: true,
        element: <OffersPage />,
      },
      {
        path: "/vendor/booster/offers/offer-name/:id",
        element: <OfferNamePage />,
      },
      {
        path: "/vendor/booster/offers/join-offer/:id",
        element: <JoinOfferPage />,
      },
      {
        path: "/vendor/booster/offers/single-registered-offer/:id",
        element: <SingleRegisteredOfferPage />,
      },
    ],
  },

  {
    path: "/vendor/booster/product-booster",
    children: [
      {
        index: true,
        element: <ProductBoosterPage />,
      },
      {
        path: "/vendor/booster/product-booster/custom-ad",
        element: <CustomAdPage />,
      },
      {
        path: "/vendor/booster/product-booster/pre-made-ad/:id",
        element: <PreMadeAdPage />,
      },
      {
        path: "/vendor/booster/product-booster/payment",
        element: <PaymentPage />,
      },
    ],
  },
  {
    path: "/vendor/booster/premium-product-booster",
    children: [
      {
        index: true,
        element: <PremiumProductBoosterPage />,
      },
      {
        path: "/vendor/booster/premium-product-booster/premium-custom-ad",
        element: <PremiumCustomAdPage />,
      },
      {
        path: "/vendor/booster/premium-product-booster/premium-pre-made-ad",
        element: <PremiumPreMadeAdPage />,
      },
      {
        path: "/vendor/booster/premium-product-booster/premium-product/:id",
        element: <PremiumProductPage />,
      },
      {
        path: "/vendor/booster/premium-product-booster/your-bids",
        element: <PremiumProductYourBidsPage />,
      },
      {
        path: "/vendor/booster/premium-product-booster/premium-product-Boost-Setting/:id",
        element: <PremiumProductBoostSettingPage />,
      },
    ],
  },
  {
    path: "/vendor/booster/store-boost",
    element: <StoreBoostPage />,
  },
  {
    path: "/vendor/booster/store-boost/custom-booster-setting",
    element: <CustomBoosterSettingPage />,
  },
  {
    path: "/vendor/booster/booster-performance",
    element: <BoosterPerformancePage />,
  },
];

export default marketingSolutionsRoutes;
