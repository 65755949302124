import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";
import ManageReviews from "../../../../components/MyShop/Orders-Management/Manage-Reviews/ManageReviews";

export default function ManageReviewsPage() {
  return (
    <>
      <MyShopLayout>
        <ManageReviews />
      </MyShopLayout>
    </>
  );
}
