import React, { useEffect } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTicketById } from "../../../store/vendorslice";
import { imagePath } from "../../../functions/URLString";
import moment from "moment";

export default function TicketDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(getTicketById(id));
  }, [id]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-5">
        <BreadCrums
          breadCrum={[
            {
              name: "Seller Support",
              path: "/vendor/my-shop/help-center",
            },
            {
              name: "Tickets",
              path: "/vendor/my-shop/tickets",
            },
            {
              name: data?.ticket?.ticket_id? data.ticket.ticket_id:"Ticket Id",
              path: "/vendor/my-shop/tickets/ticket-details",
            },
          ]}
        />

        {/* cards */}
        <div className="w-full md:w-[60%] border border-[#EBF0ED] flex flex-col md:flex-row justify-between gap-1 md:gap-10 rounded-lg p-3 md:p-5">
          <div className="w-full flex flex-col gap-1 md:gap-3">
            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between  ">
              <span className="text-[#6B6B6B]">FULL NAME:</span>
              <span>{data?.ticket?.ticket_headline && data.ticket.ticket_headline}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>
            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between  ">
              <span className="text-[#6B6B6B]">EMAIL:</span>
              <span>{data?.ticket?.user?.email && data.ticket.user.email}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>

            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between  ">
              <span className="text-[#6B6B6B]">DATE CREATED:</span>
              <span>
                {data?.ticket?.created_at &&
                  moment(data.ticket.created_at).format("MM/DD/YYYY")}
              </span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>
          </div>
          <div className="w-full flex flex-col gap-1 md:gap-3">
            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between  ">
              <span className="text-[#6B6B6B]">STATUS:</span>
              <span>{data?.ticket?.status && data.ticket.status==="0"?"Pending":"Approved"}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>

            <span className="border-b border-[#EBF0ED] mt-1"></span>

            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between  ">
              <span className="text-[#6B6B6B]">HEADING:</span>
              <span>{data?.ticket?.ticket_headline && data.ticket.ticket_headline}</span>
            </div>
            <span className=" hidden md:block border-b border-[#EBF0ED] mt-1"></span>
          </div>{" "}
        </div>
        {/* summary */}
        <div>
          <h2 className="text-sm md:text-lg font-semibold">Ticket Summary</h2>
          <div className="border border-[#EBF0ED] p-3 bg-[#FAFAFA] rounded-lg text-xs font-[400]">
            <span>{data?.ticket?.ticket_summary && data.ticket.ticket_summary}</span>
          </div>
        </div>
        {/* attachment */}
        <div className="border border-[#EBF0ED] p-2 md:p-4 md:my-4 rounded-lg">
          <h2 className="text-sm md:text-lg font-semibold">Attachments</h2>
          <div className="flex items-center justify-between">
            <div className="w-full flex justify-start">
              <div className="border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md">
                <img
                  src={
                    data?.ticket?.upload_attachment?
                    data.ticket.upload_attachment:""}
                  alt=""
                  className="w-full h-full"
                />
              </div>
            </div>
            {/* <div className="w-full flex justify-start">
              <div className=" border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md">
                {" "}
                <img
                  src={
                    data?.ticket?.upload_attachment &&
                    imagePath + data.ticket.upload_attachment
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="w-full flex justify-start">
              <div className=" border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md">
                {" "}
                <img
                  src={
                    data?.ticket?.upload_attachment &&
                    imagePath + data.ticket.upload_attachment
                  }
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
