import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import ContactUs from "../../../components/MyShop/Seller-Support-Components/ContactUs";

export default function ContactUsPage() {
  return (
    <>
      <MyShopLayout>
        <ContactUs />
      </MyShopLayout>
    </>
  );
}
