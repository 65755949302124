import React, { useState } from "react";

export default function ChatSettingsUserManagment() {
  return (
    <>
      {/* main */}
      <div className="xl:px-4">
        {/* ---- */}
        <div className="border border-[#EBF0ED] flex flex-col gap-4 py-5 px-3 rounded-xl">
          <div className="flex justify-between md:justify-normal md:gap-[142px] items-center text-xs">
            <span className="font-semibold ">Admin</span>
            <span className="text-[#18120F] font-[400]">Danyy@gmail.com</span>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-24">
            <span className="text-xs font-semibold text-[#18120F]">
              Working Hours
            </span>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col md:flex-row md:gap-7 md:items-center">
                <span className="text-xs font-[400] text-[#18120F]">
                  On Weekday
                </span>
                <div className="flex items-center gap-2">
                  <input
                    className="bg-[#FAFAFA] h-[38px] w-full md:w-[180px] rounded-lg px-3 border border-[#EBF0ED]"
                    type="time"
                    name=""
                    id=""
                  />
                  <input
                    className="bg-[#FAFAFA] h-[38px] w-full md:w-[180px] rounded-lg px-3 border border-[#EBF0ED]"
                    type="time"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:gap-7 md:items-center">
                <span className="text-xs font-[400] text-[#18120F]">
                  On Weekend
                </span>
                <div className="flex items-center gap-2">
                  <input
                    className="bg-[#FAFAFA] h-[38px] w-[180px] rounded-lg px-3 border border-[#EBF0ED]"
                    type="time"
                    name=""
                    id=""
                  />
                  <input
                    className="bg-[#FAFAFA] h-[38px] w-[180px] rounded-lg px-3 border border-[#EBF0ED]"
                    type="time"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:justify-end  gap-3 items-center  mt-3 md:mt-7">
            <input
              placeholder="Search Email,UserName..."
              type="text"
              className="border px-2 w-full md:w-[15rem] outline-none text-xs md:text-sm font-semibold text-[#6B6B6B] py-[7px] md:py-[10px] border-[#EBF0ED] rounded-lg "
            />
            <div className="flex items-center gap-2">
              <button className=" border-[#D9D9D9] text-nowrap bg-[#DCDCDC] text-[#B9B9B9] text-xs md:text-sm font-semibold py-[7px] px-5 md:py-[10px] md:px-9 rounded-lg">
                Remove
              </button>
              <button className="  text-nowrap bg-[#C19A6B] text-white text-xs md:text-sm font-semibold py-[7px] px-5 md:py-[10px] md:px-9 rounded-lg">
                Add User
              </button>
            </div>
          </div>
          {/*  */}
          <div className="border border-[#EBF0ED] rounded-xl md:my-5 ">
            {/* header */}
            <div className="flex items-center text-[10px] md:text-xs justify-between py-[0.7rem]  ">
              <input className="w-[10%] text-left" type="checkbox" />
              <span className="w-[35%] text-left  font-semibold text-[#6B6B6B]">
                EMAIL
              </span>
              <span className="w-[25%] text-left  font-semibold text-[#6B6B6B]">
                USERNAME
              </span>
              <span className="w-[25%] text-left  font-semibold text-[#6B6B6B]">
                ACTIONS
              </span>
            </div>
            {/* body */}
            <div className="flex items-center justify-between py-[0.6rem] text-xs md:text-sm border-t">
              <input className="w-[10%] text-left" type="checkbox" />
              <span className="w-[35%] text-left  font-semibold text-[#18120F]">
                saad@gmail.com
              </span>

              <span className="w-[25%] text-left flex gap-4  font-semibold text-[#18120F]">
                dany123
              </span>
              <span className="w-[25%] text-left flex gap-4  font-[500] text-[#C19A6B]">
                remove
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
