import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addStoreInfo, clearMessage, getVendorStoreInfo } from "../../../store/vendorslice";

export default function StoreManagmentStoreInfo() {
  const { data, message, error, storeInfoData } = useSelector((state) => state.admin);
  const [activeTab, setActiveTab] = useState("STORE INFO");
  const [initialStoreLogo, setInitialStoreLogo] = useState(null);
  const [initialStoreBanner, setInitialStoreBanner] = useState(null);
  const [storeLogo, setStoreLogo] = useState(null);
  const [storeBanner, setStoreBanner] = useState(null);
  const [payload, setPayload] = useState({ name: "", logo: "", banner: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorStoreInfo());
  }, [dispatch]);

  useEffect(() => {
    if (storeInfoData && storeInfoData.length > 0) {
      const storeInfo = storeInfoData[0];
      setPayload({ name: storeInfo.name, logo: "", banner: "" });
      setInitialStoreLogo(storeInfo.logo);
      setInitialStoreBanner(storeInfo.banner);
      setStoreLogo(storeInfo.logo);
      setStoreBanner(storeInfo.banner);
    }
  }, [storeInfoData]);

  const handleImageUpload = (event, setImage, field) => {
    const file = event.target.files[0];
    if (file) {
      setPayload((prev) => ({ ...prev, [field]: file }));
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  };

  const handleDeleteImage = (setImage, field) => {
    setImage(null);
    setPayload((prev) => ({ ...prev, [field]: "" }));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const isStoreInfoEmpty = !storeInfoData || storeInfoData.length === 0;

    if (isStoreInfoEmpty) {
      // Add all fields if there's no existing store info
      Object.keys(payload).forEach((key) => {
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      });
    } else {
      // Add only changed fields
      Object.keys(payload).forEach((key) => {
        if (payload[key] && payload[key] !== storeInfoData[0][key]) {
          formData.append(key, payload[key]);
        }
      });
    }

    if (formData.has("name") || formData.has("logo") || formData.has("banner")) {
      dispatch(addStoreInfo(formData));
    } else {
      toast.error("Kindly Fill All Fields Correctly");
    }
  };

  useEffect(() => {
    if (message) {
      // if (message === "StoreIntro created successfully" || message === "StoreIntro updated successfully") {
        toast.success(message);
        dispatch(clearMessage());
        // setPayload({ name: "", logo: "", banner: "" });
        // setStoreLogo(null);
        // setStoreBanner(null);
      // }
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [error, message, dispatch]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* head */}
        <div className="flex justify-between items-center gap-2">
          <BreadCrums
            breadCrum={[
              {
                name: "Store Decoration",
                path: "/vendor/my-shop/store-decoration",
              },
              {
                name: "Info",
                path: "/vendor/my-shop/store-decoration",
              },
            ]}
          />
          <button
            className="py-2 md:py-[10px] px-5 md:px-9 bg-[#C19A6B] rounded-xl text-white font-semibold text-xs"
            onClick={handleSubmit}
          >
            Publish
          </button>
        </div>
        {/* filter */}
        <div className="flex text-[10px] md:text-sm gap-2 md:gap-6 py-1 md:py-3">
          <Link
            to="/vendor/my-shop/store-decoration"
            onClick={() => setActiveTab("STORE INFO")}
            className={`font-semibold text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "STORE INFO"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STORE INFO
          </Link>
          <Link
            to="/vendor/my-shop/store-decoration/intro"
            onClick={() => setActiveTab("INTRO VIDEO")}
            className={`font-semibold text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "INTRO VIDEO"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INTRO VIDEO
          </Link>

          <Link
            to="/vendor/my-shop/store-decoration/banner-products"
            onClick={() => setActiveTab("BANNERS AND PRODUCTS")}
            className={`font-semibold text-center cursor-pointer px-[0.625rem] py-1 md:py-2 border rounded-xl ${
              activeTab === "BANNERS AND PRODUCTS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            BANNERS AND PRODUCTS
          </Link>
        </div>
        {/*  */}
        <div>
          <h2 className="text-base md:text-lg font-semibold">Store Info</h2>
          <div className="border border-[#EBF0ED] flex flex-col gap-4 rounded-lg p-2">
            <div className="">
              <label htmlFor="" className="text-[10px] md:text-xs font-semibold text-[#6B6B6B]">
                STORE NAME
              </label>
              <input
                type="text"
                placeholder="Store Name"
                value={payload.name}
                className="w-full text-xs outline-none rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] py-[0.69rem] px-[0.62rem]"
                onChange={(e) => setPayload({ ...payload, name: e.target.value })}
              />
            </div>
            <div className="flex flex-col gap-3">
              <div className="">
                <h2 className="text-[10px] md:text-xs font-semibold text-[#6B6B6B] mb-1">STORE LOGO</h2>
                <div>
                  <div className="w-[100px] md:w-[160px] h-[100px] md:h-[160px] flex items-center justify-center border-2 border-dashed border-[#D9D9D9] rounded-xl bg-[#F6F6F6] cursor-pointer relative">
                    {storeLogo ? (
                      <>
                        <img
                          src={storeLogo}
                          alt="Store Logo"
                          className="w-full h-full object-cover rounded-xl"
                        />
                        <button
                          className="absolute top-2 right-2 text-red-500 bg-white p-2 rounded-full"
                          onClick={() => handleDeleteImage(setStoreLogo, "logo")}
                        >
                          <RxCross2 className="w-3 h-3 md:w-6 md:h-6" />
                        </button>
                      </>
                    ) : (
                      <div className="flex items-center justify-center bg-[#D9D9D9] rounded-full">
                        <img
                          src="/assets/icons/StoreDecoration/plus.svg"
                          className="p-1 md:p-2"
                          alt="Upload"
                        />
                        <input
                          type="file"
                          accept="image/*"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={(e) => handleImageUpload(e, setStoreLogo, "logo")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <h2 className="text-[10px] md:text-xs font-semibold text-[#6B6B6B] mb-1">STORE BANNER</h2>
                <div>
                  <div className="w-full lg:w-[474px] h-[100px] md:h-[160px] flex items-center justify-center border-2 border-dashed border-[#D9D9D9] rounded-xl bg-[#F6F6F6] cursor-pointer relative">
                    {storeBanner ? (
                      <>
                        <img
                          src={storeBanner}
                          alt="Store Banner"
                          className="w-full h-full object-cover rounded-xl"
                        />
                        <button
                          className="absolute top-2 right-2 text-red-500 bg-white p-2 rounded-full"
                          onClick={() => handleDeleteImage(setStoreBanner, "banner")}
                        >
                          <RxCross2 className="w-6 h-6" />
                        </button>
                      </>
                    ) : (
                      <div className="flex items-center justify-center bg-[#D9D9D9] rounded-full">
                        <img
                          src="/assets/icons/StoreDecoration/plus.svg"
                          className="p-2"
                          alt="Upload"
                        />
                        <input
                          type="file"
                          accept="image/*"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={(e) => handleImageUpload(e, setStoreBanner, "banner")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
