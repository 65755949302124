import React from "react";
import TheHeader from "../components/VendorHeader/TheHeader";
import { Outlet } from "react-router-dom";

export default function InSightLayout({ children }) {
  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 z-50 w-full py-2 px-4 bg-white"
        // style={{ width: "calc(100% - 18rem)" }}
      >
        <div className="">
          <div className="flex-grow flex-shrink-0">
            <TheHeader />
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="mt-24">
          <Outlet />
        </div>
      </div>
    </>
  );
}
