import React from "react";
import OrdersManagement from "../../../../components/MyShop/Orders-Management/OrdersManagement";
import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";

export default function OrderManagementPage() {
  return (
    <>
      <MyShopLayout>
        <OrdersManagement />
      </MyShopLayout>
    </>
  );
}
