import ContactUsPage from "../../pages/My-Shop/Seller-Support-Pages/ContactUsPage";
import HelpCenterPage from "../../pages/My-Shop/Seller-Support-Pages/HelpCenterPage";
import TicketDetailsPage from "../../pages/My-Shop/Seller-Support-Pages/TicketDetailsPage";
import TicketFormPage from "../../pages/My-Shop/Seller-Support-Pages/TicketFormPage";
import TicketsPage from "../../pages/My-Shop/Seller-Support-Pages/TicketsPage";

const sellerSupportRoutes = [
    {
        path: "/vendor/my-shop/help-center",
        element: <HelpCenterPage />,
      },

      {
        path: "/vendor/my-shop/contact-us",
        element: <ContactUsPage/>,
      },

      {
        path: "/vendor/my-shop/tickets",
        element: <TicketsPage />,
      },

      {
        path: "/vendor/my-shop/tickets/ticket-form",
        element: <TicketFormPage />,
      },
      {
        path: "/vendor/my-shop/tickets/ticket-details/:id",
        element: <TicketDetailsPage />,
      },
 
 
];

export default sellerSupportRoutes;
