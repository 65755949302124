import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import VendorBundles from "../../../components/MyShop/Vendor-Bundles-Components/VendorBundles";

export default function VendorBundlesPage() {
  return (
    <>
      <MyShopLayout>
        <VendorBundles />
      </MyShopLayout>
    </>
  );
}
