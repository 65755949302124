import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import StoreBoostModal from "./StoreBoostModal";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllMainCategories,
  getCitiesByCountries,
  getCountrie,
  vendorStoreBooster,
} from "../../../../store/vendorslice";
import moment from "moment";
import { toast } from "react-toastify";
import { clearMessage } from "../../../../store/vendorslice.js";

export default function StoreBoost() {
  const [activeRole, setActiveRole] = useState("");

  const [orders, setOrders] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [booster, setBooster] = useState([]);
  const [payload, setPayload] = useState({ uid: null,tags:[] ,country:null,city:null,category_id:null,start_date:null});
  const [selectCity, setSelectCity] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const { cities, countries, states } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector((state) => state.admin);

  useEffect(() => {
    if(activeRole==""){
      dispatch(vendorStoreBooster({}));
    } else {
        dispatch(vendorStoreBooster({status:activeRole}));
    }
  }, [activeRole]);

  useEffect(() => {
    if (Array.isArray(data?.Defaults)) setDefaults(data.Defaults);
    if (Array.isArray(data?.Boosters)) setBooster(data.Boosters);
  }, [data]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(vendorStoreBooster({}));
      dispatch(clearMessage());
      document.getElementById("my_modal_10").close();
    } else if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [message]);

  useEffect(() => {
    dispatch(getCountrie());
    dispatch(fetchAllMainCategories());
  }, []);

  useEffect(() => {
    setSelectCity(cities);
    setSelectCountry(countries);
  }, [cities, countries]);

  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.Boosters_pagination?.total) {
      let totalLength = data.Boosters_pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(vendorStoreBooster({page:page}));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4 pb-20">
        {/* name */}
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "Marketing Solution",
                path: "/vendor/booster/store-boost",
              },
              {
                name: "Store Booster",
                path: "/vendor/booster/store-boost",
              },
            ]}
          />
          <Link
            to="/vendor/booster/store-boost/custom-booster-setting"
            className="bg-[#C19A6B] text-xs md:text-sm py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg text-white"
          >
            Custom Setting
          </Link>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full">
          <div className="w-full flex flex-col gap-1 bg-[#C19A6B] p-2 md:p-5 rounded-xl  ">
            <p className="font-semibold text-sm md:text-base  text-white tracking-widest">
              Performance Summary
            </p>
            <p className="font-[400] text-[10px] md:text-xs  text-white tracking-wide">
              Summary of last 30 days of ads performance.
            </p>

            <div className="flex justify-between py-2 px-2 md:px-6 bg-white rounded-xl gap-2 w-full">
              <span className="w-full flex flex-col items-center justify-center border-r-2 border-[#EBF0ED] px-2">
                <p className="font-extrabold text-base md:text-3xl">$200</p>
                <p className="font-semibold text-[6px] md:text[9px] text-[#6B6B6B] ">
                  Total Spend
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center border-r-2 border-[#EBF0ED] ">
                <p className="font-extrabold text-base md:text-3xl">1982</p>
                <p className="font-semibold text-[6px] md:text[9px] text-[#6B6B6B]">
                  Ckicks
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center  ">
                <p className="font-extrabold text-base md:text-3xl">4553</p>
                <p className="font-semibold text-[6px] md:text[9px] text-[#6B6B6B]">
                  IMPRESSIONS
                </p>
              </span>
            </div>
          </div>

          <div className="w-full flex items-center gap-2 justify-center border rounded-lg border-[#EBF0ED] p-2 ">
            <div className="">
              <img
                className="w-24 h-24 md:w-36 md:h-36"
                src="/assets/images/undraw_outer_space_re_u9vd 1.svg"
                alt=""
              />
            </div>
            <div className="flex flex-col gap-2 py-2">
              <h2 className="text-sm md:text-base font-semibold">
                Boost Your Store!
              </h2>
              <span className="text-xs md:text-sm font-[400]">
                Boost your sales with our product ads or create your own custom
                ads that suits you.
              </span>
            </div>
          </div>
        </div>
        {/* preview */}
        <div className="md:py-4 ">
          <div className="flex gap-2 md:gap-10">
            {/* 1st card */}

            {defaults.map((item) => {
              return (
                <div
                  onClick={() => {
                    document.getElementById("my_modal_10").showModal();
                    setPayload({...payload, uid: item.uid});
                  }}
                  className="cursor-pointer border w-full py-6 flex flex-col md:flex-row items-center justify-center gap-2 lg:gap-5 px-4 border-[#EBF0ED] rounded-2xl"
                >
                  <img
                    src="/assets/icons/rocket.svg"
                    className="w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20"
                    alt=""
                  />
                  <div className="flex flex-col items-center md:items-start gap-1">
                    <span className="text-sm  md:text-base lg:text-[1.3rem] text-[#18120F] font-semibold">
                      {item.package_name}
                    </span>
                    <span className="text-[#C19A6B] text-center md:text-start text-xs md:text-sm lg:text-base font-bold tracking-wide">
                      {item.price} per day for {item.time_span} day
                    </span>
                  </div>
                </div>
              );
            })}

            {/* 2nd card */}
          </div>
        </div>
        {/*  */}
        <div className="flex flex-col md:gap-3 mt-3">
          <h2 className="text-base font-semibold">Ads History List</h2>
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Order …"
            />
          </div>
        </div>

        <div className="flex items-center text-xs md:textsm justify-between md:mt-2">
          <div className="flex gap-6">
            <NavLink
              onClick={() => setActiveRole("")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
              }`}
            >
              All Store Booster
              <span
                className={` font-semibold p-1 md:py-2 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
                }`}
              >
                {/* {data?.Boosters_pagination?.total} */}
                {data.boostersPendingCount +
                  data.boostersApprovedCount +
                  data.boostersRejectedCount +
                  data.boostersPausedCount +
                  data.boostersEndedCount}
              </span>
            </NavLink>

            <NavLink
              onClick={() => setActiveRole("active")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "active"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              ACTIVE
              <span
                className={` font-semibold p-1 md:py-2 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "active"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data.boostersApprovedCount}
              </span>
            </NavLink>

            <NavLink
              onClick={() => setActiveRole("ended")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "ended"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              ENDED
              <span
                className={` font-semibold p-1 md:py-2 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "ended"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data.boostersEndedCount}
              </span>
            </NavLink>
          </div>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[568px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[15%] text-left">START DATE</span>
                <span className="w-[15%] text-left"> END DATE</span>
                <span className="w-[20%] text-left">LOCATION</span>
                <span className="w-[20%] text-left">CATEGORY</span>
                <span className="w-[15%] text-left">STATUS</span>
                <span className="w-[15%] text-left">AD COST</span>
              </div>

              {/* Table Body */}
              {data?.Boosters?.length>0? data.Boosters.map((order) => (
                <div
                  key={order.id}
                  className="flex text-[10px] font-semibold md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                >
                  <span className="w-[15%] text-left">
                    {moment(order.start_date).format("YYYY MM DD")}
                  </span>

                  <span className="w-[15%] text-left">
                    {moment(order.end_date).format("YYYY MM DD")}{" "}
                  </span>
                  <span className="w-[20%] text-left">
                    {order.country + " " + order.city}
                  </span>
                  <span className="w-[20%] text-left">{order.category}</span>
                  <div className="w-[15%] text-left ">
                    <span
                      // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                      className={`${
                        order.status === "0"? "bg-[#FFFBF0]  py-1 px-3 border border-[#FEC600] text-[#FEC600] rounded-lg"
                        : order.status === "1"? "bg-[#F6FFF7]  py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                        : order.status === "2"? "bg-[#FFF3F4]  py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                        : order.status === "3"? "bg-[#F1F7FF]  py-1 px-3 border border-[#44A6E9] text-[#44A6E9] rounded-lg"
                        : "bg-[#FFF3F4]  py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                      }`}
                    >
                      {order.status == "0"? "Pending"
                        : order.status == "1"? "Active"
                        : order.status == "2"? "Rejected"
                        : order.status == "3"? "Paused"
                        : "Ended"}
                    </span>
                  </div>

                  <div className=" w-[15%] text-left  ">${order.price}</div>
                </div>
              )):<>Boosters Not Found</>}
            </div>
          </div>
        </div>

        {/* Pagination */}

        {!loading &&
        data &&
        data?.Boosters_pagination?.total > 10 ? (
          <div className="flex flex-col md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              {/* SHOWING {data.Boosters_pagination.from + "-" + data.Boosters_pagination.to} OF{" "}
              {data.Boosters_pagination.total} */}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/*  */}
      </div>
      <StoreBoostModal payload={payload} setPayload={setPayload} />
    </>
  );
}
