import MyShopLayout from "../../../../Vender-Dashboard-Layout/MyShopLayout";
import TrackOrder from "../../../../components/MyShop/Orders-Management/OrderTrack";

export default function OrderTrackPage() {
  return (
    <>
      <MyShopLayout>
        <TrackOrder />
      </MyShopLayout>
    </>
  );
}
