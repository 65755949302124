import BreadCrums from "../../Common/BreadCrums";

export default function TrackOrder() {
  return (
    <>
      <div className="px-5 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Order",
              path: "/vendor/my-shop/orders-management",
            },
            {
              name: "Order View",
              path: "/vendor/my-shop/orders-management/order-view",
            },
            {
              name: "Track Order",
              path: "",
            },
          ]}
        />

        {/* map */}
        <div></div>
      </div>
    </>
  );
}
