import React, { useEffect } from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import HelpCenter from "../../../components/MyShop/Seller-Support-Components/HelpCenter";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList, getSellerSupportFaqs } from "../../../store/vendorslice";

export default function HelpCenterPage() {
  const { ticketCategoryList, sellerSupportFaqs } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getCategoryList())
    dispatch(getSellerSupportFaqs())
  }, [])
  

  return (
    <>
      <MyShopLayout>
        <HelpCenter sellerSupportFaqs={sellerSupportFaqs?.faq}  categoriesList={ticketCategoryList?.categories} />
      </MyShopLayout>
    </>
  );
}
