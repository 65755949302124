import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function StoreInfo() {
  return (
    <div className="flex md:flex gap-3 items-center bg-white card-shadow rounded-xl py-2 px-4 max-w-[278px] xl:max-w-[461px]">
      <div className=" ">
        <img
          className="card-shadow rounded-full max-h-[71px] max-w-[71px] xl:max-h-[118px] xl:max-w-[118px] mx-auto"
          src="/assets/images/reviews/owner-shop-1.png"
          alt=""
        />
      </div>
      <div className=" py-2">
        <div className="flex space-x-4 items-start">
          <div className="text-sm xl:text-[23px] font-bold">Gwen Star</div>
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 h-3 xl:w-5 xl:h-5"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M9.70683 8.62526C8.98289 8.79909 8.22715 8.79067 7.50726 8.60075C6.78738 8.41083 6.12584 8.04534 5.58187 7.53701C5.0379 7.02868 4.6285 6.39337 4.39031 5.68799C4.15213 4.9826 4.0926 4.22915 4.21706 3.49512C4.19813 3.5134 4.17814 3.53055 4.15721 3.54649C4.00891 3.65931 3.82353 3.70115 3.45278 3.78483L3.11645 3.8611C1.81353 4.15611 1.16206 4.30336 1.00688 4.80175C0.85222 5.29962 1.29606 5.8192 2.18428 6.85784L2.41414 7.12637C2.66626 7.42138 2.79284 7.56915 2.84951 7.75135C2.90618 7.93407 2.88712 8.1311 2.84898 8.52463L2.81403 8.8832C2.68003 10.2693 2.61276 10.9621 3.01847 11.2698C3.42418 11.578 4.03433 11.2973 5.25357 10.7354L5.56977 10.5902C5.91616 10.4303 6.08935 10.3508 6.27314 10.3508C6.45693 10.3508 6.63012 10.4303 6.97704 10.5902L7.29218 10.7354C8.51195 11.2968 9.1221 11.5775 9.52728 11.2703C9.93352 10.9621 9.86625 10.2693 9.73225 8.8832L9.70683 8.62526Z"
                fill="#001AFF"
              />
              <path
                opacity="0.5"
                d="M4.76634 2.60874L4.59262 2.92017C4.40195 3.26232 4.30661 3.4334 4.15831 3.54621C4.17949 3.53033 4.19909 3.51338 4.21816 3.49484C4.09367 4.22893 4.15319 4.98244 4.3914 5.68788C4.62961 6.39332 5.03907 7.02866 5.58311 7.53701C6.12714 8.04536 6.78877 8.41083 7.50872 8.60071C8.22868 8.79058 8.98449 8.79892 9.70846 8.62498L9.69787 8.52435C9.66027 8.13083 9.6412 7.9338 9.69787 7.75107C9.75454 7.56887 9.8806 7.4211 10.1332 7.12609L10.3631 6.85756C11.2513 5.81945 11.6952 5.29987 11.54 4.80147C11.3853 4.30308 10.7339 4.15531 9.43093 3.86082L9.09407 3.78456C8.72385 3.70087 8.53848 3.65903 8.38965 3.54621C8.24135 3.4334 8.14601 3.26232 7.95534 2.92017L7.78214 2.60874C7.11108 1.40539 6.77582 0.803711 6.27424 0.803711C5.77267 0.803711 5.4374 1.40539 4.76634 2.60874Z"
                fill="#001AFF"
              />
            </svg>
          </div>
        </div>
        <div className="flex gap-2 items-center mt-1">
          <div className="rating rating-xs space-x-1 ">
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-yellow-500 max-w-[9px] max-h-[9px] xl:max-w-[15px] xl:max-h-[15px]"
              readOnly
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-yellow-500 max-w-[9px] max-h-[9px] xl:max-w-[15px] xl:max-h-[15px]"
              readOnly
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-yellow-500 max-w-[9px] max-h-[9px] xl:max-w-[15px] xl:max-h-[15px]"
              checked
              readOnly
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-yellow-500 max-w-[9px] max-h-[9px] xl:max-w-[15px] xl:max-h-[15px]"
              readOnly
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-yellow-500 max-w-[9px] max-h-[9px] xl:max-w-[15px] xl:max-h-[15px]"
              readOnly
            />
          </div>
          <div className="text-[#8C8C8C] text-xs xl:text-xl">(92)</div>
        </div>

        <div className="flex gap-4 w-full mt-1">
          <Link
            to="/vendor-shop-front"
            className="border border-[#C19A6B]  py-1 px-[22px] xl:py-[7px] xl:px-[36px] rounded-md bg-white text-[#C19A6B] text-nowrap font-bold text-[10px] xl:text-[17px] text-center tracking-wide flex items-center"
          >
            Visit Store
          </Link>

          {/* heart and person icon */}
          <div className="flex justify-around w-full items-center gap-2">
            <div className="flex flex-col items-center ">
              <AiOutlineHeart className="text-[#828282] text-xl w-[11px] h-[11px]  xl:w-[18px] xl:h-[18px]" />
              <p className="text-[10px]  xl:text-base text-[#828282]">88k</p>
            </div>
            <div className="flex flex-col items-center  ">
              <AiOutlineUser className=" text-[#828282]  w-[11px] h-[11px]  xl:w-[18px] xl:h-[18px]" />
              <p className="text-[10px] xl:text-base text-center text-[#828282]">
                23.5k
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
