import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./product-card.css";

export default function ProdCard({ item }) {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [selectVarient, setSelectVarient] = useState(false);
  const [cartButtonOne, setCartButtonOne] = useState(true);
  const [itemsQuantity, SetItemsQuantity] = useState(1);
  const [itemsQuantityBadge, SetItemsQuantityBadge] = useState(false);
  const [cartButtonSecond, setCartButtonSecond] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCartButtonOne = () => {
    setSelectVarient(true);
    setCartButtonOne(false);
  };

  const handleVarientButtons = () => {
    setSelectVarient(false);
    SetItemsQuantityBadge(true);
    setCartButtonSecond(true);
  };

  const handlePlus = () => {
    SetItemsQuantity(itemsQuantity + 1);
  };
  const handleMinus = () => {
    if (itemsQuantity === 1) {
    } else {
      SetItemsQuantity(itemsQuantity - 1);
    }
  };

  const handleCartButtonSecond = () => {
    setCartButtonSecond(false);
    SetItemsQuantityBadge(false);
    setCartButtonOne(true);
  };

  return (
    <div
      className={`card border-none card-shadow relative  cursor-pointer max-w-[240px] `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* heart and discount start here */}
      <div
        className={`rating gap-1 absolute right-2 top-2 flex justify-center items-center md:p-1 lg:p-2 bg-[#1983FF33] rounded-full w-[26px] h-[26px] md:w-8 md:h-8 lg:w-9 lg:h-9 ${
          isHeartFilled
            ? "text-blue-500 duration-500 ease-in-out"
            : "text-white"
        }`}
        onClick={handleHeartClick}
      >
        <FaHeart className="w-[17px] h-[15px] md:w-[21px] md:h-[18px] lg:w-6 lg:h-[21px] cursor-pointer" />
      </div>

      <div className={` gap-1 absolute left-2 mt-2 `}>
        <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 py-[1.7px] px-[6.9px] md:py-[2px] md:px-[8.5px]  lg:px-[10px] rounded-[22px] md:rounded-[28px] lg:rounded-[31px] text-[9px] md:text-[11px] lg:text-[13px]  font-bold">
          {item.discount}
        </p>
      </div>
      {/* heart and discount end here */}

      {/* image start here  */}
      <Link>
        <img src={item.image} alt="Shoes" className="max-h-[282px] w-full" />
      </Link>
      {/* Image end here */}
      <div
        className={` absolute bottom-[6.5rem] xl:bottom-[7rem] flex w-full gap-1 items-center justify-center px-3 md:px-1 xl:px-3 ${
          selectVarient ? " flex slide-from-right" : "hidden slide-to-left"
        }`}
      >
        <div
          onClick={handleVarientButtons}
          className="bg-white py-[1.46px] px-[7.2px] md:py-[1.79px] md:px-[8.9px] xl:py-[2px] xl:px-[10px] rounded-full text-[11px] md:text-[11.7px] xl:text-[13px]  font-bold text-black"
        >
          100ml
        </div>
        <div
          onClick={handleVarientButtons}
          className="bg-white py-[1.46px] px-[7.2px] md:py-[1.79px] md:px-[8.9px] xl:py-[2px] xl:px-[10px]  rounded-full text-[11px] md:text-[11.7px] xl:text-[13px] font-bold text-black"
        >
          300ml
        </div>
        <div
          onClick={handleVarientButtons}
          className="bg-white py-[1.46px] px-[7.2px] md:py-[1.79px] md:px-[8.9px] xl:py-[2px] xl:px-[10px]  rounded-full text-[11px] md:text-[11.7px] xl:text-[13px] font-bold text-black"
        >
          600ml
        </div>
      </div>

      <div
        className={`flex w-full justify-center items-center absolute bottom-[6.5rem] xl:bottom-[7rem] ${
          itemsQuantityBadge ? " flex slide-from-right" : "hidden"
        }`}
      >
        <div className="flex items-center   px-4  bg-white rounded-full ">
          <div onClick={handleMinus} className="px-1 mr-1">
            -
          </div>
          <div className="px-1">{itemsQuantity}</div>
          <div onClick={handlePlus} className="px-1 ml-1">
            +
          </div>
        </div>
      </div>

      <div className="px-3 py-2 flex flex-col gap-[2px]">
        <Link>
          <h2 className="font-semibold text-xs md:text-sm lg:text-base overflow-hidden text-nowrap">
            {item.name}
          </h2>
        </Link>
        <div className="h-4 flex items-center">
          <div
            className={`  ${
              isHovered
                ? "hidden"
                : "flex gap-2 items-center slide-from-top w-fit "
            }`}
          >
            <div className="rating rating-xs space-x-1 h-[9px] md:h-[10px] lg:h-3 ">
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400 max-w-2 max-h-2"
              />
            </div>
            <div className="text-[#8C8C8C] text-[7px] md:text-[8px] lg:text-[10px]">
              224
            </div>
          </div>
          <div
            className={`${
              isHovered
                ? "flex items-center gap-1 slide-from-bottom "
                : "hidden "
            }`}
          >
            <img src="./assets/images/top-sell/review-avatar.png" alt="" />
            <p className="text-[10px] overflow-hidden text-nowrap">
              I love this product it smells...
            </p>
          </div>
        </div>

        <div className="card-actions justify-between items-center ">
          <div className="flex items-center gap-1">
            <h5 className="font-semibold  text-[13px] md:text-sm lg:text-base">
              ${item.price}.99
            </h5>
            <span className=" text-[#8C8C8C] font-medium text-[9px] md:text-[11px] lg:text-xs">
              $27.99
            </span>
          </div>
          <div
            onClick={handleCartButtonOne}
            className={` rounded-md bg-white text-[#1983FF]  cursor-pointer h-[18px] w-[17px] md:h-[22px] md:w-[21px] lg:h-[25px] lg:w-[24px] ${
              cartButtonOne ? "block" : "hidden"
            }`}
          >
            <img
              src="/assets/images/top-sell/top-sell-cart-icon.png"
              alt="cart-button"
              className="w-full h-full"
            />
          </div>
          <div
            onClick={handleCartButtonSecond}
            className={`  rounded-md bg-white text-[#1983FF] text-xs cursor-pointer h-[18px] w-[17px] md:h-[22px] md:w-[21px] lg:h-[25px] lg:w-[24px] ${
              cartButtonSecond ? "block" : "hidden"
            }`}
          >
            <img
              src="/assets/images/top-sell/plus-button.png"
              alt="cart-button"
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
