import React, { useState } from "react";

export default function LocationModal({ locationModal, setLocation }) {
  // State to track the selected country
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // Data structure for countries and their cities
  const countryCities = {
    Pakistan: ["Peshawar", "Islamabad", "Karachi", "Lahore", "Swabi", "Mardan"],
    India: ["Delhi", "Mumbai", "Kolkata", "Chennai", "Bangalore", "Hyderabad"],
    USA: [
      "New York",
      "Los Angeles",
      "Chicago",
      "Houston",
      "Phoenix",
      "Philadelphia",
    ],
    UAE: [
      "Dubai",
      "Abu Dhabi",
      "Sharjah",
      "Ajman",
      "Fujairah",
      "Ras Al Khaimah",
    ],
    Russia: [
      "Moscow",
      "Saint Petersburg",
      "Novosibirsk",
      "Yekaterinburg",
      "Kazan",
      "Chelyabinsk",
    ],
    England: [
      "London",
      "Birmingham",
      "Manchester",
      "Liverpool",
      "Leeds",
      "Newcastle",
    ],
    Brazil: [
      "São Paulo",
      "Rio de Janeiro",
      "Brasília",
      "Salvador",
      "Fortaleza",
      "Belo Horizonte",
    ],
  };

  // Function to handle the selection of a country
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };
  const handleCitySelect = (city) => {
    setSelectedCity(city);
  };

  return (
    <>
      <div className={` ${locationModal ? "block" : "hidden"} `}>
        <div
          onMouseLeave={() => setLocation(false)}
          className="fixed w-[90%] md:w-[25%]  top-[186px] z-50 ml-2 md:ml-20  bg-white  rounded-2xl border-[#D9D9D9] p-2 md:p-4"
        >
          <div className="w-full flex justify-between text-[#8a8a8a]">
            {/* Render countries */}
            <div className="w-full border-r border-gray-500 px-5">
              <div className="text-sm font-bold tracking-wide flex items-center gap-3 ">
                <span>Country</span>
              </div>
              <div className="mt-4 mb-6 text-sm font-[300]">
                {Object.keys(countryCities).map((country, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer px-2 rounded-md ${
                      selectedCountry === country ? "bg-gray-200 " : ""
                    }`}
                    onMouseEnter={() => handleCountrySelect(country)}
                  >
                    {country}
                  </div>
                ))}
              </div>
            </div>
            {/* Render cities based on selected country */}
            <div className="w-full  px-5">
              <div className="text-sm font-bold tracking-wide flex items-center gap-3 ">
                <span>City</span>
              </div>
              <div className="mt-4 mb-6 text-sm font-[300]">
                {selectedCountry &&
                  countryCities[selectedCountry].map((city, index) => (
                    <div
                      onMouseEnter={() => handleCitySelect(city)}
                      key={index}
                      className={`cursor-pointer px-2 rounded-md ${
                        selectedCity === city ? "bg-gray-200 " : ""
                      }`}
                    >
                      {city}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
