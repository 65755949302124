import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

export default function ChatSettingQuickReply() {
  const [savedTexts, setSavedTexts] = useState([]);
  const [inputText, setInputText] = useState("");

  const handleSave = () => {
    if (inputText.trim() !== "") {
      setSavedTexts([...savedTexts, inputText]);
      setInputText("");
    }
  };

  const handleDelete = (index) => {
    const newSavedTexts = [...savedTexts];
    newSavedTexts.splice(index, 1);
    setSavedTexts(newSavedTexts);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent textarea from creating new line
      handleSave();
    }
  };

  return (
    <>
      <div className="w-full flex flex-col gap-5 border border-[#EBF0ED] rounded-xl py-4 md:py-8 px-4 h-fit">
        {/* Display saved texts */}
        <div className="flex gap-2">
          {savedTexts.map((text, index) => (
            <div
              key={index}
              className="border flex gap-2 border-[#EBF0ED] rounded-xl text-xs font-[400] text-[#6B6B6B] px-4 py-[0.7rem] w-fit "
            >
              <span>{text}</span>
              <button
                className="text-red-500"
                onClick={() => handleDelete(index)}
              >
                <RxCross2 />
              </button>
            </div>
          ))}
        </div>
        {/* Input field */}
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown} // Add keydown event listener
          className="w-full border outline-none border-[#EBF0ED] bg-[#FAFAFA] h-16 md:h-24 rounded-xl text-[#6B6B6B] text-xs font-[400] px-3 pt-2"
          placeholder="Quick Reply Option ..."
        ></textarea>
        {/* Save button */}
        <div className="text-end">
          <button
            onClick={handleSave}
            className="text-white bg-[#C19A6B] px-5 md:px-9 py-[7px] md:py-[0.625rem] text-sm font-semibold rounded-lg"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
