import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import BreadCrums from "../../../Common/BreadCrums";
import "./input.css";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import {
  clearMessage,
  fetchAllMainCategories,
  getCitiesByCountries,
  getCountrie,
  vendorStoreBoosterCustom,
} from "../../../../store/vendorslice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function CustomBoosterSetting() {
  const [dropdown, setDropdown] = useState(false);
  const [dropdownCountry, setDropdownCountry] = useState(false);
  const [dropdownCity, setDropdownCity] = useState(false);

  const [selectCategory, setSelectCategory] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  // const [aayload,setPayload]=useState({name})
  const { cities, countries, states, category, message, error } = useSelector(
    (state) => state.admin
  );
  const navigate = useNavigate();
  const [savedTexts, setSavedTexts] = useState([]); //tag
  const [inputText, setInputText] = useState("");
  const [payload, setPayload] = useState({
    name: null,
    start_date: null,
    end_date: null,
    tags: [],
    price: null,
    country: null,
    city: null,
    category_id : null
  });

  // const toggleDropdown = (id) => {
  //   if (id === "1") {
  //     setDropdownCountry(!dropdownCountry);
  //     setDropdownCity(false);
  //     setDropdown(false);
  //   }
  //   if (id === "2") {
  //     setDropdownCity(!dropdownCity);
  //     setDropdownCountry(false);
  //     setDropdown(false);
  //   }
  //   if (id === "3") {
  //     setDropdown(!dropdown);
  //     setDropdownCountry(false);
  //     setDropdownCity(false);
  //   }
  // };

  const handleCountrySelect = (country) => {
    // setSelectCountry(country);
    dispatch(getCitiesByCountries(country));
    setPayload({ ...payload, country:country });
    setDropdownCountry(false);
  };

  const handleCitySelect = (city) => {
    // setSelectCity(city);
    setPayload({ ...payload, city:city });
    setDropdownCity(false);
  };

  const handleCategorySelect = (category) => {
    // setSelectCategory(category);
    setPayload({ ...payload, category_id: category });
    setDropdown(false);
  };

  const handleDelete = (index) => {
    const newSavedTexts = [...savedTexts];
    newSavedTexts.splice(index, 1);
    setPayload({ ...payload, tags: newSavedTexts });
    setSavedTexts(newSavedTexts);
  };

  const handleSave = () => {
    if (inputText.trim() !== "") {
      setSavedTexts([...savedTexts, inputText]);
      payload.tags.push(inputText)
      // setPayload({ ...payload, keyword: savedTexts });
      setInputText("");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent textarea from creating new line
      handleSave();
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (
      payload.start_date &&
      payload.end_date &&
      payload.name &&
      payload.tags &&
      payload.price &&
      payload.country &&
      payload.city &&
      payload.category_id && payload.tags.length>0
    ) {
      dispatch(vendorStoreBoosterCustom(payload));
    } else {
      toast.error("Kindly Fill All Fields");
    }
  };

  useEffect(() => {
    dispatch(getCountrie());
  }, []);

  useEffect(() => {
    setSelectCity(cities);
    setSelectCountry(countries);
    dispatch(fetchAllMainCategories());
  }, [cities, countries]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate(-1);
    } else if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [message, error]);

  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        {/* name */}
        <div className="flex flex-col gap-2 md:gap-0 md:flex-row  md:items-center md:justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "Marketing Solution",
                path: "/vendor/booster/store-boost",
              },
              {
                name: "Store Booster",
                path: "/vendor/booster/store-boost",
              },
              {
                name: "Custom Booster Setting",
                path: "/vendor/booster/store-boost/custom-booster-setting",
              },
            ]}
          />
          <div className="text-end">
            <Link
              // to="/vendor/booster/store-boost"
              className="bg-[#C19A6B] text-xs md:text-sm py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg text-white"
              onClick={() => handleSubmit()}
            >
              Boost Store
            </Link>
          </div>
        </div>
        {/* offers detail */}
        <div className="border border-[#EBF0ED] rounded-xl px-4 py-4 md:py-8">
          <div>
            <h2 className="text-base md:text-lg  md:mb-4  font-semibold text-[#18120F]">
              Custom Boost Setting
            </h2>
          </div>
          {/* first */}
          <div className="flex gap-5">
            {/* left */}
            <div className="w-full md:w-1/2 flex flex-col gap-3">
              <div className="flex flex-col md:flex-row gap-2">
                <div className="flex flex-col gap-1 w-full">
                  <label
                    className="text-[10px] md:text-xs font-semibold text-[#18120F]"
                    htmlFor=""
                  >
                    Start Date
                  </label>
                  <input
                    className="border outline-none w-full py-2 md:py-3 px-3 text-xs border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                    placeholder=""
                    type="date"
                    onChange={(e) => {
                      setPayload({ ...payload, start_date: e.target.value });
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <label
                    className="text-[12px] font-semibold text-[#18120F] "
                    htmlFor=""
                  >
                    End Date
                  </label>
                  <input
                    className="border  outline-none w-full py-2 md:py-3 px-3 text-xs  border-[#EBF0ED] rounded-lg bg-[#FAFAFA] disabled"
                    placeholder=" "
                    type="date"
                    onChange={(e) => {
                      setPayload({ ...payload, end_date: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="flex gap-2">
                <div className="w-full ">
                  <h2 className="text-[12px] font-semibold text-[#18120F]">
                    Country
                  </h2>
                  <div className="relative flex flex-col gap-2">
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 placeholder:text-black px-3 outline-none cursor-pointer"
                        onChange={(e) => handleCountrySelect(e.target.value)}
                      >
                        <option value="">none</option>
                        {selectCountry.length > 0 &&
                          selectCountry.map((c) => {
                            return <option value={c.id}>{c.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-full cursor-pointer">
                  <h2 className="text-[12px] font-semibold text-[#18120F]">
                    City
                  </h2>
                  <div className="relative flex flex-col gap-2">
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 placeholder:text-black px-3 outline-none cursor-pointer"
                        onChange={(e) => handleCitySelect(e.target.value)}
                      >
                        <option value="">none</option>
                        {cities.length > 0 &&
                          cities.map((c) => {
                            return <option value={c.id}>{c.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <h2 className="text-[12px] font-semibold text-[#18120F]">
                  Category
                </h2>
                <div className="relative flex flex-col gap-2 cursor-pointer">
                  <div className="relative w-full">
                    <select
                      className="bg-[#FAFAFA] rounded-lg w-full py-2 placeholder:text-black px-3 outline-none cursor-pointer"
                      onChange={(e) => handleCategorySelect(e.target.value)}
                    >
                      <option value="">none</option>
                      {category.length > 0 &&
                        category.map((c) => {
                          return <option value={c.id}>{c.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <label className="font-[500] text-xs" htmlFor="">
                  Keywords
                </label>
                <div className="w-full flex flex-col gap-5 border border-[#EBF0ED] rounded-xl p-2 md:p-4 h-fit">
                  {/* Display saved texts */}

                  <div className="flex flex-wrap gap-2">
                    {savedTexts.map((text, index) => (
                      <div
                        key={index}
                        className="border flex  gap-2 border-[#EBF0ED] rounded-xl text-xs font-[400] text-[#6B6B6B] px-4 py-[0.7rem] w-fit "
                      >
                        <span>{text}</span>
                        <button
                          className="text-red-500"
                          onClick={() => handleDelete(index)}
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    ))}
                  </div>
                  {/* Input field */}

                  <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={handleKeyDown} // Add keydown event listener
                    className="w-full border placeholder:text-black resize-none outline-none border-[#EBF0ED] bg-[#FAFAFA] py-2 md:py-3 px-3 text-xs rounded-xl text-[#6B6B6B]  font-[400]  "
                    placeholder="Enter Tags"
                  ></textarea>
                </div>
              </div>

              <div className="numberInput">
                <h2 className="text-[12px] font-semibold text-[#18120F]  ">
                  Booster Name
                </h2>
                <input
                  type="text"
                  className="w-full outline-none placeholder:text-black rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-[12px] font-[400] py-2 md:py-3 px-3 text-xs"
                  placeholder="moderate"
                  value={payload.name}
                  onChange={(e) => {
                    setPayload({ ...payload, name: e.target.value });
                  }}
                />
              </div>
              <div className="numberInput">
                <h2 className="text-[12px] font-semibold text-[#18120F]  ">
                  Boost Cost
                </h2>
                <input
                  type="number"
                  className="w-full outline-none placeholder:text-black rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-[12px] font-[400] py-2 md:py-3 px-3 text-xs"
                  placeholder="$2500"
                  onChange={(e) => {
                    setPayload({ ...payload, price: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
