import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import JoinOfferModal from "./JoinOfferModal";
import BreadCrums from "../../../Common/BreadCrums";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  joinOfferProductPost,
} from "../../../../store/vendorslice";

export default function JoinOffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector((state) => state.admin);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [temp, setTemp] = useState(true);

  const handleSelectedProducts = (products) => {
    setSelectedProducts(products);
    console.log("Received selected products:", products);
  };

  const handleDeleteProduct = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );
    setSelectedProducts(updatedProducts);
  };

  const handleProductSubmit = () => {
    if (selectedProducts === null) {
      toast.error("Please add some Products");
    } else {
      const productIds = selectedProducts.map((product) => product.id);
      console.log("id", id,productIds);

      dispatch(joinOfferProductPost({id:id,data:{product_id:productIds}}));
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      navigate(`/vendor/booster/offers`);
    }

    if (error) {
      if (typeof error === "object" && Object.keys(error).length > 0) {
        const firstKey = Object.values(error)[0];
        if (Array.isArray(firstKey) && firstKey.length > 0) {
          toast.error(firstKey[0]);
        } else {
          toast.error("An unknown error occurred.");
        }
      }
      else if (typeof error === "string") {
        toast.error(error);
      }
      else {
        toast.error("An unknown error occurred.");
      }
    }
    dispatch(clearMessage());
  }, [error, message, loading]);

  const handleAddProduct = () => {
    setTemp(false);
    document.getElementById("my_modal_4").showModal()
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* Header */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "Join Offers",
                path: -1,
              },
            ]}
          />

          <div className="flex gap-4 items-center">
            <button
              onClick={handleAddProduct}
              className="border border-[#EBF0ED] bg-[#FAFAFA] text-[9px] md:text-[11px] font-semibold text-[#828282] rounded-lg px-[10px] md:px-[20px]  py-[8px] md:py-[10px]"
            >
              Add Product
            </button>
            {selectedProducts.length > 0 ? (
              <>
                <button
                  onClick={() => handleProductSubmit()}
                  disabled={loading}
                  className="border text-white border-[#C19A6B] bg-[#C19A6B]  text-[9px] md:text-[11px]  rounded-lg px-[10px] md:px-[20px]  py-[8px] md:py-[10px]"
                >
                  {!loading ? "Submit" : "Submitting"}
                </button>
              </>
            ) : (
              <>
                <div className="cursor-not-allowed border text-white text-[9px] md:text-[11px] font-semibold border-[#C19A6B] bg-[#C19A6B] rounded-lg px-[10px] md:px-[20px]  py-[5px] md:py-[10px] opacity-15">
                  Submit
                </div>
              </>
            )}
          </div>
        </div>

        {/* Table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left">PRODUCT</span>
                <span className="w-[15%] text-left">STOCK</span>
                <span className="w-[20%] text-left">CATEGORY</span>
                <span className="w-[15%] text-left">ORIGINAL PRICE</span>
                <span className="w-[15%] text-left">OFFER PRICE</span>
                <span className="w-[15%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              <div>
                {selectedProducts.length > 0 ? (
                  <>
                    {selectedProducts.slice(0, 10).map((item, index) => (
                      <div
                        key={index}
                        className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                      >
                        <div className="w-[20%] text-left flex gap-2 items-center  font-[600] ">
                          <img
                            className="w-8 h-8 md:w-10 md:h-10"
                            src={item.image}
                            alt=""
                          />
                          <span className="font-semibold text-[#18120F] ">
                            {item.name}
                          </span>
                        </div>
                        <span className="w-[15%] text-left  font-semibold text-[#18120F] ">
                          {item.quantity}
                        </span>

                        <span className="w-[20%] text-left font-[600]">
                          {item.category?.name}
                        </span>
                        <span className=" w-[15%] text-left  font-semibold text-[#18120F] ">
                          ${item.price}
                        </span>

                        <span className=" w-[15%] text-left  font-semibold text-[#18120F] ">
                          ${item.discountprice}
                        </span>

                        <span
                          className="w-[15%] text-left text-[#C19A6B]  font-[500] cursor-pointer"
                          onClick={() => handleDeleteProduct(item.id)}
                        >
                          Delete
                        </span>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="text-center font-bold tracking-wide py-4">
                      <span colSpan={6}>No Products Founds</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Pagination */}

        {selectedProducts.length > 0 ? (
          <>
            {/* <div className="flex flex-col md:flex-row justify-between items-center mb-4">
              <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
                SHOWING {startIndex + 1} -{" "}
                {Math.min(startIndex + itemsPerPage, selectedProducts.length)}{" "}
                OF {selectedProducts.length} RESULTS
              </span> */}
              {/* <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
                <div className="py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]">
                  <svg
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed "
                        : "text-blue-500"
                    }`}
                    disabled={currentPage === 1}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className="flex items-center gap-2">
                  {Array.from({ length: totalPages }, (_, i) => i + 1)
                    .slice(0, 3)
                    .map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`py-[6px] px-[12px] md:py-[10px] md:px-[14.5px] text-lg   rounded ${
                          currentPage === page
                            ? "bg-[#C19A6B] text-white rounded-xl border border-[#EBF0ED]"
                            : " bg-[#FAFAFA] text-[#828282] rounded-xl border border-[#EBF0ED]"
                        }`}
                      >
                        {page}
                      </button>
                    ))}
                </div>

                <div className="py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]">
                  <svg
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    disabled={currentPage === totalPages}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div> */}
            {/* </div> */}
          </>
        ) : (
          <></>
        )}

      </div>
      <JoinOfferModal onSelect={handleSelectedProducts} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} temp={temp} setTemp={setTemp} />
    </>
  );
}
