import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import PremiumProductBoostSetting from "../../../../components/Booster/Marketing-Solutions/Premium-Product-Components/PremiumProductBoostSetting";

export default function PremiumProductBoostSettingPage() {
  return (
    <>
      <BoosterLayout>
        <PremiumProductBoostSetting />
      </BoosterLayout>
    </>
  );
}
