import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import AffilateProductsManage from "../../../components/MyShop/Affilate-Products-Components/AffilateProductsManage";

export default function AffilateProductManagementPage() {
  return (
    <>
      <MyShopLayout>
        <AffilateProductsManage />
      </MyShopLayout>
    </>
  );
}
