import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import ProductBoosterModal from "./ProductBoosterModal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  fetchAllMainCategories,
  getCitiesByCountries,
  getCountrie,
  listVendorProduct,
  vendorCustomBooster,
} from "../../../../store/vendorslice";
import { imagePath } from "../../../../functions/URLString";
import { toast } from "react-toastify";

export default function PreMadeAds() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cards, setCards] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [countryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [cityDropdownVisible, setCityDropdownVisible] = useState(false);
  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);
  const [recomendedProducts, setRecomendedProducts] = useState([]);
  const [selectedCardIds, setSelectedCardIds] = useState([]);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({
    uid: searchParams.get("uid"),
    product_ids: "",
    name: searchParams.get("name"),
    start_date: "",
    end_date: "",
    keyword: "",
    price: searchParams.get("price"),
    country: 0,
    city: 0,
    category_i: 0,
  });

  const [savedTexts, setSavedTexts] = useState([]); //tag
  const [inputText, setInputText] = useState("");
  const { cities, countries, states, message, data, loading, category, error } =
    useSelector((state) => state.admin);
  const countryOptions = ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1"];
  const cityOptions = ["2", "1", "3"];
  const categoryOptions = ["1", "2"];
  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
  };
  const toggleCategoryDropdown = () => {
    setCategoryDropdownVisible((prev) => !prev);
  };
  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
  };
  const selectCountry = (country) => {
    setSelectedCountry(country);
    setCountryDropdownVisible(false);
  };
  const selectCity = (city) => {
    setSelectedCity(city);
    setCityDropdownVisible(false);
  };
  const selectCategory = (category) => {
    setSelectedCategory(category);
    setCategoryDropdownVisible(false);
  };

  const handleSave = () => {
    if (inputText.trim() !== "") {
      setSavedTexts([...savedTexts, inputText]);
      setInputText("");
    }
  };
  const handleCountrySelect = (country) => {
    setPayload({ ...payload, country });
    dispatch(getCitiesByCountries(country));
  };

  const handleCitySelect = (city) => {
    setPayload({ ...payload, city: city });
  };
  const handleCategorySelect = (category) => {
    setPayload({ ...payload, category_id: category });
  };
  const handleDelete = (index) => {
    const newSavedTexts = [...savedTexts];
    newSavedTexts.splice(index, 1);
    setSavedTexts(newSavedTexts);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent textarea from creating new line
      handleSave();
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(listVendorProduct());
    dispatch(getCountrie());
    dispatch(fetchAllMainCategories());
  }, []);
  useEffect(() => {
    console.log(cards, "cart");
  }, [cards]);
  const handleSubmit = () => {
    var f = "";
    Array.isArray(savedTexts) &&
      savedTexts.forEach((item) => {
        f += item;
      });
    console.log(f);
    dispatch(
      vendorCustomBooster({
        product_ids: selectedCardIds,
        name: payload.name,
        start_date: payload.start_date,
        end_date: payload.end_date,
        tags: savedTexts,
        price: payload.price,
        country: payload.country,
        city: payload.city,
        category_id: payload.category_id,
        uid: payload.uid,
      })
    );
  };
  useEffect(() => {
    console.log(message);

    if (message) {
      toast.success(message);

      dispatch(clearMessage());
      navigate("/vendor/booster/product-booster");
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [message, error]);
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solution",
              path: "/vendor/booster/product-booster",
            },
            {
              name: "Product Booster",
              path: "/vendor/booster/product-booster",
            },
            {
              name: "PreMade Booster",
              path: "/vendor/booster/product-booster/custom-ad",
            },
          ]}
        />

        <div className="flex flex-col gap-2 md:gap-4">
          <div className="w-full flex justify-end">
            <Link
              // to="/vendor/booster/product-booster/payment"
              onClick={(e) => handleSubmit()}
              className="bg-[#C19A6B] text-white px-5 md:px-9 py-2 md:py-[10px] rounded-lg text-xs md:text-sm "
            >
              Publish Ad
            </Link>
          </div>
          <div className=" border border-[#EBF0ED] p-2 md:p-4 rounded-xl">
            <h1 className="text-base md:text-lg font-[500]">Ad Setting</h1>
            <div className="flex flex-col md:flex-row gap-4 ">
              <div className="w-full md:w-1/2 flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <label
                    className="font-[500] text-[10px] md:text-xs"
                    htmlFor=""
                  >
                    Ad Name
                  </label>
                  <input
                    className="border outline-none placeholder:text-black text-[10px] md:text-xs w-full py-2 md:py-3 px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                    placeholder="Ad name ... "
                    type="text"
                    value={payload.name}
                    onChange={(e) =>
                      setPayload({ ...payload, name: e.target.value })
                    }
                    readOnly
                  />
                </div>
                <div className="flex gap-2">
                  <div className="flex flex-col gap-1 w-full">
                    <label
                      className="font-[500] text-[10px] md:text-xs"
                      htmlFor=""
                    >
                      Start Date
                    </label>
                    <input
                      className="border outline-none placeholder:text-black text-[10px] md:text-xs w-full py-2 md:py-3 px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                      placeholder="Ad name ... "
                      type="date"
                      value={payload.start_date}
                      onChange={(e) =>
                        setPayload({ ...payload, start_date: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label
                      className="font-[500] text-[10px] md:text-xs "
                      htmlFor=""
                    >
                      End Date
                    </label>
                    <input
                      className="border outline-none opacity-30 placeholder:text-black text-[10px] md:text-xs w-full py-2 md:py-3 px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA] disabled"
                      placeholder="Ad name ... "
                      type="date"
                      value={payload.end_date}
                      onChange={(e) =>
                        setPayload({ ...payload, end_date: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1 numberInput">
                  <label
                    className="font-[500] text-[10px] md:text-xs "
                    htmlFor=""
                  >
                    daily Budget
                  </label>
                  <input
                    className="border outline-none placeholder:text-black text-[10px] md:text-xs w-full py-2 md:py-3 px-3 border-[#EBF0ED] rounded-lg bg-[#FAFAFA]"
                    placeholder="$400 "
                    type="number"
                    value={payload.price}
                    onChange={(e) =>
                      setPayload({ ...payload, price: e.target.value })
                    }
                    readOnly
                  />
                </div>
              </div>
              {/*  */}
              <div className="w-full md:w-1/2">
                <label className="font-[500] text-[10px] md:text-xs" htmlFor="">
                  Keywords
                </label>
                <div className="w-full flex flex-col gap-5 border border-[#EBF0ED] rounded-xl p-2 md:p-4 h-fit">
                  {/* Display saved texts */}

                  <div className="flex flex-wrap gap-2">
                    {savedTexts.map((text, index) => (
                      <div
                        key={index}
                        className="border flex  gap-2 border-[#EBF0ED] rounded-xl text-xs font-[400] text-[#6B6B6B] px-4 py-[0.7rem] w-fit "
                      >
                        <span>{text}</span>
                        <button
                          className="text-red-500"
                          onClick={() => handleDelete(index)}
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    ))}
                  </div>
                  {/* Input field */}

                  <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={handleKeyDown} // Add keydown event listener
                    className="w-full border placeholder:text-black resize-none outline-none border-[#EBF0ED] bg-[#FAFAFA]  rounded-xl text-[#6B6B6B]  font-[400] text-[10px] md:text-xs  py-1 md:py-3 px-3 pt-2"
                    placeholder="Enter Tags"
                  ></textarea>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          <div className="border border-[#EBF0ED]  rounded-xl w-full p-2 md:p-4">
            <h2 className="text-sm md:text-lg font-semibold">
              Where whould you like to boost
            </h2>
            <div className="flex flex-col md:flex-row gap-4">
              {/* Country */}
              <div className="w-full">
                <label className=" text-[10px] md:text-xs ml-1 my-1 font-semibold">
                  COUNTRY
                </label>
                <div className="relative w-full">
                  {countries.length > 0 && (
                    <select
                      name=""
                      id=""
                      onChange={(e) => handleCountrySelect(e.target.value)}
                      className="bg-[#FAFAFA] text-xs md:text-base placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                    >
                      <option value="">none</option>
                      {countries.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  )}
                </div>
              </div>
              {/* City */}
              <div className="w-full">
                <label className=" text-[10px] md:text-xs ml-1 my-1 font-semibold">
                  CITY
                </label>
                <div className="relative w-full">
                  <select
                    name=""
                    id=""
                    onChange={(e) => handleCitySelect(e.target.value)}
                    className="bg-[#FAFAFA] text-xs md:text-base placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                  >
                    <option value="">none</option>
                    {cities.length > 0 &&
                      cities.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-[#EBF0ED]  rounded-xl w-full p-2 md:p-4">
            <h2 className="text-sm md:text-lg font-semibold">
              Select product category in which you want to boost{" "}
            </h2>
            <div className="flex gap-4">
              {/* Category */}
              <div className="w-full">
                <label className=" text-[10px] md:text-xs ml-1 my-1 font-semibold">
                  CATEGORY
                </label>
                <div className="relative w-full">
                  {category.length > 0 && (
                    <select
                      name=""
                      id=""
                      onChange={(e) => handleCategorySelect(e.target.value)}
                      className="bg-[#FAFAFA] text-xs md:text-base placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                    >
                      <option value="">none</option>
                      {category.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border border-[#EBF0ED] flex flex-col gap-3  rounded-xl w-full p-4 mb-4">
            <div className="flex justify-between">
              <h2 className="text-sm md:text-lg font-semibold">Product List</h2>
              <button
                onClick={() =>
                  document.getElementById("my_modal_8").showModal()
                }
                className="bg-[#C19A6B] text-sm md:text-base text-white rounded-lg px-5 md:px-9 py-2 md:py-[10px] "
              >
                Change
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-10">
              {cards?.length > 0 &&
                cards?.map((card, index) => (
                  <>
                    <div className="flex gap-3 border  border-[#E1E1E1] rounded-2xl">
                      <img
                        className="w-20 h-20 rounded-s-2xl"
                        src={card.image}
                        alt=""
                      />
                      <div className="flex flex-col gap-1 py-1">
                        <h2 className="mb-1 text-xs md:text-sm font-semibold">
                          {card?.name && card.name}
                        </h2>
                        <span className="text-xs md:text-sm font-[500] text-[#6B6B6B]">
                          Category: {card?.name && card?.category?.name}
                        </span>
                        <span className="text-xs md:text-sm font-[500] text-[#6B6B6B]">
                          ID: {card?.name && card.price}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
      <ProductBoosterModal
        cards={cards}
        selectedCardIds={selectedCardIds}
        setSelectedCardIds={setSelectedCardIds}
        setCards={setCards}
        recomendedProducts={recomendedProducts}
        data={data}
        setRecomendedProducts={setRecomendedProducts}
      />
    </>
  );
}
