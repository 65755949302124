import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import PremiumCustomAd from "../../../../components/Booster/Marketing-Solutions/Premium-Product-Components/PremiumCustomAd";

export default function PremiumCustomAdPage() {
  return (
    <>
      <BoosterLayout>
        <PremiumCustomAd />
      </BoosterLayout>
    </>
  );
}
