import React from "react";
import Address from "../../components/Vendor-Profile-Creation/Address";

export default function AddressPage() {
  return (
    <div className="w-full">
      <Address />
    </div>
  );
}
