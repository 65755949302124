import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

import { useEffect, useState } from "react";
import EmailVerificationModal from "./EmailVerificationModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, storeStyleGet, storeTypeGet, vendorAccountCreation } from "../../store/vendorslice";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [storeLogoDisplay, setStoreLogoDisplay] = useState("");

  const [vendorName, setVendorName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeLogo, setStoreLogo] = useState("");
  const [selectedStoreType, setSelectedStoreType] = useState("");
  const [selectedMall, setSelectedMall] = useState(1);
  const [selectedStyleType, setSelectedStyleType] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, storeType, storeStyle, error, loading, step, vendorEmail } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    if (step == 1) {
      document.getElementById("my_modal_3").showModal();
    }
    if (vendorEmail) {
      setEmail(vendorEmail);
    }
    if (step == 2) {
      navigate("vendor/profile-creation/address");
    }
  }, [step, vendorEmail,navigate]);

  useEffect(()=>{
    dispatch(storeTypeGet());
    dispatch(storeStyleGet());
  },[dispatch])

  useEffect(() => {
    if (error) {
      Object.keys(error).map((key) => {
        console.log(error[key]);
        const errorMessage = error[key].join(" ");
        toast.error(errorMessage);
        return "";
      });
    }
    if (message === "Vendor registered Successfully") {
      toast.success(message);
      setStoreLogoDisplay("");
      setVendorName("");
      setStoreName("");
      setStoreLogo("");
      setSelectedStoreType("");
      setSelectedMall("");
      setSelectedStyleType("");

      document.getElementById("my_modal_3").showModal();
    }
    if (message === "Email and OTP verified successfully.") {
      navigate("/vendor/profile-creation/address");
    }
    dispatch(clearMessage());
  }, [message,error,dispatch,navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedMall);

    if ( storeName === "" ||
      vendorName === "" ||
      selectedStoreType === "" ||
      selectedStyleType === "" ||
      email === "" ||
      password === ""
    ) {
      toast.error("Input All Fields");
      console.log("1");
    } else {
      if (storeLogoDisplay === null || storeLogoDisplay === "") {
        toast.error("Select Logo");
        console.log("2");
      } else {
        console.log("3");
        const formData = new FormData();
        formData.append("name", vendorName);
        formData.append("store_name", storeName);
        formData.append("shop_picture", storeLogo);
        formData.append("storetype_id", selectedStoreType);
        formData.append("mall_id", selectedStyleType);
        formData.append("styletype_id", selectedStyleType);
        formData.append("email", email);
        formData.append("password", password);

        dispatch(vendorAccountCreation(formData));
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleStoreLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setStoreLogoDisplay(imageUrl);
      setStoreLogo(file);
    }
  };

  const handleStoreLogoDelete = () => {
    setStoreLogo(null);
    setStoreLogoDisplay(null);
  };

  const handleStoreType = (e) => {
    setSelectedStoreType(e.target.value);
  }

  const handleStoreStyle = (e) => {
    setSelectedStyleType(e.target.value);
  }

  useEffect(()=>{
    console.log(selectedStoreType,'---',selectedStyleType)
  },[selectedStyleType,selectedStoreType])

  return (
    <div className="flex justify-center w-full mt-14">
      <div className="flex flex-col gap-4 md:gap-16">
        <div className=" flex justify-center">
          <img
            className="w-[300px] md:w-[372px]"
            src="/assets/icons/progress-1.svg"
            alt=""
          />
        </div>
        <div className=" p-4 flex flex-col-reverse md:flex-row gap-16">
          <div className="flex flex-col gap-4">
            <span className="font-semibold text-sm md:text-base">
              Please complete the form below to start your own online business
              store!
            </span>

            <span className="font-bold text-lg md:text-2xl flex flex-col mt-3">
              <span>Hello Seller,</span>
              <span>How about we learn more about you?</span>
            </span>

            <form
              id="uploadForm"
              enctype="multipart/form-data"
              method="post"
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 w-full"
            >
              {/* vendor name  */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Enter Your Vendor Name
                </label>
                <input
                  type="text"
                  name="vendor_name"
                  placeholder="Vendor Name"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={(e) => setVendorName(e.target.value)}
                  value={vendorName}
                />
              </div>

              {/* store name  */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Enter Your Store Name
                </label>
                <input
                  type="text"
                  name="store_name"
                  placeholder="Store Name"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={(e) => setStoreName(e.target.value)}
                  value={storeName}
                />
              </div>

              {/* store logo  */}

              <div className="flex flex-col gap-1 h-20 w-24 cursor-pointer">
                <h2 className="text-xs font-semibold "> Store Logo</h2>
                <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                  <input
                    id="frontFileInput"
                    type="file"
                    accept="image/*"
                    className="hidden "
                    onChange={handleStoreLogoUpload}
                  />
                  {storeLogo ? (
                    <div className="relative w-full h-full">
                      <img
                        src={storeLogoDisplay}
                        alt="Uploaded"
                        className="w-full h-full object-cover rounded"
                      />

                      <span
                        className="absolute top-0 right-0 text-white p-1 rounded-full bg-[#FAFAFA]"
                        onClick={handleStoreLogoDelete}
                      >
                        <img
                          src="/assets/icons/close-modal.svg"
                          alt=""
                          className="w-3 h-3 !stroke-white  "
                        />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="flex flex-col items-center justify-center w-full"
                      onClick={() =>
                        document.getElementById("frontFileInput").click()
                      }
                    >
                      <span className="text-[0.75rem] font-[400] text-[#dddcdc]">
                        +
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {/* store type  */}
              <div className="flex flex-col gap-1 relative pt-3">
                <label htmlFor="" className="font-semibold text-xs">
                  Store Type
                </label>
                <select 
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs" 
                  id="options"  value={selectedStoreType} onChange={handleStoreType}>
                  <option className="text-gray-400" value="" disabled>Store Type</option>
                  {storeType?.data?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                  <option value="">None</option>
                </select>
              </div>

           
              {/* store style */}
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="" className="font-semibold text-xs">
                  Store Style
                </label>
                <select 
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs" 
                  id="options"  value={selectedStyleType} onChange={handleStoreStyle}>
                  <option className="text-gray-400" value="" disabled>Store Style</option>
                  {storeStyle?.data?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                  <option value="">None</option>
                </select>
              </div>

              {/* Number  */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Phone Number
                </label>
                <input
                  type="number"
                  name="number"
                  placeholder="Phone Number"
                  // onChange={updateData}
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                />
              </div>

              {/* Email  */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                />
              </div>

              {/*Password */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Password
                </label>
                <span className="flex items-center relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="******"
                    className="w-full bg-[#FAFAFA] text-xs border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  {passwordVisible ? (
                    <IoEyeOutline
                      className="absolute right-4 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <IoEyeOffOutline
                      className="absolute right-4 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </span>
              </div>

              {/* submit button  */}
              {!loading ? (
                <button
                disabled={loading}
                  type="submit"
                  className="bg-[#C19A6B] py-3 md:py-[14px] px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
                >
                  Next
                </button>
              ) : (
                <button disabled={loading} className="bg-[#C19A6B] animate-pulse py-3 md:py-[14px] px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4">
                  Loading...
                </button>
              )}
            </form>
          </div>

          <div className="hidden lg:block">
            <img src="/assets/icons/profile.svg" alt="" />
          </div>
        </div>
      </div>

      {/* modal  */}
      <dialog id="my_modal_3" className="modal p-8 !rounded-lg w-[50%]">
        <div className="modal-box bg-white">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-8 top-8 text-[21px]">
              <img src="/assets/icons/close-modal.svg" alt="" />
            </button>
          </form>
          <h3 className="font-semibold text-lg">Verify Email</h3>
          <p className="py-4">
            An email is sent to the address you entered. Enter the code in the
            email to verify your email address.
          </p>
          <EmailVerificationModal email={email} />
        </div>
      </dialog>
    </div>
  );
}
