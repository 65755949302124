import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ProductBoosterModal from "./ProductBoosterModal";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { vendorProductBooster } from "../../../../store/vendorslice";
import moment from "moment";
import Loader from "../../../Common/Loader";

export default function ProductBooster() {
  const [activeRole, setActiveRole] = useState("All");
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const { message, data, error,loading } = useSelector((state) => state.admin);
  const filteredProducts =
    activeRole === "All"
      ? orders
      : orders.filter((user) => user.status === activeRole);

  useEffect(() => {
    dispatch(vendorProductBooster());
  }, []);

  // Pagination control
  const totalPagess = Math.ceil(orders.length / itemsPerPage);
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(vendorProductBooster({ arg1: "page", arg2: page }));
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, orders.length);
  const paginatedProducts = orders.slice(startIndex, endIndex);

  const shouldShowPagination = orders.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (data?.Boosters_pagination?.total) {
      let totalLength = data.Boosters_pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    console.log(totalNumberOfPages);
    const pages = [];

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 rounded ${
            currentPage === i
              ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
              : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
          }`}
        >
          {i}
        </button>
      );
    }
    console.log(pages);
    return <div className="pagination">{pages}</div>;
  }
  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solution",
              path: "/vendor/booster/product-booster",
            },
            {
              name: "Product Booster",
              path: "/vendor/booster/product-booster",
            },
          ]}
        />

        <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full">
          <div className="w-full md:w-[60%] flex flex-col gap-2 bg-[#C19A6B] p-3 md:p-6 rounded-xl  ">
            <p className="font-semibold text-base  text-white tracking-widest">
              Performance Summary
            </p>
            <p className="font-[400] text-xs  text-white tracking-wide">
              Summary of last 30 days of ads performance.
            </p>

            <div className="flex justify-between py-2 px-2 md:px-6 bg-white rounded-xl gap-2 w-full">
              <span className="w-full flex flex-col items-center justify-center border-r-2 border-[#EBF0ED] px-2">
                <p className="font-extrabold text-lg lg:text-3xl">$200</p>
                <p className="font-semibold text-[10px] lg:text-xs text-[#6B6B6B] text-nowrap">
                  Total Spend
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center border-r-2 border-[#EBF0ED] ">
                <p className="font-extrabold text-lg lg:text-3xl">1982</p>
                <p className="font-semibold text-[10px] lg:text-xs text-[#6B6B6B]">
                  Clicks
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center  border-r-2 border-[#EBF0ED] ">
                <p className="font-extrabold text-lg lg:text-3xl">3.12</p>
                <p className="font-semibold text-[10px] lg:text-xs text-[#6B6B6B]">
                  ROI
                </p>
              </span>
              <span className="w-full flex flex-col items-center justify-center ">
                <p className="font-extrabold text-lg lg:text-3xl">$1200</p>
                <p className="font-semibold text-[10px] lg:text-xs text-[#6B6B6B]">
                  PROFIT
                </p>
              </span>
            </div>
          </div>

          <div className="w-full md:w-[40%] flex items-center justify-center gap-3 border rounded-lg border-[#EBF0ED] p-2 ">
            <div className="w-[30%] md:w-[40%]">
              <img
                className="w-24 h-24 md:w-36 md:h-36"
                src="/assets/images/undraw_outer_space_re_u9vd 1.svg"
                alt=""
              />
            </div>
            <div className="w-[70%] md:w-[60%] flex flex-col gap-2 md:py-2">
              <h2 className="text-sm md:text-base font-semibold">
                Boost Your Sales!
              </h2>
              <span className="text-xs md:text-sm font-[400]">
                Boost your sales with our product ads or create your own custom
                ads that suits you.
              </span>
              <div className="w-full flex justify-end">
                <Link
                  to="/vendor/booster/product-booster/custom-ad"
                  className="bg-[#C19A6B] text-sm md:text-base px-5 md:px-9 rounded-lg py-[5px] md:py-[10px] text-white"
                >
                  Create Ad
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* preview */}
        <div className=" md:py-4 ">
          <div className="flex  gap-2 md:gap-10">
            {/* 1st card */}
            {data?.Defaults?.length > 0
              ? data?.Defaults?.map((item) => {
                  return (
                    <div
                      onClick={() =>
                        navigate(
                          `/vendor/booster/product-booster/pre-made-ad/${item.id}?package_name=${item.package_name}&uid=${item.uid}&name=${item.name}&time_span=${item.time_span}&price=${item.price}`
                        )
                      }
                      className="cursor-pointer border w-full py-6 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-5 px-4 border-[#EBF0ED] rounded-2xl"
                    >
                      <img
                        src="/assets/icons/rocket.svg"
                        className="w-10 h-10 md:w-20 md:h-20"
                        alt=""
                      />
                      <div className="flex flex-col items-center md:items-start gap-1">
                        <span className="text-sm md:text-[1.3rem] text-[#18120F] font-semibold">
                          {item.package_name}
                        </span>
                        <span className="text-[#C19A6B] text-center md:text-start text-xs md:text-base font-bold tracking-wide">
                          ${item.price} per day for {item.time_span} day
                        </span>
                      </div>
                    </div>
                  );
                })
              : "loading"}
          </div>
        </div>

        <div className="flex flex-col gap-3 md:mt-3">
          <h2 className="text-sm md:text-base font-semibold">
            Ads History List
          </h2>
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full  px-8 py-2 md:py-3 text-xs md:text-sm rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px]"
              type="search"
              placeholder="Search ad…"
            />
          </div>
        </div>

        <div className="flex items-center justify-between md:mt-2">
          <div className="flex gap-6">
            <NavLink
              onClick={() => {
                setActiveRole("All");
                dispatch(vendorProductBooster());
              }}
              className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
                activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
              }`}
            >
              All Ad
              <span
                className={`text-xs md:text-sm font-semibold py-1 md:py-2 px-[5px] md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
                }`}
              >
                {data.boostersPendingCount +
                  data.boostersApprovedCount +
                  data.boostersRejectedCount +
                  data.boostersPausedCount +
                  data.boostersEndedCount}
              </span>
            </NavLink>

            <NavLink
              onClick={() => {
                setActiveRole("Active");
                dispatch(
                  vendorProductBooster({ arg1: "status", arg2: "active" })
                );
              }}
              className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
                activeRole === "Active"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              ACTIVE
              <span
                className={`text-xs md:text-sm font-semibold py-1 md:py-2 px-[5px] md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "Active"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data.boostersApprovedCount}
              </span>
            </NavLink>

            <NavLink
              onClick={() => {
                setActiveRole("Ended");
                dispatch(
                  vendorProductBooster({ arg1: "status", arg2: "rejected" })
                );
              }}
              className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
                activeRole === "Ended"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              ENDED
              <span
                className={`text-xs md:text-sm font-semibold py-1 md:py-2 px-[5px] md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "Ended"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data.boostersRejectedCount}
              </span>
            </NavLink>
          </div>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[568px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left">AD NAME</span>
                <span className="w-[16%] text-left">STATUS</span>
                <span className="w-[16%] text-left">DAILY BUDGET</span>
                <span className="w-[16%] text-left">START DATE</span>
                <span className="w-[16%] text-left">END DATE</span>
                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              <div className="w-full">
                {loading ? (
                  <div className="flex justify-center py-4">
                    <Loader />
                  </div>
                ) : data?.Boosters?.length > 0 ? (
                  data?.Boosters?.map((order) => {
                    return (
                      <div
                        key={order.id}
                        className="flex text-[10px] md:text-sm items-center font-semibold justify-between text-[#18120F] p-2 md:p-4 border-b"
                      >
                        <span className="w-[20%] text-nowrap text-left">
                          {order.name}
                        </span>
                        <div className="w-[16%] text-left">
                          <span
                            // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                            className={`${
                              order.status === "1"
                                ? "bg-[#F6FFF7] text-[10px] md:text-sm py-1 px-2 md:px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                                : order.status === "2"
                                ? "bg-[#F1F7FF] text-[10px] md:text-sm py-1 px-2 md:px-3 border border-[#44A6E9] text-[#44A6E9] rounded-lg"
                                : order.status === "0"
                                ? "bg-[#FFFBF0] text-[10px] md:text-sm py-1 px-2 md:px-3 border border-[#FEC600] text-[#FEC600] rounded-lg"
                                : "bg-[#FFF3F4] text-[10px] md:text-sm py-1 px-2 md:px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                            }`}
                          >
                            {order.status === "1"
                              ? "Resolved"
                              : order.status === "2"
                              ? "Rejected"
                              : "Pending"}
                          </span>
                        </div>
                        <span className="w-[16%] text-left">
                          ${order.price}{" "}
                        </span>
                        <span className="w-[16%] text-left ">
                          {moment(order.start_date).format("DD MM YYYY")}
                        </span>
                        <span className="w-[16%] text-left">
                          {moment(order.end_date).format("DD MM YYYY")}
                        </span>

                        <div className="w-[10%] text-left">
                          <div className="flex gap-2 ">
                            <Link>
                              <img
                                src="/assets/icons/manage-products/view.svg"
                                alt=""
                              />
                            </Link>

                            {/* <Link to={"/vendor/product-booster/custom-ad/"+order.id}>
                      <img
                        src="/assets/icons/manage-products/edit.svg"
                        alt=""
                      />
                    </Link> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center font-bold tracking-wide py-4">
                    No Product booster Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Pagination */}

        <div className="flex flex-col md:flex-row justify-between items-center">
          <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
            SHOWING {startIndex + 1} -{" "}
            {Math.min(startIndex + itemsPerPage, orders.length)} OF{" "}
            {orders.length} RESULTS
          </span>
          <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
            {/* Previous Button */}
            <div
              className="py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <svg
                className={`${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="6"
                height="12"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#828282"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {/* Page Numbers */}
            <div className="flex items-center gap-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .slice(0, 3)
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`py-[6px] px-[12px] md:py-[10px] md:px-[14.5px] text-lg   rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white rounded-xl border border-[#EBF0ED]"
                        : " bg-[#FAFAFA] text-[#828282] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
            </div>

            {/* Next Button */}
            <div
              className="py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <svg
                className={`${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="6"
                height="12"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#828282"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <ProductBoosterModal />
    </>
  );
}
