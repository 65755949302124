import React from "react";
import BoosterPerformance from "../../../../components/Booster/Marketing-Solutions/Booster-Performance-Components/BoosterPerformance";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";

export default function BoosterPerformancePage() {
  return (
    <>
      <BoosterLayout>
        <BoosterPerformance />
      </BoosterLayout>
    </>
  );
}
