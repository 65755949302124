import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";
import TicketForm from "../../../components/MyShop/Seller-Support-Components/TicketForm";

export default function TicketFormPage() {
  return (
    <>
      <MyShopLayout>
        <TicketForm />
      </MyShopLayout>
    </>
  );
}
