import React from "react";
import BreadCrums from "../../Common/BreadCrums";

export default function ContactUs() {
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-5">
        <BreadCrums
          breadCrum={[
            {
              name: "Seller Support",
              path: "/vendor/my-shop/help-center",
            },
            {
              name: "Contact Us",
              path: "/vendor/my-shop/contact-us",
            },
          ]}
        />
        {/* Contacts */}
        <div className="md:px-12">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            Contact
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 ">
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Chat-Bubble.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Chat With Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  10 AM - 6 PM (Mon-Sat)
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Phone--Streamline.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Call Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  123-456-7890123
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Mail-Send.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Email Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  contact@wizalot.com
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Cyborg-2.svg"
                className="w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F] text-nowrap">
                  Tell Us Your Problem
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  Assistance 24/7
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* question */}
        <div className="md:px-12 mt-3">
          <div className="flex flex-col gap-4 p-7 rounded-xl bg-[#F1F1F1]">
            <h2 className="text-sm md:text-base lg:text-xl font-semibold text-[#18120F]">
              Have any questions, complaints or feedback?
            </h2>
            <span className="text-xs md:text-base font-[400] text-[#18120F]">
              Please visit our{" "}
              <span className="text-[#C19A6B] border-b border-[#C19A6B]">
                Help Center
              </span>{" "}
              to view answers to the most frequently asked questions.
            </span>
            <span className="text-xs md:text-base font-[400] text-[#18120F]">
              If you are unable to find the solution to your question, you can
              also contact us through any of the above ways.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
