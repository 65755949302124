import LoginPage from "../../src/pages/LoginPage"
import ForgotPasswordPage from "../pages/Sign-up&Sign-In/ForgotPasswordPage";
import NewPasswordPage from "../pages/Sign-up&Sign-In/NewPasswordPage";
const authRoutes = [
  {
    index: true,
    element: <LoginPage/>,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage/>,
  },
  {
    path: "/new-password",
    element: <NewPasswordPage/>,
  },
 
];

export default authRoutes;
