import React from "react";
import BoosterLayout from "../../../../Vender-Dashboard-Layout/BoosterLayout";
import PremiumProductYourBid from "../../../../components/Booster/Marketing-Solutions/Premium-Product-Components/PremiumProductYourBid";

export default function PremiumProductYourBidsPage() {
  return (
    <>
      <BoosterLayout>
        <PremiumProductYourBid />
      </BoosterLayout>
    </>
  );
}
