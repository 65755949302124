import React from "react";
import MyShopLayout from "../../../Vender-Dashboard-Layout/MyShopLayout";

import ProfileSetting from "../../../components/MyShop/Vendor-Profile-Components/ProfileSetting";

export default function ProfileSettingPage() {
  return (
    <>
      <MyShopLayout>
        <ProfileSetting />
      </MyShopLayout>
    </>
  );
}
